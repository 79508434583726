import Service from './Service';

class TagService extends Service {

  async list() {
    try {
      return await this.http.get('/tags');
    } catch (error) {
      return error.response;
    }
  }
}

export default TagService;