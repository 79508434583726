export const UNITS = ["Bag", "Box", "Case", "Each", "Hours", "Pack"];
export const PRICE_VARIANTS = [
  "General",
  "Cash",
  "Waiver",
  "Iowa",
  "Arkansas",
  "MoHealth",
  "Medicare",
];
