import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  IconButton,
  AppBar,
  Toolbar,
  TextareaAutosize
} from "@material-ui/core";
// import { Resizable } from 'react-resizable';
import {Row, RowsWrapper} from "react-grid-resizable/lib";
import CloseIcon from "@material-ui/icons/Close";
import { Errors } from "../../../components";
import { compose } from "recompose";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  updateOrder,
  getOrder,
  addCallNote,
  getAllCallNotes,
} from "../../../redux/actions/orderActions";
import {
  getPatient,
  updatePatient,
} from "../../../redux/actions/patientActions";
import { setLoading } from "../../../redux/actions/globalActions";
import {
  getCallCustomerWEligibility,
  resetCallCustomerWEligibilityOcurrence,
} from "../../../redux/actions/callCustomerActionsWCheckEligibilityActions";
import styles from "./styles";
import ServicesTab from "./ServicesTab";
import _get from "lodash/get";

const initialState = {
  ocurrence: {},
  form: {
    general_notes: "",
    office_notes: "",
    patient_notes: "",
  },
  original_general_notes: "",
  original_office_notes: "",
  original_patient_notes: "",
  callNote: "",
  openCallNoteDialog: false,
  // openModal: false,
};

class OrderEditComponent extends React.Component {
  state = initialState;

  componentDidMount = async () => {
    this.props.setLoading(true);
    try {
      await this.fetchAllOrderData();
    } catch (error) {
      console.log(error);
    }
    this.props.setLoading(false);
    this.UpdateTextArea()
  };

   UpdateTextArea = () => {
    let textArea = document.querySelectorAll("textarea")
    textArea.forEach((area) => {
      area.style.resize = "vertical"
    })
  }

  fetchAllOrderData = async () => {
    const { data: ocurrence } = await this.props.getCallCustomerWEligibility(
      this.props.match.params.id
    );

    const { data: order } = await this.props.getOrder(
      _get(ocurrence, "order.id")
    );
    await this.props.getAllCallNotes(ocurrence.id);
    const { data: patient = {} } = await this.props.getPatient(
      order.patient_id
    );

    this.setState((prevState) => {
      return {
        ocurrence,
        form: {
          ...prevState.form,
          general_notes: patient.notes || "",
          office_notes: order.office_notes || "",
          patient_notes: order.patient_notes || "",
        },
      };
    });

    this.setState({
      "original_office_notes": order.office_notes || "",
      "original_patient_notes": order.patient_notes || "",
      "original_general_notes": patient.notes || "",
    })

  };

  updateOrderNote = (name) => async () => {
    if ( this.state.original_patient_notes !== this.state.form.patient_notes ||
         this.state.original_office_notes !== this.state.form.office_notes){
      try {
        // const { orderId } = this.props;
        const orderId = _get(this.state.ocurrence, "order.id");
        const form = { [name]: this.state.form[name] };
        const { status, data } = await this.props.updateOrder(orderId, form);

        if (status === 200) {
          this.setState({
            form: {
              ...this.state.form,
              office_notes: data.office_notes || "",
              patient_notes: data.patient_notes || "",
            },
          });
          this.setState({
            original_office_notes: data.office_notes || "",
            original_patient_notes: data.patient_notes || "",
          })
          this.props.enqueueSnackbar("Updated successfully!", {
            variant: "success",
          });
        } else {
          this.props.enqueueSnackbar("The request could not be processed!", {
            variant: "error",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.props.setLoading(false);
        this.UpdateTextArea()
      }
    }
  };

  updateGeneralNote = async () => {
    console.log(this.state.original_general_notes);
    console.log(this.state.form.general_notes);
    if( this.state.original_general_notes !== this.state.form.general_notes){
      try {
        const form = { notes: this.state.form.general_notes };
        console.log(this.state);
        const {
          status,
          data: { notes: general_notes = "" } = {},
        } = await this.props.updatePatient(
            _get(this.state.ocurrence, "order.patient_id"),
            form
        );

        if (status === 200) {
          console.log("RESPUESTA", general_notes)
          this.setState({
            form: {
              ...this.state.form,
              general_notes,
            },
          });
          this.setState({ original_general_notes: general_notes });
          this.props.enqueueSnackbar("Updated successfully!", {
            variant: "success",
          });
        } else {
          this.props.enqueueSnackbar("The request could not be processed!", {
            variant: "error",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.props.setLoading(false);
        this.UpdateTextArea()
      }
    }
  };

  handleChangeNotes = (name) => (e) => {
    const value = e.target.value;
    console.log(value);
    this.setState((prevState, props) => {
      console.log(value);
      return {
        form: {
          ...prevState.form,
          [name]: value,
        },
      };
    });
  };

  handleCloseCallNoteDialog = () =>
    this.setState({ openCallNoteDialog: false, callNote: "" });

  handleOpenCallNoteDialog = () => this.setState({ openCallNoteDialog: true });

  handleAddCallNote = async () => {
    this.props.setLoading(true);
    this.handleCloseCallNoteDialog();
    try {
      const form = {
        body: this.state.callNote,
        ocurrence: this.state.ocurrence.id,
      };
      const userId = _get(this.state.ocurrence, "order.user_creator_id");
      const response = await this.props.addCallNote(userId, form);
      // const response = await this.props.addCallNote(this.props.userId, form);
      if (response.status === 201) {
        this.props.enqueueSnackbar("The note was added successfully", {
          variant: "success",
        });
        this.props.getAllCallNotes(this.state.ocurrence.id);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
      this.UpdateTextArea()
    }
  };

  handleClose = () => {
    this.props.resetCallCustomerWEligibilityOcurrence();
    this.setState(initialState);
    this.props.history.goBack();
  };

  getPatientName = (ocurrence) => {
    const { first_name = "", last_name = "" } = _get(
      ocurrence,
      "order.patient",
      {}
    );

    return `${first_name} ${last_name}`;
  };

  render() {
    const {
      openCallNoteDialog,
      form: { general_notes, office_notes, patient_notes }, ocurrence,} = this.state;
    const { classes, loading, callPatientNotes = [] } = this.props;
    return (
      <Dialog fullScreen open onClose={() => {}}>
        <AppBar position="relative">
          <Toolbar className={classes.toolbarModal}>
            {/* <Typography variant="h5">Order Service Detail</Typography> */}
            <Grid container justify="space-between">
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  Patient Name: {this.getPatientName(ocurrence)}{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  Date of Service:{" "}
                  {_get(ocurrence, "date", "")
                    ? moment(_get(ocurrence, "date", "")).format("MM/DD/YYYY")
                    : ""}{" "}
                </Typography>
              </Grid>
            </Grid>
            <IconButton
              edge="start"
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{ marginTop: 16 }}
            alignItems="flex-start"
          >
            <Grid item xs={4} className={classes.callNoteContainer}>
              <div className={classes.paper}>
                <Grid item xs={12} className={classes.divCallNotes}>
                  <Typography variant="h6">Call Notes</Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={loading}
                    onClick={this.handleOpenCallNoteDialog}
                  >
                    Add
                  </Button>
                </Grid>
                <RowsWrapper>
                <Row
                  xs={12}
                  style={{ height: 81, overflowY: "auto", marginTop: 12 }}
                >
                  <List>
                    {(() => {
                      if (loading) {
                        return null;
                      }
                      return callPatientNotes.map((callNote) => (
                        <React.Fragment key={callNote.id}>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary={callNote.body}
                              secondary={
                                <Typography
                                  component="p"
                                  variant="body2"
                                  className={classes.inlineText}
                                >
                                  <span className={classes.username}>
                                    {callNote.user_stamped.username}
                                  </span>
                                  <span>
                                    {moment(callNote.created).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </span>
                                </Typography>
                              }
                            />
                          </ListItem>
                          <Divider component="li" />
                        </React.Fragment>
                      ));
                    })()}
                  </List>
                </Row>
                <Row>
                <Divider />
                  <Grid container item xs={12} style={{ marginTop: 32 }}>
                  <Grid item xs={6}>
                    <Typography variant="h6">General Notes</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: -16 }}>
                  <TextField
                    name="general_notes"
                    margin="normal"
                    value={general_notes}
                    onChange={this.handleChangeNotes("general_notes")}
                    onBlur={this.updateGeneralNote}
                    label=""
                    fullWidth
                    multiline
                    disabled={loading}
                    rows={20}
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justify="space-between"
                  style={{ marginTop: 32 }}
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Office Notes</Typography>
                  </Grid>
                  {/* <Grid item xs={6} container justify="flex-end">
                    <Button
                      type="submit"
                      onClick={this.updateOrderNote("office_notes")}
                      variant="outlined"
                      color="primary"
                      disabled={loading}
                    >
                      UPDATE
                    </Button>
                  </Grid> */}
                  <Grid item xs={12} style={{ marginTop: -28 }}>
                    <TextField
                      name="office_notes"
                      margin="normal"
                      value={office_notes}
                      onChange={this.handleChangeNotes("office_notes")}
                      onBlur={this.updateOrderNote("office_notes")}
                      label=""
                      fullWidth
                      multiline
                      disabled={loading}
                      rows={1}
                    />
                  </Grid>
                </Grid>
                <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    style={{ marginTop: 32 }}
                >
                  <Grid item xs={6}>
                    <Typography variant="h6">Patient Notes</Typography>
                  </Grid>
                  {/* <Grid item xs={6} container justify="flex-end">
                    <Button
                      type="submit"
                      onClick={this.updateOrderNote("office_notes")}
                      variant="outlined"
                      color="primary"
                      disabled={loading}
                    >
                      UPDATE
                    </Button>
                  </Grid> */}
                  <Grid item xs={12} style={{ marginTop: -28 }}>
                    <TextField
                        name="patient_notes"
                        margin="normal"
                        value={patient_notes}
                        onChange={this.handleChangeNotes("patient_notes")}
                        onBlur={this.updateOrderNote("patient_notes")}
                        label=""
                        fullWidth
                        multiline
                        disabled={loading}
                        rows={1}
                    />
                  </Grid>
                </Grid>
                </Row>
                </RowsWrapper>
                <Dialog
                  open={openCallNoteDialog}
                  onClose={this.handleCloseCallNoteDialog}
                  aria-labelledby="form-dialog-title"
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle id="form-dialog-title">
                    New Call Note
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      value={this.state.callNote}
                      onChange={(e) =>
                        this.setState({ callNote: e.target.value })
                      }
                      label="Content"
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.handleCloseCallNoteDialog}
                      color="primary"
                      disabled={loading}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={this.handleAddCallNote}
                      color="primary"
                      disabled={loading || this.state.callNote === ""}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </Grid>
            <Grid item xs={8} className={classes.containerDetail}>
              <div className={classes.paper}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="flex-end"
                  style={{ marginBottom: 16 }}
                >
                  <Typography variant="h5">Order Details</Typography>
                  {_get(this.state.ocurrence, "order.patient_id") ? (
                    <RouterLink
                      to={{
                        pathname: `/patients/${_get(
                          this.state.ocurrence,
                          "order.patient_id"
                        )}`,
                        state: "order_edit_component",
                      }}
                      style={{ color: "#3f51b5" }}
                    >
                      Go to Patient
                    </RouterLink>
                  ) : null}
                </Grid>

                <br />
                <ServicesTab
                  // services={_get(ocurrence, "services", [])}
                  loading={loading}
                  ocurrenceId={ocurrence.id}
                  shipped={ocurrence.shipped}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    currentForm: state.global.currentForm,
    order: state.orders.order,
    errors: new Errors(state.orders.errors),
    callPatientNotes: state.orders.callPatientNotes,
  };
};
const mapDispatchToProps = {
  setLoading,
  getOrder,
  updateOrder,

  addCallNote,
  getAllCallNotes,
  getPatient,
  updatePatient,
  getCallCustomerWEligibility,
  resetCallCustomerWEligibilityOcurrence,
};

OrderEditComponent.propTypes = {};
OrderEditComponent.defaultProps = {};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "OrderEditComponent" }),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderEditComponent);
