import React from "react";
import { connect } from "react-redux";
import {
  Button,
  DialogActions,
  TextField,
  Grid,
  DialogTitle,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { updateService } from "../../../../redux/actions/remittanceAction";
import { useSnackbar } from "notistack";

function EditServiceModal({ open, onClose: _handleClose, service, ...props }) {
  //fast fix, legacy code
  const { values: formValues, setFieldValue } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();

  const _handleSubmit = async (body, { setSubmitting }) => {
    try {
      const response = await props.updateService(service.id, body);

      if (response.status === 200) {
        const {
          data: { sale_price, amount_remmited },
        } = response;

        setFieldValue(
          "services",
          formValues.services.map((_service) =>
            _service.id === service.id
              ? { ..._service, sale_price, amount_remmited }
              : _service
          )
        );

        enqueueSnackbar("Update successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        _handleClose();
      }
    } catch (error) {
      enqueueSnackbar("Updated failed.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      onSubmit={_handleSubmit}
      enableReinitialize
      initialValues={{
        sale_price: service.sale_price,
        amount_remmited: service.amount_remmited,
      }}
      validationSchema={Yup.object().shape({
        sale_price: Yup.mixed().required("Sale price is required"),
        amount_remmited: Yup.mixed().required("Quantity is required"),
      })}
    >
      {(props) => {
        const {
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          dirty,
          errors,
          isValid,
          touched,
          isSubmitting,
        } = props;
        return (
          <Dialog
            open={open}
            onClose={_handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Update Original Amount
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.amount_remmited}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Quantity"
                    fullWidth
                    name="amount_remmited"
                    type="number"
                    margin="normal"
                    error={
                      !!touched.amount_remmited && !!errors.amount_remmited
                    }
                    helperText={
                      !!touched.amount_remmited &&
                      !!errors.amount_remmited &&
                      errors.amount_remmited
                    }
                    disabled={isSubmitting}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.sale_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Sale Price"
                    fullWidth
                    name="sale_price"
                    type="number"
                    margin="normal"
                    error={!!touched.sale_price && !!errors.sale_price}
                    helperText={
                      !!touched.sale_price &&
                      !!errors.sale_price &&
                      errors.sale_price
                    }
                    disabled={isSubmitting}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={_handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={!dirty || !isValid || !touched || isSubmitting}
                color="primary"
                autoFocus
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  updateService,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditServiceModal);
