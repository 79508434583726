import RemmitanceRevertService from "../../services/RemmitanceRevertService";
import {
    GET_REVERT_REMITTANCES,
    DELETE_REMITTANCE_PAYMENT,
    SET_REMITTANCE_FILTERS,
    ON_LOADING, DELETE_VENDOR,
} from "../actionTypes";
import {setLoading} from "./globalActions";

const remmitanceRevertService = new RemmitanceRevertService();

export const getRevertRemittances = () => async (dispatch, getState) => {
    try {
        dispatch({ type: ON_LOADING, loading: true });

        const response = await remmitanceRevertService.getAll(
            getState().remittance.filters
        );
        const { status, data } = response;
        console.log("DATA", data);
        if (status === 200) {
            const { results, ...meta } = data;

            dispatch({
                type: GET_REVERT_REMITTANCES,
                payload: results,
            });
            dispatch({
                type: SET_REMITTANCE_FILTERS,
                payload: {
                    // ...meta,
                    count: meta.count,
                    page_size: meta.page_size,
                    page: meta.current,
                },
            });
        }
        dispatch({ type: ON_LOADING, loading: false });

        return response;
    } catch (error) {
        dispatch({ type: ON_LOADING, loading: false });
        console.log(error);
    }
};

export const deleteOcurrencePayment = id => {
    return async dispatch => {
        dispatch(setLoading(true));

        try {
            const response = await remmitanceRevertService.delete(id);

            // if (response.status === 204) {
            //     dispatch({ type: GET_REVERT_REMITTANCES, payload: id });
            // }
            return response;
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(setLoading(false));
        }
    };
};




export const setRemittanceFilters = (payload) => ({
    type: SET_REMITTANCE_FILTERS,
    payload,
});


