const styles = theme => ({
  chip: {
    margin: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(1)
  },
  red: {
    backgroundColor: '#F44336'
  },
  green: {
    backgroundColor: '#4CAF50'
  },
  downloadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  tableContainer:{
    overflow: 'auto',
  },
  
});

export default styles;