import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Button,
  InputBase,
  IconButton
} from "@material-ui/core";
import { Edit, Delete, Search } from "@material-ui/icons";
import {
  Layout,
  SimpleBreadcrumbs,
  Panel,
  DialogDelete,
  TextEmpty
} from "../../../components";

import { setLoading } from "../../../redux/actions/globalActions";
import styles from "./styles";

import EnhancedTableHeader from "./EnhancedTableHeader";
import { getVendors, deleteVendor } from "../../../redux/actions/vendorActions";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Vendors", to: null }
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const columns = [
  { id: "id", numeric: false, label: "ID" },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name"
  },
  { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
  { id: "actions", numeric: false, disablePadding: false, label: "Actions" }
];

class List extends React.Component {
  state = {
    order: "asc",
    orderBy: "calories",
    selected: [],
    data: [],
    page: 0,
    rowsPerPage: 25,
    search: "",
    open: false,
    vendorId: ""
  };

  componentDidMount = async () => {
    this.props.setLoading(true);

    try {
      const response = await this.props.getVendors();
      if (response.status !== 200) {
        this.props.history.push("404")
      }
    } catch (error) {
      this.props.history.push("404")
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleDelete = async () => {
    this.props.setLoading(true);
    this.setState({ open: false });
    try {
      const { status } = await this.props.deleteVendor(this.state.vendorId);

      if (status === 204) {
        this.props.enqueueSnackbar("The vendor has been deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" }
        });
        return;
      }
      if (status === 200) {
        this.props.enqueueSnackbar("The vendor cannot be deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" }
        });
        return;
      }
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  handleSearch = event => {
    this.setState({ search: event.target.value });
  };

  filter = (vendors, keyword) => {
    if (keyword === "") return vendors;

    const fields = ["name", "phone"];
    const regex = new RegExp(keyword, "i");

    return vendors.filter(obj => {
      return (
        fields.filter(field => {
          return typeof obj[field] === "string" && obj[field].match(regex);
        }).length > 0
      );
    });
  };

  render() {
    const { classes, vendors } = this.props;
    const { order, orderBy, rowsPerPage, page, search, open } = this.state;
    const data = vendors;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, vendors.length - page * rowsPerPage);

    return (
      <Layout title="Vendors">
        <DialogDelete
          item="vendor"
          open={open}
          closeModal={() => this.setState({ open: false })}
          remove={this.handleDelete}
          isDelivery={false}
        />
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <div className={classes.headTable}>
            <Link component={RouterLink} to="/vendors/create">
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
              >
                New Vendor
              </Button>
            </Link>

            <div className={classes.grow} />
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                name="search"
                placeholder="Search..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                autoFocus={true}
                value={this.state.search}
                onChange={this.handleSearch}
              />
            </div>
          </div>

          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHeader
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {stableSort(
                  this.filter(data, search),
                  getSorting(order, orderBy)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(vendor => {
                    return (
                      <TableRow hover key={vendor.id}>
                        <TableCell>{vendor.id}</TableCell>
                        <TableCell>{vendor.name}</TableCell>
                        <TableCell>{vendor.phone}</TableCell>

                        <TableCell>
                          <div className={classes.actionButtons}>
                            <Link
                              component={RouterLink}
                              to={`/vendor/${vendor.id}`}
                            >
                              <IconButton aria-label="Edit" color="primary">
                                <Edit />
                              </IconButton>
                            </Link>

                            <IconButton
                              color="secondary"
                              aria-label="Delete"
                              onClick={() =>
                                this.setState({
                                  open: true,
                                  vendorId: vendor.id
                                })
                              }
                            >
                              <Delete />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TextEmpty
              itemName={"VENDORS"}
              empty={data.length === 0}
            />
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Panel>
      </Layout>
    );
  }
}

List.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    loading: state.global.loading,
    vendors: state.vendors.list
  };
};

const mapDispatchToProps = { getVendors, deleteVendor, setLoading };

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "VendorList" }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(List);
