const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontSize: 60
  },
  link: {
    textDecoration: 'none'
  }
});

export default styles;