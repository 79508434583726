import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) =>
  createStyles({
    PaginationWrapper: {
      position: "absolute",
      bottom: 4,
      left: 0,
      right: 0,
    },
    DocumentWrapper: {
      overflowX: "auto",
      width: "100%",
    },
    PageWrapper: { border: "1px solid black" },
  })
);

function PlaceHolderWrapper({ children }) {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: 400 }}
    >
      {children}
    </Grid>
  );
}

function PdfViewer({ file }) {
  const classes = useStyles();
  const wrapperRef = useRef();
  const [numPages, setNumPages] = useState(null);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(1);

  const _handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const _handleLoadError = () => {
    setLoading(false);
  };
  const _handleSourceError = () => {
    setError(error);
    setLoading(false);
  };

  const _handlePageChange = (e, newPage) => {
    setPageNumber(newPage);
  };

  const documenWidth = useMemo(() => {
    if (wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.getBoundingClientRect().width;
      return wrapperWidth < 900 ? +wrapperWidth.toFixed(2) : 900;
    }
    return null;
  }, [wrapperRef.current]);

  const _renderError = (
    <PlaceHolderWrapper>
      <Typography variant="h6" align="center">
        PDF preview error
      </Typography>
    </PlaceHolderWrapper>
  );

  const _renderLoading = (
    <PlaceHolderWrapper>
      <CircularProgress />
    </PlaceHolderWrapper>
  );

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ position: "relative" }}
      ref={wrapperRef}
    >
      {loading || !documenWidth ? (
        _renderLoading
      ) : (
        <>
          <Grid container justify="center" className={classes.DocumentWrapper}>
            <Document
              key={documenWidth}
              file={file}
              onLoadError={_handleLoadError}
              onLoadSuccess={_handleDocumentLoadSuccess}
              onSourceError={_handleSourceError}
              error={_renderError}
              loading={_renderLoading}
              renderMode="canvas"
            >
              <Page
                width={documenWidth}
                renderMode="canvas"
                pageNumber={pageNumber}
                className={classes.PageWrapper}
                scale={1}
              />
            </Document>
          </Grid>
          <Grid
            container
            justify="center"
            className={classes.PaginationWrapper}
          >
            <Pagination
              size="large"
              count={numPages}
              showFirstButton
              variant="outlined"
              color="primary"
              showLastButton
              page={pageNumber}
              onChange={_handlePageChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

PdfViewer.propTypes = { file: PropTypes.instanceOf(Blob) };

PdfViewer.defaultProps = {};

export default PdfViewer;
