import React from "react";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  MenuItem,
  Tabs,
  Tab,
  Fab,
} from "@material-ui/core";
import {
  SimpleBreadcrumbs,
  Panel,
  Layout,
  Errors,
  AutocompleteV2,
} from "../../../components";
import {
  setLoading,
  setHandleForm,
} from "../../../redux/actions/globalActions";
import {
  createOrder,
  getStatus,
  getDeliveries,
  setPatientIdOrigin,
} from "../../../redux/actions/orderActions";
import {
  getRawPatients,
  getPatient,
  setRawPatientFilters,
} from "../../../redux/actions/patientActions";
import { datePickerFormatToParseDate } from "../../../common/Helpers/DateHelper";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styles from "./styles";
import moment from "moment";
import { ArrowBack } from "@material-ui/icons";
import _debounce from "lodash/debounce";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Orders", to: "/orders" },
  { name: "Create Order", to: null },
];

class OrderCreate extends React.Component {
  state = {
    tab: 0,
  };
  componentDidMount = async () => {
    this.props.setLoading(true);
    try {
      if (this.props.patientIdOrigin !== ""){
        const { status, data } = await this.props.getPatient(this.props.patientIdOrigin);
        this.props.setRawPatientFilters({
            search: `${data.first_name} ${data.last_name}`,
            page: 1,
          });
        await this.fetchRawPatientsDelayed();
      }
      else {
        this.props.setRawPatientFilters({ search: "" });
        this.fetchRawPatients();
      }
      this.props.getStatus();
      this.props.getDeliveries();
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };
  componentWillUnmount() {
    this.props.setRawPatientFilters({
      search: "",
      page: 1,
    });
  }

  fetchRawPatients = () => {
    return this.props.getRawPatients();
  };

  fetchRawPatientsDelayed = _debounce((e) => {
    return this.fetchRawPatients();
  }, 1000);

  handleSearch = (_, value, reason) => {
    if (reason === "input") {
      this.props.setRawPatientFilters({
        search: value,
        page: 1,
      });
      this.fetchRawPatientsDelayed.cancel();
      this.fetchRawPatientsDelayed();
    }
  };

  handleSubmit = async (values, formikActions) => {
    const { setSubmitting, resetForm } = formikActions;
    this.props.setLoading(true);
    setSubmitting(true);
    try {
      const form = { ...values };
      form.expired_at = datePickerFormatToParseDate(
        form.expired_at || moment()
      );

      const { status, data } = await this.props.createOrder(form);

      if (status === 201) {
        resetForm();
        this.props.history.push(`/orders/${data.id}/edit`);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      this.props.setLoading(false);
    }
  };

  handleTab = (event, tab) => {
    this.setState({ tab });
  };
  form;

  render() {
    const {
      loading,
      rawPatients,
      deliveries,
      patientIdOrigin,
      classes,
    } = this.props;

    return (
      <Layout title="Create Order">
        <SimpleBreadcrumbs routes={breadcrumbs} />
        {patientIdOrigin ? (
          <Grid container justify="flex-end">
            <Fab
              variant="extended"
              aria-label="Back"
              color="primary"
              className={classes.buttonBack}
              onClick={() =>
                this.props.history.push(`/patients/${patientIdOrigin}`)
              }
            >
              <ArrowBack />
              Back to patient
            </Fab>
          </Grid>
        ) : null}
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            patient_id: "" || patientIdOrigin,
            status_id: "",
            expired_at: null,
            delivery_id: "",
            name: "",
            customer_approved: false,
            po_number: "",
          }}
          validationSchema={Yup.object().shape({
            patient_id: Yup.string().required("Patient is required"),
            status_id: Yup.string(),
            expired_at: Yup.mixed(),
            delivery_id: Yup.string().required("Delivery is required"),
            name: Yup.string().required("Order name is required"),
          })}
          enableReinitialize
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              errors,
              touched,
              dirty,
              isValid,
              handleChange,
              handleBlur,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Prompt
                  when={dirty}
                  message={() => {
                    this.props.setPatientIdOrigin("");
                    return "Are you sure you want to leave?, You will lose your changes";
                  }}
                />
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      { !patientIdOrigin ? (
                          <Field name="patient_id">
                            {(props) => {
                              const { field, form } = props;

                              return (
                                  <AutocompleteV2
                                      loading={loading}
                                      options={rawPatients.map((_patient) => ({
                                        ..._patient,
                                        full_name: `${_patient.first_name} ${_patient.last_name}`,
                                      }))}
                                      disabled={loading || !!patientIdOrigin}
                                      extractOptionLabel="full_name"
                                      extractOptionValue="id"
                                      textFieldProps={{
                                        label: "Patient",
                                        helperText:
                                            !!touched.patient &&
                                            !!errors.patient_id &&
                                            errors.patient_id,
                                        error:
                                            !!touched.patient_id && !!errors.patient_id,
                                        required: true,
                                      }}
                                      onInputChange={this.handleSearch}
                                      onBlur={() =>
                                          form.setFieldTouched(field.name, true, true)
                                      }
                                      value={field.value}
                                      onChange={(newValue) =>
                                          setFieldValue(field.name, newValue)
                                      }
                                  />
                              );
                            }}
                          </Field>
                      ):(
                          <TextField
                              name="patient_id"
                              value={parseInt(patientIdOrigin)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label="Patient"
                              error={!!touched.patient_id && !!errors.patient_id}
                              helperText={
                                !!touched.patient &&
                                !!errors.patient_id &&
                                errors.patient_id
                              }
                              fullWidth
                              select
                              required
                              disabled={loading || !!patientIdOrigin}
                              margin="normal"
                          >
                            {rawPatients.map((patient) => {
                              return (
                                  <MenuItem key={patient.id} value={patient.id}>
                                    {patient.first_name} {patient.last_name}
                                  </MenuItem>
                              );
                            })}
                          </TextField>
                      )}

                      <TextField
                          name="delivery_id"
                          value={values.delivery_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Delivery"
                          fullWidth
                          required
                          margin="normal"
                          error={!!touched.delivery_id && !!errors.delivery_id}
                          helperText={
                            !!touched.delivery_id &&
                            !!errors.delivery_id &&
                            errors.delivery_id
                          }
                          select
                      >
                        {deliveries.map((delivery) => {
                          return (
                              <MenuItem key={delivery.id} value={delivery.id}>
                                {delivery.name}
                              </MenuItem>
                          );
                        })}
                      </TextField>


                    </Grid>

                    <Grid container item xs={12} sm={6}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Order Name"
                          fullWidth
                          margin="normal"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          name="po_number"
                          value={values.po_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="PO Number"
                          fullWidth
                          margin="normal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Panel>
                <div style={{ height: 16 }} />
                <Panel>
                  <Tabs
                    value={this.state.tab}
                    onChange={this.handleTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                  >
                    <Tab label="Products" />
                    <Tab label="Services" />
                    <Tab label="Notes" />
                    <Tab label="Expirations" />
                  </Tabs>
                </Panel>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={loading || !dirty || !isValid || isSubmitting}
                  style={{ marginTop: 16, marginBottom: 16 }}
                >
                  Create Order
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    rawPatients: state.patients.rawList,
    rawFilters: state.patients.rawFilters,
    status: state.orders.status,
    deliveries: state.orders.deliveries,
    patientIdOrigin: state.orders.patientIdOrigin,
    errors: new Errors(state.orders.errors),
  };
};

const mapDispatchToProps = {
  setLoading,
  createOrder,
  getRawPatients,
  getPatient,
  setRawPatientFilters,
  getStatus,
  getDeliveries,
  setHandleForm,
  setPatientIdOrigin,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "OrderCreate" }),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderCreate);
