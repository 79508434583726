import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import _get from "lodash/get";
import {
  withStyles,
  Switch,
  Grid,
  IconButton,
  Button,
  InputBase,
  InputAdornment,
  Link,
} from "@material-ui/core";
import { Layout, SimpleBreadcrumbs, Panel } from "../../../components";
import {
  setLoading,
  fetchPatientStates,
} from "../../../redux/actions/globalActions";
import {
  getCallCustomers,
  updateCallCustomer,
  setEmptyCallCustomers,
  setFiltersCallCustomer,
} from "../../../redux/actions/callCustomerActions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import styles from "./styles";
import { Search, Today } from "@material-ui/icons";
import { getCallCustomer } from "./../../../redux/actions/callCustomerActions";
import TableResponsive from "../../../components/TableResponsive";
import Autocomplete from "../../../components/Autocomplete";
import ContactsModal from "./ContactsModal";
import _debounce from "lodash/debounce";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Call Patients", to: null },
];

class CallCustomersList extends React.Component {
  state = {
    // search: "",
    openModal: false,
    orderId: 0,
    ocurrenceId: 0,
    // patientId: 0,
    userId: "",
    servicesOcurrence: [],
    customerApproved: false,
    openContactsModal: false,
    currentOcurrence: {},
    currentContacts: [],
  };

  config = {
    columns: [
      {
        display_name: "Patient Name",
        name: "patient_full_name",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "patient_name",
      },
      {
        display_name: "Address State",
        name: "order.patient.state.name",
        type: "string",
        align: "right",
        sorted: true,
        sorted_name: "state",
      },
      {
        display_name: "Phone Number",
        name: "order.patient.phone",
        type: "string",
        style: { minWidth: 175 },
        align: "inherit",
        sorted: true,
        sorted_name: "phone",
      },
      {
        display_name: "Cell Phone Number",
        name: "order.patient.mobile",
        type: "string",
        style: { minWidth: 175 },
        align: "inherit",
        sorted: true,
        sorted_name: "cell_phone",
      },
      {
        display_name: "Contact Name",
        name: "order.patient.contact_person[0].contact_name",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <>
                {_get(
                  item,
                  "order.patient.contact_person[0].contact_name",
                  "-"
                )}
                {!!item.order.patient &&
                  !!item.order.patient.contact_person &&
                  !!(item.order.patient.contact_person.length >= 2) && (
                    <Link
                      href="javascript:void(0)"
                      aria-label="delete"
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        this.setState({
                          openContactsModal: true,
                          currentContacts: item.order.patient.contact_person,
                        });
                      }}
                    >
                      <br />
                      show more
                    </Link>
                  )}
              </>
            );
          },
          compare: () => 0,
        },
        align: "inherit",
        // sorted: true,
      },
      {
        display_name: "Contact Phone number",
        name: "order.patient.contact_person[0].contact_number",
        type: "string",
        style: { minWidth: 175 },
        align: "inherit",
        // sorted: true,
      },
      {
        display_name: "Contact Relation",
        name: "order.patient.contact_person[0].contact_relation",
        type: "string",
        align: "inherit",
        // sorted: true,
      },
      {
        display_name: "Order Number",
        name: "order.id",
        type: "string",
        align: "right",
        sorted: true,
        sorted_name: "order_number",
      },
      {
        display_name: "DOS",
        name: "date",
        type: "date",
        align: "right",
        sorted: true,
        sorted_name: "date",
      },
      {
        display_name: "Show detail",
        name: "date",
        type: "custom",
        align: "right",
        // sorted: true,
        custom: {
          render: (item, column) => {
            return (
              <Button
                onClick={this.handleOpen(
                  item
                  // item.order.patient_id,
                  // item.order.id,
                  // item.id,
                  // item.order.user_creator_id,
                  // item.services,
                  // item.customer_approved
                )}
              >
                Show detail
              </Button>
            );
          },
          compare: () => 0,
        },
      },
      {
        display_name: "Patient Approved",
        name: "order.id",
        type: "custom",
        align: "right",
        custom: {
          render: (item, column) => (
            <Switch
              checked={item.customer_approved}
              onChange={(e) => this.handleUpdate(e, item)}
              disabled={this.props.loading}
              color="primary"
            />
          ),
          compare: (firstValue, secondValue) => firstValue - secondValue,
        },
        // sorted: true,
        // sorted_name: "customer_approved",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchPatientStates();
      const { start_date, end_date } = this.props.filters;
      if (start_date && end_date) {
        await this.fetchCallCustomers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchCallCustomers = () => {
    return this.props.getCallCustomers();
  };

  fetchCallCustomersDelayed = _debounce((e) => {
    return this.fetchCallCustomers();
  }, 1500);

  handleSearch = (event) => {
    //to future move to tableResponsive component
    this.props.setFiltersCallCustomer({
      search: event.target.value,
      page: 1,
    });
    this.fetchCallCustomersDelayed.cancel();
    this.fetchCallCustomersDelayed();
  };

  handleFilters = async (name, value) => {
    const { filters, setFiltersCallCustomer } = this.props;

    filters[name] = value;
    setFiltersCallCustomer(filters);

    if (
      filters.start_date &&
      moment(filters.start_date).isValid() &&
      filters.end_date &&
      moment(filters.end_date).isValid()
    ) {
      if (moment(filters.start_date).isAfter(filters.end_date)) {
        return;
      }

      try {
        setFiltersCallCustomer({
          start_date: moment(filters.start_date).format("YYYY-MM-DD"),
          end_date: moment(filters.end_date).format("YYYY-MM-DD"),
          page: 1,
        });
        const response = await this.props.getCallCustomers();
        if (response.status !== 200) {
          this.props.enqueueSnackbar("The request could not be processed", {
            variant: "success",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleUpdate = async (event, service) => {
    event.preventDefault();
    this.props.setLoading(true);
    try {
      const form = { customer_approved: event.target.checked };
      const { status } = await this.props.updateCallCustomer(
        service.order.id,
        service.id,
        form
      );

      if (status === 200) {
        this.props.enqueueSnackbar("The service has been updated!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  getPatientName = (service) => {
    const { first_name = "", last_name = "" } = service.order.patient;

    return `${first_name} ${last_name}`;
  };

  handleOpen = (ocurrence) => () => {
    this.props.history.push(`/call-customers/${ocurrence.id}`);
  };

  getCurrentState = (states, state) => {
    if (state && states.length) {
      const currentState = states.find((_state) => _state.id === state);
      return {
        value: (currentState || {}).id,
        label: (currentState || {}).name,
      };
    }
    return null;
  };

  render() {
    const { search } = this.state;
    const {
      callCustomers,
      loading,
      classes,
      filters,
      us_patient_states,
    } = this.props;

    return (
      <Layout title="Call Patients">
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid container spacing={4} justify="space-between">
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="start_date"
                label="Start Date"
                value={filters.start_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("start_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="MM/DD/YYYY"
              />
            </Grid>
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="end_date"
                label="End Date"
                value={filters.end_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("end_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="MM/DD/YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} container alignItems="flex-end">
              <Autocomplete
                onChange={(value) => {
                  const newValue = (value || {}).value;
                  this.props.setFiltersCallCustomer({
                    state: newValue ? newValue : "",
                    page: 1,
                  });

                  this.fetchCallCustomers();
                }}
                value={this.getCurrentState(us_patient_states, filters.state)}
                // value={filters.state_id}
                suggestions={us_patient_states.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                placeholder="State"
              />
            </Grid>
            <Grid item xs={3}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <Search />
                </div>
                <InputBase
                  name="search"
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={filters.search}
                  onChange={this.handleSearch}
                />
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
          <TableResponsive
            search={search}
            items={callCustomers.map(
              // items={this.filter(callCustomers, search, filters.state_id).map(
              (_callCustomer) => ({
                ..._callCustomer,
                patient_full_name: this.getPatientName(_callCustomer),
              })
            )}
            config={this.config}
            metaFilters={{
              filters: this.props.filters,
              setFilters: this.props.setFiltersCallCustomer,
            }}
            fetchData={this.fetchCallCustomers}
            emptyMessage={"CALL PATIENTS"}
          />
        </Panel>

        <ContactsModal
          open={this.state.openContactsModal}
          onClose={() =>
            this.setState({ openContactsModal: false, currentContacts: [] })
          }
          contacts={this.state.currentContacts}
        />
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    callCustomers: state.callCustomers.list,
    filters: state.callCustomers.filters,
    us_patient_states: state.global.us_patient_states,
  };
};

const mapDispatchToProps = {
  setLoading,
  getCallCustomers,
  getCallCustomer,
  updateCallCustomer,
  setEmptyCallCustomers,
  setFiltersCallCustomer,
  fetchPatientStates,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "CallCustomersList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(CallCustomersList);
