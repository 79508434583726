import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import _get from "lodash/get";
import {
  withStyles,
  Switch,
  Grid,
  IconButton,
  InputBase,
  InputAdornment,
  Avatar,
  FormControlLabel, Button, MenuItem, TextField,
} from "@material-ui/core";
import { Layout, SimpleBreadcrumbs, Panel } from "../../../components";
import {
  setLoading,
  fetchPatientStates,
} from "../../../redux/actions/globalActions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import styles from "./styles";
import { Search, Today, Visibility, PhoneDisabled } from "@material-ui/icons";
import TableResponsive from "../../../components/TableResponsive";
import Autocomplete from "../../../components/Autocomplete";
import _debounce from "lodash/debounce";
import {
  getCallCustomersWEligibilities,
  getCallCustomerWEligibility,
  setFiltersCallCustomerWEligibilities,
  updateCallCustomerWEligibility,
} from "../../../redux/actions/callCustomerActionsWCheckEligibilityActions";
import PdfIcon from "@material-ui/core/SvgIcon/SvgIcon";
import { ReactComponent as ShieldDisableSvg } from '../../../shield-disabled.svg';

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Call Patient w Eligibility", to: null },
];

class CallCustomersWElegibilityList extends React.Component {
  state = {
    // search: "",
  };

  config = {
    columns: [
      {
        display_name: "Patient Name",
        name: "patient_full_name",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "patient_name",
      },
      {
        display_name: "St",
        name: "order.patient.state.name",
        type: "string",
        align: "right",
        sorted: true,
        sorted_name: "state",
      },
      {
        display_name: "DOS",
        name: "date",
        type: "date",
        align: "right",
        sorted: true,
        sorted_name: "date",
      },
      {
        display_name: "Insurance",
        name: "order.patient.insurances",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { insurances = [] } = item.order.patient;
            if (insurances.length === 0) return "-";
            return insurances.map(({ name, policy_number }) => {
              return (
                <p key={name}>
                  {name} - {policy_number}
                </p>
              );
            });
          },
        },
        align: "inherit",
        sorted: true,
        sorted_name: "insurance",
      },
      {
        display_name: "DOB",
        name: "order.patient.birthdate",
        type: "date",
        align: "inherit",
        sorted: true,
        sorted_name: "birth_date",
      },
      {
        display_name: "Alert",
        name: "order.patient.birthdate",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { classes } = this.props;

            const color = this.getAlertColor(
              item,
              _get(item, "order.expirations", [])
            );
            const classesColor = {
              green: classes.rowGreen,
              yellow: classes.rowYellow,
              red: classes.rowRed,
            };
            if (color === "empty") return null;
            return <Avatar className={classesColor[color]}> </Avatar>;
          },
        },
        align: "inherit",
        sorted: false,
        sorted_name: "birth_date",
      },
      {
        display_name: "Order",
        name: "date",
        type: "custom",
        align: "right",
        // sorted: true,
        custom: {
          render: (item, column) => {
            return (
              <IconButton onClick={this.handleOpen(item)} aria-label="detail">
                <Visibility />
              </IconButton>
            );
          },
          compare: () => 0,
        },
      },
      {
        display_name: "Eligibility",
        name: "eligibility_toggle",
        type: "custom",
        custom: {
          render: (item, column) => {
            if (item.insurances) {
              return <ShieldDisableSvg />
            }
            return (
                <Switch
                    checked={item.eligibility_toggle}
                    onChange={this.handleUpdate(item, "eligibility_toggle")}
                    value="1"
                    disabled={this.props.loading}
                    color="primary"
                />
            );
          },
        },
        align: "right",
      },
      {
        display_name: "Patient Approved",
        name: "order.id",
        type: "custom",
        align: "right",
        custom: {
          render: (item, column) => {
            if (!_get(item, "order.patient.call_before_delivery")) {
              return <PhoneDisabled style={{ color: "#3f51b5" }} />;
            }
            return (
              <Switch
                checked={item.customer_approved}
                onChange={this.handleUpdate(item, "customer_approved")}
                disabled={this.props.loading}
                color="primary"
              />
            );
          },
          compare: (firstValue, secondValue) => firstValue - secondValue,
        },
        // sorted: true,
        // sorted_name: "customer_approved",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchPatientStates();

      const { start_date, end_date } = this.props.filters;
      if (start_date && end_date) {
        await this.fetchCallCustomers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchCallCustomers = () => {
    return this.props.getCallCustomersWEligibilities();
  };

  fetchCallCustomersDelayed = _debounce((e) => {
    return this.fetchCallCustomers();
  }, 1500);

  handleSearch = (event) => {
    // this.props.setLoading(false)
    //to future move to tableResponsive component
    this.props.setFiltersCallCustomerWEligibilities({
      search: event.target.value,
      page: 1,
    });
    this.fetchCallCustomersDelayed.cancel();
    this.fetchCallCustomersDelayed();
  };

  handleFilters = async (name, value) => {
    const { filters, setFiltersCallCustomerWEligibilities } = this.props;

    filters[name] = value;
    setFiltersCallCustomerWEligibilities(filters);

    if (
      filters.start_date &&
      moment(filters.start_date).isValid() &&
      filters.end_date &&
      moment(filters.end_date).isValid()
    ) {
      if (moment(filters.start_date).isAfter(filters.end_date)) {
        return;
      }

      try {
        setFiltersCallCustomerWEligibilities({
          start_date: moment(filters.start_date).format("YYYY-MM-DD"),
          end_date: moment(filters.end_date).format("YYYY-MM-DD"),
          page: 1,
        });
        const response = await this.props.getCallCustomersWEligibilities();
        if (response.status !== 200) {
          this.props.enqueueSnackbar("The request could not be processed", {
            variant: "success",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleUpdate = (ocurrence, field) => async (event) => {
    event.preventDefault();
    this.props.setLoading(true);
    try {
      const form = { [field]: event.target.checked };
      const { status } = await this.props.updateCallCustomerWEligibility(
        ocurrence.id,
        form
      );

      if (status === 200) {
        this.props.enqueueSnackbar("The ocurrence has been updated!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  getPatientName = (service) => {
    const { first_name = "", last_name = "" } = service.order.patient;

    return `${first_name} ${last_name}`;
  };

  handleOpen = (ocurrence) => () => {
    this.props.history.push(`/call-customer-w-eligibility/${ocurrence.id}`);
  };

  getCurrentState = (states, state) => {
    if (state && states.length) {
      const currentState = states.find((_state) => _state.id === state);
      return {
        value: (currentState || {}).id,
        label: (currentState || {}).name,
      };
    }
    return null;
  };

  isBetweenRange = (date, start_date, end_date) => {
    return date
      ? moment(date, "YYYY-MM-DD").isSameOrAfter(
          moment(start_date, "YYYY-MM-DD")
        ) &&
          moment(date, "YYYY-MM-DD").isSameOrBefore(
            moment(end_date, "YYYY-MM-DD")
          )
      : false;
  };

  getAlertColor = (item, expirations = []) => {
    const { start_date, end_date } = this.props.filters;
    const filteredExpiration = expirations.find(
      ({ date, yellow, yellow_date }) =>
        // date === item.date || (yellow && yellow_date === item.date)
        date === item.date ||
        (yellow && this.isBetweenRange(yellow_date, start_date, end_date))
    );

    if (!filteredExpiration) return "green";

    if (filteredExpiration.solved) return "green";

    const yellow_date = _get(filteredExpiration, "yellow_date");

    const yellowDateIsBetweenRange = this.isBetweenRange(
      yellow_date,
      start_date,
      end_date
    );

    return filteredExpiration.yellow && yellowDateIsBetweenRange
      ? "yellow"
      : "red";
    // return filteredExpiration.yellow && yellowDateIsBetweenRange
    //   ? "yellow"
    //   : "red";
  };

  render() {
    const { search } = this.state;
    const {
      callCustomersWEligibilities,
      loading,
      classes,
      filters,
      us_patient_states,
    } = this.props;

    return (
      <Layout title="Call Patient w Eligibility">
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid container spacing={4} justify="space-between">
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="start_date"
                label="Start Date"
                value={filters.start_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("start_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="MM/DD/YYYY"
              />
            </Grid>
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="end_date"
                label="End Date"
                value={filters.end_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("end_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="MM/DD/YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} container alignItems="flex-end">
              <Autocomplete
                onChange={(value) => {
                  const newValue = (value || {}).value;
                  this.props.setFiltersCallCustomerWEligibilities({
                    state: newValue ? newValue : "",
                    page: 1,
                  });

                  this.fetchCallCustomers();
                }}
                value={this.getCurrentState(us_patient_states, filters.state)}
                // value={filters.state_id}
                suggestions={us_patient_states.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                placeholder="State"
              />
            </Grid>
            <Grid item xs={3} container alignItems="flex-end">
              <TextField
                  label="Facility"
                  margin="normal"
                  value={filters.facility}
                  onChange={(value) => {
                    const newValue = (value || {}).target.value;
                    this.props.setFiltersCallCustomerWEligibilities({
                      facility: newValue ? newValue : "",
                      page: 1,
                    });

                    this.fetchCallCustomers();
                  }}
                  fullWidth
                  select
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </Grid>
            <Grid
                container
                item
                xs={12}
                sm={3}
                alignItems="flex-end"
                className={classes.downloadButtonContainer}
            >
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.divSearch}>
            <FormControlLabel
                style={{ marginLeft: "auto" }}
                control={
                  <Switch
                      checked={filters.hidden_completed}
                      onChange={() => {
                        this.props.setFiltersCallCustomerWEligibilities({
                          hidden_completed: !filters.hidden_completed,
                        });
                        this.fetchCallCustomers();
                      }}
                      disabled={loading}
                      color="primary"
                  />
                }
                label="Hide Completed"
            />
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                  name="search"
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  autoFocus={true}
                  // disabled={this.props.loading}
                  value={filters.search}
                  onChange={this.handleSearch}
              />
            </div>

          </Grid>
          <TableResponsive
            search={search}
            items={callCustomersWEligibilities.map(
              // items={this.filter(callCustomers, search, filters.state_id).map(
              (_callCustomer) => ({
                ..._callCustomer,
                patient_full_name: this.getPatientName(_callCustomer),
              })
            )}
            config={this.config}
            metaFilters={{
              filters: this.props.filters,
              setFilters: this.props.setFiltersCallCustomerWEligibilities,
            }}
            fetchData={this.fetchCallCustomers}
            emptyMessage={"CALL PATIENTS"}
          />
        </Panel>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    callCustomersWEligibilities: state.callCustomerwCheckelibility.list,
    filters: state.callCustomerwCheckelibility.filters,
    us_patient_states: state.global.us_patient_states,
    loading: state.global.loading,
  };
};

const mapDispatchToProps = {
  setLoading,
  getCallCustomersWEligibilities,
  getCallCustomerWEligibility,
  updateCallCustomerWEligibility,
  setFiltersCallCustomerWEligibilities,
  fetchPatientStates,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "CallCustomersWElegibilityList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(CallCustomersWElegibilityList);
