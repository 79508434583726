import {
  GET_CALL_CUSTOMERS,
  UPDATE_CALL_CUSTOMER,
  SET_EMPTY,
  GET_CALL_CUSTOMER,
  SET_FILTER_CALL_CUSTOMERS,
  RESET_CALL_CUSTOMERS,
} from "../actionTypes";

const initialState = {
  list: [],
  filters: {
    start_date: "",
    end_date: "",
    state: null,

    ///pagination filters
    search: "",
    ordering: "",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPTY:
      return { ...state, list: action.payload };

    case GET_CALL_CUSTOMERS:
      return { ...state, list: action.payload };

    case GET_CALL_CUSTOMER:
      return {
        ...state,
        list: state.list.map((callCustomer) =>
          callCustomer.id === action.payload.id ? action.payload : callCustomer
        ),
      };

    case UPDATE_CALL_CUSTOMER:
      return {
        ...state,
        list: state.list.map((callCustomer) =>
          callCustomer.id === action.payload.serviceId
            ? {
                ...callCustomer,
                customer_approved: action.payload.customer_approved,
              }
            : callCustomer
        ),
      };
    case SET_FILTER_CALL_CUSTOMERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    
    case RESET_CALL_CUSTOMERS:
      return initialState

    default:
      return state;
  }
};
