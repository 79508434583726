import Service from "./Service";

class PatientService extends Service {
  async list(params = {}) {
    try {
      return await this.http.get("/patients/", { params });
    } catch (error) {
      return error.response;
    }
  }

  async listRentals(params = {}) {
    try {
      return await this.http.get("/patients_rentals/", { params });
    } catch (error) {
      return error.response;
    }
  }

  async rawList(params = {}) {
    try {
      return await this.http.get("/patients_list/", { params });
    } catch (error) {
      return error.response;
    }
  }

  async get(id) {
    try {
      const url = `/patients/${id}/`;
      return await this.http.get(url);
    } catch (error) {
      return error.response;
    }
  }

  async create(body = {}) {
    try {
      return await this.http.post("/patients/", body);
    } catch (error) {
      return error.response;
    }
  }

  async update(id, body = {}) {
    try {
      const url = `/patients/${id}/`;
      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async delete(id) {
    try {
      const url = `/patients/${id}/`;
      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addTag(patientId, tagId) {
    try {
      const url = `/patients/${patientId}/tags/`;

      return await this.http.post(url, { tag_id: tagId });
    } catch (error) {
      return error.response;
    }
  }

  async removeTag(patientId, tagId) {
    try {
      const url = `/patients/${patientId}/tags/${tagId}/`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addDiagnosis(patientId, text) {
    try {
      const url = `/patients/${patientId}/diagnostics/`;

      return await this.http.post(url, { text });
    } catch (error) {
      return error.response;
    }
  }

  async removeDiagnosis(patientId, diagnosisId) {
    try {
      const url = `/patients/${patientId}/diagnostics/${diagnosisId}/`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addHCPCS(patientId, text) {
    try {
      const url = `/patients/${patientId}/hcps/`;

      return await this.http.post(url, { text });
    } catch (error) {
      return error.response;
    }
  }

  async removeHCPCS(patientId, hcpcsId) {
    try {
      const url = `/patients/${patientId}/hcps/${hcpcsId}/`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addModifier(patientId, hcpcsId, payload) {
    try {
      const url = `/patients/${hcpcsId}/modifier/`;

      return await this.http.post(url, payload);
    } catch (error) {
      return error.response;
    }
  }

  async removeModifier(patientId, hcpcsId, modifierId) {
    try {
      const url = `/patients/${hcpcsId}/modifier/${modifierId}/`;
      // const url = `/patients/${patientId}/modifier/${modifierId}/`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addAttachment(formData) {
    try {
      const headers = { "content-type": "multipart/form-data" };

      return await this.http.post("/attachments/", formData, { headers });
    } catch (error) {
      return error.response;
    }
  }

  async removeAttachment(attachmentId) {
    try {
      const url = `/attachments/${attachmentId}/`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addInsurance(patientId, body) {
    try {
      const url = `/patients/${patientId}/insurances/`;
      return await this.http.post(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async removeInsurance(patientId, insuranceId) {
    try {
      const url = `/patients/${patientId}/insurances/${insuranceId}/`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async updateInsurance(patientId, insuranceId, body) {
    try {
      const url = `/patients/${patientId}/insurances/${insuranceId}/`;

      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async updateHCPCS(patientId, hcpcsId, body) {
    try {
      const url = `/patients/${patientId}/hcps/${hcpcsId}/`;

      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async updateModifier(patientId, modifierId, body) {
    try {
      const url = `/patients/${patientId}/modifier/${modifierId}/`;

      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async updateDiagnostics(patientId, diagnosticsId, body) {
    try {
      const url = `/patients/${patientId}/diagnostics/${diagnosticsId}/`;

      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }
}

export default PatientService;
