import React, { useState, useMemo } from "react";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import {
  SimpleBreadcrumbs,
  Panel,
  Layout,
  Errors,
  PdfViewer,
} from "../../../components";
import {
  setLoading,
  setHandleForm,
} from "../../../redux/actions/globalActions";
import { getOrders } from "./../../../redux/actions/orderActions";
import {
  getRawPatients,
  setRawPatientFilters,
} from "../../../redux/actions/patientActions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Form } from "formik";
import styles from "./styles";
import {
  getInvoice,
  getInvoices,
  updateInvoice,
  printInvoice,
} from "../../../redux/actions/invoiceActions";
import moment from "moment";
import { Today } from "@material-ui/icons";
import * as Yup from "yup";
import InvoiceCheckDialog from "./InvoiceCheckDialog";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Invoices", to: "/invoices" },
  { name: "Edit Invoice", to: null },
];

class InvoiceEdit extends React.Component {
  state = {
    tab: 0,
    openDialog: false,
    errorDeliveryDate: false,
    services: []
  };
  componentDidMount = async () => {
    this.props.setLoading(true);
    try {
      this.previewInvoicePdf();
      // await this.props.getInvoices();
      await this.props.getOrders();
      const response = await this.props.getInvoice(this.props.match.params.id);
      // this.setServices();
      this.props.setRawPatientFilters({
        search: `${response.data.order.patient.first_name} ${response.data.order.patient.last_name}`,
        page: 1,
      });
      await this.props.getRawPatients();
      if (response.status === 200) {
      } else {
        this.props.history.push("/404");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  componentWillUnmount() {
    this.props.setRawPatientFilters({
      search: "",
      page: 1,
    });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  setServices = async() => {
    const response = await this.props.getInvoice(this.props.match.params.id);
    this.setState({services: response.data.services})

  }
  previewInvoicePdf = async () => {
    try {
      const response = await this.props.printInvoice(
        this.props.match.params.id
      );
      this.setState({
        pdf_file: new Blob([response.data], { type: "application/pdf" }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = async (values, formikActions) => {
    const { setSubmitting, resetForm } = formikActions;
    this.props.setLoading(true);
    setSubmitting(true);
    try {
      const form = {
        ...values,
        // delivery_date: values.delivery_date
        //   ? moment(values.delivery_date).format("YYYY-MM-DD")
        // : null,
        date: moment(values.date).format("YYYY-MM-DD"),
      };
      const response = await this.props.updateInvoice(
        this.props.match.params.id,
        form
      );
      if (response.status === 200) {
        this.props.enqueueSnackbar("The invoice was updated succesfully", {
          variant: "success",
        });
        resetForm({ values });
        this.props.history.goBack();
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
      });
      console.error(error);
    } finally {
      setSubmitting(false);
      this.props.setLoading(false);
    }
  };

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { loading, invoice, orders, rawPatients } = this.props;
    const { errorDeliveryDate, openDialog } = this.state;
    return (
      <Layout title="Edit Invoice">
        <SimpleBreadcrumbs routes={breadcrumbs} />
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            patient_id: (invoice.id && invoice.order.patient.id) || "",
            order_number: (invoice.id && invoice.order.id) || "",
            invoice_number: invoice.id || "",
            notes: invoice.notes || "",
            date: (invoice.date && moment(invoice.date)) || "",
            // delivery_date:
            //   (invoice.delivery_date && moment(invoice.delivery_date)) || "",
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            date: Yup.date(),
          })}
        >
          {(props) => {
            const {
              values,
              isSubmitting,

              errors,
              touched,
              dirty,
              isValid,
              handleChange,
              handleBlur,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Prompt
                  when={dirty}
                  message="Are you sure you want to leave?, You will lose your changes"
                />

                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          name="patient_id"
                          value={values.patient_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Patient"
                          error={!!touched.patient_id && !!errors.patient_id}
                          helperText={
                            !!touched.patient &&
                            !!errors.patient_id &&
                            errors.patient_id
                          }
                          fullWidth
                          select
                          disabled
                          required
                          margin="normal"
                        >
                          {rawPatients.map((patient) => {
                            return (
                              <MenuItem key={patient.id} value={patient.id}>
                                {patient.first_name} {patient.last_name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="invoice_number"
                          value={values.invoice_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Invoice Number"
                          required
                          error={
                            !!touched.invoice_number && !!errors.invoice_number
                          }
                          helperText={
                            !!touched.patient &&
                            !!errors.invoice_number &&
                            errors.invoice_number
                          }
                          fullWidth
                          // select
                          disabled
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          name="order_number"
                          value={values.order_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Order Name"
                          fullWidth
                          select
                          disabled
                          required
                          margin="normal"
                          error={
                            !!touched.order_number && !!errors.order_number
                          }
                          helperText={
                            !!touched.order_number &&
                            !!errors.order_number &&
                            errors.order_number
                          }
                        >
                          {orders.map((order) => {
                            return (
                              <MenuItem key={order.id} value={order.id}>
                                {order.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <KeyboardDatePicker
                          name="date"
                          label="Invoice date"
                          value={values.date || null}
                          invalidLabel={""}
                          margin="normal"
                          format={"MM/DD/YYYY"}
                          onBlur={handleBlur}
                          onChange={(date) => {
                            setFieldValue("date", date);
                          }}
                          fullWidth
                          animateYearScrolling
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <Today />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          disabled={loading}
                          placeholder="MM/DD/YYYY"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        label="Invoice notes"
                        error={!!touched.notes && !!errors.notes}
                        helperText={
                          !!touched.notes && !!errors.notes && errors.notes
                        }
                        disabled={loading}
                        fullWidth
                        multiline
                        rows={6}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={
                        loading
                      }
                      onClick={(e) => {
                        this.setState({openDialog: true});
                        }
                      }
                      style={{ marginTop: 16, marginBottom: 16 }}
                  >
                    Write Off
                  </Button>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={
                      loading ||
                      isSubmitting ||
                      (isValid && !dirty) ||
                      (!isValid && dirty) ||
                      errorDeliveryDate
                    }
                    style={{ marginTop: 16, marginBottom: 16 }}
                  >
                    Update
                  </Button>
                </Panel>
              </Form>
            );
          }}
        </Formik>
        <br />
        <br />
        <Panel>
          <Typography variant="h6" align="center">
            Invoice Preview
          </Typography>
          <br />
          <br />
          {this.state.pdf_file ? (
            <PdfViewer file={this.state.pdf_file} />
          ) : null}
        </Panel>
        {this.state.openDialog && (
            <InvoiceCheckDialog
                services={invoice.services}
                open={this.state.openDialog}
                handleClose={this.handleCloseDialog}
            />
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    rawPatients: state.patients.rawList,
    orders: state.orders.list,
    invoice: state.invoice.invoice,
    invoices: state.invoice.list,
    status: state.orders.status,
    errors: new Errors(state.orders.errors),
  };
};

const mapDispatchToProps = {
  setLoading,
  getRawPatients,
  setRawPatientFilters,
  setHandleForm,
  getInvoice,
  getInvoices,
  getOrders,
  updateInvoice,
  printInvoice,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "InvoiceEdit" }),
  connect(mapStateToProps, mapDispatchToProps)
)(InvoiceEdit);
