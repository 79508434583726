import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import { withStyles, Button, Link, IconButton, Grid } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import {
  SimpleBreadcrumbs,
  Panel,
  Layout,
  InputSearch,
  DialogDelete,
} from "../../../components";
import styles from "./styles";

import {
  getProducts,
  deleteProduct,
  setProductFilters,
} from "../../../redux/actions/productActions";
import { setLoading } from "../../../redux/actions/globalActions";
import TableResponsive from "../../../components/TableResponsive";
import _debounce from "lodash/debounce";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Products", to: null },
];

class ProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      open: false,
      productId: "",
    };
  }

  config = {
    columns: [
      {
        display_name: "Reference Number",
        name: "internal_code",
        type: "string",
        align: "inherit",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Name",
        name: "name",
        type: "string",
        align: "left",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Actions",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { classes } = this.props;
            return (
              <div className={classes.actionButtons}>
                <Link component={RouterLink} to={`/products/${item.id}`}>
                  <IconButton
                    aria-label="Edit"
                    color="primary"
                    disabled={this.props.loading}
                  >
                    <Edit />
                  </IconButton>
                </Link>

                <IconButton
                  color="secondary"
                  aria-label="Delete"
                  onClick={() =>
                    this.setState({
                      open: true,
                      productId: item.id,
                    })
                  }
                  disabled={this.props.loading}
                >
                  <Delete />
                </IconButton>
              </div>
            );
          },
        },
        align: "center",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    this.setState({search: window.search})
    try {
      await this.fetchProducts();
    } catch (e) {
      console.error(e);
    }
  };
  componentWillUnmount() {
    this.props.setProductFilters({
      search: this.state.search,
      page: 1,
    });
  }

  fetchProducts = () => {
    console.log(  "search",  this.state.search, "issue");
    return this.props.getProducts();
  };

  handleClean = () => {
    window.search = ""
    this.setState({ search: "" });
    this.props.setProductFilters({
      search: "",
      page: 1,
    });
    this.props.getProducts();
  }

  fetchProductsDelayed = _debounce((e) => {
    return this.fetchProducts();
  }, 1500);

  handleSearch = (event) => {
    //to future move to tableResponsive component
    window.search = event.target.value;
    this.setState({ search: event.target.value });
    this.props.setProductFilters({
      search: event.target.value,
      page: 1,
    });
    this.fetchProductsDelayed.cancel();
    this.fetchProductsDelayed();
  };

  handleDelete = async () => {
    this.props.setLoading(true);
    this.setState({ open: false });
    try {
      const { status } = await this.props.deleteProduct(this.state.productId);

      if (status === 204) {
        this.props.enqueueSnackbar("The product has been deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        return;
      }
      if (status === 200) {
        this.props.enqueueSnackbar("The product cannot be deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        return;
      }
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  render() {
    const { open } = this.state;
    const { classes, loading, products, filters = {} } = this.props;

    return (
      <Layout title="Products">
        <div>
          <DialogDelete
            item="product"
            open={open}
            closeModal={() => this.setState({ open: false })}
            remove={this.handleDelete}
            isDelivery={false}
          />
          <SimpleBreadcrumbs routes={breadcrumbs} />

          <Panel>
            <div className={classes.header}>
              <Link
                component={RouterLink}
                color="inherit"
                to="/products/create"
                style = {{flex: 2}}
              >
                <Button variant="outlined" color="primary" disabled={loading}>
                  Create Product
                </Button>
              </Link>

              <InputSearch
                value={this.state.search}
                onChange={this.handleSearch}
                autoFocus={true}
                // disabled={loading}
              />
              { !!this.state.search && (
                  <IconButton
                      color="secondary"
                      aria-label="Clean"
                      onClick={() =>
                          this.handleClean()
                      }
                      disabled={this.props.loading}
                  >
                    x
                  </IconButton>
              )}
            </div>

            <Grid container>
              <Grid item xs={12}>
                <TableResponsive
                  items={products}
                  config={this.config}
                  emptyMessage="PRODUCTS"
                  metaFilters={{
                    filters: this.props.filters,
                    setFilters: this.props.setProductFilters,
                  }}
                  fetchData={this.fetchProducts}
                />
              </Grid>
            </Grid>
          </Panel>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    products: state.products.list,
    filters: state.products.filters,
  };
};

const mapDispatchToProps = {
  getProducts,
  deleteProduct,
  setLoading,
  setProductFilters,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ProductList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductList);
