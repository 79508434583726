import Service from "./Service";

export default class RemmitanceRevertService extends Service {
    async getAll(params = {}) {
        try {
            return await this.http.get(`/remmitance_revert/`, { params });
        } catch (error) {
            return error.response;
        }
    }

    async delete(id) {
        try {
            const url = `/ocurrence_payments/${id}/`;
            return await this.http.delete(url);
        } catch (error) {
            return error.response;
        }
    }
    // async get(id) {
    //     try {
    //         return await this.http.get(`/remmitance/${id}`);
    //     } catch (error) {
    //         return error.response;
    //     }
    // }
    // async getAllRemittancesPayment(ocurrenceId) {
    //     try {
    //         return await this.http.get(`/remmitance_payments`, {
    //             params: {
    //                 ocurrence: ocurrenceId,
    //             },
    //         });
    //     } catch (error) {
    //         return error.response;
    //     }
    // }
    // async getLastRemittancesPayment() {
    //     try {
    //         return await this.http.get(`/last_remmitance_payments`);
    //     } catch (error) {
    //         return error.response;
    //     }
    // }
    // async createRemittancePayment(body) {
    //     try {
    //         return await this.http.post(`/remmitance_payments/`, body);
    //     } catch (error) {
    //         return error.response;
    //     }
    // }
}
