import {
  GET_VENDORS,
  GET_VENDORS_ERROR,
  CREATE_VENDOR,
  CREATE_VENDOR_ERROR,
  GET_VENDOR,
  UPDATE_VENDOR,
  UPDATE_VENDOR_ERROR,
  DELETE_VENDOR
} from "../actionTypes";
import { setLoading } from "./globalActions";
import VendorService from "../../services/VendorService";

const service = new VendorService();

export const getVendors = () => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      const response = await service.list();

      if (response.status === 200) {
        dispatch({ type: GET_VENDORS, payload: response.data });
      } else {
        dispatch({ type: GET_VENDORS_ERROR, payload: response.data });
      }

      return response;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const createVendor = body => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      const response = await service.create(body);

      if (response.status === 201) {
        dispatch({ type: CREATE_VENDOR, payload: response.data });
      } else {
        dispatch({ type: CREATE_VENDOR_ERROR, payload: response.data });
      }

      return response;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getVendor = id => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      const response = await service.get(id);

      if (response.status === 200) {
        dispatch({ type: GET_VENDOR, payload: response.data });
      }

      return response;
    } catch (error) {
      console.error(error.log);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const updateVendor = (id, body) => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      const response = await service.update(id, body);
      console.log(response);

      if (response.status === 200) {
        dispatch({ type: UPDATE_VENDOR, payload: response.data });
      } else {
        dispatch({ type: UPDATE_VENDOR_ERROR, payload: response.data });
      }

      return response;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteVendor = id => {
  return async dispatch => {
    dispatch(setLoading(true));

    try {
      const response = await service.delete(id);

      if (response.status === 204) {
        dispatch({ type: DELETE_VENDOR, payload: id });
      }
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};
