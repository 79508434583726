import React from "react";
import { compose } from "recompose";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import { withStyles, Button, IconButton } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import Link from "@material-ui/core/Link/Link";
import {
  Layout,
  Panel,
  SimpleBreadcrumbs,
  InputSearch,
  DialogDelete,
} from "../../../components";
import { setLoading } from "../../../redux/actions/globalActions";
import {
  getOrders,
  deleteOrder,
  setPatientIdOrigin,
  setOrderFilters,
} from "../../../redux/actions/orderActions";
import styles from "./styles";
import GridFlex from "../../../components/GridFlex";
import TableResponsive from "../../../components/TableResponsive";
import _debounce from "lodash/debounce";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Orders", to: null },
];

class OrderList extends React.Component {
  state = {
    open: false,
    orderId: "",
  };

  config = {

    columns: [
      // {
      //   display_name: "Patient",
      //   name: "patient_full_name",
      //   type: "string",
      //   align: "inherit",
      //   sorted: false,
      //   sorted_name: "",
      // },
      {
        display_name: "Patient",
        name: "patient_full_name",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      this.props.setPatientIdOrigin("");
                      this.props.history.push(
                          `/patients/${item.patient_id}`
                          // `/patients/${item.order.patient.id}`
                      );
                    }}
                    style={{ textAlign: "left" }}
                >
                  {this.getPatientName(item)}
                </Link>
            );
          },
        },
        align: "left",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Order name",
        name: "name",
        type: "string",
        align: "left",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Status",
        name: "status.name",
        type: "string",
        align: "left",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Delivery",
        name: "delivery.name",
        type: "string",
        align: "left",
        sorted: true,
        sorted_name: "phone",
      },
      {
        display_name: "Actions",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <>
                <Link component={RouterLink} to={`/orders/${item.id}/edit`}>
                  <IconButton
                    aria-label="Edit"
                    color="primary"
                    disabled={this.props.loading}
                  >
                    <Edit />
                  </IconButton>
                </Link>
                <IconButton
                  onClick={() =>
                    this.setState({ open: true, orderId: item.id })
                  }
                  disabled={this.props.loading}
                >
                  <Delete color="secondary" />
                </IconButton>
              </>
            );
          },
        },
        align: "left",
        sorted: false,
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      this.props.setPatientIdOrigin("");
      console.log("entro a component did mount");
      const response = await this.fetchOrders();
      if (response.status !== 200) {
        // this.props.history.push("/404")
      }
    } catch (error) {
      console.log(error);
      // this.props.history.push("/404")
    }
  };

  componentWillUnmount() {
    this.props.setOrderFilters({
      search: "",
      page: 1,
    });
  }

  fetchOrders = () => {
    console.log("entro fetch orders");

    return this.props.getOrders();
  };

  fetchOrdersDelayed = _debounce((e) => {
    return this.fetchOrders();
  }, 1500);

  handleSearch = (event) => {
    this.props.setOrderFilters({
      search: event.target.value,
      page: 1,
    });
    this.fetchOrdersDelayed.cancel();
    this.fetchOrdersDelayed();
  };

  handleDelete = async () => {
    this.props.setLoading(true);
    this.setState({ open: false });
    try {
      const response = await this.props.deleteOrder(this.state.orderId);
      if (response.status === 204) {
        this.props.enqueueSnackbar("The order has been deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        return;
      }
      this.props.enqueueSnackbar(
        "You cannot delete this order because one or more of the occurrences are marked as Shipped",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      return;
    } catch (error) {
      this.props.enqueueSnackbar(
        "You cannot delete this order because one or more of the occurrences are marked as Shipped",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  getPatientName = (order = {}) => {
    const { patient: { first_name, last_name } = {} } = order || {};
    return `${first_name} ${last_name}`;
  };

  render() {
    const { open } = this.state;
    const { loading, orders, filters } = this.props;

    return (
      <Layout title="Orders">
        <DialogDelete
          item="order"
          open={open}
          closeModal={() => this.setState({ open: false })}
          remove={this.handleDelete}
          isDelivery={false}
        />
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <GridFlex typeFlex="space-between">
            <Link component={RouterLink} color="inherit" to="/orders/create">
              <Button variant="outlined" color="primary" disabled={loading}>
                Create Order
              </Button>
            </Link>

            <InputSearch value={filters.search} onChange={this.handleSearch} autoFocus={true}
                         // disabled={loading}
            />
          </GridFlex>
          <TableResponsive
            items={orders.map((_order) => ({
              ..._order,
              patient_full_name: this.getPatientName(_order),
            }))}
            config={this.config}
            emptyMessage="ORDERS"
            metaFilters={{
              filters: this.props.filters,
              setFilters: this.props.setOrderFilters,
            }}
            fetchData={this.fetchOrders}
          />
        </Panel>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    orders: state.orders.list,
    filters: state.orders.filters,
  };
};

const mapDispatchToProps = {
  setLoading,
  getOrders,
  deleteOrder,
  setPatientIdOrigin,
  setOrderFilters,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "OrderList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderList);
