import {
    DELETE_REMITTANCE_PAYMENT,
    GET_REVERT_REMITTANCES,
    SET_REMITTANCE_FILTERS
} from "../actionTypes";

// import _set from "lodash/set";
// import _get from "lodash/get";

const initial_state = {
    list: [],

    filters: {
        search: "",
        page: 1,
        page_size: 25,
        count: 0,
    },
};

export default (state = initial_state, action) => {
    switch (action.type) {
        // case DELETE_REMITTANCE_PAYMENT:
        //     return {
        //         ...state,
        //         list: action.payload,
        //     };
        case GET_REVERT_REMITTANCES:
            return {
                ...state,
                list: action.payload,
            };

        case SET_REMITTANCE_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };
        // case DELETE_REMITTANCE_PAYMENT:
        //     return {
        //         ...state,
        //         list: state.list.filter(vendor => vendor.id !== action.payload)
        //     };


        // case UPDATE_REMITTANCE_PRODUCT_SERVICE:
        //   console.log(_get(state.list, action.payload.path));
        //   _set(state.list, action.payload.path, {
        //     ..._get(state.list, action.payload.path),
        //     sale_price: action.payload.sale_price,
        //     amount_remmited: action.payload.amount_remmited,
        //   });
        //   console.log(_get(state.list, action.payload.path));
        //   return {
        //     ...state,
        //     list: _set(state.list, action.payload.path, {
        //       ..._get(state.list, action.payload.path),
        //       sale_price: action.payload.sale_price,
        //       amount_remmited: action.payload.amount_remmited,
        //     }),
        //   };

        default:
            return state;
    }
};
