import React from "react";
import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Paper,
  MenuItem,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
  Link,
  Switch,
  InputAdornment,
} from "@material-ui/core";
import {
  Place,
  Work,
  VerifiedUser,
  Label,
  AttachFile,
  Delete,
  InsertDriveFile,
  Note,
  Today,
  FileCopy, Add, Edit, AddBox,
} from "@material-ui/icons";
import {
  Layout,
  Panel,
  SimpleBreadcrumbs,
  TabContainer,
  Errors, DialogDelete, AutocompleteV2
} from "../../../components";
import { NpiMask, PhoneMask } from "../../../components/InputMask";
import Autocomplete from "../../../components/Autocomplete";
import {
  getPatient,
  updatePatient,
  getTags,
  addTag,
  removeTag,
  addDiagnosis,
  updateDiagnostics,
  updateHCPCS,
  updateModifier,
  removeDiagnosis,
  addAttachment,
  removeAttachment,
  addInsurance,
  removeInsurance,
  addHCPCS,
  removeHCPCS,
  addModifier,
  removeModifier,
  updateInsurance,
} from "../../../redux/actions/patientActions";
import {
  getOrdersByPatient,
  deleteOrder,
  setPatientIdOrigin,
} from "../../../redux/actions/orderActions";
import {
  fetchStates,
  setLoading,
  setHandleForm,
} from "../../../redux/actions/globalActions";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import styles from "./styles";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import InsurancesTab from "./InsurancesTab";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import OrderIcon from "@material-ui/icons/ShoppingCart";
import { datePickerFormatToParseDate } from "../../../common/Helpers/DateHelper";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import OrderItem from "./OrderItem";
import ContactList from "../../../components/ContactList";
import { copyTextFromNode } from "../../../common/Helpers/ClipboardHelper";
import DialogConfirmation from "../../../components/DialogConfirmation";
import { cloningOrder } from "../../../redux/actions/orderActions";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Patients", to: "/patients" },
  { name: "Edit Patient", to: null },
];

const breadcrumbs_rentals = [
  { name: "Home", to: "/home" },
  { name: "Patients Rentals", to: "/patients_rentals" },
  { name: "Edit Patient", to: null },
];

const DIALOG_TYPE = {
  NEW_DIAGNOSIS: "NewDiagnosis",
  EDIT_DIAGNOSIS: "EditDiagnosis",
  NEW_HCPCS: "NewHCPCS",
  EDIT_HCPCS: "EditHCPCS",
  NEW_MODIFIER: "NewModifier",
  EDIT_MODIFIER : "EditModifier"
};

class PatientEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      changeField: false,
      action: "",
      form: {
        first_name: "",
        middle_name: "",
        last_name: "",
        address_1: "",
        address_2: "",
        city: "",
        state_id: null,
        zip: "",
        phone: "",
        mobile: "",
        fax: "",
        gender: "",
        facility: false,
        rentals: false,
        email: "",
        birthdate: null,
        notes: "",
        status: "active",
        call_before_delivery: false,
        physician_name: "",
        physician_npi: "",
        physician_phone: "",
        physician_fax: "",
        contact_different_patient: false,
        contact_person: [],
        // contact_name: "",
        // contact_number: "",
        // contact_relation: "",
      },
      tags: [],
      diagnosis: [],
      attachments: [],
      tab: 0,
      openDialog: false,
      openDelete: false,
      openConfirmation: false,
      elementTypeDelete: null,
      elementTypeConfirmation: null,
      elementIdDelete: null,
      elementIdClone: null,
      dialogText: "",
      hcpcsId: null,
      diagnosticsId: null,
      modifierId: null,
      file: null,
      dialog_type: DIALOG_TYPE.NEW_DIAGNOSIS,
      errorBirthdate: false,
    };
  }

  componentDidMount = async () => {
    this.props.setLoading(true);

    try {
      if (this.props.patientIdOrigin) {
        this.setState({ tab: 2 });
      }
      const { id } = this.state;
      const { status, data } = await this.props.getPatient(id);
      if (status === 200) {
        this.props.fetchStates();
        this.props.getTags();
        this.loadForm(data);
      } else {
        this.props.history.push("/404");
      }
    } catch (error) {
      this.props.history.push("/404");
    } finally {
      this.props.setLoading(false);
    }
    this.setState({ id: this.props.match.params.id });
  };


  handleDeleteBtnClick = (value) => {
    this.setState({ openDelete: value });
  };

  handleConfirmationBtnClick = (value) => {
    this.setState({ openConfirmation: value });
  };


  loadForm = (data) => {
    this.setState({contact_different_patient: data.contact_different_patient})
    const form = {
      first_name: data.first_name || "",
      middle_name: data.middle_name || "",
      last_name: data.last_name || "",
      address_1: data.address_1 || "",
      address_2: data.address_2 || "",
      city: data.city || "",
      state_id: data.state
        ? { value: data.state.id, label: data.state.name }
        : null,
      zip: data.zip || "",
      phone: data.phone || "",
      mobile: data.mobile || "",
      fax: data.fax || "",
      gender: data.gender || "",
      email: data.email || "",
      birthdate: (data.birthdate && moment(data.birthdate)) || null,
      notes: data.notes || "",
      status: data.status || "Inactive",
      call_before_delivery: data.call_before_delivery || false,
      physician_name: data.physician_name || "",
      physician_npi: data.physician_npi || "",
      physician_phone: data.physician_phone || "",
      physician_fax: data.physician_fax || "",
      contact_different_patient: data.contact_different_patient || false,
      facility: data.facility || false,
      rentals: data.rentals || false,
      contact_person: data.contact_person || [],
      // contact_name: data.contact_name || "",
      // contact_number: data.contact_number || "",
      // contact_relation: data.contact_relation || "",
    };

    this.setState({ form });
  };

  submit = async (values, formikActions) => {
    this.props.setLoading(true);
    try {
      const { id } = this.state;
      const form = { ...values };
      if (values.state_id) {
        form.state_id = values.state_id.value;
      }
      if (!values.call_before_delivery || !values.contact_different_patient) {
        delete form.contact_person;
      }
      form.birthdate = form.birthdate
        ? datePickerFormatToParseDate(form.birthdate)
        : null;
      const { status } = await this.props.updatePatient(id, form);
      if (status === 200) {
        this.setState({ changeField: false });
        this.props.enqueueSnackbar("The patient has been updated!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  handleTag = (tagId) => async (event) => {
    this.props.setLoading(true);

    try {
      const patientId = this.state.id;

      if (event.target.checked) {
        await this.props.addTag(patientId, tagId);
      } else {
        await this.props.removeTag(patientId, tagId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  setActiveTags = (list, actives) => {
    const ids = actives.map((item) => item.id);

    return list.map((tag) => {
      return { ...tag, checked: ids.includes(tag.id) };
    });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  getAge = (birthDate) => {
    const today = new Date();
    // const birthDate = this.state.birthdate;
    console.log("BIRTH", birthDate)
    if (!birthDate){
      return ""
    }
    let age = today.getFullYear() - birthDate.toDate().getFullYear();
    const m = today.getMonth() - birthDate.toDate().getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.toDate().getDate())) {
      age--;
    }
    return age;
  }

  handleEditItemFromDialog = async () => {
    this.props.setLoading(true);
    this.handleCloseDialog();

    try {
      const { id, dialogText, dialog_type, hcpcsId, diagnosticsId, modifierId } = this.state;
      let response = null;
      if (dialog_type === DIALOG_TYPE.EDIT_DIAGNOSIS) {
        response = await this.props.updateDiagnostics(id, diagnosticsId, {
          text: dialogText
        });
      } else if (dialog_type === DIALOG_TYPE.EDIT_HCPCS) {
        response = await this.props.updateHCPCS(id, hcpcsId, {
          text: dialogText
        });
      } else {
        response = await this.props.updateModifier(id, modifierId, {
          text: dialogText,
          hcps_id: hcpcsId,
        });
      }

      if (response.status === 200) {
        await this.props.getPatient(id);
        this.props.enqueueSnackbar("Updated successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
      this.setState({ dialogText: "" });
    }
  };

  handleAddItemFromDialog = async () => {
    this.props.setLoading(true);
    this.handleCloseDialog();

    try {
      const { id, dialogText, dialog_type, hcpcsId } = this.state;
      let response = null;
      if (dialog_type === DIALOG_TYPE.NEW_DIAGNOSIS) {
        response = await this.props.addDiagnosis(id, dialogText);
      } else if (dialog_type === DIALOG_TYPE.NEW_HCPCS) {
        response = await this.props.addHCPCS(id, dialogText);
      } else {
        response = await this.props.addModifier(id, hcpcsId, {
          text: dialogText,
          hcps_id: hcpcsId,
        });
      }

      if (response.status === 201) {
        await this.props.getPatient(id);
        this.props.enqueueSnackbar("Created successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
      this.setState({ dialogText: "" });
    }
  };

  handleDeleteDiagnosis = async (diagnosisId) => {
    this.props.setLoading(true);

    try {
      const patientId = this.state.id;
      const response = await this.props.removeDiagnosis(patientId, diagnosisId);

      if (response.status === 204) {
        await this.props.getPatient(patientId);
        this.props.enqueueSnackbar("Diagnosis Deleted successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.handleOpenModalDeleteNull();
      this.props.setLoading(false);
    }
  };

  handleDeleteHCPCS = async (hcpcsId) => {
    this.props.setLoading(true);

    try {
      const patientId = this.state.id;
      const response = await this.props.removeHCPCS(patientId, hcpcsId);

      if (response.status === 204) {
        await this.props.getPatient(patientId);
        this.props.enqueueSnackbar("HCPCS Deleted successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.handleOpenModalDeleteNull();
      this.props.setLoading(false);
    }
  };

  handleDeleteModifier = async (hcpsId, modifierId) => {
    this.props.setLoading(true);

    try {
      const patientId = this.state.id;
      const response = await this.props.removeModifier(
        patientId,
        hcpsId,
        modifierId
      );

      if (response.status === 204) {
        await this.props.getPatient(patientId);
        this.props.enqueueSnackbar("Modifier Deleted successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.handleOpenModalDeleteNull();
      this.props.setLoading(false);
    }
  };

  handleOpenModalDelete = (id, type) => {
    this.handleDeleteBtnClick(true);
    this.setState({ elementIdDelete: id , elementTypeDelete: type});
    // this.setState({ elementTypeDelete: type });
  };

  handleOpenModalConfirmation = (id, type) => {
    this.handleConfirmationBtnClick(true);
    this.setState({ elementIdClone: id , elementTypeConfirmation: 'order'});
  }

  handleOpenModalDeleteNull = () => {
    this.handleDeleteBtnClick(false);
    this.setState({ elementIdDelete: null , elementTypeDelete: null});
  };

  handleOpenModalConfirmationNull = () => {
    this.handleConfirmationBtnClick(false);
    this.setState({ elementIdClone: null , elementTypeDelete: null});
  };

  handleElementDelete = () => {
    switch (this.state.elementTypeDelete) {
      case "order":
        return this.handleDeleteOrder(this.state.elementIdDelete);
      case "insurance":
        return this.handleRemoveInsurance(this.state.elementIdDelete);
      case "diagnosis":
        return this.handleDeleteDiagnosis(this.state.elementIdDelete);
      case "attachment":
        return this.handleRemoveAttachment(this.state.elementIdDelete);
      case "hcps":
        return this.handleDeleteHCPCS(this.state.elementIdDelete);
      case "modifier":
        return this.handleDeleteModifier(this.state.elementIdDelete, this.state.elementIdDelete);
      default:
        return null
    }
  };

  handleOrderConfirmation =  () => {
    switch (this.state.elementTypeConfirmation) {
      case "order":
        return this.handleCloneOrder(this.state.elementIdClone);
      default:
        return null
    }
  };

  handleCloneOrder = async (order_id) => {
    try {
      const { data: _order } = await this.props.cloningOrder(
          order_id
      );
      this.handleOpenModalConfirmationNull()
      this.props.setPatientIdOrigin(this.state.id);
      this.props.history.push(`/orders/${_order.id}/edit`);
      
    } catch (error) {
      console.log(error);
    }
  };


  handleDeleteOrder = async (orderId) => {
    this.props.setLoading(true);
    try {
      const response = await this.props.deleteOrder(orderId);
      console.log(response)
      if (response.status === 204) {
        this.props.getPatient(this.state.id);
        this.props.enqueueSnackbar("The order has been deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        return;
      }
      this.props.enqueueSnackbar(
        response.data.error,
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      return;
    } catch (error) {
      this.props.enqueueSnackbar(
        "You cannot delete this order because one or more of the occurrences are marked as Shipped",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
      console.log(error);
    } finally {
      this.handleOpenModalDeleteNull();
      this.props.setLoading(false);
    }
  };

  handleAddAttachment = async (event) => {
    this.props.setLoading(true);
    try {
      const files = event.target.files;

      if (files.length > 0) {
        const { id } = this.state;
        let formData = new FormData();
        formData.append("file", files[0]);
        formData.append("patient", id);

        const response = await this.props.addAttachment(formData);
        if (response.status === 201) {
          await this.props.getPatient(id);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  handleRemoveAttachment = async (id) => {
    this.props.setLoading(true);

    try {
      const response = await this.props.removeAttachment(id);

      if (response.status === 204) {
        await this.props.getPatient(this.state.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.handleOpenModalDeleteNull();
      this.props.setLoading(false);
    }
  };

  handleAddInsurance = async (form) => {
    this.props.setLoading(true);

    try {
      const patientId = this.state.id;
      const response = await this.props.addInsurance(patientId, form);

      if (response.status === 201) {
        this.props.enqueueSnackbar("Insurance added successfully!", {
          variant: "success",
        });
        this.props.getPatient(patientId);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
      });
    } finally {
      this.props.setLoading(false);
    }
  };

  handleRemoveInsurance = async (id) => {
    this.props.setLoading(true);

    try {
      const patientId = this.state.id;
      const response = await this.props.removeInsurance(patientId, id);
      if (response.status === 204) {
        this.props.enqueueSnackbar("Insurance removed successfully!", {
          variant: "success",
        });
        this.props.getPatient(patientId);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
      });
    } finally {
      this.handleOpenModalDeleteNull();
      this.props.setLoading(false);
    }
  };

  handleUpdateInsurance = async (id, body) => {
    this.props.setLoading(true);

    try {
      const patientId = this.state.id;
      const response = await this.props.updateInsurance(patientId, id, body);

      if (response.status === 200) {
        this.props.enqueueSnackbar("Insurance updated successfully!", {
          variant: "success",
        });
        this.props.getPatient(patientId);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
      });
    } finally {
      this.props.setLoading(false);
    }
  };

  cleanConditionalsFields = ({ values, setFieldValue }) => {
    if (!values.call_before_delivery && values.contact_different_patient) {
      setFieldValue("contact_different_patient", false);
    }
    if (!values.contact_different_patient && values.contact_name) {
      setFieldValue("contact_name", "");
    }
    if (!values.contact_different_patient && values.contact_number) {
      setFieldValue("contact_number", "");
    }
    if (!values.contact_different_patient && values.contact_relation) {
      setFieldValue("contact_relation", "");
    }
  };

  _handleCopy = (node) => {
    copyTextFromNode(node);
    this.props.enqueueSnackbar("Copied to clipboard", {
      variant: "info",
      anchorOrigin: { vertical: "top", horizontal: "center" },
    });
  };

  render() {
    const { classes, loading, us_states, tags, patient } = this.props;
    const { tab, form, changeField, dialogText, errorBirthdate, action } = this.state;
    const activeTags = this.setActiveTags(tags, patient.tags);
    return (
      <Layout title="Edit Patient">
        <SimpleBreadcrumbs
          routes={this.props.location.state === "rentals" ? breadcrumbs_rentals :  breadcrumbs}
          action={
            this.props.location.state === "order_edit_component" ? (
              <Link
                style={{ color: "#3f51b5", textDecoration: "underline" }}
                href="javascript:void(0);"
                onClick={() => this.props.history.goBack()}
              >
                Go Back to Call Patient Details
              </Link>
            ) : null
          }
        />
        <Formik
          enableReinitialize
          onSubmit={this.submit}
          initialValues={{
            ...form,
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("First name is required"),
            middle_name: Yup.string(),
            last_name: Yup.string().required("Last name is required"),
            address_1: Yup.string(),
            address_2: Yup.string(),
            city: Yup.string(),
            state_id: Yup.mixed(),
            zip: Yup.string(),
            phone: Yup.string(),
            mobile: Yup.string(),
            fax: Yup.string(),
            gender: Yup.string(),
            facility: Yup.boolean(),
            rentals: Yup.boolean(),
            email: Yup.string().email("Must be a valid mail"),
            birthdate: Yup.mixed(),
            notes: Yup.string(),
            status: Yup.string(),
            call_before_delivery: Yup.boolean(),
            physician_name: Yup.string(),
            physician_npi: Yup.string().length(
              10,
              "NPI must have 10 characters"
            ),
            physician_phone: Yup.string(),
            physician_fax: Yup.string(),
            contact_person: Yup.array().of(
              Yup.object().shape({
                // contact_name: Yup.string().when('contact_different_patient', {
                //   is: true,//just an e.g. you can return a function
                //   then: Yup.string().required('Is required'),
                //   otherwise: Yup.string()
                // }),
                contact_name:  this.state.contact_different_patient ? Yup.string().required("Is required") : Yup.string(),
                contact_number: Yup.mixed(),
                contact_relation: Yup.string(),
              })
            ),
          })}
        >
          {(props) => {
            const {
              isValid,
              dirty,
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <React.Fragment>
                <Prompt
                  when={changeField}
                  message="Are you sure you want to leave?, You will lose your changes"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Panel>
                      <Form className={classes.form} onSubmit={this.submit}>
                        <Grid
                          container
                          spacing={2}
                          justify-content="space-between"
                        >
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="first_name"
                              value={values.first_name}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              disabled={loading}
                              error={
                                !!touched.first_name && !!errors.first_name
                              }
                              helperText={
                                !!touched.first_name &&
                                !!errors.first_name &&
                                errors.first_name
                              }
                              label="First Name"
                              fullWidth
                              margin="normal"
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="middle_name"
                              value={values.middle_name}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              label="Middle Name"
                              fullWidth
                              margin="normal"
                              disabled={loading}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="last_name"
                              value={values.last_name}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              error={!!touched.last_name && !!errors.last_name}
                              helperText={
                                !!touched.last_name &&
                                !!errors.last_name &&
                                errors.last_name
                              }
                              label="Last Name"
                              fullWidth
                              margin="normal"
                              disabled={loading}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="phone"
                              value={values.phone}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              error={!!touched.phone && !!errors.phone}
                              helperText={
                                !!touched.phone &&
                                !!errors.phone &&
                                errors.phone
                              }
                              label="Primary Phone Number"
                              fullWidth
                              disabled={loading}
                              margin="normal"
                              InputProps={{
                                inputComponent: PhoneMask,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              name="mobile"
                              value={values.mobile}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              error={!!touched.mobile && !!errors.mobile}
                              helperText={
                                !!touched.mobile &&
                                !!errors.mobile &&
                                errors.mobile
                              }
                              label="Secondary Phone Number"
                              disabled={loading}
                              fullWidth
                              margin="normal"
                              InputProps={{
                                inputComponent: PhoneMask,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                name="gender"
                                label="Gender"
                                margin="normal"
                                value={values.gender}
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({ changeField: true });
                                }}
                                onBlur={handleBlur}
                                error={!!touched.gender && !!errors.gender}
                                helperText={
                                  !!touched.gender &&
                                  !!errors.gender &&
                                  errors.gender
                                }
                                fullWidth
                                select
                                disabled={loading}
                                InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem value="Female">Female</MenuItem>
                              <MenuItem value="Male">Male</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="email"
                              value={values.email}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              error={!!touched.email && !!errors.email}
                              helperText={
                                !!touched.email &&
                                !!errors.email &&
                                errors.email
                              }
                              label="Email Address"
                              fullWidth
                              disabled={loading}
                              margin="normal"
                              type="email"
                            />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <KeyboardDatePicker
                              name="birthdate"
                              label="Date of Birth"
                              value={values.birthdate || null}
                              margin="normal"
                              format={"MM/DD/YYYY"}
                              onChange={(date) => {
                                this.setState({ changeField: true });
                                if (date && date.isValid()) {
                                  this.setState({ errorBirthdate: false });
                                  setFieldValue("birthdate", date);
                                } else {
                                  if (date)
                                    this.setState({ errorBirthdate: true });
                                  else {
                                    setFieldValue("birthdate", "");
                                    this.setState({ errorBirthdate: false });
                                  }
                                }
                              }}
                              onBlur={handleBlur}
                              error={errorBirthdate}
                              helperText={errorBirthdate && "Invalid date"}
                              fullWidth
                              animateYearScrolling
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="calendar">
                                      <Today />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="MM/DD/YYYY"
                            />
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <TextField
                              name={"age"}
                              // disabled={true}
                              margin="normal"
                              autoFocus={true}
                              value={this.getAge(values.birthdate)}
                              // className={this.getAge(values.birthdate) === 20 ? classes.textRedInput: classes.textBlackInput}
                              InputProps={{
                                className: this.getAge(values.birthdate) === 20 ? classes.textRedInput: classes.textBlackInput
                                // className: classes.textRedInput
                              }}
                              label="Age"
                              fullWidth
                            />
                          </Grid>

                          <Grid item xs={12} sm={5}>
                            <TextField
                              name="status"
                              label="Status"
                              margin="normal"
                              value={values.status}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              error={!!touched.status && !!errors.status}
                              helperText={
                                !!touched.status &&
                                !!errors.status &&
                                errors.status
                              }
                              fullWidth
                              select
                              disabled={loading}
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem value="Active">Active</MenuItem>
                              <MenuItem value="Inactive">Inactive</MenuItem>
                              <MenuItem value="Deceased">Deceased</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormControlLabel
                              name="call_before_delivery"
                              control={
                                <Switch
                                  checked={values.call_before_delivery}
                                  onChange={(e) => {
                                    this.setState({ changeField: true });
                                    setFieldValue(
                                      "call_before_delivery",
                                      e.target.checked
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  value="1"
                                  color="primary"
                                />
                              }
                              label="Contact prior to delivery"
                            />
                          </Grid>
                          {(() => {
                            if (values.call_before_delivery) {
                              return (
                                <Grid item xs={12} sm={6}>
                                  <FormControlLabel
                                    name="contact_different_patient"
                                    control={
                                      <Switch
                                        checked={
                                          values.contact_different_patient
                                        }
                                        onChange={(e) => {
                                          this.setState({ changeField: true });
                                          this.setState( {contact_different_patient: !this.state.contact_different_patient})
                                          setFieldValue(
                                            "contact_different_patient",
                                            e.target.checked
                                          );

                                          if (
                                            values.contact_different_patient &&
                                            !values.contact_person.length
                                          ) {
                                            setFieldValue("contact_person", [
                                              {
                                                contact_name: "",
                                                contact_number: "",
                                                contact_relation: "",
                                              },
                                            ]);
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        value="1"
                                        color="primary"
                                      />
                                    }
                                    label="Contact different than patient"
                                  />
                                </Grid>
                              );
                            }
                          })()}
                          {(() => {
                            if (
                              values.call_before_delivery &&
                              values.contact_different_patient
                            ) {
                              return <ContactList />;
                            }
                          })()}
                        </Grid>
                      </Form>
                    </Panel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DialogDelete
                        item="element"
                        open={this.state.openDelete}
                        closeModal={() => this.handleDeleteBtnClick(false)}
                        remove={this.handleElementDelete}
                        isDelivery={false}
                    />
                    <DialogConfirmation
                        item="element"
                        open={this.state.openConfirmation}
                        closeModal={() => this.handleConfirmationBtnClick(false)}
                        remove={this.handleOrderConfirmation}
                        isDelivery={false}
                    />
                    <Paper classes={{ root: classes.tabsContainer }}>
                      <Tabs
                        value={tab}
                        onChange={this.handleTab}
                        variant="standard"
                        scrollButtons="auto"
                      >
                        <Tab
                          classes={{ root: classes.tabContainer }}
                          icon={<Place />}
                        />
                        <Tab
                          classes={{ root: classes.tabContainer }}
                          icon={<Note />}
                        />
                        <Tab
                          classes={{ root: classes.tabContainer }}
                          icon={<OrderIcon />}
                        />
                        <Tab
                          classes={{ root: classes.tabContainer }}
                          icon={<VerifiedUser />}
                        />
                        <Tab
                            classes={{ root: classes.tabContainer }}
                            icon={<Work />}
                        />
                        <Tab
                          classes={{ root: classes.tabContainer }}
                          icon={<AttachFile />}
                        />
                        <Tab
                          classes={{ root: classes.tabContainer }}
                          icon={<Label />}
                        />
                      </Tabs>

                      {tab === 0 && (
                        <TabContainer>
                          <TextField
                            name="address_1"
                            value={values.address_1}
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ changeField: true });
                            }}
                            onBlur={handleBlur}
                            error={!!touched.address_1 && !!errors.address_1}
                            helperText={
                              !!touched.address_1 &&
                              !!errors.address_1 &&
                              errors.address_1
                            }
                            label="Address 1"
                            margin="normal"
                            fullWidth
                          />

                          <TextField
                            name="address_2"
                            value={values.address_2}
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ changeField: true });
                            }}
                            onBlur={handleBlur}
                            error={!!touched.address_2 && !!errors.address_2}
                            helperText={
                              !!touched.address_2 &&
                              !!errors.address_2 &&
                              errors.address_2
                            }
                            label="Address 2"
                            margin="normal"
                            fullWidth
                          />

                          <TextField
                            name="city"
                            value={values.city}
                            onChange={(e) => {
                              handleChange(e);
                              this.setState({ changeField: true });
                            }}
                            onBlur={handleBlur}
                            error={!!touched.city && !!errors.city}
                            helperText={
                              !!touched.city && !!errors.city && errors.city
                            }
                            label="City"
                            margin="normal"
                            fullWidth
                          />

                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              container
                              alignItems="flex-end"
                            >
                              <Autocomplete
                                onChange={(value) => {
                                  setFieldValue("state_id", value);
                                  this.setState({ changeField: true });
                                }}
                                value={values.state_id}
                                suggestions={us_states.map((state) => ({
                                  value: state.id,
                                  label: state.name,
                                }))}
                                className={ classes.paddingAutocomplete}
                                placeholder="State *"
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                name="zip"
                                value={values.zip || ""}
                                onChange={(e) => {
                                  handleChange(e);
                                  this.setState({ changeField: true });
                                }}
                                onBlur={handleBlur}
                                error={!!touched.zip && !!errors.zip}
                                helperText={
                                  !!touched.zip && !!errors.zip && errors.zip
                                }
                                label="Zip"
                                margin="normal"
                                fullWidth
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <label className={ classes.labelCheckBox }>Facility</label>
                              <FormControlLabel
                                  name="facility"
                                  control={
                                    <Switch
                                        checked={values.facility}
                                        onChange={(e) => {
                                          this.setState({ changeField: true });
                                          setFieldValue(
                                              "facility",
                                              e.target.checked
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value="1"
                                        color="primary"
                                    />
                                  }
                                  label={values.facility ? "Yes" : "No"}
                                  labelPlacement={values.facility ? "start" : "end"}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <label className={ classes.labelCheckBox }>Rentals</label>
                              <FormControlLabel
                                  name="rentals"
                                  control={
                                    <Switch
                                        checked={values.rentals}
                                        onChange={(e) => {
                                          this.setState({ changeField: true });
                                          setFieldValue(
                                              "rentals",
                                              e.target.checked
                                          );
                                        }}
                                        onBlur={handleBlur}
                                        value="1"
                                        color="primary"
                                    />
                                  }
                                  label={values.rentals ? "Yes" : "No"}
                                  labelPlacement={values.rentals ? "start" : "end"}
                              />
                            </Grid>
                          </Grid>
                        </TabContainer>
                      )}
                      {tab === 1 && (
                        <TabContainer>
                          <FormControl component="fieldset" fullWidth>
                            <FormGroup>
                              <TextField
                                name="notes"
                                value={values.notes}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.notes && !!errors.notes}
                                helperText={
                                  !!touched.notes &&
                                  !!errors.notes &&
                                  errors.notes
                                }
                                label="General Notes"
                                fullWidth
                                margin="normal"
                                multiline
                                rows={19}
                              />
                            </FormGroup>
                          </FormControl>
                        </TabContainer>
                      )}
                      {tab === 2 && (
                        <TabContainer>
                          <div className={classes.headSection}>
                            <FormLabel component="legend">Orders</FormLabel>
                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              onClick={() => {
                                this.props.setPatientIdOrigin(this.state.id);
                                this.props.history.push("/orders/create");
                              }}
                            >
                              Add New
                            </Button>
                          </div>

                          <List
                            role={undefined}
                            className={classes.listSection}
                          >
                            {patient.orders.map((order, index) => (
                              <OrderItem
                                order={order}
                                patient={patient}
                                loading={loading}
                                setLoading={this.props.setLoading}
                                key={order.id}
                                getPatient={() =>
                                  this.props.getPatient(this.state.id)
                                }
                                setPatientIdOrigin={() =>
                                  this.props.setPatientIdOrigin(this.state.id)
                                }
                                handleOpenModalDelete={this.handleOpenModalDelete}
                                handleOpenModalConfirmation={this.handleOpenModalConfirmation}
                              />
                            ))}
                          </List>
                        </TabContainer>
                      )}
                      {tab === 3 && (
                          <TabContainer>
                            <Paper classes={{ root: classes.tabsContainer }}>
                              <InsurancesTab
                                  loading={loading}
                                  insurances={patient.insurances}
                                  addInsurance={this.handleAddInsurance}
                                  handleOpenModalDelete={this.handleOpenModalDelete}
                                  updateInsurance={this.handleUpdateInsurance}
                              />
                            </Paper>
                            <Paper classes={{ root: classes.tabsContainer2 } }>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <TextField
                                      name="physician_name"
                                      value={values.physician_name}
                                      onChange={(e) => {
                                        handleChange(e);
                                        this.setState({ changeField: true });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        !!touched.physician_name &&
                                        !!errors.physician_name
                                      }
                                      helperText={
                                        !!touched.physician_name &&
                                        !!errors.physician_name &&
                                        errors.physician_name
                                      }
                                      label="Physician Name"
                                      fullWidth
                                      margin="normal"
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                      name="physician_npi"
                                      value={values.physician_npi}
                                      onChange={(e) => {
                                        handleChange(e);
                                        this.setState({ changeField: true });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        !!touched.physician_npi &&
                                        !!errors.physician_npi
                                      }
                                      helperText={
                                        !!touched.physician_npi &&
                                        !!errors.physician_npi &&
                                        errors.physician_npi
                                      }
                                      label="NPI"
                                      fullWidth
                                      margin="normal"
                                      InputProps={{
                                        inputComponent: NpiMask,
                                      }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                      name="physician_phone"
                                      value={values.physician_phone}
                                      onChange={(e) => {
                                        handleChange(e);
                                        this.setState({ changeField: true });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        !!touched.physician_phone &&
                                        !!errors.physician_phone
                                      }
                                      helperText={
                                        !!touched.physician_phone &&
                                        !!errors.physician_phone &&
                                        errors.physician_phone
                                      }
                                      label="Phone"
                                      fullWidth
                                      margin="normal"
                                      InputProps={{
                                        inputComponent: PhoneMask,
                                      }}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                      name="physician_fax"
                                      value={values.physician_fax}
                                      onChange={(e) => {
                                        handleChange(e);
                                        this.setState({ changeField: true });
                                      }}
                                      onBlur={handleBlur}
                                      error={
                                        !!touched.physician_fax &&
                                        !!errors.physician_fax
                                      }
                                      helperText={
                                        !!touched.physician_fax &&
                                        !!errors.physician_fax &&
                                        errors.physician_fax
                                      }
                                      label="Fax"
                                      fullWidth
                                      margin="normal"
                                      InputProps={{
                                        inputComponent: PhoneMask,
                                      }}
                                  />
                                </Grid>
                              </Grid>

                              <div className={classes.headSection}>
                                <FormLabel component="legend">Diagnosis</FormLabel>
                                <Button
                                    onClick={() =>
                                        this.setState({
                                          openDialog: true,
                                          action: "create",
                                          dialog_type: DIALOG_TYPE.NEW_DIAGNOSIS,
                                        })
                                    }
                                    color="secondary"
                                    // variant="outlined"
                                    size="medium"
                                    disabled={loading}
                                >
                                  <IconButton
                                      color="primary"
                                      aria-label="delete"
                                  >
                                    <Add />
                                  </IconButton>
                                </Button>
                              </div>

                              <List
                                  role={undefined}
                                  className={classes.listSection}
                              >
                                {patient.diagnosis.map((diagnosis, index) => (
                                    <ListItem key={diagnosis.id}>
                                      <InsertDriveFile />
                                      <div style={{display: "flex"}}>
                                        <div style={{flex: 4}}>
                                          <ListItemText
                                              primary={`DX #${index + 1}`}
                                              secondary={diagnosis.text}
                                              // secondaryTypographyProps={{
                                              //   id: `diagnosis-item-${diagnosis.id}`,
                                              // }}
                                          />
                                        </div>
                                        <div style={{flex: 1}}>
                                          <ListItemSecondaryAction>
                                            <IconButton
                                                aria-label="Comments"
                                                color="primary"
                                                onClick={() =>
                                                    this.setState({
                                                      dialog_type: DIALOG_TYPE.EDIT_DIAGNOSIS,
                                                      diagnosticsId: diagnosis.id,
                                                      openDialog: true,
                                                      action: "update",
                                                      dialogText: diagnosis.text
                                                    })
                                                }
                                            >
                                              <Edit />
                                            </IconButton>
                                            <IconButton
                                                aria-label="Comments"
                                                onClick={() =>
                                                    // this.handleDeleteDiagnosis(diagnosis.id)
                                                    this.handleOpenModalDelete(diagnosis.id, "diagnosis")
                                                }
                                                disabled={loading}
                                                className={classes.iconDelete}
                                            >
                                              <Delete />
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        </div>
                                      </div>
                                    </ListItem>
                                ))}
                              </List>

                              <div className={classes.headSection}>
                                <FormLabel component="legend">HCPCS</FormLabel>
                                <Button
                                    onClick={() =>
                                        this.setState({
                                          openDialog: true,
                                          action: "create",
                                          dialog_type: DIALOG_TYPE.NEW_HCPCS,
                                        })
                                    }
                                    color="secondary"
                                    // variant="outlined"
                                    size="medium"
                                    disabled={loading}
                                >
                                  <IconButton
                                      color="primary"
                                      aria-label="delete"
                                  >
                                    <Add />
                                  </IconButton>
                                </Button>
                              </div>
                              <List
                                  role={undefined}
                                  className={classes.listSection}
                              >
                                {!!patient.health &&
                                patient.health.map((health, index) => (
                                    <>
                                      <ListItem key={health.id}>
                                        <InsertDriveFile />
                                        <div style={{display: "flex"}}>
                                            <div style={{flex: 4}}>
                                              <ListItemText
                                                  primary={`HCPCS #${index + 1}`}
                                                  secondary={health.text}
                                              />
                                            </div>
                                            <div style={{flex: 1}}>
                                              <ListItemSecondaryAction>
                                                <IconButton
                                                    aria-label="Comments"
                                                    color="primary"
                                                    onClick={() =>
                                                        this.setState({
                                                          dialog_type: DIALOG_TYPE.EDIT_HCPCS,
                                                          hcpcsId: health.id,
                                                          openDialog: true,
                                                          action: "update",
                                                          dialogText: health.text
                                                        })
                                                    }
                                                >
                                                  <Edit />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="Comments"
                                                    onClick={() =>
                                                        this.handleOpenModalDelete(health.id, "hcps")
                                                    }
                                                    disabled={loading}
                                                    className={classes.iconDelete}
                                                >
                                                  <Delete />
                                                </IconButton>
                                                <Button
                                                    onClick={() =>
                                                        this.setState({
                                                          openDialog: true,
                                                          hcpcsId: health.id,
                                                          action: "create",
                                                          dialog_type:
                                                          DIALOG_TYPE.NEW_MODIFIER,
                                                        })
                                                    }
                                                    color="secondary"
                                                    // variant="outlined"
                                                    size="small"
                                                    disabled={loading}
                                                >
                                                  <AddBox />
                                                </Button>
                                              </ListItemSecondaryAction>
                                            </div>
                                        </div>
                                      </ListItem>

                                      <List
                                          role={undefined}
                                          style={{ marginLeft: 16 }}
                                      >
                                        {!!health.modifier &&
                                        health.modifier.map((modifier, index) => (
                                            <ListItem key={modifier.id}>
                                              <InsertDriveFile />
                                              <div style={{display: "flex"}}>
                                                <div style={{flex: 4}}>
                                                  <ListItemText
                                                      primary={`Modifier #${index + 1}`}
                                                      secondary={modifier.text}
                                                  />
                                                </div>
                                                <div style={{flex: 1}}>
                                                  <ListItemSecondaryAction>
                                                    <IconButton
                                                        aria-label="Comments"
                                                        color="primary"
                                                        onClick={() =>
                                                            this.setState({
                                                              openDialog: true,
                                                              hcpcsId: health.id,
                                                              dialog_type:
                                                              DIALOG_TYPE.EDIT_MODIFIER,
                                                              modifierId: modifier.id,
                                                              action: "update",
                                                              dialogText: modifier.text
                                                            })
                                                        }
                                                    >
                                                      <Edit />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="Comments"
                                                        onClick={() =>
                                                            this.handleOpenModalDelete(modifier.id, "modifier")
                                                        }
                                                        disabled={loading}
                                                        className={classes.iconDelete}
                                                    >
                                                      <Delete />
                                                    </IconButton>
                                                  </ListItemSecondaryAction>
                                                </div>
                                              </div>
                                            </ListItem>
                                        ))}
                                      </List>
                                    </>
                                ))}
                              </List>
                            </Paper>
                          </TabContainer>
                      )}
                      {tab ===4 &&(
                          <TabContainer>

                          </TabContainer>
                      )}
                      {tab === 5 && (
                        <TabContainer>
                          <div className={classes.headSection}>
                            <FormLabel component="legend">
                              Attachments
                            </FormLabel>

                            <input
                              style={{ display: "none" }}
                              ref={(inputFile) => (this.inputFile = inputFile)}
                              type="file"
                              onChange={this.handleAddAttachment}
                            />

                            <Button
                              color="primary"
                              variant="outlined"
                              size="small"
                              onClick={() => this.inputFile.click()}
                            >
                              Add New
                            </Button>
                          </div>

                          <List role={undefined}>
                            {patient.attachments.map((attachment) => (
                              <ListItem key={attachment.id}>
                                <AttachFile />

                                <ListItemText
                                  primary={
                                    <Link
                                      className={classes.link}
                                      href={attachment.url}
                                      target="_blank"
                                    >
                                      {attachment.name}
                                    </Link>
                                  }
                                />

                                <ListItemSecondaryAction>
                                  <IconButton
                                    aria-label="Comments"
                                    onClick={() =>
                                        this.handleOpenModalDelete(attachment.id, "attachment")
                                    }
                                    className={classes.iconDelete}
                                  >
                                    <Delete />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>

                          <TextField
                            style={{ display: "none" }}
                            label="File"
                            type="file"
                            fullWidth
                            margin="normal"
                          />
                        </TabContainer>
                      )}
                      {tab === 6 && (
                        <TabContainer>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Tags</FormLabel>
                            <FormGroup>
                              {activeTags.map((tag) => {
                                return (
                                  <FormControlLabel
                                    key={tag.id}
                                    control={
                                      <Checkbox
                                        checked={tag.checked}
                                        onChange={this.handleTag(tag.id)}
                                        disabled={loading}
                                      />
                                    }
                                    label={tag.name}
                                  />
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        </TabContainer>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                <br />
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={
                    loading ||
                    (isValid && !dirty) ||
                    (!isValid && dirty) ||
                    errorBirthdate
                  }
                >
                  Update Patient
                </Button>
              </React.Fragment>
            );
          }}
        </Formik>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {(() => {
              if( this.state.action === "create"){
                if (this.state.dialog_type === DIALOG_TYPE.NEW_HCPCS)
                  return "New HCPCS";
                if (this.state.dialog_type === DIALOG_TYPE.NEW_MODIFIER)
                  return "New Modifier";
                return "New Diagnosis";
              }
              else{
                if (this.state.dialog_type === DIALOG_TYPE.EDIT_HCPCS)
                  return "Edit HCPCS";
                if (this.state.dialog_type === DIALOG_TYPE.EDIT_DIAGNOSIS)
                  return "Edit Diagnosis";
                return "Edit Modifier";
              }

            })()}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {(() => {

                if (this.state.dialog_type === DIALOG_TYPE.NEW_HCPCS || this.state.dialog_type === DIALOG_TYPE.EDIT_HCPCS)
                  return "Enter the patient's HCPCS";
                if (this.state.dialog_type === DIALOG_TYPE.NEW_DIAGNOSIS || this.state.dialog_type === DIALOG_TYPE.EDIT_DIAGNOSIS)
                  return "Enter the patient's diagnosis";
                return "Enter the patient's modifier";
              })()}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              value={dialogText}
              onChange={(e) => this.setState({ dialogText: e.target.value })}
              label=""
              multiline
              rows={4}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleCloseDialog}
              color="primary"
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              onClick={ action === "update" ?  this.handleEditItemFromDialog : this.handleAddItemFromDialog}
              color="primary"
              disabled={loading || dialogText.length === 0}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    us_states: state.global.us_states,
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    errors: new Errors(state.patients.errors),
    patient: state.patients.patient,
    patientIdOrigin: state.orders.patientIdOrigin,
    tags: state.patients.tags,
    orders: state.orders.list,
  };
};

const mapDispatchToProps = {
  getPatient,
  updatePatient,
  setLoading,
  fetchStates,
  getTags,
  deleteOrder,
  getOrdersByPatient,
  addTag,
  removeTag,
  addDiagnosis,
  updateModifier,
  updateHCPCS,
  updateDiagnostics,
  removeDiagnosis,
  addHCPCS,
  removeHCPCS,
  addModifier,
  removeModifier,
  addAttachment,
  removeAttachment,
  addInsurance,
  removeInsurance,
  setHandleForm,
  updateInsurance,
  setPatientIdOrigin,
  cloningOrder
};
export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "PatientEdit" }),
  connect(mapStateToProps, mapDispatchToProps)
)(PatientEdit);
