import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import {
    withStyles,
    Grid,
    IconButton,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody, Table, TextField, Collapse, Box, Typography, Chip, Switch, TablePagination, FormControlLabel, InputBase
} from "@material-ui/core";
import {
    Layout,
    SimpleBreadcrumbs,
    Panel,
    InputSearch, DialogDelete,
} from "../../../components";
import { setLoading } from "../../../redux/actions/globalActions";
import styles from "./styles";
import {
    getRevertRemittances,
    setRemittanceFilters,
    deleteOcurrencePayment,
} from "../../../redux/actions/remmitanceRevertAction";
import {Cancel, CheckCircle, Delete, Edit, FileCopy, Payment, Search} from "@material-ui/icons";
import _debounce from "lodash/debounce";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ServicesTab from "../../Orders/OrderEdit/ServicesTab";
import formatNumber, {formatNumberWithOutComma} from "../../../common/Helpers/FormatNumberHelper";
import Link from "@material-ui/core/Link";
import classNames from "classnames";
import PdfIcon from "@material-ui/core/SvgIcon/SvgIcon";
import TableResponsive from "../../../components/TableResponsive";
import TableResponsiveAccordion from "../../../components/TableResponsiveAccordion";

const breadcrumbs = [
    { name: "Home", to: "/home" },
    { name: "Remittance", to: null },
];



function Row(data) {
    const { remittance, props } = data;
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [elementToDelete, setElementToDelete] = React.useState(null);
    // const [ocurrence_po, setOcurrencePo] = React.useState( ocurrence.po_number);


    async function handleDeleteRemmitancePayment(){
        props.setLoading(true);

        try {
            const response = await props.deleteOcurrencePayment(elementToDelete);

            if (response.status === 204) {
                await props.getRevertRemittances();
                this.props.enqueueSnackbar("Unremit successfully!!", {
                    variant: "success",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setOpenDelete(false)
            setElementToDelete(null)
            props.setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <TableRow key={remittance.id}>
                <TableCell component="th" scope="row">
                    {remittance.check_number}
                </TableCell>
                <TableCell>
                    {moment(remittance.check_date).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                    {remittance.total_amount_check}
                </TableCell>
                <TableCell>
                    {remittance.vendor}
                </TableCell>
                <TableCell>
                    {remittance.total_amount_remmited.toFixed(2)}
                </TableCell>
                <TableCell style={{textAlign: "center"}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <DialogDelete
                                item="service"
                                open={openDelete}
                                closeModal={() => setOpenDelete(false)}
                                remove={handleDeleteRemmitancePayment}
                                isDelivery={false}
                            />
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TableSortLabel
                                            >
                                                Patient Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                            >
                                                Invoice Number
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                            >
                                                DOS
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell>
                                            <TableSortLabel
                                            >
                                                Amount Applied
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {remittance.remmitance_payments.map((remmitance_payment) => (
                                        <TableRow key={remmitance_payment.id}>
                                            <TableCell component="th" scope="row">
                                                {remmitance_payment.patient}
                                            </TableCell>
                                            <TableCell>{remmitance_payment.invoice}</TableCell>
                                            <TableCell>{remmitance_payment.date_of_service|| "0"}</TableCell>
                                            <TableCell>{ remmitance_payment.amount_applied}</TableCell>
                                            <TableCell>
                                                <Grid container justify="center" wrap="nowrap">
                                                    <IconButton
                                                        onClick={() =>{
                                                            setOpenDelete(true);
                                                            setElementToDelete(remmitance_payment.id)}
                                                        }
                                                    >
                                                        <Delete color="secondary" />
                                                    </IconButton>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


class RemittanceRevertList extends React.Component {

    config = {
        columns: [
            {
                display_name: "Check Number",
                name: "check_number",
                type: "string",
                align: "left",
                sorted: true,
                sorted_name: "check_number",
            },
            {
                display_name: "Check Date",
                name: "check_date",
                type: "date",
                align: "left",
                sorted: true,
                sorted_name: "check_date",
            },
            {
                display_name: "Total Amount per Check",
                name: "total_amount_check",
                type: "number",
                align: "left",
                sorted: true,
                sorted_name: "total_amount_check",
            },
            {
                display_name: "Vendor",
                name: "vendor",
                type: "string",
                align: "left",
                sorted: true,
                sorted_name: "vendor",
            },
            {
                display_name: "Total Amount Remitted",
                name: "total_amount_remitted",
                type: "price",
                align: "left",
                sorted: true,
                sorted_name: "total_amount_remitted",
            },
            {
                display_name: "Actions",
                name: "actions",
                align: "center",
            },
        ],
        pagination: true,
    };


    state = {
        open: false,
        currentService: {},
        selectFocus: false,
    };

    componentDidMount = async () => {
        try {
            await this.fetchRemittances();
        } catch (error) {
            console.error(error);
        }
    };

    fetchRemittances = () => {
        return this.props.getRevertRemittances();
    };

    fetchRemittancesDelayed = _debounce((e) => {
        return this.fetchRemittances();
    }, 1500);

     _handleChangePage = (event, newPage) => {
         this.props.setRemittanceFilters({
            page: newPage + 1, //table pagination of material ui is ZERO-based
        });
        this.fetchRemittances();
    };

     _handleChangeRowsPerPage = (event) => {
        this.props.setRemittanceFilters({
            page_size: parseInt(event.target.value, 10), //table pagination of material ui is ZERO-based,
            page: 1,
        });
       this.fetchRemittances();
    };

    handleSearch = (event) => {
        //to future move to tableResponsive component
        this.props.setRemittanceFilters({
            search: event.target.value,
            page: 1,
        });
        this.fetchRemittancesDelayed.cancel();
        this.fetchRemittancesDelayed();
    };

    handleDeleteRemmitancePayment = async (hcpcsId) => {
        this.props.setLoading(true);

        try {
            const patientId = this.state.id;
            const response = await this.props.removeHCPCS(patientId, hcpcsId);

            if (response.status === 204) {
                await this.props.getPatient(patientId);
                this.props.enqueueSnackbar("HCPCS Deleted successfully!", {
                    variant: "success",
                });
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.handleOpenModalDeleteNull();
            this.props.setLoading(false);
        }
    };


    render() {
        let { classes, remittances, filters } = this.props;
        const { open } = this.state

        return (
            <Layout title="Remittance Revert">
                <SimpleBreadcrumbs routes={breadcrumbs} />

                <Panel>
                    <Grid container justify="flex-end" className={classes.divSearch}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <Search />
                            </div>
                            <InputBase
                                name="search"
                                placeholder="Search..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={filters.search}
                                onChange={this.handleSearch}
                            />
                        </div>
                    </Grid>

                    <div className={classes.tableContainer}>
                        <TableResponsiveAccordion
                            items={remittances}
                            config={this.config}
                            emptyMessage="REMITTANCES"
                            metaFilters={{
                                filters: this.props.filters,
                                setFilters: this.props.setRemittanceFilters,
                            }}
                            fetchData={this.fetchRemittances}
                            props={this.props}
                        />

                        {/*<Table>*/}
                        {/*    <TableHead>*/}
                        {/*        <TableRow>*/}
                        {/*            <TableCell>*/}
                        {/*                <TableSortLabel>*/}
                        {/*                    Check Number*/}
                        {/*                </TableSortLabel>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                <TableSortLabel>*/}
                        {/*                    Check Date*/}
                        {/*                </TableSortLabel>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                <TableSortLabel>*/}
                        {/*                    Total Amount per Check*/}
                        {/*                </TableSortLabel>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                <TableSortLabel>*/}
                        {/*                    Vendor*/}
                        {/*                </TableSortLabel>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell>*/}
                        {/*                <TableSortLabel>*/}
                        {/*                    Total Amount Remitted*/}
                        {/*                </TableSortLabel>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell align="center">Actions</TableCell>*/}
                        {/*        </TableRow>*/}
                        {/*    </TableHead>*/}
                        {/*    <TableBody>*/}
                        {/*        {remittances.map((remittance) => (*/}
                        {/*            <Row key={remittance.id} remittance={remittance} props={this.props}/>*/}
                        {/*        ))}*/}
                        {/*    </TableBody>*/}
                        {/*</Table>*/}
                        {/*<TablePagination*/}
                        {/*    rowsPerPageOptions={[10, 15, 25, 100]}*/}
                        {/*    component="div"*/}
                        {/*    count={filters.count}*/}
                        {/*    rowsPerPage={filters.page_size}*/}
                        {/*    page={filters.page - 1}*/}
                        {/*    onChangePage={this._handleChangePage}*/}
                        {/*    onChangeRowsPerPage={this._handleChangeRowsPerPage}*/}
                        {/*/>*/}
                    </div>
                </Panel>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        remittances: state.remmitanceReverts.list,
        filters: state.remittance.filters,
        loading: state.global.loading,
    };
};

const mapDispatchToProps = {
    setLoading,
    getRevertRemittances,
    setRemittanceFilters,
    deleteOcurrencePayment
};

export default compose(
    withRouter,
    withSnackbar,
    withStyles(styles, { name: "RemittanceList" }),
    connect(mapStateToProps, mapDispatchToProps)
)(RemittanceRevertList);
