function formatNumber(number, minDecimals = 2, maxDecimals = 3) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(number);
}

function formatNumberWithOutComma(number, minDecimals = 2, maxDecimals = 3) {
  const numberFormated = formatNumber(number, minDecimals, maxDecimals);
  try {
    if (
      `${numberFormated}`.includes(",") &&
      `${numberFormated}`.includes(".")
    ) {
      return `${numberFormated}`.replace(/,/, "");
    }
    return `${numberFormated}`;
  } catch (error) {
    console.log(error);
    return "0.0";
  }
}

function formatNumberWithOutCommaDecimal(number, minDecimals = 2, maxDecimals = 2) {
  const numberFormated = formatNumber(number, minDecimals, maxDecimals);
  try {
    if (
        `${numberFormated}`.includes(",") &&
        `${numberFormated}`.includes(".")
    ) {
      return `${numberFormated}`.replace(/,/, "");
    }
    return `${numberFormated}`;
  } catch (error) {
    console.log(error);
    return "0.0";
  }
}

export default formatNumber;
export { formatNumberWithOutComma, formatNumberWithOutCommaDecimal};
