import React from "react";
import {connect} from "react-redux";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withSnackbar} from "notistack";
import {Button, Grid, IconButton, Input, Link, TextField, withStyles} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {UNITS} from "../../../common/Constants/ProductConstants";
import {InputSearch, Layout, Panel, SimpleBreadcrumbs,} from "../../../components";
import styles from "./styles";
import {ReactComponent as ReplaceSvg} from '../../../replace.svg';
import {
  addQtyPerUnitMeasure,
  deleteProduct,
  getProducts,
  reportProductsPdf,
  setProductFilters,
} from "../../../redux/actions/productActions";
import {setLoading} from "../../../redux/actions/globalActions";
import TableResponsive from "../../../components/TableResponsive";
import _debounce from "lodash/debounce";
import moment from "moment";
import PdfIcon from "@material-ui/icons/CloudDownload";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Products", to: null },
];

class ProductInventory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      open: false,
      productId: "",
    };
  }

  config = {
    columns: [
      {
        display_name: "Reference Number",
        name: "internal_code",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      this.props.history.push(
                          `/products/${item.id}`
                          // `/patients/${item.order.patient.id}`
                      );
                    }}
                    style={{ textAlign: "left" }}
                >
                  {item.internal_code}
                </Link>
            );
          },
        },
        align: "left",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Add",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { classes } = this.props;

            return (
                <div style={{width: 40, display: 'flex'}}>
                  <div className={classes.actionButtons}>
                    <IconButton
                        color="primary"
                        aria-label="delete"
                        onClick={() => this.calculateTotal(item, item.quantity)}
                    >
                      <Add />
                    </IconButton>
                  </div>
                </div>
            );
          },
        },
        align: "center",
      },
      {
        display_name: "Name",
        name: "name",
        type: "string",
        align: "left",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Each",
        name: "name",
        type: "custom",
        custom: {
          render: (item, column) => {
            if (this.existUnitMeasure(item, "Each")){
              return(
                <div style={{width: 80}}>
                  <TextField
                      name={item.id + "_Each"}
                      margin="normal"
                      type="number"
                      // value={it}
                      // onChange={(e) => calculate(e.target.value)}
                      // onBlur={updateOcurrencePo}
                      label=""
                      // disabled={loading}
                      rows={1}
                  />
                </div>

              )}
          },
        },
        align: "inherit",
        sorted: false,
      },
      {
        display_name: "Bag",
        type: "custom",
        custom: {
          render: (item, column) => {
            if (this.existUnitMeasure(item, "Bag")){
              return(
                  <div style={{width: 80}}>
                    <TextField
                        name={item.id + "_Bag"}
                        margin="normal"
                        label=""
                        rows={1}
                    />
                  </div>

              )}
          },
        },
        align: "inherit",
        sorted: false,
      },
      {
        display_name: "Case",
        type: "custom",
        custom: {
          render: (item, column) => {
            if (this.existUnitMeasure(item, "Case")){
              return(
                  <div style={{width: 80}}>
                    <TextField
                        name={item.id + "_Case"}
                        margin="normal"
                        // value={item.qty_unit_measures}
                        // onChange={(e) => calculate(e.target.value)}
                        // onBlur={updateOcurrencePo}
                        label=""
                        // disabled={loading}
                        rows={1}
                    />
                  </div>

              )}
          },
        },
        align: "inherit",
        sorted: false,
      },
      {
        display_name: "Pack",
        type: "custom",
        custom: {
          render: (item, column) => {
            if (this.existUnitMeasure(item, "Pack")){
              return(
                  <div style={{width: 80}}>
                    <TextField
                      name={item.id + "_Pack"}
                      margin="normal"
                      // value={item.qty_unit_measures}
                      // onChange={(e) => calculate(e.target.value)}
                      // onBlur={updateOcurrencePo}
                      label=""
                      // disabled={loading}
                      rows={1}
                    />
                  </div>

              )}
          },
        },
        align: "inherit",
        sorted: false,
      },
      {
        display_name: "Hours",
        type: "custom",
        custom: {
          render: (item, column) => {
            if (this.existUnitMeasure(item, "Hours")){
              return(
                  <div style={{width: 80}}>
                    <TextField
                        name={item.id + "_Hours"}
                        margin="normal"
                        // value={item.qty_unit_measures}
                        // onChange={(e) => calculate(e.target.value)}
                        // onBlur={updateOcurrencePo}
                        label=""
                        // disabled={loading}
                        rows={1}
                    />
                  </div>
              )}
          },
        },
        align: "inherit",
        sorted: false,
      },
      {
        display_name: "Box",
        type: "custom",
        custom: {
          render: (item, column) => {
            if (this.existUnitMeasure(item, "Box")){
              return(
                  <div style={{width: 80}}>
                    <TextField
                        name={item.id + "_Box"}
                        margin="normal"
                        // value={item.qty_unit_measures}
                        // onChange={(e) => calculate(e.target.value)}
                        // onBlur={updateOcurrencePo}

                        label=""
                        // disabled={loading}
                        rows={1}
                    />
                  </div>
              )}
          },
        },
        align: "inherit",
        sorted: false,
      },
      {
        display_name: "Quantity on Hand",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { classes } = this.props;

            return (
              <div style={{ display: 'flex'}}>
                <Input
                    name={item.id + "total"}
                    margin="normal"
                    value={this.state[item.id+'_total'] || item.quantity}
                    onChange={(e) => this.setState({[item.id+'_total']: e.target.value })}
                    onBlur={(e) =>
                        this.handleUpdateQuantityOfHand(item.id, e.target.value)
                    }
                    label=""
                    // disabled={loading}
                    rows={1}
                />
                <div className={classes.actionButtons}>
                  <IconButton
                      color="secondary"
                      aria-label="Delete"
                      onClick={() =>
                          this.saveTotal(item, this.state[item.id+'_total'] || item.quantity)
                      }
                      disabled={this.props.loading}
                  >
                    <ReplaceSvg />
                  </IconButton>
                </div>
              </div>
            );
          },
        },
        align: "center",
      },
    ],
    pagination: true,
  };


  calculateTotal = (product, old_total, replace=false) => {
    let total = replace ? 0 : +old_total
    UNITS.forEach((unit) => {
      let input = document.getElementsByName(product.id+'_'+unit)
      if(input.length > 0){
        let value = input[0].value || 0;
        if (!isNaN(value)){
          let unit_measure = this.getValueUnitMeasure(product, unit)
          if ( unit_measure !== undefined && value !== undefined){
            total += +value * +unit_measure
          }
        }
      }
    })
    UNITS.forEach((unit) => {
      let input = document.getElementsByName(product.id+'_'+unit)
      if(input.length > 0){
        input[0].value = ""
      }
    })
    console.log('foo')
    // this.setState({[product.id+'_total']: total })
    this.handleUpdateQuantityOfHand(product.id, total)
  }

  saveTotal = (product, total) => {
    console.log("TOTAL", total)
    // this.calculateTotal(product, total, true)
    this.handleUpdateQuantityOfHand(product.id, total)
  }

  getValueUnitMeasure = (item, unit) => {
    const qty_unit_measures = item.qty_unit_measures
    const unit_measure = qty_unit_measures.find(o => o.unit_measure === unit)
    if( !!unit_measure ){
      return unit_measure.quantity
    }
    return 0
  }

  existUnitMeasure = (item, unit) => {
    const qty_unit_measures = item.qty_unit_measures
    if(qty_unit_measures.length === 0){
      return false
    }
    const qty_unit_measure = qty_unit_measures.find(o => o.unit_measure === unit)

    if(qty_unit_measure.active){
      return true
    }
  }


  updateInput = (id, qty) => {
    const { products } = this.props;
    products.find((product) => product.id === id).quantity = qty
  }

  handleUpdateQuantityOfHand = async (product_id, total) => {
    this.props.setLoading(true);

    try {
      console.log("total_update", total)
      const body = {
        'product_id': product_id,
        'quantity': total
      }
      let response = null;
      response = await this.props.addQtyPerUnitMeasure(body);

      if (response.status === 200) {
        // await this.props.getPatient(id);
        this.props.enqueueSnackbar("Updated successfully!", {
          variant: "success",
        });
        const { products } = this.props;
        products.find((product) => product.id === product_id).quantity = total
        this.setState({[product_id+'_total']: total})

      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
      // this.setState({[product_id+'_total']: total })
      this.setState({ openDialog: false,  qty_per_type: '', unit_measure: ''});
    }
  };


  componentDidMount = async () => {
    // this.setState({search: window.search})

    try {
      await this.fetchProducts();
      // const { products } = this.props;
      // products.forEach((product) => {
      //   this.setState({[product.id+'_total']: product.quantity })
      // });
    } catch (e) {
      console.error(e);
    }
  };
  componentWillUnmount() {
    this.props.setProductFilters({
      search: this.state.search,
      page: 1,
    });
  }

  fetchProducts = () => {
    console.log(  "search",  this.state.search, "issue");
    return this.props.getProducts();
  };

  handleClean = () => {
    window.search = ""
    this.setState({ search: "" });
    this.props.setProductFilters({
      search: "",
      page: 1,
    });
    this.props.getProducts();
  }

  fetchProductsDelayed = _debounce((e) => {
    return this.fetchProducts();
  }, 1500);

  handleSearch = (event) => {
    //to future move to tableResponsive component
    window.search = event.target.value;
    this.setState({ search: event.target.value });
    this.props.setProductFilters({
      search: event.target.value,
      page: 1,
    });
    this.fetchProductsDelayed.cancel();
    this.fetchProductsDelayed();
  };


  reportProductsPdf = async () => {
    this.props.setLoading(true);
    try {
      const response = await this.props.reportProductsPdf();
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `InventoryReport-${moment().format('YYYY-MM-DD HH:mm')}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };


  render() {
    const { classes, loading, products, filters = {} } = this.props;

    return (
      <Layout title="Products Inventory">
        <div>
          <SimpleBreadcrumbs routes={breadcrumbs} />

          <Panel>
            <div className={classes.header}>
              <div style={{flex: 2}}>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    disabled={loading}
                    onClick={this.reportProductsPdf}
                >
                  Report PDF
                  <PdfIcon className={classes.rightIcon} />
                </Button>
              </div>
              <div style={{flex: 1}} >
                <InputSearch
                    value={this.state.search}
                    onChange={this.handleSearch}
                />
              </div>
            </div>

            <Grid container>
              <Grid item xs={12}>
                <TableResponsive
                  items={products}
                  config={this.config}
                  emptyMessage="PRODUCTS"
                  metaFilters={{
                    filters: this.props.filters,
                    setFilters: this.props.setProductFilters,
                  }}
                  fetchData={this.fetchProducts}
                />
              </Grid>
            </Grid>
          </Panel>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    products: state.products.list,
    filters: state.products.filters,
  };
};

const mapDispatchToProps = {
  getProducts,
  deleteProduct,
  reportProductsPdf,
  setLoading,
  setProductFilters,
  addQtyPerUnitMeasure,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ProductList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductInventory);
