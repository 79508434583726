import React, { useState } from "react";
import useStyles from "./styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  TextField
} from "@material-ui/core";

const DialogConfirmation = ({ ...props }) => {
  const [reason, setReason] = useState("");
  const { open, item, isDelivery } = props;
  const { buttonCancel, buttonAccept } = useStyles();

  function retireDeliveryOrRemove() {
    if (isDelivery){
      props.remove(reason)
      setReason("")
      return
    }
    return props.remove()
  };

  return (
    (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={() => props.closeModal(open)}
        onEscapeKeyDown={() => props.closeModal(open)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to clone this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={buttonCancel}
            onClick={() => props.closeModal(open)}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            className={buttonAccept}
            onClick={() => retireDeliveryOrRemove()}
            disabled={isDelivery ? reason.length === 0 : false}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    ) || ""
  );
};

export default DialogConfirmation;
