import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import { withStyles, Grid, IconButton } from "@material-ui/core";
import {
  Layout,
  SimpleBreadcrumbs,
  Panel,
  InputSearch,
} from "../../../components";
import { setLoading } from "../../../redux/actions/globalActions";
import { getShippingStatus } from "../../../redux/actions/shippingStatusActions";
import styles from "./styles";
import { getOrder } from "../../../redux/actions/orderActions";
import {
  getLastRemittancesPayment,
  getRemittance,
  getRemittances,
  setRemittanceFilters,
} from "../../../redux/actions/remittanceAction";
import { Payment } from "@material-ui/icons";
import RemittanceCheckDialog from "./RemittanceCheckDialog";
import TableResponsive from "../../../components/TableResponsive";
import _debounce from "lodash/debounce";
import Link from "@material-ui/core/Link";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Remittance", to: null },
];

class RemittanceList extends React.Component {
  state = {
    openDialog: false,
  };

  config = {
    columns: [
      // {
      //   display_name: "Patient Name",
      //   name: "patient_name",
      //   type: "string",
      //   align: "inherit",
      //   sorted: true,
      //   sorted_name: "patient_name",
      // },
      {
        display_name: "Patient Name",
        name: "patient_name",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      this.props.history.push(
                          `/patients/${item.order.patient_id}`
                          // `/patients/${item.order.patient.id}`
                      );
                    }}
                    style={{ textAlign: "left" }}
                >
                  {this.getPatientName(item.order)}
                </Link>
            );
          },
        },
        align: "left",
        sorted: true,
        sorted_name: "patient_name",
      },
      {
        display_name: "Invoice Number",
        name: "invoice",
        type: "number",
        align: "right",
        sorted: true,
        sorted_name: "invoice",
      },
      {
        display_name: "DOS",
        name: "date",
        type: "date",
        align: "right",
        sorted: true,
        sorted_name: "date",
      },
      {
        display_name: "Amount Remitted",
        name: "amount_remitted",
        type: "decimal",
        align: "center",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Invoice Amount",
        name: "invoice_amount",
        type: "price",
        align: "center",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Balance",
        name: "balance",
        type: "price",
        align: "center",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Actions",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <IconButton
                onClick={() => this.handleOpenDialog(item)}
                disabled={this.props.loading}
              >
                <Payment />
              </IconButton>
            );
          },
        },
        align: "center",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.fetchRemittances();
    } catch (error) {
      console.error(error);
    }
  };

  fetchRemittances = () => {
    return this.props.getRemittances();
  };

  fetchRemittancesDelayed = _debounce((e) => {
    return this.fetchRemittances();
  }, 1500);

  handleSearch = (event) => {
    //to future move to tableResponsive component
    this.props.setRemittanceFilters({
      search: event.target.value,
      page: 1,
    });
    this.fetchRemittancesDelayed.cancel();
    this.fetchRemittancesDelayed();
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenDialog = async (ocurrence) => {
    this.props.setLoading(true);
    await this.props.getLastRemittancesPayment();
    await this.props.getRemittance(ocurrence.id);
    this.props.setLoading(false);
    this.setState({ openDialog: true });
  };

  getPatientName = (order) => {
    if (order.patient) {
      const { first_name = "", last_name = "" } = order.patient;
      return `${first_name} ${last_name}`;
    }
    return "-";
  };

  transformData = (_remittances) => {
    const newRemittances = _remittances
      //fix services logic in frontend , must be in backend === start
      .map((_ocurrence, _ocurrenceIndex) => ({
        ..._ocurrence,
      }))
      //fix services logic in frontend , must be in backend === end
      .map((ocurrence) => ({
        ...ocurrence,
        patient_name: this.getPatientName(ocurrence.order),
        order_number: ocurrence.order.id,
        amount_remitted: ocurrence.total_outstanding,
        invoice_amount: ocurrence.total_invoice_ammount,

        balance: ocurrence.total_balance,
      }));
    return newRemittances;
  };

  render() {
    const { openDialog } = this.state;
    let { classes, remittances, filters } = this.props;

    return (
      <Layout title="Remittance">
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid container spacing={4} justify="flex-end">
            <Grid item>
              <InputSearch
                value={filters.search}
                onChange={this.handleSearch}
                autoFocus={true}
                // disabled={this.props.loading}
              />
            </Grid>
          </Grid>
          <div className={classes.tableContainer}>
            <TableResponsive
              items={this.transformData(remittances)}
              config={this.config}
              emptyMessage="REMITTANCES"
              metaFilters={{
                filters: this.props.filters,
                setFilters: this.props.setRemittanceFilters,
              }}
              fetchData={this.fetchRemittances}
            />
          </div>
          {openDialog && (
            <RemittanceCheckDialog
              open={openDialog}
              handleClose={this.handleCloseDialog}
            />
          )}
        </Panel>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    remittances: state.remittance.list,
    filters: state.remittance.filters,
    loading: state.global.loading,
  };
};

const mapDispatchToProps = {
  setLoading,
  getShippingStatus,
  getOrder,
  getRemittances,
  getRemittance,
  getLastRemittancesPayment,
  setRemittanceFilters,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "RemittanceList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(RemittanceList);
