import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Grid,
    MenuItem, TableHead, TableRow, TableBody, TableCell, Table, Switch,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { UNITS, PRICE_VARIANTS } from "../../common/Constants/ProductConstants";
import { formatNumberWithOutComma } from "../../common/Helpers/FormatNumberHelper";

function UnitMeasureQuantityModal({ open, onClose: _handleClose }) {
    const { setFieldValue, values: { qty_unit_measures = [] } = {} } = useFormikContext();

    const formikRef = useRef();

    useEffect(() => {

        if (!open) {
            // console.log(formikRef.current.resetForm());
        }
    }, [open]);

    const get_value = (unit) => {
        if(Array.isArray(qty_unit_measures)){
            if (qty_unit_measures.length === 0 || !Array.isArray(qty_unit_measures)){
                return ""
            }
            let obj = qty_unit_measures.find(o => o.unit_measure === unit);
            return obj.quantity
        }
        if(typeof String(qty_unit_measures) === "string"){
            let QtyUnitMeasures = JSON.parse(qty_unit_measures)
            return QtyUnitMeasures[unit+'_qty']
        }
        return ""
    }


    const get_value_checked = (unit) => {
        if(Array.isArray(qty_unit_measures)){
            if (qty_unit_measures.length === 0){
                return false
            }
            let obj = qty_unit_measures.find(o => o.unit_measure === unit);
            return obj.active
        }
        if( typeof String(qty_unit_measures) === "string"){
            let QtyUnitMeasures = JSON.parse(qty_unit_measures)
            return QtyUnitMeasures[unit+'_checked']
        }
        return false

    }

    const _handleSubmit = (values, actions) => {
        setFieldValue("qty_unit_measures", JSON.stringify(values));
        _handleClose();
        return;
    };

    // const isEditing = Boolean(index) || index === 0;

    return (
        <Formik
            innerRef={formikRef}
            onSubmit={_handleSubmit}
            enableReinitialize={true}
            initialValues={ {
                "Bag_qty": get_value("Bag"),
                "Bag_checked": get_value_checked("Bag"),
                "Box_qty": get_value("Box"),
                "Box_checked": get_value_checked("Box"),
                "Case_qty": get_value("Case"),
                "Case_checked": get_value_checked("Case"),
                "Each_qty": get_value("Each"),
                "Each_checked": get_value_checked("Each"),
                "Hours_qty": get_value("Hours"),
                "Hours_checked": get_value_checked("Hours"),
                "Pack_qty": get_value("Pack"),
                "Pack_checked": get_value_checked("Pack"),
            }}>
            {(props) => {
                const {
                    handleBlur,
                    handleSubmit,
                    values,
                    handleChange,
                } = props;
                return (
                    <Dialog
                        open={open}
                        onClose={_handleClose}
                        fullWidth
                        maxWidth="sm"
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Unit Measure - Quantity
                        </DialogTitle>
                        <DialogContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Unit of Measure
                                        </TableCell>
                                        <TableCell>
                                            Quantity
                                        </TableCell>
                                        <TableCell>
                                            Active
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {UNITS.map((unit) => (
                                        <TableRow>
                                            <TableCell>
                                                {unit}
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    name={unit+ "_qty"}
                                                    value={values[unit + "_qty"]}
                                                    onChange={(e) =>
                                                        {
                                                            console.log('foo');
                                                            handleChange(e)
                                                        }
                                                    }
                                                    // onChange={{
                                                    //     handleChange;
                                                    //     updateValue()
                                                    // }
                                                    onBlur={handleBlur}
                                                    // error={!!touched.sale_price && !!errors.sale_price}
                                                    // helperText={
                                                    //     !!touched.sale_price &&
                                                    //     !!errors.sale_price &&
                                                    //     errors.sale_price
                                                    // }
                                                    label="Quantity"
                                                    fullWidth
                                                    margin="normal"
                                                    type="number"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Switch
                                                    checked={values[unit + "_checked"]}
                                                    onChange={handleChange}
                                                    name={unit+ "_checked"}
                                                    onBlur={handleBlur}
                                                    type="checkbox"
                                                    // value="1"
                                                    color="primary"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={_handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleSubmit} color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            }}
        </Formik>
    );
}

UnitMeasureQuantityModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

UnitMeasureQuantityModal.defaultProps = {
    index: null,
};

export default UnitMeasureQuantityModal;
