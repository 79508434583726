import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import SimpleBreadcrumbs from "../../../components/SimpleBreadcrumbs";
import Layout from "../../../components/Layout/index";
import Panel from "../../../components/Panel";
import Errors from "../../../components/Errors";
import { PhoneMask } from "../../../components/InputMask";
import { connect } from "react-redux";
import {
  fetchRoles,
  setHandleForm,
  setLoading,
} from "../../../redux/actions/globalActions";

import styles from "./styles";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { createVendor } from "../../../redux/actions/vendorActions";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Vendors", to: "/vendors" },
  { name: "Create Vendor", to: null },
];

class VendorCreate extends React.Component {
  state = {
    tab: 0,
    redirect: false,
    form: {
      name: "",
      phone: "",
    },
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchRoles();
    } catch (error) {
      console.error(error.message);
    }
  };

  handleSubmit = async (values, formikActions) => {
    const { setSubmitting, resetForm } = formikActions;
    this.props.setLoading(true);
    const form = { ...values };

    try {
      const response = await this.props.createVendor(form);

      if (response.status === 201) {
        resetForm();
        this.props.history.push("/vendors");
        this.props.enqueueSnackbar("The vendor has been created!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar(error.message, { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
    this.props.setLoading(false);
  };

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { form } = this.state;
    const { classes, loading } = this.props;

    return (
      <Layout title="Create Vendor">
        <div className={classes.root}>
          <SimpleBreadcrumbs routes={breadcrumbs} />

          <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
              ...form,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              phone: Yup.string().required("Phone is required"),
            })}
          >
            {(props) => {
              const {
                isSubmitting,
                values,
                isValid,
                dirty,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <Form onSubmit={this.handleSubmit}>
                  <Prompt
                    when={dirty}
                    message="Are you sure you want to leave?, You will lose your changes"
                  />
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={12}>
                      <Panel>
                        <Grid container spacing={2}>
                          <Grid item xs>
                            <TextField
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!touched.name && !!errors.name}
                              helperText={
                                !!touched.name && !!errors.name && errors.name
                              }
                              label="Name"
                              fullWidth
                              margin="normal"
                              required
                            />
                          </Grid>
                          <Grid item xs>
                            <TextField
                              name="phone"
                              value={values.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!touched.phone && !!errors.phone}
                              helperText={
                                !!touched.phone &&
                                !!errors.phone &&
                                errors.phone
                              }
                              label="Phone"
                              fullWidth
                              margin="normal"
                              required
                              InputProps={{
                                inputComponent: PhoneMask,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Panel>
                    </Grid>
                  </Grid>

                  <br />
                  <Grid container justify="center">
                    <Button
                      disabled={loading || isSubmitting || !isValid || !dirty}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Create Vendor
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    errors: new Errors(state.vendors.errors),
  };
};

const mapDispatchToProps = {
  fetchRoles,
  createVendor,
  setHandleForm,
  setLoading,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "VendorCreate" }),
  connect(mapStateToProps, mapDispatchToProps)
)(VendorCreate);
