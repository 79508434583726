import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  List,
  Divider,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import { setLoading } from "../../../redux/actions/globalActions";
import { getAllCallNotes } from "../../../redux/actions/orderActions";
import { useEffect } from "react";
import { Close } from "@material-ui/icons";
import { TextEmpty } from "../../../components";

function CallNotesModal({
  open,
  onClose: _handleClose,
  callPatientNotes,
  loading,
  ocurrenceId = "",
  getAllCallNotes,
  setLoading,
}) {
  useEffect(() => {
    if (ocurrenceId) {
      fetchCallNotes();
    }
  }, [ocurrenceId]);
  const fetchCallNotes = async () => {
    try {
      setLoading(true);
      getAllCallNotes(ocurrenceId);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open={open} onClose={_handleClose}>
      <DialogTitle id="alert-dialog-title">
        <Grid container justify="space-between">
          Call Notes{" "}
          <IconButton aria-label="close" onClick={_handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ minWidth: 300 }}>
        <List>
          {(() => {
            if (loading) {
              return null;
            }
            if (!callPatientNotes.length) {
              return (
                <TextEmpty
                  itemName="CALL NOTES"
                  empty={!callPatientNotes.length}
                />
              );
            }
            return callPatientNotes.map((callNote) => (
              <React.Fragment key={callNote.id}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={callNote.body}
                    secondary={
                      <Typography component="p" variant="body2">
                        <span>{callNote.user_stamped.username}{' '}</span>
                        <Typography variant="caption">
                          <span>
                            {moment(callNote.created).format("MM/DD/YYYY")}
                          </span>
                        </Typography>
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ));
          })()}
        </List>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    callPatientNotes: state.orders.callPatientNotes,
  };
};

const mapDispatchToProps = {
  setLoading,
  getAllCallNotes,
};

CallNotesModal.propTypes = {};

CallNotesModal.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CallNotesModal);
