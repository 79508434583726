import {
  GET_CALL_CUSTOMERS_W_ELIGIBILITIES,
  GET_CALL_CUSTOMER_W_ELIGIBILITIES,
  UPDATE_CALL_CUSTOMER_W_ELIGIBILITIES,
  SET_FILTER_CALL_CUSTOMERS_W_ELIGIBILITIES,
  RESET_CALL_CUSTOMERS_W_ELIGIBILITIES,
  RESET_CALL_CUSTOMER_W_ELIGIBILITIES_OCURRENCE,
} from "../actionTypes";

const initialState = {
  list: [],
  ocurrence: {},
  filters: {
    start_date: "",
    end_date: "",
    state: null,
    facility: null,
    hidden_completed: true,

    ///pagination filters
    search: "",
    ordering: "date",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CALL_CUSTOMERS_W_ELIGIBILITIES:
      return { ...state, list: action.payload };

    case GET_CALL_CUSTOMER_W_ELIGIBILITIES:
      return {
        ...state,
        ocurrence: action.payload,
        list: state.list.map((callCustomer) =>
          callCustomer.id === action.payload.id ? action.payload : callCustomer
        ),
      };

    case RESET_CALL_CUSTOMER_W_ELIGIBILITIES_OCURRENCE:
      return {
        ...state,
        ocurrence: {},
      };

    case UPDATE_CALL_CUSTOMER_W_ELIGIBILITIES:
      return {
        ...state,
        list: state.list.map((item) =>
          item.id === action.payload.ocurrenceId
            ? {
                ...item,
                customer_approved:
                  "customer_approved" in action.payload
                    ? action.payload.customer_approved
                    : item.customer_approved,
                eligibility_toggle:
                  "eligibility_toggle" in action.payload
                    ? action.payload.eligibility_toggle
                    : item.eligibility_toggle,
              }
            : item
        ),
      };
    case SET_FILTER_CALL_CUSTOMERS_W_ELIGIBILITIES:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case RESET_CALL_CUSTOMERS_W_ELIGIBILITIES:
      return initialState;

    default:
      return state;
  }
};
