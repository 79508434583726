import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
  MenuItem,
} from "@material-ui/core";
import * as Yup from "yup";
import { useFormikContext, Formik } from "formik";
import { UNITS, PRICE_VARIANTS } from "../../common/Constants/ProductConstants";
import { formatNumberWithOutComma } from "../../common/Helpers/FormatNumberHelper";

function PriceProductVariantModal({ open, index, onClose: _handleClose }) {
  const { setFieldValue, values: { variants = [] } = {} } = useFormikContext();

  const formikRef = useRef();

  useEffect(() => {
    if (!open) {
      console.log(formikRef.current.resetForm());
    }
  }, [open]);

  const _handleSubmit = (values, actions) => {
    if (isEditing) {
      setFieldValue(
        "variants",
        variants.map((_price_variant, _index) =>
          index === _index
            ? {
                ..._price_variant,
                ...values,
                cost_price: formatNumberWithOutComma(values.cost_price),
                sale_price: formatNumberWithOutComma(values.sale_price),
              }
            : _price_variant
        )
      );
      _handleClose();
      return;
    }
    setFieldValue("variants", [...variants, values]);

    _handleClose();
    return;
  };

  const isEditing = Boolean(index) || index === 0;

  return (
    <Formik
      initialValues={
        isEditing
          ? { ...variants[index] }
          : {
              price_variant: "",
              unit_measure: "",
              sale_price: "",
              cost_price: "",
              description: "",
            }
      }
      innerRef={formikRef}
      onSubmit={_handleSubmit}
      validationSchema={Yup.object().shape({
        sale_price: Yup.number().required("Sale price is required"),
        cost_price: Yup.number().required("Cost price is required"),
        unit_measure: Yup.mixed().required("Unit measure is required"),
        price_variant: Yup.mixed().required("Price variant is required"),
      })}
      enableReinitialize
    >
      {(props) => {
        const {
          handleBlur,
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
        } = props;
        return (
          <Dialog
            open={open}
            onClose={_handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {isEditing ? "Edit" : "Add"} Price Variant
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="price_variant"
                    value={values.price_variant}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.price_variant && !!errors.price_variant}
                    helperText={
                      !!touched.price_variant &&
                      !!errors.price_variant &&
                      errors.price_variant
                    }
                    label="Price Variant *"
                    select
                    fullWidth
                    margin="normal"
                  >
                    {PRICE_VARIANTS.map((variant) => (
                      <MenuItem value={variant} key={variant}>
                        {variant}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="unit_measure"
                    value={values.unit_measure}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.unit_measure && !!errors.unit_measure}
                    helperText={
                      !!touched.unit_measure &&
                      !!errors.unit_measure &&
                      errors.unit_measure
                    }
                    label="Unit of Measure *"
                    select
                    fullWidth
                    margin="normal"
                  >
                    {UNITS.map((unit) => (
                      <MenuItem value={unit} key={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    name="sale_price"
                    value={values.sale_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.sale_price && !!errors.sale_price}
                    helperText={
                      !!touched.sale_price &&
                      !!errors.sale_price &&
                      errors.sale_price
                    }
                    label="Sale Price *"
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="cost_price"
                    value={values.cost_price}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.cost_price && !!errors.cost_price}
                    helperText={
                      !!touched.cost_price &&
                      !!errors.cost_price &&
                      errors.cost_price
                    }
                    label="Cost price *"
                    fullWidth
                    margin="normal"
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!touched.description && !!errors.description}
                    helperText={
                      !!touched.description &&
                      !!errors.description &&
                      errors.description
                    }
                    label="Description"
                    multiline
                    rows={3}
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={_handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
}

PriceProductVariantModal.propTypes = {
  open: PropTypes.bool.isRequired,
  index: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

PriceProductVariantModal.defaultProps = {
  index: null,
};

export default PriceProductVariantModal;
