import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Checkbox,
  Typography,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Input } from "@material-ui/core";
import formatNumber, {
  formatNumberWithOutComma,
} from "../../../../common/Helpers/FormatNumberHelper";

import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    TextLink: {
      color: "#3f51b5",
      textDecoration: "underline",
      "&:hover": {
        color: "#3f51b5",
        cursor: "pointer",
      },
    },
  })
);

function LinkText({ children, ...props }) {
  const classes = useStyles();
  return (
    <Typography className={classes.TextLink} {...props}>
      {children}
    </Typography>
  );
}

class ServiceList extends React.Component {
  state = {
    openEditServiceModal: false,
    currentService: {},
    checkAllRemmited: false
  };
  handleChange = (id, name = {}) => (e) => {
    const { setFieldValue, services } = this.props;


    if (name === "is_wf") {
      const checked = e.target.checked;
      setFieldValue(
          "services",
          services.map((service) =>
              service.id === id
                  ? {
                      ...service,
                      write_off: checked
                          ? this.getOutstanding(service, service.entireRemit)
                          : 0,
                  }
                  : service
          )
      );
    }
  };

  getInvoiceAmount(service) {
      const sale_price = service.added_percentage ? service.adjusted_sale_price : service.sale_price;
      return (+service.amount_remmited * +sale_price).toFixed(2);
  }

  getTotalInvoiceAmount(){
      const { services } = this.props;
      return formatNumber(
          +services
              .map(
                  (_service) =>
                      +formatNumberWithOutComma(
                          (_service.added_percentage ? _service.adjusted_sale_price : _service.sale_price || 0) *
                          (+_service.amount_remmited || 0)
                      )
              )
              .reduce((total, current) => +total + +current, 0)
      )
  }

  getTotalOutstanding() {
      const { services } = this.props;
      return formatNumber(
          +services
              .map(
                  (_service) =>
                      +this.getOutstanding(
                          _service
                      )
              )
              .reduce((total, current) => +total + +current, 0)
      )
  }

  getTotalAmountApplied() {
      const { services } = this.props;

      return formatNumber(
        +services
            .map(
                (_service) =>
                    _service.amountApplied
                        ? formatNumberWithOutComma(+_service.amountApplied)
                        : 0
            )
            .reduce((total, current) => +total + +current, 0)
      )
  }

    getTotalAmountAdjusted() {
        const { services } = this.props;

        return formatNumber(
            +services
                .map(
                    (_service) =>
                        _service.amount_ajusted
                            ? formatNumberWithOutComma(+_service.amount_ajusted)
                            : 0
                )
                .reduce((total, current) => +total + +current, 0)
        )
    }

  getOutstanding(service, entireRemit = false) {
    if (entireRemit) service.outstanding = service.outstanding_saved;
    if (service.amount_ajusted > 0 || service.write_off > 0) {
      return 0;
    }
    return this.getInvoiceAmount(service) - service.outstanding;
  }

  // handleProductServiceModalClose = () => {
  //   this.setState({
  //     openEditServiceModal: false,
  //   });
  // };

  // handleEditServiceModalOpen = (service) => () => {
  //   this.setState({
  //     openEditServiceModal: true,
  //     currentService: service,
  //   });
  // };

  // handleCheckBox = () => (e) => {
  //     const { setFieldValue, services } = this.props;
  //     this.setState({'checkAllRemmited': e.target.checked})
  //     const checked = e.target.checked;
  //     setFieldValue(
  //         "services",
  //         services.map((service) =>
  //             !(this.getInvoiceAmount(service) - service.outstanding_saved === 0 ||
  //                 Boolean(+service.amount_ajusted) ||
  //                 Boolean(+service.write_off))
  //                 ? {
  //                     ...service,
  //                     entireRemit: checked,
  //                     amountApplied: checked ? this.getOutstanding(service, true) : 0,
  //                     credit: 0,
  //                     percentage: 0,
  //                     outstanding: checked
  //                         ? service.outstanding_saved + this.getOutstanding(service)
  //                         : service.outstanding_saved,
  //                 }
  //                 : service
  //         )
  //     );
  // };

  render() {
    const { services } = this.props;
    return (
      <TableContainer>
          
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="left">Original Amount</TableCell>
              <TableCell align="left">Outstanding</TableCell>
              <TableCell align="right">Write Off</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell component="th" scope="row">
                  <Link
                    to={`/products/${service.product.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    {service.order_product.order_product_name}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  <LinkText>
                    {formatNumber(this.getInvoiceAmount(service))}
                  </LinkText>
                </TableCell>
                <TableCell align="left">
                  {formatNumber(+this.getOutstanding(service))}
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                      checked={Boolean(+service.write_off) || false}
                      disabled={
                          this.getInvoiceAmount(service) -
                          service.outstanding_saved ===
                          0 || service.entireRemit
                      }
                      onClick={this.handleChange(service.id, "is_wf")}
                      value="checkedB"
                      color="primary"
                  />
                </TableCell>

              </TableRow>
            ))}
              <TableRow>
                  <TableCell component="th" scope="row">
                      <p style={{fontSize: 15}}>Total</p>
                  </TableCell>
                  <TableCell component="th" scope="row">
                      {this.getTotalInvoiceAmount()}
                  </TableCell>
                  <TableCell align="left">
                      {this.getTotalOutstanding()}
                  </TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default ServiceList;
