import Service from './Service';

class CallCustomerWEligibilityService extends Service {

  async list(params = {}) {
    try {
      return await this.http.get("/call-patient-eligibility/", { params });
      // return await this.http.get('/call-customers-eligibility/', { params });
    } catch (error) {
      return error.response;
    }
  }

  async get(ocurrenceId) {
    try {
      return await this.http.get(`/call-patient-eligibility/${ocurrenceId}/`);
    } catch (error) {
      return error.response;
    }
  }

  async update(id, body) {
    try {
      return await this.http.patch(`/call-patient-eligibility/${id}/`, body);
    } catch (error) {
      return error.response;
    }
  }
}

export default CallCustomerWEligibilityService;