import {
  GET_SHIPPING_STATUS,
  UPDATE_SHIPPING,
  SET_FILTER_SHIPPING_STATUS,
  RESET_SHIPPING_STATUS,
  GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
  GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT, BATCH_PRINT,
} from "../actionTypes";

const defaultState = {
  list: [],
  filters: {
    start_date: "",
    end_date: "",
    state: null,
    hidden_shipped: false,

    ///pagination filters
    search: "",
    ordering: "date",
    page: 1,
    facility: null,
    page_size: 25,
    count: 0,
  },
  occurrencesAvailablesForPrint: 0,
  batchPrintId: 0,
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_SHIPPING_STATUS:
      return { ...state, list: action.payload };

    case UPDATE_SHIPPING:
      return {
        ...state,
        list: state.list.map((ocurrence) =>
          ocurrence.id === action.payload.id ? action.payload : ocurrence
        ),
      };

    case SET_FILTER_SHIPPING_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case RESET_SHIPPING_STATUS:
      return defaultState;

    case GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT:
      return {
        ...state,
        occurrencesAvailablesForPrint: action.payload,
      };

    case GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT:
      return {
        ...state,
        batchPrintId: action.payload,
      };

    default:
      return state;
  }
};
