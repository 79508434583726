import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import {
  withStyles,
  Switch,
  Grid,
  InputBase,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Layout, SimpleBreadcrumbs, Panel } from "../../../components";
import {
  setLoading,
  fetchPatientStates,
} from "../../../redux/actions/globalActions";
import {
  getCheckEligibilities,
  updateCheckEligibility,
  setFiltersCheckEligibility,
} from "../../../redux/actions/checkEligibilityActions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import styles from "./styles";
import { Search, Today } from "@material-ui/icons";
import TableResponsive from "../../../components/TableResponsive";
import Autocomplete from "../../../components/Autocomplete";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Check Eligibility", to: null },
];

class CheckEligibilityList extends React.Component {
  state = {
    search: "",
  };

  config = {
    columns: [
      {
        display_name: "Patient Name",
        name: "patient_full_name",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "patient_name",
      },
      {
        display_name: "Date of Birth",
        name: "order.patient.birthdate",
        type: "date",
        align: "inherit",
        sorted: true,
        sorted_name: "birth_date",
      },
      {
        display_name: "Address State",
        name: "order.patient.state.name",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "state",
      },
      {
        display_name: "Insurance Name - Policy Number",
        name: "order.patient.insurances",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { insurances = [] } = item.order.patient;
            if (insurances.length === 0) return "-";
            return insurances.map(({ name, policy_number }) => {
              return (
                <p key={name}>
                  {name} - {policy_number}
                </p>
              );
            });
          },
        },
        align: "inherit",
        sorted: true,
        sorted_name: "insurance",
      },

      {
        display_name: "Order Number",
        name: "order.id",
        type: "number",
        align: "right",
        sorted: true,
        sorted_name: "order_number",
      },
      {
        display_name: "DOS",
        name: "date",
        type: "date",
        align: "right",
        sorted: true,
        sorted_name: "date",
      },
      {
        display_name: "Eligibility",
        name: "eligibility_toggle",
        type: "custom",
        custom: {
          render: (item, column) => (
            <Switch
              checked={item.eligibility_toggle}
              onChange={this.handleUpdate(item)}
              value="1"
              disabled={this.props.loading}
              color="primary"
            />
          ),
        },
        align: "right",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchPatientStates();

      const { start_date, end_date } = this.props.filters;
      if (start_date && end_date) {
        await this.fetchCheckEligibilities();
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchCheckEligibilities = () => {
    return this.props.getCheckEligibilities();
  };

  handleFilters = async (name, value) => {
    const { filters, setFiltersCheckEligibility } = this.props;
    filters[name] = value;
    setFiltersCheckEligibility({ ...filters, page: 1 });
    if (
      filters.start_date &&
      moment(filters.start_date).isValid() &&
      filters.end_date &&
      moment(filters.end_date).isValid()
    ) {
      if (moment(filters.start_date).isAfter(filters.end_date)) {
        return;
      }

      try {
        setFiltersCheckEligibility({
          start_date: moment(filters.start_date).format("YYYY-MM-DD"),
          end_date: moment(filters.end_date).format("YYYY-MM-DD"),
          page: 1,
        });

        const response = await this.fetchCheckEligibilities();
        if (response.status !== 200) {
          this.props.enqueueSnackbar("The request could not be processed", {
            variant: "success",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleUpdate = (ocurrence) => async (event) => {
    event.preventDefault();
    this.props.setLoading(true);

    try {
      const form = { eligibility_toggle: event.target.checked };
      const { status } = await this.props.updateCheckEligibility(
        ocurrence.id,
        form
      );

      if (status === 200) {
        this.props.enqueueSnackbar("The order has been updated!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  getPatientName = (service) => {
    const { first_name = "", last_name = "" } = service.patient;

    return `${first_name} ${last_name}`;
  };

  getCurrentState = (states, state) => {
    if (state && states.length) {
      const currentState = states.find((_state) => _state.id === state);
      return {
        value: (currentState || {}).id,
        label: (currentState || {}).name,
      };
    }
    return null;
  };

  render() {
    const { search } = this.state;
    const {
      checkEligibilities,
      loading,
      classes,
      filters,
      us_patient_states,
    } = this.props;

    return (
      <Layout title="Check Eligibility">
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid
            container
            spacing={4}
            justify="space-between"
            className={classes.divTools}
          >
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="start_date"
                label="Start Date"
                value={filters.start_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("start_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="MM/DD/YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="end_date"
                label="End Date"
                value={filters.end_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("end_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="MM/DD/YYYY"
              />
            </Grid>
            <Grid item xs={3} container alignItems="flex-end">
              <Autocomplete
                onChange={(value) => {
                  const newValue = (value || {}).value;
                  this.props.setFiltersCheckEligibility({
                    state: newValue ? newValue : "",
                    page: 1,
                  });
                  this.fetchCheckEligibilities();
                }}
                value={this.getCurrentState(us_patient_states, filters.state)}
                suggestions={us_patient_states.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                placeholder="State"
              />
            </Grid>
            <Grid item xs={3}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <Search />
                </div>
                <InputBase
                  name="search"
                  placeholder="Search..."
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={search}
                  onChange={(e) => this.setState({ search: e.target.value })}
                />
              </div>
            </Grid>
          </Grid>
          <TableResponsive
            search={search}
            items={checkEligibilities.map(
              /**row  === ocurrence  */
              (_checkEligibility) => ({
                ..._checkEligibility,
                patient_full_name: this.getPatientName(_checkEligibility.order),
                insurances: _checkEligibility.order.patient.insurances,
              })
            )}
            emptyMessage={"CHECK ELIGIBILITIES"}
            config={this.config}
            metaFilters={{
              filters: this.props.filters,
              setFilters: this.props.setFiltersCheckEligibility,
            }}
            fetchData={this.fetchCheckEligibilities}
          />
        </Panel>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    checkEligibilities: state.checkEligibility.list,
    filters: state.checkEligibility.filters,
    us_patient_states: state.global.us_patient_states,
  };
};
const mapDispatchToProps = {
  setLoading,
  getCheckEligibilities,
  updateCheckEligibility,
  setFiltersCheckEligibility,
  fetchPatientStates,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "CheckEligibilityList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckEligibilityList);
