import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: props => props.typeFlex,
    alignItems: "center",
    padding: 20
  }
});

export default useStyles;