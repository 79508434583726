import ProductService from "../../services/ProductService";
import {
  GET_PRODUCT_TYPES,
  GET_CATEGORIES_LEVEL_1,
  GET_CATEGORIES_LEVEL_2,
  GET_CATEGORIES_LEVEL_3,
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
  GET_PRODUCT,
  UPDATE_PRODUCT_ERROR,
  DELETE_PRODUCT,
  SET_PRODUCTS_FILTERS,
  ON_LOADING,
  GET_PRODUCTS_RAW,
} from "../actionTypes";

const service = new ProductService();

export const getProducts = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ON_LOADING, loading: true });

      const response = await service.list(getState().products.filters);
      const { status, data } = response;

      if (status === 200) {
        const { results, ...meta } = data;
        dispatch({ type: GET_PRODUCTS, payload: results });
        dispatch({
          type: SET_PRODUCTS_FILTERS,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      }

      dispatch({ type: ON_LOADING, loading: false });
      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.log(error);
    }
  };
};

export const getRawProducts = () => {
  return async (dispatch, getState) => {
    try {
      const { data: { count } = {} } = await service.list();
      const response = await service.list({ page_size: count });
      const { status, data } = response;

      if (status === 200) {
        const { results } = data;
        dispatch({ type: GET_PRODUCTS_RAW, payload: results });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const reportProductsPdf = () => async (dispatch) => {
  try {
    const response = await service.export_products();
    return response;
  } catch (error) {
    return error;
  }
};

export const setProductFilters = (payload) => ({
  type: SET_PRODUCTS_FILTERS,
  payload,
});

export const getProduct = (id) => {
  return async (dispatch) => {
    try {
      const response = await service.get(id);
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_PRODUCT, payload: data });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const createProduct = (formData) => {
  return async (dispatch) => {
    try {
      const response = await service.create(formData);
      const { status, data } = response;

      if (status === 201) {
        dispatch({ type: CREATE_PRODUCT, payload: data });
      } else {
        dispatch({ type: CREATE_PRODUCT_ERROR, payload: data });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateProduct = (id, formData) => {
  return async (dispatch) => {
    try {
      const response = await service.update(id, formData);
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: UPDATE_PRODUCT, payload: data });
      } else {
        dispatch({ type: UPDATE_PRODUCT_ERROR, payload: data });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteProduct = (id) => {
  return async (dispatch) => {
    try {
      const response = await service.delete(id);

      if (response.status === 204) {
        dispatch({ type: DELETE_PRODUCT, payload: id });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTypes = () => {
  return async (dispatch) => {
    try {
      const response = await service.types();
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_PRODUCT_TYPES, payload: data });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCategories = (parent_id = "", level = 1) => {
  return async (dispatch) => {
    try {
      const response = await service.categories(parent_id);
      const { status, data } = response;

      if (status === 200) {
        if (level === 1) {
          dispatch({ type: GET_CATEGORIES_LEVEL_1, payload: data });
        } else if (level === 2) {
          dispatch({ type: GET_CATEGORIES_LEVEL_2, payload: data });
        } else if (level === 3) {
          dispatch({ type: GET_CATEGORIES_LEVEL_3, payload: data });
        }
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };
};


export const addQtyPerUnitMeasure = (body) => {
  return async (dispatch) => {
    try {
      return await service.addQtyPerUnitMeasure(body);
    } catch (error) {
      console.log(error);
    }
  };
};

export const addCategory = (id) => {};

export const removeCategory = (id) => {};
