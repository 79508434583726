import {
  GET_CHECK_ELIGIBILITIES,
  UPDATE_CHECK_ELIGIBILITY,
  SET_FILTER_CHECK_ELIGIBILITY,
  ON_LOADING,
} from "../actionTypes";
import CheckEligibilityService from "../../services/CheckEligibilityService";

const service = new CheckEligibilityService();

export const getCheckEligibilities = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ON_LOADING, loading: true });

      const {
        start_date,
        end_date,
        state,
        ...restFilters
      } = getState().checkEligibility.filters;

      //temp frontend validation

      const newFilters = { ...restFilters };

      if (state) {
        newFilters.state = state;
      }

      if (start_date && end_date) {
        newFilters.start_date = start_date;
        newFilters.end_date = end_date;
      }
      const response = await service.list(newFilters);

      const { data, status } = response;

      if (status === 200) {
        const { results, ...meta } = data;

        dispatch({ type: GET_CHECK_ELIGIBILITIES, payload: results });
        dispatch({
          type: SET_FILTER_CHECK_ELIGIBILITY,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      }
      dispatch({ type: ON_LOADING, loading: false });
      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const updateCheckEligibility = (serviceId, body) => {
  return async (dispatch) => {
    try {
      const response = await service.update(serviceId, body);
      const { data, status } = response;

      if (status === 200) {
        dispatch({ type: UPDATE_CHECK_ELIGIBILITY, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const setFiltersCheckEligibility = (payload) => ({
  type: SET_FILTER_CHECK_ELIGIBILITY,
  payload,
});
