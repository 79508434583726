import {
  GET_CHECK_ELIGIBILITIES,
  UPDATE_CHECK_ELIGIBILITY,
  SET_FILTER_CHECK_ELIGIBILITY,
  RESET_CHECK_ELIGIBILITY,
} from "../actionTypes";

const initialState = {
  list: [],
  filters: {
    start_date: "",
    end_date: "",
    state: null,

    ///pagination filters
    search: "",
    ordering: "",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHECK_ELIGIBILITIES:
      return {
        ...state,
        list: action.payload,
      };

    case UPDATE_CHECK_ELIGIBILITY:
      /** */
      return {
        ...state,
        list: state.list.map((checkEligibility) =>
          checkEligibility.id === action.payload.id
            ? {
                ...checkEligibility,
                eligibility_toggle: action.payload.eligibility_toggle,
              }
            : checkEligibility
        ),
      };

    case SET_FILTER_CHECK_ELIGIBILITY:
      /** */
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case RESET_CHECK_ELIGIBILITY:
      return initialState;

    default:
      return state;
  }
};
