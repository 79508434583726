import React from "react";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import {
  SimpleBreadcrumbs,
  Panel,
  Layout,
  Errors,
  PriceProductVariantList, DialogDelete,
} from "../../../components";
import {
  MenuItem,
  TextField,
  Button,
  Grid,
  withStyles, IconButton, TableRow, TableHead, Table, TableBody, TableCell, Paper,
} from "@material-ui/core";
import {
  getProduct,
  updateProduct,
  getTypes,
  getCategories,
  addQtyPerUnitMeasure,
} from "../../../redux/actions/productActions";
import {
  setLoading,
  setHandleForm,
} from "../../../redux/actions/globalActions";
import {UNITS} from "../../../common/Constants/ProductConstants";
import styles from "./styles";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputFiles from "react-input-files";
import { formatNumberWithOutComma } from "../../../common/Helpers/FormatNumberHelper";
import {Add} from "@material-ui/icons";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import UnitMeasureQuantityModal from "../../../components/UnitMeasureQuantity";
import useModal from "../../../hooks/use-modal";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Products", to: "/products" },
  { name: "Edit Product", to: null },
];

class ProductEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      open: false,
      openDialog: false,
      form: {
        name: "",
        description: "",
        // sale_price: "",
        // cost_price: "",
        // unit_measure: "",
        internal_code: "",
        type_id: "",
        quantity: "",
        cost_price: "",
        photo: "",
        variants: [],
        qty_unit_measures: [],
      },
      categories: ["", "", ""],
      // variants: "[]",
    };
  }
  logoFile = null;
  componentDidMount = async () => {
    this.props.setLoading(true);

    try {
      const { id } = this.state;
      const { status, data } = await this.props.getProduct(id);
      if (status === 200) {
        this.loadForm(data);
        this.props.getTypes();
      } else if (status === 404) {
        this.props.history.push("/404");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };


  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleAddUOMQty = async () => {
    this.props.setLoading(true);
    this.handleCloseDialog();

    try {
      const { id, unit_measure, qty_per_type} = this.state;

      const body = {
        'product_id': id,
        'unit_measure': unit_measure,
        'quantity': qty_per_type
      }
      let response = null;
      response = await this.props.addQtyPerUnitMeasure(body);

      if (response.status === 201) {
        // await this.props.getPatient(id);
        this.props.enqueueSnackbar("Created successfully!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
      this.setState({ openDialog: false,  qty_per_type: '', unit_measure: ''});
    }
  };

  loadForm = async (data) => {
    console.log("DATA", data)
    const form = {
      name: data.name || "",
      photo: data.photo || null,
      description: data.description || "",
      internal_code: data.internal_code || "",
      type_id: data.type.id || "",
      quantity: data.quantity || 0,
      cost_price: data.cost_price || "",
      variants: data.variants || [],
      qty_unit_measures: data.qty_unit_measures || [],
    };

    const categories = ["", "", ""];
    const list = data.categories_list || [];

    list.forEach(async (category, index) => {
      categories[index] = category.id;
      await this.props.getCategories(category.parent_id || "", index + 1);
    });

    if (list.length < 3) {
      const category_id = list.length > 0 ? list[list.length - 1].id : "";
      const level = list.length + 1;
      await this.props.getCategories(category_id, level);
    }

    this.setState({ form, categories });
  };

  getFormData = (values) => {
    const { categories } = this.state;
    const form = { ...values };
    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      if (key === "variants") {
        formData.append(
          "variants",
          JSON.stringify(
            form["variants"].map((variant) => ({
              ...variant,
              sale_price: +formatNumberWithOutComma(variant.sale_price),
              cost_price: +formatNumberWithOutComma(variant.cost_price),
            }))
          )
        );
      } else {
        formData.append(key, form[key]);
      }
    });

    categories.forEach((category) => {
      if (category !== "") {
        formData.append("categories", category);
      }
    });
    if (this.logoFile !== null) {
      formData.delete("photo");
      formData.append("photo", this.logoFile);
    } else {
      formData.delete("photo");
    }

    return formData;
  };

  handleSubmit = async (values, formikActions) => {
    const { setSubmitting, resetForm } = formikActions;
    this.props.setLoading(true);
    setSubmitting(true);
    try {
      const form = this.getFormData(values);
      const { status } = await this.props.updateProduct(this.state.id, form);

      if (status === 200) {
        resetForm();
        this.props.enqueueSnackbar("The product has been updated!", {
          variant: "success",
        });
        this.props.history.push(`/products`);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
      setSubmitting(false);
    }
  };

  handleCategory = (index) => (event) => {
    const category_id = event.target.value;
    const { categories } = this.state;

    if (index === 0) {
      categories[0] = category_id;
      categories[1] = "";
      categories[2] = "";

      this.props.getCategories(category_id, 2);
    } else if (index === 1) {
      categories[1] = category_id;
      categories[2] = "";

      this.props.getCategories(category_id, 3);
    } else if (index === 2) {
      categories[2] = category_id;
    }

    this.setState({ categories });
  };

  handleDelete = async () => {
    this.props.setLoading(true);
    this.setState({ open: false });
    try {
      const { status } = await this.props.deleteProduct(this.state.productId);

      if (status === 204) {
        this.props.enqueueSnackbar("The product has been deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        return;
      }
      if (status === 200) {
        this.props.enqueueSnackbar("The product cannot be deleted!", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        return;
      }
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  render() {
    const { form, categories, open } = this.state;
    const {
      classes,
      loading,
      types,
      categories_1,
      categories_2,
      categories_3,
    } = this.props;

    return (
      <Layout title="Edit Product">
        <DialogDelete
            item="product"
            open={open}
            closeModal={() => this.setState({ open: false })}
            remove={this.handleDelete}
            isDelivery={false}
        />

        <SimpleBreadcrumbs routes={breadcrumbs} />
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            ...form,
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            description: Yup.string(),
            variants: Yup.array().min(1),
            internal_code: Yup.string().required("Internal code is required"),
            type_id: Yup.mixed().required("Type is required"),
            quantity: Yup.number(),
            cost_price: Yup.number(),
          })}
        >
          {(props) => {
            const {
              isSubmitting,
              isValid,
              values,
              dirty,
              handleChange,
              handleBlur,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={this.handleSubmit}>
                <Prompt
                  when={dirty}
                  message="Are you sure you want to leave?, You will lose your changes"
                />
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.name && !!errors.name}
                        helperText={
                          !!touched.name && !!errors.name && errors.name
                        }
                        label="Name"
                        fullWidth
                        margin="normal"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="internal_code"
                        required
                        value={values.internal_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          !!touched.internal_code && !!errors.internal_code
                        }
                        helperText={
                          !!touched.internal_code &&
                          !!errors.internal_code &&
                          errors.internal_code
                        }
                        label="Reference Number"
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Panel>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: 8, marginBottom: 8 }}
                >
                  <Grid item xs={12}>
                    <PriceProductVariantList />
                  </Grid>
                </Grid>
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.description && !!errors.description}
                        helperText={
                          !!touched.description &&
                          !!errors.description &&
                          errors.description
                        }
                        label="Main Description"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                      />
                      <Grid item xs className={classes.divPhoto}>
                        <p style={{ color: "gray" }}>Photo</p>
                        <InputFiles
                          name="photo"
                          style={{ width: "100%", height: "140px" }}
                          accept="image/*"
                          onChange={(files) => {
                            this.logoFile = files[0];
                            setFieldValue(
                              "photo",
                              URL.createObjectURL(files[0])
                            );
                          }}
                        >
                          <Grid item xs className={classes.gridLogo}>
                            {values.photo ? (
                              <img src={values.photo} alt="logo" />
                            ) : (
                              <AddIcon
                                color="primary"
                                style={{ fontSize: 30 }}
                              />
                            )}
                          </Grid>
                        </InputFiles>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          name="quantity"
                          // required
                          disabled
                          value={values.quantity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!touched.quantity && !!errors.quantity}
                          helperText={
                            !!touched.quantity &&
                            !!errors.quantity &&
                            errors.quantity
                          }
                          label="Quantity On Hand"
                          fullWidth
                          margin="normal"
                          type="number"
                        />
                      </Grid>
                      <Grid  item xs={12} sm={2}>
                        <IconButton
                            style={{marginTop: 20}}
                            color="primary"
                            aria-label="delete"
                            onClick={() => this.setState({openDialog: true})}
                        >
                          <Add />
                        </IconButton>
                      </Grid>
                      <Grid  item xs={12} sm={5}>
                        <TextField
                            name="cost_price"
                            // required
                            value={values.cost_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.cost_price && !!errors.cost_price}
                            helperText={
                              !!touched.cost_price &&
                              !!errors.cost_price &&
                              errors.cost_price
                            }
                            label="Product Cost Price"
                            fullWidth
                            margin="normal"
                            type="number"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="type_id"
                          value={values.type_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!touched.type_id && !!errors.type_id}
                          helperText={
                            !!touched.type_id &&
                            !!errors.type_id &&
                            errors.type_id
                          }
                          label="Type"
                          select
                          fullWidth
                          margin="normal"
                          required
                        >
                          {types.map((type) => {
                            return (
                              <MenuItem key={type.id} value={type.id}>
                                {type.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={categories[0]}
                          onChange={this.handleCategory(0)}
                          label="Product Group"
                          fullWidth
                          select
                          margin="normal"
                        >
                          {categories_1.map((category) => {
                            return (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={categories[1]}
                          onChange={this.handleCategory(1)}
                          label="Product Category"
                          fullWidth
                          select
                          margin="normal"
                          disabled={categories[0] === ""}
                        >
                          {categories_2.map((category) => {
                            return (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={categories[2]}
                          onChange={this.handleCategory(2)}
                          label="Product Subcategory"
                          fullWidth
                          select
                          margin="normal"
                          disabled={categories[1] === ""}
                        >
                          {categories_3.map((category) => {
                            return (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Panel>
                <UnitMeasureQuantityModal
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                />

                <br />

                <Button
                  // type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(e);
                  }}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={
                    loading ||
                    isSubmitting ||
                    (isValid && !dirty) ||
                    (!isValid && dirty)
                  }
                >
                  Update Product
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    product: state.products.product,
    types: state.products.types,
    errors: new Errors(state.products.errors),
    categories_1: state.products.categories_1,
    categories_2: state.products.categories_2,
    categories_3: state.products.categories_3,
  };
};

const mapDispatchToProps = {
  getProduct,
  getTypes,
  getCategories,
  updateProduct,
  addQtyPerUnitMeasure,
  setLoading,
  setHandleForm,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ProductEdit" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductEdit);
