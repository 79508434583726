import { fade } from "@material-ui/core/styles/colorManipulator";
const styles = (theme) => ({
  toolbarModal: {
    display: "flex",
    justifyContent: "space-between",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 50,
  },
  searchIcon: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    // top: 16,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    // marginTop: 34,
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   width: 120,
    //   "&:focus": {
    //     width: 200,
    //   },
    // },
  },
  divSearch: {
    margin: "20px 0",
  },
  rowGreen: {
    backgroundColor: "rgba(67,129,3, 0.5)",
  },
  rowYellow: {
    backgroundColor: "rgba(250,236,14, 0.5)",
  },
  rowOrange: {
    backgroundColor: "rgba(246,164,27, 0.5)",
  },
  rowRed: {
    backgroundColor: "rgba(237,77,32, 0.5)",
  },
});

export default styles;
