import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";

import { withStyles, Grid, Link, IconButton } from "@material-ui/core";
import {
  Layout,
  SimpleBreadcrumbs,
  Panel,
  InputSearch,
} from "../../../components";
import { setLoading } from "../../../redux/actions/globalActions";
import styles from "./styles";
import { getOrder } from "../../../redux/actions/orderActions";
import {
  getInvoices,
  updateInvoice,
  setInvoiceFilters,
  printInvoice,
} from "../../../redux/actions/invoiceActions";
import TableResponsive from "../../../components/TableResponsive";
import { Edit, CloudDownload } from "@material-ui/icons";
import _debounce from "lodash/debounce";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Invoices", to: null },
];

class InvoiceList extends React.Component {
  state = {
    search: "",
  };

  config = {
    columns: [
      // {
      //   display_name: "Patient",
      //   name: "patient_full_name",
      //   type: "string",
      //   align: "inherit",
      //   sorted: true,
      //   sorted_name: "patient_name",
      // },
      {
        display_name: "Patient",
        name: "patient_full_name",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      this.props.history.push(
                          `/patients/${item.order.patient.id}`
                          // `/patients/${item.order.patient.id}`
                      );
                    }}
                    style={{ textAlign: "left" }}
                >
                  {this.getPatientName(item.order)}
                </Link>
            );
          },
        },
        align: "left",
        sorted: true,
        sorted_name: "patient_name",
      },
      {
        display_name: "Order Number",
        name: "order.id",
        type: "number",
        align: "inherit",
        sorted: true,
        sorted_name: "order_number",
      },
      {
        display_name: "Invoice Number",
        name: "id",
        type: "number",
        align: "right",
        sorted: true,
        sorted_name: "id",
      },
      {
        display_name: "Invoice Date",
        name: "service_date",
        type: "date",
        align: "center",
        sorted: true,
        sorted_name: "date",
      },
      {
        display_name: "Balance Due",
        name: "balance",
        type: "number",
        align: "center",
        sorted: false,
        sorted_name: "balance",
      },
      {
        display_name: "Actions",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <div style={{ display: "flex" }}>
                <Link component={RouterLink} to={`/invoices/${item.id}`}>
                  <IconButton
                    aria-label="Edit"
                    color="primary"
                    disabled={this.props.loading}
                  >
                    <Edit />
                  </IconButton>
                </Link>
                <IconButton
                  aria-label="pdf-icon"
                  color="primary"
                  disabled={this.props.loading}
                  onClick={() => this.printInvoiceTicket(item.id)}
                >
                  <CloudDownload />
                </IconButton>
              </div>
            );
          },
        },
        align: "center",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.fetchInvoices();
    } catch (error) {
      console.error(error);
    }
  };
  fetchInvoices = () => {
    return this.props.getInvoices();
  };

  fetchInvoicesDelayed = _debounce((e) => {
    return this.fetchInvoices();
  }, 1500);

  handleSearch = (event) => {
    //to future move to tableResponsive component
    this.props.setInvoiceFilters({
      search: event.target.value,
      page: 1,
    });
    this.fetchInvoicesDelayed.cancel();
    this.fetchInvoicesDelayed();
  };

  printInvoiceTicket = async (invoiceId) => {
    this.props.setLoading(true);
    try {
      const response = await this.props.printInvoice(invoiceId);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice-${invoiceId}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  getPatientName = (order) => {
    const { first_name = "", last_name = "" } = order.patient;

    return `${first_name} ${last_name}`;
  };

  openDialog = () => {
    this.setState({ showDialog: true });
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
  };

  render() {
    let {
      classes,
      invoices,
      loading,
      filters: { search },
    } = this.props;
    return (
      <Layout title="Invoice">
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid container spacing={4} justify="flex-end">
            <Grid item>
              <InputSearch value={search} onChange={this.handleSearch} autoFocus={true}
                           // disabled={loading}
              />
            </Grid>
          </Grid>
          <div className={classes.tableContainer}>
            <TableResponsive
              items={invoices.map((_invoice) => ({
                ..._invoice,
                patient_full_name: this.getPatientName(_invoice.order),
              }))}
              config={this.config}
              emptyMessage="INVOICES"
              metaFilters={{
                filters: this.props.filters,
                setFilters: this.props.setInvoiceFilters,
              }}
              fetchData={this.fetchInvoices}
            />
          </div>
        </Panel>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    invoices: state.invoice.list,
    filters: state.invoice.filters,
    loading: state.global.loading,
  };
};
const mapDispatchToProps = {
  setLoading,
  printInvoice,
  getOrder,
  getInvoices,
  updateInvoice,
  setInvoiceFilters,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "InvoiceList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(InvoiceList);
