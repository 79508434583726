const styles = theme => ({
  root: {
  },

  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  photoSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  photo: {
    margin: theme.spacing(1),
  },
  uploadName: {
    whiteSpace: 'nowrap',
    maxWidth: theme.spacing(20),
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  divPhoto: {
    width: 210,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  gridLogo: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '5px',
    border: `1px dashed ${theme.palette.primary.main}`,
    cursor: 'pointer',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain'
    }
  }

});

export default styles;