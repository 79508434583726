import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import moment from "moment";
import classNames from "classnames";
import _debounce from "lodash/debounce";
import {
  withStyles,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Tabs,
  Tab,
  Link,
  FormControlLabel,
  Checkbox,
  Grid,
  InputAdornment,
  Switch, InputBase, MenuItem,
} from "@material-ui/core";
import {Edit, Search, Today} from "@material-ui/icons";
import { Layout, SimpleBreadcrumbs, Panel } from "../../../components";
import {
  setLoading,
  setHandleForm,
  fetchPatientStates,
} from "../../../redux/actions/globalActions";
import {
  getExpirations,
  updateExpiration,
  printBatchExpirations,
  setExpirationFilters,
} from "../../../redux/actions/expirationActions";
import { setPatientIdOrigin } from "../../../redux/actions/orderActions";
import { KeyboardDatePicker } from "@material-ui/pickers";
import styles from "./styles";
import { datePickerFormatToParseDate } from "../../../common/Helpers/DateHelper";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import TableResponsive from "../../../components/TableResponsive";
import Autocomplete from "../../../components/Autocomplete";

import _get from "lodash/get";
import _random from "lodash/random";
import PdfIcon from "@material-ui/core/SvgIcon/SvgIcon";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Expirations", to: null },
];

class ExpirationList extends React.Component {
  state = {
    showDialog: false,
    expirationId: 0,
    form: {
      date: null,
      // alert_days: "",
      yellow: false,
      yellow_date: null,
      waiver: false,
      note: "",
    },
  };

  config = {
    columns: [
      {
        display_name: "Patient Name",
        name: "id",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  this.props.setPatientIdOrigin("");
                  this.props.history.push(
                    `/patients/${item.order.patient_id}`
                    // `/patients/${item.order.patient.id}`
                  );
                }}
                style={{ textAlign: "left" }}
              >
                {this.getPatientName(item)}
              </Link>
            );
          },
        },
        align: "left",
        sorted: true,
        sorted_name: "patient_name",
      },
      {
        display_name: "DOS",
        name: "no_exists_some_with_DOS",
        // name: "ocurrence_date",
        type: "date",
        align: "right",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Last Service Date",
        name: "last_service.date",
        type: "date",
        align: "right",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Expiration Notes",
        name: "note",
        type: "string",
        align: "right",
        sorted: false,
        sorted_name: "",
      },
      {
        display_name: "Expiration Date",
        name: "date",
        type: "date",
        align: "right",
        sorted: true,
        sorted_name: "expiration_date",
      },
      {
        display_name: "Solved",
        name: "solved",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <Switch
                checked={this.get_solved_value(item)}
                onChange={() => this.changeSolved(item)}
                disabled={this.props.loading}
                color="primary"
              />
            );
          },
        },
      },
      {
        display_name: "Actions",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <IconButton
                onClick={() => this.openDialog(item)}
                aria-label="Edit"
                color="primary"
                disabled={this.props.loading}
              >
                <Edit />
              </IconButton>
            );
          },
        },
        align: "right",
      },
    ],
    rowClassName: (item) => {
      const { classes } = this.props;
      const color = this.getColorExpiration(item);
      const classesColor = {
        green: classes.rowGreen,
        yellow: classes.rowYellow,
        red: classes.rowRed,
      };

      return classesColor[color];
    },
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchPatientStates();
      const response = await this.fetchExpirations();
      if (response.status !== 200) {
        this.props.enqueueSnackbar("The request could not be processed", {
          variant: "success",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchExpirations = () => {
    return this.props.getExpirations();
  };

  handleFilters = async (name, value) => {
    const { filters, setExpirationFilters } = this.props;

    filters[name] = value;
    setExpirationFilters(filters);

    if (
      filters.start_date &&
      moment(filters.start_date).isValid() &&
      filters.end_date &&
      moment(filters.end_date).isValid()
    ) {
      if (moment(filters.start_date).isAfter(filters.end_date)) {
        return;
      }

      try {
        setExpirationFilters({
          start_date: moment(filters.start_date).format("YYYY-MM-DD"),
          end_date: moment(filters.end_date).format("YYYY-MM-DD"),
          page: 1,
        });
        const response = await this.fetchExpirations();
        if (response.status !== 200) {
          this.props.enqueueSnackbar("The request could not be processed", {
            variant: "success",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };


  handleTab = (event, tab) => {
    try {
      this.props.setExpirationFilters({
        waiver: tab,
        page: 1,
      });
      this.fetchExpirations();
    } catch (error) {}
  };

  handleUpdate = async (values, formikActions) => {
    const { setSubmitting } = formikActions;
    this.closeDialog();
    this.props.setLoading(true);
    try {
      const { expirationId } = this.state;
      const form = {
        ...values,
        date: values.date ? datePickerFormatToParseDate(values.date) : null,
        yellow_date: values.yellow_date
          ? datePickerFormatToParseDate(values.yellow_date)
          : null,
      };
      const { status } = await this.props.updateExpiration(expirationId, form);
      if (status === 200) {
        this.fetchExpirations();
        this.props.enqueueSnackbar("The expiration has been updated!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
      });
      console.error(error);
    } finally {
      this.props.setLoading(false);
      setSubmitting(false);
    }
  };

  get_solved_value(expiration) {
    const color = this.getColorExpiration(expiration);
    if (color === "green"){
      return true
    }
    else {
      return false
    }

  }


  batchExpirationPrint = async () => {
    try {
      const params = {
        ...this.props.filters,
      };
      const response = await this.props.printBatchExpirations(params);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
          "download",
          `expirations-${params.start_date}-${params.end_date}.pdf`
      );
      document.body.appendChild(link);
      link.click();

    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  changeSolved = async (expiration) => {
    try {
      const body = {
        solved: !this.get_solved_value(expiration),
        color: this.getColorExpiration(expiration),
      };
      if ( !!expiration.red_solved_date && this.get_yellow_color(expiration) && !this.get_red_color(expiration) ){
        return false
      }
      const response = await this.props.updateExpiration(expiration.id, body);
      await this.fetchExpirations();
      if (response.status === 200) {
        this.props.enqueueSnackbar("Updated successfully", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      } else {
        this.props.enqueueSnackbar("Update failed ", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    } catch (error) {}
  };

  filter = (items, waiver = true) => {
    return items.filter((item) => item.waiver === waiver);
  };

  getPatientName = (ocurrence) => {
    const { first_name = "", last_name = "" } = ocurrence.patient || {};

    return `${first_name} ${last_name}`;
  };

  openDialog = (expiration) => {
    const { date, yellow = false, waiver, note, yellow_date } = expiration;

    this.setState({
      showDialog: true,
      expirationId: expiration.id,
      form: { date: moment(date), yellow, waiver, note, yellow_date },
    });
  };

  closeDialog = () => {
    this.setState({
      showDialog: false,
      form: {
        date: null,
        yellow: false,
        yellow_date: null,
        waiver: false,
        note: "",
      },
    });
  };

  get_yellow_color(expiration){
    const { start_date, end_date } = this.props.filters;
    const yellow_date = _get(expiration, "yellow_date");

    const yellowDateIsBetweenRange = yellow_date
      ? moment(yellow_date, "YYYY-MM-DD").isSameOrAfter(
          moment(start_date, "YYYY-MM-DD")
        ) &&
        moment(yellow_date, "YYYY-MM-DD").isSameOrBefore(
          moment(end_date, "YYYY-MM-DD")
        )
      : false;

    const mustBeYellow = expiration.yellow && yellowDateIsBetweenRange;
    return mustBeYellow
  }

  fetchShippingStatusDelayed = _debounce((e) => {
    return this.fetchExpirations();
  }, 1500);

  get_red_color(expiration){
    const { start_date, end_date } = this.props.filters;
    const date = _get(expiration, "date");

    //temp fix for date changed // ? : ternary operator
    const dateIsBetweenRange = date
      ? moment(date, "YYYY-MM-DD").isSameOrAfter(
          moment(start_date, "YYYY-MM-DD")
        ) &&
        moment(date, "YYYY-MM-DD").isSameOrBefore(
          moment(end_date, "YYYY-MM-DD")
        )
      : false;
    
      return dateIsBetweenRange
  }

  handleSearch = (event) => {
    //to future move to tableResponsive component
    this.props.setExpirationFilters({
      search: event.target.value,
      page: 1,
    });
    this.fetchShippingStatusDelayed.cancel();
    this.fetchShippingStatusDelayed();

  };

  getColorExpiration(expiration) {
    if (!!expiration.red_solved_date) return "green";
    const { start_date, end_date } = this.props.filters;

    const date = _get(expiration, "date");
    const yellow_date = _get(expiration, "yellow_date");

    //temp fix for date changed // ? : ternary operator
      const dateIsBetweenRange = date
      ? moment(date, "YYYY-MM-DD").isSameOrAfter(
          moment(start_date, "YYYY-MM-DD")
        ) &&
        moment(date, "YYYY-MM-DD").isSameOrBefore(
          moment(end_date, "YYYY-MM-DD")
        )
      : false;
    const yellowDateIsBetweenRange = yellow_date
      ? moment(yellow_date, "YYYY-MM-DD").isSameOrAfter(
          moment(start_date, "YYYY-MM-DD")
        ) &&
        moment(yellow_date, "YYYY-MM-DD").isSameOrBefore(
          moment(end_date, "YYYY-MM-DD")
        )
      : false;

    const mustBeYellow = expiration.yellow && yellowDateIsBetweenRange;

    if (!mustBeYellow) {
      if (!!expiration.red_solved_date){
        return "green"
      }
      else{
        return "red";
      }
    }
    if (mustBeYellow && dateIsBetweenRange) {
      if(!!expiration.red_solved_date){
        return "green";
      }
      else{
        return "red";
      }
    }
    if (mustBeYellow && !dateIsBetweenRange) {
      if(!!expiration.yellow_solved_date){
        return "green";
      }
      else {
        return "yellow";
      }
    }
    return "";

    // return expiration.yellow && isBetweenRange ? "yellow" : "red";
  }

  getCurrentState = (states, state) => {
    if (state && states.length) {
      const currentState = states.find((_state) => _state.id === state);
      return {
        value: (currentState || {}).id,
        label: (currentState || {}).name,
      };
    }
    return null;
  };

  render() {
    const { showDialog } = this.state;
    const {
      expirations,
      loading,
      classes,
      filters: { waiver, start_date, end_date, state, hide_solved , search, facility},
      us_patient_states,
    } = this.props;

    return (
      <Layout title="Expirations">
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid
            container
            spacing={4}
            justify="space-between"
            alignItems="flex-end"
            style={{ marginBottom: 16 }}
          >
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="start_date"
                label="Start Date"
                value={start_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("start_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="MM/DD/YYYY"
              />
            </Grid>
            <Grid item xs={3}>
              <KeyboardDatePicker
                name="end_date"
                label="End Date"
                value={end_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => this.handleFilters("end_date", date)}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="MM/DD/YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} container alignItems="flex-end">
              <Autocomplete
                onChange={(value) => {
                  const newValue = (value || {}).value;
                  this.props.setExpirationFilters({
                    state: newValue ? newValue : "",
                    page: 1,
                  });

                  this.fetchExpirations();
                }}
                value={this.getCurrentState(us_patient_states, state)}
                // value={filters.state_id}
                suggestions={us_patient_states.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                placeholder="State"
              />
            </Grid>
            <Grid item xs={3} alignItems="flex-end" container>
              <TextField
                  label="Facility"
                  margin="normal"
                  value={facility}
                  onChange={(value) => {
                    const newValue = (value || {}).target.value;
                    this.props.setExpirationFilters({
                      facility: newValue ? newValue : "",
                      page: 1,
                    });

                    this.fetchExpirations();
                  }}
                  fullWidth
                  select
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </Grid>
            <Grid container justify="flex-end" className={classes.divSearch}>
              <Grid
                  container
                  item
                  xs={12}
                  sm={3}
                  alignItems="flex-end"
                  className={classes.downloadButtonContainer}
              >
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={this.batchExpirationPrint}
                >
                  Batch print
                  <PdfIcon className={classes.rightIcon} />
                </Button>
              </Grid>
              <FormControlLabel
                style={{ marginLeft: "auto" }}
                control={
                  <Switch
                    checked={hide_solved}
                    onChange={() => {
                      this.props.setExpirationFilters({
                        hide_solved: !hide_solved,
                      });
                      this.fetchExpirations();
                    }}
                    disabled={this.props.loading}
                    color="primary"
                  />
                }
                label="Hide Solved"
              />
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <Search />
                </div>
                <InputBase
                    name="search"
                    placeholder="Search..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    autoFocus={true}
                    // disabled={loading}
                    value={this.props.filters.search}
                    onChange={this.handleSearch}
                />
              </div>

            </Grid>
          </Grid>
          <Tabs
            value={waiver}
            onChange={this.handleTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Waiver True" value={true} />
            <Tab label="Waiver False" value={false} />
          </Tabs>
          <TableResponsive
            items={expirations}
            config={this.config}
            emptyMessage="EXPIRATIONS"
            metaFilters={{
              filters: this.props.filters,
              setFilters: this.props.setExpirationFilters,
            }}
            fetchData={this.fetchExpirations}
          />
        </Panel>

        <Formik
          onSubmit={this.handleUpdate}
          initialValues={{
            ...this.state.form,
          }}
          validationSchema={Yup.object().shape({
            date: Yup.date(),
            yellow: Yup.mixed(),
            yellow_date: Yup.mixed().when("yellow", (yellow, schema) =>
              Boolean(yellow)
                ? schema.required("Yellow date is required")
                : schema
            ),
            note: Yup.string().required("Note is required"),
          })}
          enableReinitialize
        >
          {(props) => {
            const {
              isSubmitting,
              handleBlur,
              setFieldTouched,
              handleChange,
              isValid,
              errors,
              touched,
              values,
              dirty,
              setFieldValue,
              handleSubmit,
            } = props;
            return (
              <Form>
                <Prompt
                  when={dirty}
                  message="Are you sure you want to leave?, You will lose your changes"
                />
                <Dialog
                  open={showDialog}
                  onClose={this.closeDialog}
                  aria-labelledby="form-dialog-title"
                  maxWidth="xs"
                  fullWidth
                >
                  <DialogTitle id="form-dialog-title">
                    Edit Expiration
                  </DialogTitle>

                  <DialogContent>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          name="date"
                          label="Date"
                          value={values.date || null}
                          invalidLabel={""}
                          margin="normal"
                          format={"MM/DD/YYYY"}
                          required
                          onChange={(date) => {
                            setFieldValue("date", date);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          animateYearScrolling
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="MM/DD/YYYY"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <Today />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          name="yellow"
                          control={
                            <Switch
                              checked={values.yellow}
                              onChange={(e) => {
                                setFieldValue("yellow", e.target.checked);
                                if (!e.target.checked) {
                                  setFieldValue("yellow_date", null);
                                  setTimeout(
                                    () => setFieldTouched("yellow"),
                                    250
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              type="checkbox"
                              value="1"
                              color="primary"
                            />
                          }
                          label="Yellow"
                        />
                      </Grid>
                      {values.yellow ? (
                        <>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            <KeyboardDatePicker
                              name="yellow_date"
                              label="Yellow Date"
                              value={values.yellow_date || null}
                              invalidLabel={""}
                              margin="normal"
                              format={"MM/DD/YYYY"}
                              required
                              onChange={(date) => {
                                setFieldValue("yellow_date", date);
                              }}
                              onBlur={handleBlur}
                              fullWidth
                              animateYearScrolling
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="MM/DD/YYYY"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="calendar">
                                      <Today />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </>
                      ) : null}
                    </Grid>

                    <TextField
                      name="note"
                      margin="normal"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!touched.note && !!errors.note}
                      helperText={
                        !!touched.note && !!errors.note && errors.note
                      }
                      label="Note"
                      required
                      fullWidth
                      multiline
                      rows={2}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="waiver"
                          checked={values.waiver}
                          onChange={(e) => {
                            setFieldValue("waiver", e.target.checked);
                          }}
                          onBlur={handleBlur}
                          value="1"
                          color="primary"
                        />
                      }
                      label="Waiver"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeDialog}
                      color="primary"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) => {
                        handleSubmit();
                      }}
                      type="submit"
                      color="primary"
                      disabled={
                        loading ||
                        isSubmitting ||
                        (isValid && !dirty) ||
                        (!isValid && dirty)
                      }
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // ocurrences: state.expirations,
    expirations: state.expirations.list,
    handleForm: state.global.handleForm,
    loading: state.global.loading,
    filters: state.expirations.filters,
    us_patient_states: state.global.us_patient_states,
  };
};

const mapDispatchToProps = {
  setLoading,
  getExpirations,
  updateExpiration,
  printBatchExpirations,
  setHandleForm,
  setPatientIdOrigin,
  setExpirationFilters,
  fetchPatientStates,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ExpirationList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ExpirationList);
