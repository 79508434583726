const styles = theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: 20
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  BreadcumbWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export default styles;