import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import TableResponsive from "../../../components/TableResponsive";

function ContactsModal({ open, onClose: _handleCloseModal, contacts }) {
  const config = {
    columns: [
      {
        display_name: "Contact Name",
        name: "contact_name",
        type: "string",
        align: "inherit",
        // sorted: true,
      },
      {
        display_name: "Contact Phone number",
        name: "contact_number",
        type: "string",
        style: { minWidth: 175 },
        align: "inherit",
        // sorted: true,
      },
      {
        display_name: "Contact Relation",
        name: "contact_relation",
        type: "string",
        align: "inherit",
        // sorted: true,
      },
    ],
    pagination: false,
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onBackdropClick={_handleCloseModal}
      onEscapeKeyDown={_handleCloseModal}
    >
      <DialogTitle id="alert-dialog-title">Contacts</DialogTitle>
      <DialogContent>
        <TableResponsive
          items={contacts}
          config={config}
          emptyMessage={"CONTACTS"}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={_handleCloseModal}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ContactsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
};

ContactsModal.defaultProps = {};

export default ContactsModal;
