import {
    GET_REPORT_END_YEAR,
    ON_LOADING, SET_FILTER_REPORT_END_YEAR,
} from "../actionTypes";
import EndYearReportService from "../../services/EndYearReportService";

const service = new EndYearReportService();

export const setLoading = (loading) => ({ type: ON_LOADING, loading });

export const setFilterEndYearReport = (payload) => ({
    type: SET_FILTER_REPORT_END_YEAR,
    payload,
});

export const getEndYearReport = () => {
    return async (dispatch, getState) => {
        dispatch(setLoading(true));
        try {
            const {
                start_date,
                end_date,
                ...restFilters
            } = getState().endYearReport.filters;
            //temp frontend validation

            const newFilters = { ...restFilters };

            if (start_date && end_date) {
                newFilters.start_date = start_date;
                newFilters.end_date = end_date;
            }

            const response = await service.list(newFilters);
            const { data, status } = response;
            if (status === 200) {

                const { results, ...meta } = data;

                dispatch({ type: GET_REPORT_END_YEAR, payload: results });
            }
            return response;
        } catch (error) {
            dispatch({ type: GET_REPORT_END_YEAR, payload: [] });

            console.error(error);
        } finally {
            dispatch(setLoading(false));
        }
    };
};

