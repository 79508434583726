import Service from "./Service";

class OrderService extends Service {
  async list(params = {}) {
    try {
      return await this.http.get("/orders/", { params });
    } catch (error) {
      return error.response;
    }
  }

  async listByPatient(id) {
    try {
      return await this.http.get(`/orders/patient-what-ever/${id}`);
    } catch (error) {
      return error.response;
    }
  }

  async create(body = {}) {
    try {
      return await this.http.post("/orders/", body);
    } catch (error) {
      return error.response;
    }
  }

  async update(id, body = {}) {
    try {
      return await this.http.patch(`/orders/${id}/`, body);
    } catch (error) {
      return error.response;
    }
  }

  async cloningOrder(id) {
    try {
      return this.http.get(`/clone-order/${id}/`);
    } catch (error) {
      return error.response;
    }
  }

  async get(id) {
    try {
      return await this.http.get(`/orders/${id}/`);
    } catch (error) {
      return error.response;
    }
  }

  async delete(id) {
    try {
      return await this.http.delete(`/orders/${id}/`);
    } catch (error) {
      return error.response;
    }
  }

  async status() {
    try {
      return await this.http.get("/order_status/");
    } catch (error) {
      return error.response;
    }
  }

  async deliveries() {
    try {
      return await this.http.get("/deliveries/");
    } catch (error) {
      return error.response;
    }
  }

  async recurrences() {
    try {
      return await this.http.get("/recurrences/");
    } catch (error) {
      return error.response;
    }
  }

  async addProduct(orderId, body = {}) {
    try {
      const url = `/orders/${orderId}/products/`;

      return await this.http.post(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async updateProduct(orderId, orderProductId, body = {}) {
    try {
      const url = `/orders/${orderId}/products/${orderProductId}/`;

      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async removeProduct(orderId, orderProductId) {
    try {
      const url = `/orders/${orderId}/products/${orderProductId}/`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addExpiration(orderId, body) {
    try {
      const url = `/orders/${orderId}/expirations/`;

      return await this.http.post(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async updateService(serviceId, body) {
    try {
      const url = `/services/${serviceId}/`;

      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async cloneService(body = {}) {
    try {
      return await this.http.post("/clone_service/", body);
    } catch (error) {
      return error.response;
    }
  }
  async deleteService(serviceId) {
    try {
      const url = `/services/${serviceId}`;

      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  }

  async addCallNote(serviceId, body) {
    try {
      const url = `/users/service_notes/${serviceId}/`;
      return await this.http.post(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async updateOcurrencePo(ocurrenceId, body) {
    try {
      const url = `/ocurrences/${ocurrenceId}/`;
      return await this.http.patch(url, body);
    } catch (error) {
      return error.response;
    }
  }

  async getAllCallNotes(serviceId) {
    try {
      const url = `/users/service_notes/${serviceId}/`;
      return await this.http.get(url);
    } catch (error) {
      return error.response;
    }
  }

  async getProductPriceVariants(productId) {
    try {
      const url = `/variants/${productId}/`;
      return await this.http.get(url);
    } catch (error) {
      return error.response;
    }
  }

  async getOrderProductPrice(orderProductId){
    try {
      const url = `/order_products/${orderProductId}`;
      return await this.http.get(url);
    } catch (error) {
      return error.response
    }
  }
}

export default OrderService;
