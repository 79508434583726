import React from "react";
import Layout from "../../components/Layout";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid/Grid";
import UserIcon from "@material-ui/icons/GroupOutlined";
import ProductIcon from "@material-ui/icons/Opacity";
import PatientIcon from "@material-ui/icons/Accessible";
import OrderIcon from "@material-ui/icons/ShoppingCartOutlined";
import ExpirationIcon from "@material-ui/icons/CalendarToday";
import CallCustomersIcon from "@material-ui/icons/CallOutlined";
import CheckEligibilityIcon from "@material-ui/icons/MobileFriendly";
import ShippingStatusIcon from "@material-ui/icons/LocalShippingOutlined";
import { withStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { compose } from "recompose";
import styles from "./styles";
import { Money, QueryBuilde, Description } from "@material-ui/icons";

class Home extends React.Component {
  render() {
    const { classes, auth } = this.props;
    return (
      <Layout title="Dashboard">
        <div className={classes.root}>
          <Grid container spacing={2}>
            {auth.role_id === 1 && (
              <Grid item xs={6} sm={3}>
                <Link
                  component={RouterLink}
                  to="/users"
                  className={classes.link}
                >
                  <Card>
                    <CardContent className={classes.card}>
                      <UserIcon className={classes.icon} color="primary" />
                      <Typography component="h6" variant="h6">
                        Users
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            )}
            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/patients"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <PatientIcon className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Patients
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Link
                  component={RouterLink}
                  to="/patients_rentals"
                  className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <PatientIcon className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Rentals
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/vendors"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <UserIcon className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Vendors
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/products"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <ProductIcon className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Products
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Link
                  component={RouterLink}
                  to="/products_inventory"
                  className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <ProductIcon className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Products Inventory
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/orders"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <OrderIcon className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Orders
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/expirations"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <ExpirationIcon className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Expirations
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/check-eligibility"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <CheckEligibilityIcon
                      className={classes.icon}
                      color="primary"
                    />
                    <Typography component="h6" variant="h6">
                      Check Eligibility
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/*<Grid item xs={6} sm={3}>*/}
            {/*  <Link*/}
            {/*    component={RouterLink}*/}
            {/*    to="/call-customers"*/}
            {/*    className={classes.link}*/}
            {/*  >*/}
            {/*    <Card>*/}
            {/*      <CardContent className={classes.card}>*/}
            {/*        <CallCustomersIcon*/}
            {/*          className={classes.icon}*/}
            {/*          color="primary"*/}
            {/*        />*/}
            {/*        <Typography component="h6" variant="h6">*/}
            {/*          Call Patients*/}
            {/*        </Typography>*/}
            {/*      </CardContent>*/}
            {/*    </Card>*/}
            {/*  </Link>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} sm={3}>*/}
            {/*  <Link*/}
            {/*    component={RouterLink}*/}
            {/*    to="/call-customer-w-eligibility"*/}
            {/*    className={classes.link}*/}
            {/*  >*/}
            {/*    <Card>*/}
            {/*      <CardContent className={classes.card}>*/}
            {/*        <CallCustomersIcon*/}
            {/*          className={classes.icon}*/}
            {/*          color="primary"*/}
            {/*        />*/}
            {/*        <Typography component="h6" variant="h6">*/}
            {/*          Call Patient w Eligibility*/}
            {/*        </Typography>*/}
            {/*      </CardContent>*/}
            {/*    </Card>*/}
            {/*  </Link>*/}
            {/*</Grid>*/}

            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/shipping-status"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <ShippingStatusIcon
                      className={classes.icon}
                      color="primary"
                    />
                    <Typography component="h6" variant="h6">
                      Shipping Status
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/remittance"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <ShippingStatusIcon
                      className={classes.icon}
                      color="primary"
                    />
                    <Typography component="h6" variant="h6">
                      Remittance
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Link
                  component={RouterLink}
                  to="/remittance_revert"
                  className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <Money className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Remittance Revert
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/invoices"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <Money className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Invoices
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Link
                  component={RouterLink}
                  to="/end_year_report"
                  className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <Description className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      End of Year Report
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            {/* <Grid item xs={6} sm={3}>
              <Link
                component={RouterLink}
                to="/expired-deliveries"
                className={classes.link}
              >
                <Card>
                  <CardContent className={classes.card}>
                    <QueryBuilder className={classes.icon} color="primary" />
                    <Typography component="h6" variant="h6">
                      Expired deliveries
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid> */}
          </Grid>
        </div>
      </Layout>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
