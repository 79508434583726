import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Grid,
  IconButton,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { useFormikContext, FieldArray } from "formik";
import { Delete, Add, Edit } from "@material-ui/icons";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import useModal from "../../hooks/use-modal";
import PriceProductVariantModal from "./PriceProductVariantModal";
import { connect } from "react-redux";
import { formatNumberWithOutComma } from "../../common/Helpers/FormatNumberHelper";
import {DialogDelete} from "../index";
const useStyles = makeStyles((theme) =>
  createStyles({
    Root: {
      padding: theme.spacing(2),
      width: "100%",
    },
  })
);

function PriceProductVariantList({ loading }) {
  const {
    values: { variants = [] , open = false , remove_id = null},

    setFieldValue,
  } = useFormikContext();

  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [
    openPriceProductVariantModal,
    _handlePriceProductVariantModalOpen,
    _handlePriceProductVariantModalClose,
  ] = useModal();

  const _handleAddBtnClick = () => {
    setCurrentIndex(null);
    _handlePriceProductVariantModalOpen();
  };

  const _handleEditBtnClick = (index) => () => {
    setCurrentIndex(index);
    _handlePriceProductVariantModalOpen();
  };

  const _handleDeleteBtnClick = (index) => () => {
    setFieldValue("open", true);
    setFieldValue("remove_id", index);
  };

  const _handleDeleteAgreeClick = () => {
    setFieldValue(
      "variants",
      variants.filter((variant, _index) => _index !== remove_id)
    );
    setFieldValue("open", false)
  };

  return (
    <Paper classes={{ root: classes.Root }}>
      <Grid container justify="space-between">
        <DialogDelete
            item="contact"
            open={open}
            closeModal={() => setFieldValue("open", false)}
            remove={_handleDeleteAgreeClick}
            isDelivery={false}
        />
        <Typography variant="h6">Price Variants</Typography>
        <Grid item>
          <IconButton
            color="primary"
            aria-label="delete"
            onClick={_handleAddBtnClick}
          >
            <Add />
          </IconButton>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Price Variant</TableCell>
                <TableCell>Unit of Measure</TableCell>
                <TableCell>Sale Price</TableCell>
                <TableCell>Cost Price</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variants.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography
                      style={{ marginTop: 32, marginBottom: 32 }}
                      align="center"
                      variant="h6"
                      color="textSecondary"
                    >
                      Must be have a price variant the at least
                    </Typography>
                  </TableCell>
                </TableRow>
              )}

              {variants.map((row, _index) => (
                <TableRow key={JSON.stringify(row)}>
                  {/* row.price_variant*/}
                  <TableCell>{row.price_variant}</TableCell>
                  <TableCell>{row.unit_measure}</TableCell>
                  <TableCell>
                    {formatNumberWithOutComma(+row.sale_price)}
                  </TableCell>
                  <TableCell>
                    {formatNumberWithOutComma(+row.cost_price)}
                  </TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex" }}>
                      <IconButton
                        aria-label="Edit"
                        color="primary"
                        onClick={_handleEditBtnClick(_index)}
                        disabled={loading}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="pdf-icon"
                        color="secondary"
                        disabled={loading}
                        onClick={_handleDeleteBtnClick(_index)}
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <PriceProductVariantModal
        open={openPriceProductVariantModal}
        onClose={_handlePriceProductVariantModalClose}
        index={currentIndex}
      />
    </Paper>
  );
}

PriceProductVariantList.propTypes = {};

PriceProductVariantList.defaultProps = {};

const mapStateToProps = (state) => ({
  loading: state.global.loading,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceProductVariantList);
