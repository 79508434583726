const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: 'relative',
    minHeight: 200,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row"
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  buttonBack: {
    height: 35,
    marginBottom: 20
  },
  dialogContentMax: {
    height: 375
  },
  dialogContentMin: {
    height: "auto"
  },
  select: {
    width: "100%",
    marginTop: 26,
    '& .react-select__value-container': {
      padding: 0
    },
    '& .react-select__indicator-separator': {
      backgroundColor: "gray"
    },
    '& .react-select__dropdown-indicator': {
      color: "gray"
    },
    '& > .react-select__control': {
      border: "none",
      borderRadius: 0,
      borderBottom: "1px solid gray",
      fontSize: 16
    },
    '& > .react-select__control--is-focused, & > .react-select__control:hover': {
      border: "none",
      boxShadow: "none",
      borderRadius: 0,
      borderBottom: "1px solid gray"
    },
    '& .react-select__option': {
      fontSize: 16
    },
    '& .react-select__option:first-child': {
      backgroundColor: "white"
    },
    '& .react-select__option:hover': {
      backgroundColor: "#ebebeb"
    },
    '& .react-select__option--is-selected': {
      backgroundColor: "#dbdbdb",
      color: "black"
    },
    '& .react-select__option--is-selected:hover': {
      backgroundColor: "#dbdbdb"
    }
  }
});

export default styles;