import {
  GET_EXPIRATIONS,
  UPDATE_EXPIRATION,
  SET_EXPIRATIONS_FILTERS,
} from "../actionTypes";

const defaulState = {
  list: [],
  filters: {
    start_date: "",
    end_date: "",
    state: null,
    waiver: true,
    hide_solved: false,

    search: "",
    //pagination filters
    ordering: "",
    page: 1,
    page_size: 25,
    facility: null,
    count: 0,
  },
};

export default (state = defaulState, action) => {
  switch (action.type) {
    case GET_EXPIRATIONS:
      return {
        ...state,
        list: action.payload,
      };

    case SET_EXPIRATIONS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case UPDATE_EXPIRATION:
      return {
        ...state,
        list: state.list.map((item) => {
          return item.id === action.payload.id ? { ...action.payload } : item;
        }),
      };

    default:
      return state;
  }
};
