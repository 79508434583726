import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import styles from "./styles";
import { Breadcrumbs } from "@material-ui/core";
import classNames from 'classnames'
class SimpleBreadcrumbs extends React.Component {
  render() {
    const { classes, routes, action } = this.props;

    return (
      <div className={classes.root}>
        <Paper
          className={classNames([classes.paper, classes.BreadcumbWrapper])}
        >
          <Breadcrumbs arial-label="Breadcrumb">
            {routes.map((route, index) => {
              return route.to !== null ? (
                <Link
                  key={index}
                  component={RouterLink}
                  color="inherit"
                  to={route.to}
                >
                  {route.name}
                </Link>
              ) : (
                <Typography key={index} color="textPrimary">
                  {route.name}
                </Typography>
              );
            })}
          </Breadcrumbs>
          {action}
        </Paper>
      </div>
    );
  }
}

SimpleBreadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
};

export default withStyles(styles)(SimpleBreadcrumbs);
