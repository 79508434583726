import { useState } from "react";

function useModal() {
  const [openModal, setOpenModal] = useState(false);
  const _handleModalOpen = () => {
    setOpenModal(true);
  };

  const _handleModalClose = () => {
    setOpenModal(false);
  };

  return [openModal, _handleModalOpen, _handleModalClose];
}

export default useModal;
