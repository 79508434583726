import {
    GET_REPORT_END_YEAR, SET_FILTER_REPORT_END_YEAR,
} from "../actionTypes";

const defaultState = {
    filters: {
        start_date: "",
        end_date: "",

        ///pagination filters
        date_range: null,
    },
};
export default (state = defaultState, action) => {
    switch (action.type) {
        case GET_REPORT_END_YEAR:
            return { ...state, list: action.payload };

        case SET_FILTER_REPORT_END_YEAR:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                },
            };


        default:
            return state;
    }
};
