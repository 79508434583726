import Service from "./Service";

class ExpirationService extends Service {
  async list(params = {}) {
    try {
      return await this.http.get("/expirations_ocurrences/", { params });
    } catch (error) {
      return error.response;
    }
  }

  async listCheckExpirations() {
    try {
      return await this.http.get("/check_expirations/");
    } catch (error) {
      return error.response;
    }
  }

  async update(id, body) {
    try {
      return await this.http.patch(`/expirations/${id}/`, body);
    } catch (error) {
      return error.response;
    }
  }

  async printBatchExpirationRows(params = {}) {
    try {
      return await this.http.get(`/export_expirations`, {
        params,
        responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }

  async delete(id, body) {
    try {
      return await this.http.delete(`/expirations/${id}/`);
    } catch (error) {
      return error.response;
    }
  }
}

export default ExpirationService;
