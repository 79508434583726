import {
  GET_CALL_CUSTOMERS,
  UPDATE_CALL_CUSTOMER,
  SET_EMPTY,
  GET_CALL_CUSTOMER,
  SET_FILTER_CALL_CUSTOMERS,
  ON_LOADING,
} from "../actionTypes";
import CallCustomerService from "../../services/CallCustomerService";

const service = new CallCustomerService();

export const setEmptyCallCustomers = () => {
  return async (dispatch) => {
    dispatch({ type: SET_EMPTY, payload: [] });
  };
};

export const setFiltersCallCustomer = (payload) => ({
  type: SET_FILTER_CALL_CUSTOMERS,
  payload,
});

export const getCallCustomers = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: ON_LOADING, loading: true });
    try {
      const {
        start_date,
        end_date,
        state,
        ...restFilters
      } = getState().callCustomers.filters;
      //temp frontend validation

      const newFilters = { ...restFilters };

      if (state) {
        newFilters.state = state;
      }

      if (start_date && end_date) {
        newFilters.start_date = start_date;
        newFilters.end_date = end_date;
      }

      const response = await service.list(newFilters);
      const { data, status } = response;

      if (status === 200) {
        const { results, ...meta } = data;

        dispatch({ type: GET_CALL_CUSTOMERS, payload: results });
        dispatch({
          type: SET_FILTER_CALL_CUSTOMERS,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      }
      dispatch({ type: ON_LOADING, loading: false });

      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const getCallCustomer = (ocurrenceId) => {
  return async (dispatch) => {
    try {
      const response = await service.get(ocurrenceId);
      if (response.status === 200) {
        dispatch({ type: GET_CALL_CUSTOMER, payload: response.data });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateCallCustomer = (orderId, serviceId, body) => {
  return async (dispatch) => {
    try {
      const response = await service.update(serviceId, body);
      const { data, status } = response;

      if (status === 200) {
        dispatch({
          type: UPDATE_CALL_CUSTOMER,
          payload: { ...data, serviceId },
        });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};
