import { combineReducers } from "redux";
import globalReducer from './globalReducer';
import userReducer from './userReducer';
import authReducer from './authReducer';
import patientReducer from './patientReducer';
import productReducer from './productReducer';
import remmitanceReducer from "./remmitanceRevertReducer";
import orderReducer from './orderReducer';
import expirationReducer from './expirationReducer';
import callCustomersReducer from './callCustomerReducer';
import checkEligibilityReducer from './checkElibilityReducer';
import shippingStatusReducer from './shippingStatusReducer';
import remittanceReducer from "./remittanceReducer";
import endYearReportReducer from "./endYearReportReducer";
import vendorReducer from "./vendorReducer";
import invoiceReducer from "./invoiceReducer";
import deliveriesReducer from "./deliveriesReducer";
import callCustomerwCheckelibilityReducer from "./callCustomerwCheckelibilityReducer";
import batchReducer from "./batchReducer";

export default combineReducers({
  global: globalReducer,
  auth: authReducer,
  users: userReducer,
  batch_list: batchReducer,
  patients: patientReducer,
  products: productReducer,
  orders: orderReducer,
  expirations: expirationReducer,
  remmitanceReverts: remmitanceReducer,
  callCustomers: callCustomersReducer,
  checkEligibility: checkEligibilityReducer,
  shippingStatus: shippingStatusReducer,
  endYearReport: endYearReportReducer,
  remittance: remittanceReducer,
  invoice: invoiceReducer,
  vendors: vendorReducer,
  deliveries: deliveriesReducer,
  callCustomerwCheckelibility: callCustomerwCheckelibilityReducer,
});
