const styles = theme => ({
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  tableContainer: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20
  },
  paragraph: {
    fontSize: 14
  }
});

export default styles;