import React from "react";
import {
  withStyles,
  Grid,
  TextField,
  Button,
  MenuItem, IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {
  SimpleBreadcrumbs,
  Panel,
  Layout,
  Errors,
  PriceProductVariantList,
} from "../../../components";
import {
  createProduct,
  getTypes,
  getCategories,
} from "../../../redux/actions/productActions";
import { UNITS } from "../../../common/Constants/ProductConstants";
import {
  setLoading,
  setHandleForm,
} from "../../../redux/actions/globalActions";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import styles from "./styles";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import InputFiles from "react-input-files";
import { formatNumberWithOutComma } from "../../../common/Helpers/FormatNumberHelper";
import {Add} from "@material-ui/icons";
import UnitMeasureQuantityModal from "../../../components/UnitMeasureQuantity";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Products", to: "/products" },
  { name: "Create Product", to: null },
];

class ProductCreate extends React.Component {
  state = {
    openDialog: false,
    form: {
      name: "",
      description: "",
      // sale_price: "",
      // cost_price: "",
      // unit_measure: "",
      internal_code: "",
      cost_price: "",
      type_id: "",
      quantity: "",
      photo: "",
      variants: [],
      qty_unit_measures: [],
    },
    categories: ["", "", ""],
  };

  componentDidMount = async () => {
    this.props.setLoading(true);

    try {
      this.props.getTypes();
      this.props.getCategories();
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  handlePhoto = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      this.setState({ file: files[0] });
    }
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  getFormData = (values) => {
    const { categories } = this.state;
    const form = { ...values };
    const formData = new FormData();

    Object.keys(form).forEach((key) => {
      if (key === "variants") {
        formData.append(
          "variants",
          JSON.stringify(
            form["variants"].map((variant) => ({
              ...variant,
              sale_price: +formatNumberWithOutComma(variant.sale_price),
              cost_price: +formatNumberWithOutComma(variant.cost_price),
            }))
          )
        );
      } else {
        formData.append(key, form[key]);
      }
    });

    categories.forEach((category) => {
      if (category !== "") {
        formData.append("categories", category);
      }
    });

    return formData;
  };

  handleSubmit = async (values, formikActions) => {
    const { setSubmitting, resetForm } = formikActions;
    this.props.setLoading(true);
    setSubmitting(true);
    try {
      const form = this.getFormData(values);
      const { status } = await this.props.createProduct(form);

      if (status === 201) {
        /**necesary for void dialog prevent in dirty mode */
        resetForm();
        this.props.enqueueSnackbar("The product has been created!", {
          variant: "success",
        });
        this.props.history.push(`/products`);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
      this.props.setLoading(false);
    }
  };

  handleCategory = (index) => (event) => {
    const category_id = event.target.value;
    const { categories } = this.state;

    if (index === 0) {
      categories[0] = category_id;
      categories[1] = "";
      categories[2] = "";

      this.props.getCategories(category_id, 2);
    } else if (index === 1) {
      categories[1] = category_id;
      categories[2] = "";

      this.props.getCategories(category_id, 3);
    } else if (index === 2) {
      categories[2] = category_id;
    }

    this.setState({ categories });
  };

  render() {
    const { form, categories } = this.state;
    const {
      classes,
      types,
      loading,
      categories_1,
      categories_2,
      categories_3,
    } = this.props;

    return (
      <Layout title="Create Product">
        <SimpleBreadcrumbs routes={breadcrumbs} />
        <Formik
          onSubmit={this.handleSubmit}
          initialValues={{
            ...form,
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            description: Yup.string(),
            variants: Yup.array().min(1),
            internal_code: Yup.string().required("Internal code is required"),
            type_id: Yup.mixed().required("Type is required"),
            quantity: Yup.number()
              .integer("The quantity must be integer"),
            cost_price: Yup.number(),
          })}
        >
          {(props) => {
            const {
              isSubmitting,
              isValid,
              values,
              dirty,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
            } = props;
            return (
              <Form>
                <Prompt
                  when={dirty}
                  message="Are you sure you want to leave?, You will lose your changes"
                />
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.name && !!errors.name}
                        helperText={
                          !!touched.name && !!errors.name && errors.name
                        }
                        label="Name"
                        fullWidth
                        margin="normal"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="internal_code"
                        required
                        value={values.internal_code}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          !!touched.internal_code && !!errors.internal_code
                        }
                        helperText={
                          !!touched.internal_code &&
                          !!errors.internal_code &&
                          errors.internal_code
                        }
                        label="Reference Number"
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Panel>

                <Grid
                  container
                  spacing={2}
                  style={{ marginBottom: 8, marginTop: 8 }}
                >
                  <Grid item xs={12}>
                    <PriceProductVariantList />
                  </Grid>
                </Grid>
                <Panel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.description && !!errors.description}
                        helperText={
                          !!touched.description &&
                          !!errors.description &&
                          errors.description
                        }
                        label="Main Description"
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                      />
                      <Grid item xs className={classes.divPhoto}>
                        <p style={{ color: "gray" }}>Photo</p>
                        <InputFiles
                          name="photo"
                          style={{ width: "100%", height: "140px" }}
                          accept="image/*"
                          onChange={(files) => {
                            setFieldValue("photo", files[0]);
                          }}
                        >
                          <Grid item xs className={classes.gridLogo}>
                            {values.photo ? (
                              <img
                                src={
                                  values.photo &&
                                  URL.createObjectURL(values.photo)
                                }
                                alt="logo"
                              />
                            ) : (
                              <AddIcon
                                color="primary"
                                style={{ fontSize: 30 }}
                              />
                            )}
                          </Grid>
                        </InputFiles>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container spacing={2}>
                      <Grid item xs={12} sm={5}>
                        <TextField
                          name="quantity"
                          required
                          value={values.quantity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!touched.quantity && !!errors.quantity}
                          helperText={
                            !!touched.quantity &&
                            !!errors.quantity &&
                            errors.quantity
                          }
                          label="Quantity On Hand"
                          fullWidth
                          disabled
                          margin="normal"
                          type="number"
                        />
                      </Grid>
                      <Grid  item xs={12} sm={2}>
                        <IconButton
                            style={{marginTop: 20}}
                            color="primary"
                            aria-label="delete"
                            onClick={() => this.setState({openDialog: true})}
                        >
                          <Add />
                        </IconButton>
                      </Grid>
                      <Grid  item xs={12} sm={5}>
                        <TextField
                            name="cost_price"
                            // required
                            value={values.cost_price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.cost_price && !!errors.cost_price}
                            helperText={
                              !!touched.cost_price &&
                              !!errors.cost_price &&
                              errors.cost_price
                            }
                            label="Product Cost Price"
                            fullWidth
                            margin="normal"
                            type="number"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="type_id"
                          value={values.type_id}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!touched.type_id && !!errors.type_id}
                          helperText={
                            !!touched.type_id &&
                            !!errors.type_id &&
                            errors.type_id
                          }
                          label="Type"
                          select
                          fullWidth
                          margin="normal"
                          required
                        >
                          {types.map((type) => {
                            return (
                              <MenuItem key={type.id} value={type.id}>
                                {type.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={categories[0]}
                          onChange={this.handleCategory(0)}
                          label="Product Group"
                          fullWidth
                          select
                          margin="normal"
                        >
                          {categories_1.map((category) => {
                            return (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={categories[1]}
                          onChange={this.handleCategory(1)}
                          label="Product Category"
                          fullWidth
                          select
                          margin="normal"
                          disabled={categories[0] === ""}
                        >
                          {categories_2.map((category) => {
                            return (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={categories[2]}
                          onChange={this.handleCategory(2)}
                          label="Product Subcategory"
                          fullWidth
                          select
                          margin="normal"
                          disabled={categories[1] === ""}
                        >
                          {categories_3.map((category) => {
                            return (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Panel>
                <UnitMeasureQuantityModal
                    open={this.state.openDialog}
                    onClose={this.handleCloseDialog}
                />

                <br />

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading || isSubmitting || !isValid || !dirty}
                  type="submit"
                >
                  Create Product
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Layout>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    product: state.products.product,
    types: state.products.types,
    errors: new Errors(state.products.errors),
    categories_1: state.products.categories_1,
    categories_2: state.products.categories_2,
    categories_3: state.products.categories_3,
  };
};

const mapDispatchToProps = {
  getTypes,
  createProduct,
  setLoading,
  getCategories,
  setHandleForm,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ProductCreate" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductCreate);
