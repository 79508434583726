// Global types
export const ON_LOADING = "ON_LOADING";
export const SHOW_ALERT = "SHOW_ALERT";
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const GET_ROLES = "GET_ROLES";
export const GET_US_STATES = "GET_US_STATES";
export const GET_US_PATIENT_STATES = "GET_US_PATIENT_STATES";

// Auth types
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

// User types
export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const DELETE_USER = "DELETE_USER";

// Patient types
export const GET_PATIENT_TAGS = "GET_PATIENT_TAGS";
export const GET_PATIENT_TAGS_ERROR = "GET_PATIENT_TAGS";
export const GET_PATIENTS = "GET_PATIENTS";
export const GET_RAW_PATIENTS = "GET_RAW_PATIENTS";
export const GET_PATIENTS_ERROR = "GET_PATIENTS_ERROR";
export const CREATE_PATIENT = "CREATE_PATIENT";
export const CREATE_PATIENT_ERROR = "CREATE_PATIENT_ERROR";
export const GET_PATIENT = "GET_PATIENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const UPDATE_PATIENT_ERROR = "UPDATE_PATIENT_ERROR";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const STORE_PATIENT = "STORE_PATIENT";
export const SET_PATIENT_FILTERS = "SET_PATIENT_FILTERS";
export const SET_RAW_PATIENT_FILTERS = "SET_RAW_PATIENT_FILTERS";

// Product types
export const GET_CATEGORIES_LEVEL_1 = "GET_CATEGORIES_LEVEL_1";
export const GET_CATEGORIES_LEVEL_2 = "GET_CATEGORIES_LEVEL_2";
export const GET_CATEGORIES_LEVEL_3 = "GET_CATEGORIES_LEVEL_3";
export const GET_PRODUCT_TYPES = "GET_PRODUCT_TYPES";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_RAW = "GET_PRODUCTS_RAW";
export const SET_PRODUCTS_FILTERS = "SET_PRODUCTS_FILTERS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const GET_ORDER_PRODUCT = "GET_ORDER_PRODUCT";


// User types
export const GET_VENDORS = "GET_VENDORS";
export const GET_VENDORS_ERROR = "GET_VENDORS_ERROR";
export const CREATE_VENDOR = "CREATE_VENDOR";
export const CREATE_VENDOR_ERROR = "CREATE_VENDOR_ERROR";
export const GET_VENDOR = "GET_VENDOR";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const UPDATE_VENDOR_ERROR = "UPDATE_VENDOR_ERROR";
export const DELETE_VENDOR = "DELETE_VENDOR";

export const GET_ORDER_STATUS = "GET_ORDER_STATUS";
export const GET_ORDER_DELIVERIES = "GET_ORDER_DELIVERIES";
export const GET_ORDER_RECURRENCES = "GET_ORDER_RECURRENCES";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const SET_ORDER_FILTERS = "SET_ORDER_FILTERS";
export const GET_PRODUCT_PRICE_VARIANTS = "GET_PRODUCT_PRICE_VARIANTS";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR";
export const ORDER_ADD_PRODUCT = "ORDER_ADD_PRODUCT";
export const ORDER_ADD_PRODUCT_ERROR = "ORDER_ADD_PRODUCT_ERROR";
export const ORDER_UPDATE_PRODUCT = "ORDER_UPDATE_PRODUCT";
export const ORDER_UPDATE_PRODUCT_ERROR = "ORDER_UPDATE_PRODUCT_ERROR";
export const ORDER_REMOVE_PRODUCT = "ORDER_REMOVE_PRODUCT";
export const ORDER_SET_PATIENT_ID_ORIGIN = "ORDER_SET_PATIENT_ID_ORIGIN";
export const GET_ALL_CALL_NOTES = "GET_ALL_CALL_NOTES";

export const GET_EXPIRATIONS = "GET_EXPIRATIONS";
export const SET_EXPIRATIONS_FILTERS = "SET_EXPIRATIONS_FILTERS";
export const UPDATE_EXPIRATION = "UPDATE_EXPIRATION";

export const GET_CALL_CUSTOMERS = "GET_CALL_CUSTOMERS";
export const GET_CALL_CUSTOMER = "GET_CALL_CUSTOMER";
export const UPDATE_CALL_CUSTOMER = "UPDATE_CALL_CUSTOMER";
export const SET_EMPTY = "SET_EMPTY";
export const SET_FILTER_CALL_CUSTOMERS = "SET_FILTER_CALL_CUSTOMERS";
export const RESET_CALL_CUSTOMERS = "RESET_CALL_CUSTOMERS";

export const GET_CHECK_ELIGIBILITIES = "GET_CHECK_ELIGIBILITIES";
export const UPDATE_CHECK_ELIGIBILITY = "UPDATE_CHECK_ELIGIBILITY";
export const SET_FILTER_CHECK_ELIGIBILITY = "SET_FILTER_CHECK_ELIGIBILITY";
export const RESET_CHECK_ELIGIBILITY = "RESET_CHECK_ELIGIBILITY";

export const GET_CALL_CUSTOMERS_W_ELIGIBILITIES =
  "GET_CALL_CUSTOMERS_W_ELIGIBILITIES";
export const GET_CALL_CUSTOMER_W_ELIGIBILITIES =
  "GET_CALL_CUSTOMER_W_ELIGIBILITIES";
export const UPDATE_CALL_CUSTOMER_W_ELIGIBILITIES =
  "UPDATE_CALL_CUSTOMER__W_ELIGIBILITIES";
export const SET_FILTER_CALL_CUSTOMERS_W_ELIGIBILITIES =
  "SET_FILTER_CALL_CUSTOMERS_W_ELIGIBILITIES";
export const RESET_CALL_CUSTOMER_W_ELIGIBILITIES_OCURRENCE =
  "RESET_CALL_CUSTOMER_W_ELIGIBILITIES_OCURRENCE";
export const RESET_CALL_CUSTOMERS_W_ELIGIBILITIES =
  "RESET_CALL_CUSTOMERS_W_ELIGIBILITIES";

export const GET_SHIPPING_STATUS = "GET_SHIPPING_STATUS";
export const UPDATE_SHIPPING = "UPDATE_SHIPPING";
export const BATCH_PRINT = "BATCH_PRINT";
export const SET_FILTER_SHIPPING_STATUS = "SET_FILTER_SHIPPING_STATUS";
export const RESET_SHIPPING_STATUS = "RESET_SHIPPING_STATUS";
export const GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT =
  "GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT";
export const GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT =
  "GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT";

export const GET_REMITTANCES = "GET_REMITTANCES";
export const GET_REVERT_REMITTANCES = "GET_REVERT_REMITTANCES";
export const DELETE_REMITTANCE_PAYMENT = "DELETE_REMITTANCE_PAYMENT";
export const GET_REMITTANCE = "GET_REMITTANCE";
export const SET_REMITTANCE_FILTERS = "SET_REMITTANCE_FILTERS";
export const UPDATE_REMITTANCE = "UPDATE_REMITTANCE";
// export const UPDATE_REMITTANCE_PRODUCT_SERVICE =
//   "UPDATE_REMITTANCE_PRODUCT_SERVICE";
export const GET_REMITTANCE_PAYMENT = "GET_REMITTANCE_PAYMENT";
export const GET_LAST_REMITTANCE_PAYMENT = "GET_LAST_REMITTANCE_PAYMENT";
export const SET_REMITTANCE_CHECKS = "SET_REMITTANCE_CHECKS";
export const GET_INVOICES = "GET_INVOICES";
export const SET_INVOICE_FILTERS = "SET_INVOICE_FILTERS";
export const GET_INVOICE = "GET_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_WRITE_OFF_INVOICE = "UPDATE_WRITE_OFF_INVOICE";

// REPORT TYPES
export const GET_REPORT_END_YEAR = "GET_REPORT_END_YEAR"
export const SET_FILTER_REPORT_END_YEAR = "SET_FILTER_REPORT_END_YEAR"

// DELIVERIES TYPES
export const GET_DELIVERIES = "GET_DELIVERIES";
export const REMOVE_DELIVERY = "REMOVE_DELIVERY";

export const HANDLE_FORM = "HANDLE_FORM";
export const SET_CURRENT_FORM = "SET_CURRENT_FORM";
