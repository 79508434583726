const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  toolbarModal: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "white",
    color: "black",
  },

  fab: {
    position: "absolute",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
  },
  callNoteContainer: {
    padding: 32,
    // height: "calc(100vh - 155px)",
    display: "flex",
    flexDirection: "column",
  },
  containerDetail: {
    alignSelf: "stretch",
    padding: 32,
    // height: "calc(100vh - 155px)",
    display: "flex",
    flexDirection: "column",
  },
  username: {
    fontWeigth: "bold",
  },
  inlineText: {
    display: "flex",
    justifyContent: "space-between",
  },
  divCallNotes: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paper: {
    flex: 1,
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    borderRadius: 4,
    padding: 16,
  },
  dialogContentMax: {
    height: 375,
  },
  dialogContentMin: {
    height: "auto",
  },
  select: {
    width: "100%",
    marginTop: 26,
    "& .react-select__value-container": {
      padding: 0,
    },
    "& .react-select__indicator-separator": {
      backgroundColor: "gray",
    },
    "& .react-select__dropdown-indicator": {
      color: "gray",
    },
    "& > .react-select__control": {
      border: "none",
      borderRadius: 0,
      borderBottom: "1px solid gray",
      fontSize: 16,
    },
    "& > .react-select__control--is-focused, & > .react-select__control:hover": {
      border: "none",
      boxShadow: "none",
      borderRadius: 0,
      borderBottom: "1px solid gray",
    },
    "& .react-select__option": {
      fontSize: 16,
    },
    "& .react-select__option:first-child": {
      backgroundColor: "white",
    },
    "& .react-select__option:hover": {
      backgroundColor: "#ebebeb",
    },
    "& .react-select__option--is-selected": {
      backgroundColor: "#dbdbdb",
      color: "black",
    },
    "& .react-select__option--is-selected:hover": {
      backgroundColor: "#dbdbdb",
    },
  },
});

export default styles;
