import React from "react";
// import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";

import UserIcon from "@material-ui/icons/GroupOutlined";
import ProductIcon from "@material-ui/icons/Opacity";
import PatientIcon from "@material-ui/icons/Accessible";
import OrderIcon from "@material-ui/icons/ShoppingCartOutlined";
import ExpirationIcon from "@material-ui/icons/CalendarToday";
import CallCustomersIcon from "@material-ui/icons/CallOutlined";
import CheckEligibilityIcon from "@material-ui/icons/MobileFriendly";
import ShippingStatusIcon from "@material-ui/icons/LocalShippingOutlined";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import {
    Money,
    QueryBuilder,
    Description,
    KeyboardArrowUp,
    KeyboardArrowDown,
    ExpandLess,
    ExpandMore
} from "@material-ui/icons";

/* const DashboardLink = props => <RouterLink to="/home" {...props} />;
const UsersLink = props => <RouterLink to="/users" {...props} />;
const PatientsLink = props => <RouterLink to="/patients" {...props} />;
const VendorsLink = props => <RouterLink to="/vendors" {...props} />;
const ProductsLink = props => <RouterLink to="/products" {...props} />;
const OrdersLink = props => <RouterLink to="/orders" {...props} />;
const ExpirationsLink = props => <RouterLink to="/expirations" {...props} />;
const CallCustomersLink = props => (
  <RouterLink to="/call-customers" {...props} />
);
const CheckEligibilityLink = props => (
  <RouterLink to="/check-eligibility" {...props} />
);
const ShippingStatusLink = props => (
  <RouterLink to="/shipping-status" {...props} />
);
const RemittanceLink = props => <RouterLink to="/remittance" {...props} />;
const InvoicesLink = props => <RouterLink to="/invoices" {...props} />;
const ExpiredDeliveries = props => <RouterLink to="/expired-deliveries" {...props} />; */

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {Collapse, IconButton} from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    ListItemText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  })
);

export function mainListItemsAdmin() {
  const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const [openRemmitance, setOpenRemmitance] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickRemmitance = () => {
        setOpenRemmitance(!openRemmitance);
    };
  return (
    <div>
      <Link to="/home" component={RouterLink} style={{ color: "black" }}>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Link>
      <Link component={RouterLink} to="/patients" style={{ color: "black" }}>
        <ListItem button>
          <ListItemIcon>
              <PatientIcon />
          </ListItemIcon>
          <ListItemText primary="Patients" />
        </ListItem>
      </Link>
        <Link
            component={RouterLink}
            to="/call-customer-w-eligibility"
            style={{ color: "black" }}
        >
            <ListItem button>
                <ListItemIcon>
                    <CallCustomersIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Call Patient w Eligibility"
                    classes={{ primary: classes.ListItemText }}
                />
            </ListItem>
        </Link>
        <Link
            component={RouterLink}
            to="/shipping-status"
            style={{ color: "black" }}
        >
            <ListItem button>
                <ListItemIcon>
                    <ShippingStatusIcon />
                </ListItemIcon>
                <ListItemText primary="Shipping Status" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/expirations" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <ExpirationIcon />
                </ListItemIcon>
                <ListItemText primary="Expirations" />
            </ListItem>
        </Link>
        <div style={{ display: "flex"}}>
            <div style={{flex: 4}}>
                <Link component={RouterLink} to="/products" style={{ color: "black" }}>
                    <ListItem button>
                        <ListItemIcon>
                            <ProductIcon />
                        </ListItemIcon>
                        <ListItemText primary="Products" />
                    </ListItem>
                </Link>
            </div>
            <div style={{flex: 1 , paddingTop: 10}}>
                {open ? <ExpandLess onClick={handleClick}/> : <ExpandMore onClick={handleClick}/>}
            </div>
        </div>
        <Collapse in={open} timeout="auto" >
            <Link component={RouterLink} to="/products_inventory" style={{ color: "black" }}>
                <ListItem button>
                    <ListItemIcon>
                        <ProductIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products Inventory" />
                </ListItem>
            </Link>
        </Collapse>

        <div style={{ display: "flex"}}>
            <div style={{flex: 4}}>
                <Link component={RouterLink} to="/remittance" style={{ color: "black"}}>
                    <ListItem button>
                        <ListItemIcon>
                            <ShippingStatusIcon />
                        </ListItemIcon>
                        <ListItemText primary="Remittance" />
                    </ListItem>
                </Link>
            </div>
            <div style={{flex: 1 , paddingTop: 10}}>
                {openRemmitance ? <ExpandLess onClick={handleClickRemmitance}/> : <ExpandMore onClick={handleClickRemmitance}/>}
            </div>
        </div>
        <Collapse in={openRemmitance} timeout="auto" >
            <Link component={RouterLink} to="/remittance_revert" style={{ color: "black"}}>
                <ListItem button>
                    <ListItemIcon>
                        <ShippingStatusIcon />
                    </ListItemIcon>
                    <ListItemText primary="Remmittance Revert" />
                </ListItem>
            </Link>
        </Collapse>
        <Link component={RouterLink} to="/invoices" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <Money />
                </ListItemIcon>
                <ListItemText primary="Invoices" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/orders" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <OrderIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/patients_rentals" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <PatientIcon />
                </ListItemIcon>
                <ListItemText primary="Rentals" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/vendors" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <UserIcon />
                </ListItemIcon>
                <ListItemText primary="Vendors" />
            </ListItem>
        </Link>

        <Link component={RouterLink} to="/users" style={{ color: "black" }}>
        <ListItem button>
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </Link>

        <Link component={RouterLink} to="/end_year_report" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <Description />
                </ListItemIcon>
                <ListItemText primary="End of Year Report" />
            </ListItem>
        </Link>

        <Link component={RouterLink} to="/batch_shipping_status" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <Description />
                </ListItemIcon>
                <ListItemText primary="Large Batch Print" />
            </ListItem>
        </Link>



      {/*<Link*/}
      {/*  component={RouterLink}*/}
      {/*  to="/check-eligibility"*/}
      {/*  style={{ color: "black" }}*/}
      {/*>*/}
      {/*  <ListItem button>*/}
      {/*    <ListItemIcon>*/}
      {/*      <CheckEligibilityIcon />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="Check Eligibility" />*/}
      {/*  </ListItem>*/}
      {/*</Link>*/}

      {/*<Link*/}
      {/*  component={RouterLink}*/}
      {/*  to="/call-customers"*/}
      {/*  style={{ color: "black" }}*/}
      {/*>*/}
      {/*  <ListItem button>*/}
      {/*    <ListItemIcon>*/}
      {/*      <CallCustomersIcon />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="Call Patients" />*/}
      {/*  </ListItem>*/}
      {/*</Link>*/}





        
      {/* <Link component={RouterLink} to="/expired-deliveries" style={{color: "black"}}>
      <ListItem button>
        <ListItemIcon>
          <QueryBuilder />
        </ListItemIcon>
        <ListItemText primary="Expired deliveries" />
      </ListItem>
    </Link> */}
    </div>
  );
}

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);

export function mainListItemsEmployee() {
  const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openRemmitance, setOpenRemmitance] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickRemmitance = () => {
        setOpenRemmitance(!openRemmitance);
    };
  return (
    <div>
      <Link to="/home" component={RouterLink} style={{ color: "black" }}>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </Link>
        <Link component={RouterLink} to="/patients" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <PatientIcon />
                </ListItemIcon>
                <ListItemText primary="Patients" />
            </ListItem>
        </Link>
        <Link
            component={RouterLink}
            to="/call-customer-w-eligibility"
            style={{ color: "black" }}
        >
            <ListItem button>
                <ListItemIcon>
                    <CallCustomersIcon />
                </ListItemIcon>
                <ListItemText
                    primary="Call Patient w Eligibility"
                    classes={{ primary: classes.ListItemText }}
                />
            </ListItem>
        </Link>
        <Link
            component={RouterLink}
            to="/shipping-status"
            style={{ color: "black" }}
        >
            <ListItem button>
                <ListItemIcon>
                    <ShippingStatusIcon />
                </ListItemIcon>
                <ListItemText primary="Shipping Status" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/expirations" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <ExpirationIcon />
                </ListItemIcon>
                <ListItemText primary="Expirations" />
            </ListItem>
        </Link>
        <div style={{ display: "flex"}}>
            <div style={{flex: 4}}>
                <Link component={RouterLink} to="/products" style={{ color: "black" }}>
                    <ListItem button>
                        <ListItemIcon>
                            <ProductIcon />
                        </ListItemIcon>
                        <ListItemText primary="Products" />
                    </ListItem>
                </Link>
            </div>
            <div style={{flex: 1 , paddingTop: 10}}>
                {open ? <ExpandLess onClick={handleClick}/> : <ExpandMore onClick={handleClick}/>}
            </div>
        </div>
        <Collapse in={open} timeout="auto" >
            <Link component={RouterLink} to="/products_inventory" style={{ color: "black" }}>
                <ListItem button>
                    <ListItemIcon>
                        <ProductIcon />
                    </ListItemIcon>
                    <ListItemText primary="Products Inventory" />
                </ListItem>
            </Link>
        </Collapse>

        <div style={{ display: "flex"}}>
            <div style={{flex: 4}}>
                <Link component={RouterLink} to="/remittance" style={{ color: "black"}}>
                    <ListItem button>
                        <ListItemIcon>
                            <ShippingStatusIcon />
                        </ListItemIcon>
                        <ListItemText primary="Remittance" />
                    </ListItem>
                </Link>
            </div>
            <div style={{flex: 1 , paddingTop: 10}}>
                {openRemmitance ? <ExpandLess onClick={handleClickRemmitance}/> : <ExpandMore onClick={handleClickRemmitance}/>}
            </div>
        </div>
        <Collapse in={openRemmitance} timeout="auto" >
            <Link component={RouterLink} to="/remittance_revert" style={{ color: "black"}}>
                <ListItem button>
                    <ListItemIcon>
                        <ShippingStatusIcon />
                    </ListItemIcon>
                    <ListItemText primary="Remmittance Revert" />
                </ListItem>
            </Link>
        </Collapse>
        <Link component={RouterLink} to="/invoices" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <Money />
                </ListItemIcon>
                <ListItemText primary="Invoices" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/orders" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <OrderIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/patients_rentals" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <PatientIcon />
                </ListItemIcon>
                <ListItemText primary="Rentals" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/vendors" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <UserIcon />
                </ListItemIcon>
                <ListItemText primary="Vendors" />
            </ListItem>
        </Link>
        <Link component={RouterLink} to="/end_year_report" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <Description />
                </ListItemIcon>
                <ListItemText primary="End of Year Report" />
            </ListItem>
        </Link>

        <Link component={RouterLink} to="/batch_shipping_status" style={{ color: "black" }}>
            <ListItem button>
                <ListItemIcon>
                    <Description />
                </ListItemIcon>
                <ListItemText primary="Large Batch Print" />
            </ListItem>
        </Link>


        {/* <Link component={RouterLink} to="/expired-deliveries" style={{color: "black"}}>
      <ListItem button>
        <ListItemIcon>
          <QueryBuilder />
        </ListItemIcon>
        <ListItemText primary="Expired deliveries" />
      </ListItem>
    </Link> */}
    </div>
  );
}
