import Service from "./Service";

export default class InvoiceService extends Service {
  async getAll(params = {}) {
    try {
      return await this.http.get(`/invoices/`, { params });
    } catch (error) {
      return error.response;
    }
  }

  async updateInvoice(invoiceId, body) {
    try {
      return await this.http.patch(`/invoices/${invoiceId}/`, body);
    } catch (error) {
      return error.response;
    }
  }

  async updateServiceWriteOff(body) {
    try {
      return await this.http.post(`/services-write-off/`, body);
    } catch (error) {
      return error.response;
    }
  }

  async getInvoice(invoiceId) {
    try {
      return await this.http.get(`/invoices/${invoiceId}`);
    } catch (error) {
      return error.response;
    }
  }
  async printInvoice(invoiceId) {
    try {
      return await this.http.get(`/invoice_export/${invoiceId}/`, {
        responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }
}
