import React, { Component } from "react";
import styles from "./styles";
import { compose } from "recompose";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  List,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  InsertDriveFile,
  KeyboardArrowDown,
  Edit,
  Delete,
  KeyboardArrowUp,
  CloudDownload,
  Refresh,
  Visibility,
} from "@material-ui/icons";
import { setLoading } from "../../../redux/actions/globalActions";
import { withRouter } from "react-router-dom";
import { printTicket } from "../../../redux/actions/shippingStatusActions";
import { printInvoice } from "../../../redux/actions/invoiceActions";
import moment from "moment";
import CallNotesModal from "./CallNotesModal";
import { cloningOrder } from "../../../redux/actions/orderActions";

class OrderItem extends Component {
  state = {
    open: false,
    openCallNotesModal: false,
    currentOcurrenceId: "",
  };

  downloadShippingTicket = async (ocurrenceId) => {
    this.props.setLoading(true);
    try {
      const response = await this.props.printTicket(ocurrenceId);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `shipping-status-${ocurrenceId}.pdf`);
        document.body.appendChild(link);
        link.click();
        this.props.getPatient();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  printInvoiceTicket = async (invoiceId) => {
    this.props.setLoading(true);
    try {
      const response = await this.props.printInvoice(invoiceId);
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `invoice-${invoiceId}.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  handleCallNotesModalOpen = (ocurrenceId) => () => {
    this.setState({
      currentOcurrenceId: ocurrenceId,
      openCallNotesModal: true,
    });
  };

  handleCallNotesModalClose = () => {
    this.setState({
      currentOcurrenceId: "",
      openCallNotesModal: false,
    });
  };

  handleShowOrderByOcurrence = (ocurrence) => () => {
    this.props.history.push(`/call-customers/${ocurrence.id}`);
  };

  render() {
    const {
      order,
      loading,
      handleOpenModalDelete,
      handleOpenModalConfirmation,
      setPatientIdOrigin,
      classes,
    } = this.props;
    const { currentOcurrenceId, openCallNotesModal } = this.state;
    return (
      <>
        <ListItem>
          <InsertDriveFile />
          <ListItemText
            primary={order.name || `No Order Name`}
            secondary={order.delivery.name}
          />
          <ListItemSecondaryAction>
            <IconButton
              aria-label="Order icon"
              onClick={() => {
                this.setState((state, props) => ({ open: !state.open }));
              }}
              disabled={loading}
            >
              {this.state.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <Tooltip title="Cloning Order" aria-label="cloning">
              <IconButton
                aria-label="Order icon"
                color="primary"
                onClick={() => handleOpenModalConfirmation(order.id, "order")}
                disabled={loading}
              >
                <Refresh />
              </IconButton>

            </Tooltip>
            <Tooltip title="Edit Order" aria-label="edit">
              <IconButton
                aria-label="Order icon"
                color="primary"
                onClick={() => {
                  setPatientIdOrigin();
                  this.props.history.push(`/orders/${order.id}/edit`);
                }}
                disabled={loading}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Order" aria-label="delete">
              <IconButton
                className={classes.iconDelete}
                aria-label="Order icon"
                onClick={() => handleOpenModalDelete(order.id, "order")}
                disabled={loading}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </ListItem>
        <div style={{ height: "100%" }}>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            {order.ocurrences.map((ocurrence, index) => (
              <List component="div" disablePadding key={ocurrence.id}>
                <ListItem style={{ padding: 8, paddingLeft: 48 }}>
                  <ListItemText
                    primary={`Ocurrence #${index + 1}`}
                    secondary={moment(ocurrence.date).format("MM-DD-YYYY")}
                  />
                  <ListItemSecondaryAction style={{ display: "flex" }}>
                    <Tooltip
                      title="Show order"
                      aria-label="Print Shipping Ticket"
                      disableFocusListener
                      disableTouchListener
                    >
                      <div>
                        <IconButton
                          aria-label="Show order"
                          onClick={this.handleShowOrderByOcurrence(ocurrence)}
                          disabled={loading}
                        >
                          <Visibility />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Show Call Notes"
                      aria-label="Print Shipping Ticket"
                      disableFocusListener
                      disableTouchListener
                    >
                      <div>
                        <IconButton
                          aria-label="Show Call Notes"
                          onClick={this.handleCallNotesModalOpen(ocurrence.id)}
                          disabled={loading}
                        >
                          <InsertDriveFile />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Print Shipping Ticket"
                      aria-label="Print Shipping Ticket"
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener={
                        ocurrence.ticket_created !== null ||
                        !ocurrence.eligibility_toggle
                      }
                    >
                      <div>
                        <IconButton
                          aria-label="Print shipping ticket"
                          onClick={() =>
                            this.downloadShippingTicket(ocurrence.id)
                          }
                          disabled={
                            loading ||
                            ocurrence.ticket_created !== null ||
                            !ocurrence.eligibility_toggle
                          }
                        >
                          <CloudDownload />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title="Print Invoice" aria-label="print Invoice">
                      <div>
                        <IconButton
                          aria-label="print invoice service"
                          onClick={() =>
                            this.printInvoiceTicket(ocurrence.invoice)
                          }
                          disabled={loading || !ocurrence.shipped}
                        >
                          <CloudDownload />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            ))}
          </Collapse>
        </div>
        <CallNotesModal
          open={openCallNotesModal}
          onClose={this.handleCallNotesModalClose}
          ocurrenceId={currentOcurrenceId}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  setLoading,
  printTicket,
  printInvoice,
  cloningOrder,
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { name: "OrderItem" })
)(OrderItem);
