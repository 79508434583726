const styles = theme => ({
  paper: {
    padding: 20
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  labelCheckBox: {
    paddingRight: 20,
    color: "rgba(0, 0, 0, 0.54)"
  },
});

export default styles;
