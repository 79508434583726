import Service from "./Service";

class EndYearReportService extends Service {
    async list(params = {}) {
        try {
            return await this.http.get("/end_year_report/", { params });
        } catch (error) {
            return error.response;
        }
    }
}

export default EndYearReportService;
