import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./containers/Home";
import PatientList from "./containers/Patients/PatientList";
import PatientRentalsList from "./containers/Patients/PatientRentalsList";
import PatientCreate from "./containers/Patients/PatientCreate";
import PatientEdit from "./containers/Patients/PatientEdit";
import Login from "./containers/Auth/Login";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword";
import Error404 from "./containers/Errors/Error404";

import UserList from "./containers/Users/UserList";
import UserEdit from "./containers/Users/UserEdit";
import UserCreate from "./containers/Users/UserCreate";

import ProductList from "./containers/Products/ProductList";
import ProductInventory from "./containers/Products/ProductInventory";
import ProductCreate from "./containers/Products/ProductCreate";
import ProductEdit from "./containers/Products/ProductEdit";

import OrderList from "./containers/Orders/OrderList";
import OrderCreate from "./containers/Orders/OrderCreate";
import OrderEdit from "./containers/Orders/OrderEdit";

import ExpirationList from "./containers/Expirations/ExpirationList";
import CallCustomersList from "./containers/CallCustomers/CallCustomersList";
import CheckEligibilityList from "./containers/CheckEligibility/CheckEligibilityList";
import ShippingStatusList from "./containers/ShippingStatus/ShippingStatusList";
import RemittanceList from "./containers/Remittance/RemittanceList";
import RemittanceRevertList from "./containers/RemmitanceRevert/RemmitanceList"
import EndYearReport from "./containers/EndYearReport"

import PrivateRoute from "./components/PrivateRoute";

import { refreshToken } from "./redux/actions/authActions";
import VendorList from "./containers/Vendors/VendorList";
import VendorCreate from "./containers/Vendors/VendorCreate";
import VendorEdit from "./containers/Vendors/VendorEdit";
import InvoicesList from "./containers/Invoices/InvoicesList";
import InvoiceEdit from "./containers/Invoices/InvoiceEdit";
import CallCustomersWElegibilityList from "./containers/CallCustomerWElegibility/CallCustomersWElegibilityList";
import OrderEditComponent from "./containers/CallCustomers/OrderEditComponent";
import LargeBatchList from "./containers/LargeBatch";
// import ExpiredDeliveries from "./containers/ExpiredDeliveries";

const REFRESH_INTERVAL = 600000; // 10 minutes

class App extends Component {
  interval = null;

  componentDidMount = () => {
    this.interval = setInterval(async () => {
      if (this.props.auth.token !== null) {
        await this.props.refreshToken();
      }
    }, REFRESH_INTERVAL);

    window.onbeforeunload = () => {
      if (this.props.handleForm) return "Are you sure you want to leave?";
    };
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Redirect exact path="/" to="/login" />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />

          <PrivateRoute exact path="/home" component={Home} />

          <PrivateRoute exact path="/users" component={UserList} />
          <PrivateRoute exact path="/users/create" component={UserCreate} />
          <PrivateRoute exact path="/users/:id" component={UserEdit} />

          <PrivateRoute exact path="/vendors" component={VendorList} />
          <PrivateRoute exact path="/vendors/create" component={VendorCreate} />
          <PrivateRoute exact path="/vendor/:id" component={VendorEdit} />

          <PrivateRoute exact path="/patients" component={PatientList} />
          <PrivateRoute exact path="/patients_rentals" component={PatientRentalsList} />
          <PrivateRoute
            exact
            path="/patients/create"
            component={PatientCreate}
          />
          <PrivateRoute exact path="/patients/:id" component={PatientEdit} />

          <PrivateRoute exact path="/products" component={ProductList} />
          <PrivateRoute exact path="/products_inventory" component={ProductInventory} />
          <PrivateRoute
            exact
            path="/products/create"
            component={ProductCreate}
          />
          <PrivateRoute exact path="/products/:id" component={ProductEdit} />

          <PrivateRoute exact path="/orders" component={OrderList} />
          <PrivateRoute exact path="/orders/create" component={OrderCreate} />
          <PrivateRoute exact path="/orders/:id/edit" component={OrderEdit} />

          <PrivateRoute exact path="/expirations/" component={ExpirationList} />
          <PrivateRoute
            exact
            path="/call-customers/"
            component={CallCustomersList}
          />
          <PrivateRoute
            exact
            path="/call-customers/:id"
            component={OrderEditComponent}
          />
          <PrivateRoute
            exact
            path="/check-eligibility/"
            component={CheckEligibilityList}
          />
          <PrivateRoute
            exact
            path="/call-customer-w-eligibility/"
            component={CallCustomersWElegibilityList}
          />
          <PrivateRoute
            exact
            path="/call-customer-w-eligibility/:id"
            component={OrderEditComponent}
          />
          <PrivateRoute
            exact
            path="/shipping-status/"
            component={ShippingStatusList}
          />
          <PrivateRoute exact path="/remittance/" component={RemittanceList} />
          <PrivateRoute exact path="/remittance_revert/" component={RemittanceRevertList} />
          <PrivateRoute exact path="/end_year_report/" component={EndYearReport} />
          <PrivateRoute exact path="/invoices/" component={InvoicesList} />
          <PrivateRoute exact path="/invoices/:id" component={InvoiceEdit} />
          <PrivateRoute
              exact
              path="/batch_shipping_status/"
              component={LargeBatchList}
          />
          {/* <PrivateRoute
            exact
            path="/expired-deliveries/"
            component={ExpiredDeliveries}
          /> */}

          {/* <Route exact path="/404" component={Error404} /> */}
          <Route component={Login} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    handleForm: state.global.handleForm,
  };
};

const mapDispatchToProps = { refreshToken };

export default connect(mapStateToProps, mapDispatchToProps)(App);
