import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import moment from "moment";
import {List, ListItem, Divider} from "@material-ui/core";
import formatNumber, {
    formatNumberWithOutComma,
} from "../../common/Helpers/FormatNumberHelper";
import {
    withStyles,
    Chip,
    Grid,
    TableHead,
    TableContainer,
    TableRow,
    TableBody,
    TableCell,
    Table,
    IconButton,
    Switch,
    InputAdornment,
    TextField, MenuItem,
} from "@material-ui/core";
import { Layout, Panel } from "../../components";
import {
    setLoading,
    fetchPatientStates,
} from "../../redux/actions/globalActions";


import {
    getEndYearReport,
    setFilterEndYearReport
} from "../../redux/actions/endYearReportActions"
import PdfIcon from "@material-ui/icons/CloudDownload";

import { KeyboardDatePicker } from "@material-ui/pickers";
import styles from "./styles";
import { getOrder } from "../../redux/actions/orderActions";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Search, Today } from "@material-ui/icons";
import _debounce from "lodash/debounce";
import Link from "@material-ui/core/Link";
import TableResponsive from "../../components/TableResponsive";

const style = {
    width: '100%',
    maxWidth: 360,
    padding: 10,
    bgcolor: 'background.paper',
};

class EndYearReport extends React.Component {
    state = {
        showResult: false,
        totalSales: 0,
        taxableSales: 0,
        salesTaxCollected: 0,
        remitted: 0,
        accountReceivable: 0,
        writeOff: 0,
        totalSalesShipped: 0,
        taxableSalesShipped: 0,
        salesTaxCollectedShipped: 0,
        remittedShipped: 0,
        accountReceivableShipped: 0,
        writeOffShipped: 0,
    };


    fetchEndYearReport = async () => {
        this.setState({'showResult': false})
        this.props.setLoading(true);
        const response = await this.props.getEndYearReport();
        if (response.status !== 200) {
            this.props.enqueueSnackbar("The request could not processed!", {
                variant: "success",
            });
        }
        const data = response.data
        this.setState(
            {
                'totalSales': data.total_sales,
                'taxableSales': data.taxable_sales,
                'salesTaxCollected': data.sales_tax_collected,
                'remitted': data.remitted,
                'accountReceivable': data.accounts_receivable,
                'totalSalesShipped': data.total_sales_shipped,
                'writeOff': data.write_off,
                'taxableSalesShipped': data.taxable_sales_shipped,
                'salesTaxCollectedShipped': data.sales_tax_collected_shipped,
                'remittedShipped': data.remmited_shipped,
                'accountReceivableShipped': data.accounts_receivable_shipped,
                'writeOffShipped': data.write_off_shipped,
            }
        )
        this.props.setLoading(false)

        this.setState({'showResult': true})
    };

    fetchEndYearReportDelayed = _debounce((e) => {
        return this.fetchEndYearReport();
    }, 1500);


    handleFilters = async (name, value) => {
        const { filters } = this.props;
        filters[name] = value;
        this.props.setFilterEndYearReport({ ...filters});
        if (
            filters.start_date &&
            moment(filters.start_date).isValid() &&
            filters.end_date &&
            moment(filters.end_date).isValid()
        ) {
            if (moment(filters.start_date).isAfter(moment(filters.end_date))) {
                return;
            }
            this.props.setFilterEndYearReport({
                date_range: "",
            });
            try {
                this.props.setFilterEndYearReport({
                    start_date: moment(filters.start_date).format("YYYY-MM-DD"),
                    end_date: moment(filters.end_date).format("YYYY-MM-DD"),
                });
                this.fetchEndYearReport();

            } catch (error) {
                console.error(error);
            }
        }
    };



    render() {
        const { totalSales,
            accountReceivable,
            remitted,
            salesTaxCollected,
            taxableSales,
            totalSalesShipped,
            writeOff,
            accountReceivableShipped,
            remittedShipped,
            salesTaxCollectedShipped,
            taxableSalesShipped,
            writeOffShipped,
            showResult } = this.state;
        let {
            loading,
            filters,
        } = this.props;

        return (
            <Layout title="End of Year Report">
                <Panel>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={3}>
                            <KeyboardDatePicker
                                name="start_date"
                                label="Start Date"
                                value={filters.start_date || null}
                                invalidLabel={""}
                                margin="normal"
                                format={"MM/DD/YYYY"}
                                onChange={(date) => {
                                    this.handleFilters("start_date", date);
                                }}
                                disabled={loading}
                                fullWidth
                                animateYearScrolling
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder="MM/DD/YYYY"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="calendar">
                                                <Today />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <KeyboardDatePicker
                                name="end_date"
                                label="End Date"
                                value={filters.end_date || null}
                                invalidLabel={""}
                                margin="normal"
                                format={"MM/DD/YYYY"}
                                onChange={(date) => {
                                    this.handleFilters("end_date", date);
                                }}
                                disabled={loading}
                                fullWidth
                                animateYearScrolling
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder="MM/DD/YYYY"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="calendar">
                                                <Today />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12} alignItems="flex-end" container>
                            <TextField
                                label="or Date Range"
                                margin="normal"
                                value={filters.date_range}
                                onChange={(value) => {
                                    const newValue = (value || {}).target.value;
                                    this.props.setFilterEndYearReport({
                                        date_range: newValue ? newValue : "",
                                        start_date: "",
                                        end_date: "",
                                    });

                                    this.fetchEndYearReport();
                                }}
                                fullWidth
                                select
                            >
                                <MenuItem value="CY">Current Year</MenuItem>
                                <MenuItem value="LY">Last Year</MenuItem>
                                <MenuItem value="CQ">Current Quarter</MenuItem>
                                <MenuItem value="LQ">Last Quarter</MenuItem>
                            </TextField>
                        </Grid>

                    </Grid>
                    { showResult &&
                        <div style={{display: "flex"}}>
                            <div style={{maxWidth: 360, fontSize: 20}}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Not accounting for shipping</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Sales:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(totalSales) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Taxable Sales:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(taxableSales) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Sales Tax Collected:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(salesTaxCollected) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Remitted:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(remitted) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Accounts Receivable</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(accountReceivable) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Write Off</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(writeOff) }</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>

                            <div style={{maxWidth: 360, fontSize: 20}}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Including shipping </TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Sales:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(totalSalesShipped) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Taxable Sales:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(taxableSalesShipped) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Sales Tax Collected:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(salesTaxCollectedShipped) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Remitted:</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(remittedShipped) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Accounts Receivable</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(accountReceivableShipped) }</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Write Off</TableCell>
                                                <TableCell style={{textAlign: "right"}}>$ { formatNumber(writeOffShipped) }</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        </div>

                    }

                </Panel>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.endYearReport.filters,
    };
};

const mapDispatchToProps = {
    setLoading,
    getEndYearReport,
    setFilterEndYearReport,
};

export default compose(
    withRouter,
    withSnackbar,
    withStyles(styles, { name: "ShippingStatusList" }),
    connect(mapStateToProps, mapDispatchToProps)
)(EndYearReport);
