import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { getUsers, deleteUser } from "../../../redux/actions/userActions";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Input,
  IconButton,
  Link,
  Typography,
  withStyles,
  TableContainer,
} from "@material-ui/core";
import { Edit, Delete } from "@material-ui/icons";
import Layout from "../../../components/Layout/index";
import SimpleBreadcrumbs from "../../../components/SimpleBreadcrumbs";
import Panel from "../../../components/Panel";
import styles from "./styles";
import { DialogDelete, TextEmpty } from "../../../components";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Users", to: null },
];

class UserList extends React.Component {
  state = {
    search: "",
    open: false,
    userId: "",
  };

  componentDidMount = async () => {
    try {
      const response = await this.props.getUsers();
      if (response.status !== 200) {
        this.props.history.push("/404");
      }
    } catch (error) {
      this.props.history.push("/404");
    }
  };

  handleSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  filter = (users, keyword) => {
    if (keyword === "") return users;
    return users.filter((user) => {
      const obj = { ...user, ...user.profile };
      return (
        `${obj.first_name} ${obj.last_name}`
          .toLowerCase()
          .indexOf(keyword.toLowerCase()) > -1 ||
        obj.middle_name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        obj.email.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        obj.username.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        (obj.role &&
          obj.role.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1)
      );
    });
  };

  handleDelete = async () => {
    this.setState({ open: false });
    const { userId } = this.state;
    if (this.props.auth.id !== userId) {
      const response = await this.props.deleteUser(userId);
      if (response.status === 204) {
        this.props.enqueueSnackbar(
          "The user was updated as inactive successfully!",
          {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
        return;
      }
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
  };

  render() {
    const { classes, users, loading, auth } = this.props;
    const { search, open } = this.state;

    return (
      <Layout title="Users">
        <DialogDelete
          item="user"
          open={open}
          closeModal={() => this.setState({ open: false })}
          remove={this.handleDelete}
          isDelivery={false}
        />
        <div className={classes.root}>
          <SimpleBreadcrumbs routes={breadcrumbs} />

          <Panel>
            <div className={classes.header}>
              <Link component={RouterLink} color="inherit" to="/users/create">
                <Button variant="outlined" color="primary">
                  Create User
                </Button>
              </Link>

              <Input
                style={{ width: 300 }}
                defaultValue=""
                className={classes.search}
                autoFocus={true}
                inputProps={{
                  placeholder: "Search...",
                  onChange: this.handleSearch,
                }}
              />
            </div>

            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Username</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.filter(users, search).map(({ profile, ...user }) => (
                    <TableRow key={user.id}>
                      <TableCell component="th" scope="row" align="left">
                        {profile.first_name || " "} {profile.middle_name || " "}{" "}
                        {profile.last_name || ""}
                      </TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.username}</TableCell>
                      <TableCell align="left">
                        {user.is_active ? (
                          <Typography
                            color="primary"
                            className={classes.paragraph}
                          >
                            Active
                          </Typography>
                        ) : (
                          <Typography
                            color="secondary"
                            className={classes.paragraph}
                          >
                            Inactive
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {user.role ? user.role.label : "-"}
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ display: "flex" }}>
                          <Link component={RouterLink} to={`/users/${user.id}`}>
                            <IconButton
                              aria-label="Edit"
                              color="primary"
                              disabled={loading}
                            >
                              <Edit />
                            </IconButton>
                          </Link>
                          <IconButton
                            aria-label="Edit"
                            color="secondary"
                            disabled={auth.id === user.id || loading}
                            onClick={() =>
                              this.setState({ open: true, userId: user.id })
                            }
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TextEmpty itemName={"USERS"} empty={users.length === 0} />
          </Panel>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    loading: state.global.loading,
    users: state.users.list,
  };
};

const mapDispatchToProps = { getUsers, deleteUser };

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "UserList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(UserList);
