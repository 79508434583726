import React from "react";
import moment from "moment";
import TabContainer from "../../../components/TabContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
  TextField, FormControlLabel, Checkbox, Typography,
} from "@material-ui/core";

import styles from "./styles";
import { Edit, Delete, Today, CheckCircle, Cancel, FileCopy } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Form } from "formik";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import {
  setLoading,
  setCurrentForm,
  setHandleForm,
} from "./../../../redux/actions/globalActions";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  updateService,
  deleteService,
  cloneService,
  getProductOrderPrice,
} from "./../../../redux/actions/orderActions";
import { getOrder } from "./../../../redux/actions/orderActions";
import { datePickerFormatToParseDate } from "../../../common/Helpers/DateHelper";
import { ORDER_EDIT_SERVICES, ORDER_CLONE_SERVICES } from "../../../common/Constants/FormConstants";
import { DialogDelete } from "../../../components";
import * as Yup from "yup";
import formatNumber, {formatNumberWithOutComma} from "../../../common/Helpers/FormatNumberHelper";

const COLUMNS = {
  DATE: "date",
  PRODUCT: "product",
  INTERNAL_CODE: "internal_code",
  QUANTITY: "amount_remmited",
  SALE_PRICE: "sale_price",
};
const ORDER_TYPE = {
  ASC: "asc",
  DESC: "desc",
};
class ServicesTab extends React.Component {
  state = {
    orderBy: {
      column: COLUMNS.DATE,
      type: ORDER_TYPE.ASC,
    },
    open: false,
    serviceId: 0,
    showModal: false,
    showCloneModal: false,
    currentService: {},
    currentProductId: null,
    services: [],
    selectFocus: false,
    ocurrence_id: null,
  };

  componentDidMount() {
    this.setState({ services: this.orderData(this.props.services) });
    this.setState({ ocurrence_id: this.props.ocurrence_id })
    // const ocurrence_id = this.props.ocurrence_id;
    // this.setState({ services: this.orderData(this.props.services.filter(
    //       (_service) =>
    //           _service.ocurrence_id !== ocurrence_id
    //       )
    //   )}
    // );
  }

  handleOrderBy = (column) => () => {
    this.setState((state, props) => ({
      orderBy: {
        column,
        type:
          state.orderBy.type === ORDER_TYPE.ASC
            ? ORDER_TYPE.DESC
            : ORDER_TYPE.ASC,
      },
    }));
  };
  orderData = (data) => {
    const { column, type } = this.state.orderBy;

    data = data.sort((firstItem, secondItem) => {
      switch (column) {
        case COLUMNS.DATE:
          return firstItem.date.localeCompare(secondItem.date);
        case COLUMNS.PRODUCT:
          return firstItem.product.name.localeCompare(secondItem.product.name);
        case COLUMNS.QUANTITY:
          return firstItem.amount_remmited - secondItem.amount_remmited;
        case COLUMNS.SALE_PRICE:
          return firstItem.sale_price - secondItem.sale_price;

        default:
          return 0;
      }
    });

    if (type === ORDER_TYPE.DESC) {
      data = data.reverse();
    }
    return data;
  };
  openEditService = (service) => () => {
    this.props.setCurrentForm(ORDER_EDIT_SERVICES);
    this.setState({
      currentService: service,
      currentProductId: this.props.products
        .map((p) => {
          return { label: p.name, value: p.id };
        })
        .find(({ value }) => value === service.product.id),
      showModal: true,
    });
  };

  openCloneService = async (service) => {
    try{
      const { data: product } = await this.props.getProductOrderPrice(
          service.order_product_id
      );

      this.props.setCurrentForm(ORDER_CLONE_SERVICES);
      console.log("service_to_cloen", service)
      this.setState({
        currentService: service,
        date: null,
        currentProductId: this.props.products
            .map((p) => {
              return { label: p.name, value: p.id };
            })
            .find(({ value }) => value === service.product.id),
        order_product_id: service.order_product_id,
        showCloneModal: true,
        tax_amount: service.tax_amount,
        sale_price: product.variant_price
      });
    } catch(error){
      console.log(error)
    }
  };


  handleDeleteService = async () => {
    this.setState({ open: false });
    this.props.setLoading(true);
    const { serviceId } = this.state;
    try {
      const response = await this.props.deleteService(serviceId);
      if (response.status === 204) {
        this.props.enqueueSnackbar("Service deleted successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });

        this.setState((prevState) => {
          return {
            services: this.orderData(
              prevState.services.filter((s) => s.id !== serviceId)
            ),
          };
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar("Service delete failed.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    this.props.setLoading(false);
  };

  cloneService = async (values, formikActions) => {
    this.setState({ showCloneModal: false });
    const { setSubmitting } = formikActions;
    this.props.setLoading(true);
    const body = {
      product_id: values.product_id.value,
      date:
          typeof values.date === "string"
              ? values.date
              : datePickerFormatToParseDate(values.date),
      amount_remmited: values.amount_remmited,
      adjusted_sale_price: values.adjusted_sale_price,
      percentage: values.percentage,
      added_percentage: values.added_percentage,
      order_product_id: this.state.order_product_id,
      tax_amount: values.tax_amount,
      sale_price: values.sale_price,
      locked_price: values.locked_price
    };
    try {
      const response = await this.props.cloneService(body);
      if (response.status === 200) {
        this.props.enqueueSnackbar("Service cloned successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        const response = await this.props.getOrder(this.props.order.id);
        if (response.status === 200) {
          if(!!this.state.ocurrence_id){
            this.setState({ services: this.orderData(this.props.services.filter(
                  (_service) =>
                      _service.ocurrence_id !== this.state.ocurrence_id
                  )
              )}
            );
          }
          else{
            this.setState({ services: this.orderData(response.data.services) });
          }
        }
      }
    } catch (error) {
      this.props.enqueueSnackbar("Service clone failed.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    this.props.setLoading(false);

    setSubmitting(false);
  };


  updateService = async (values, formikActions) => {
    this.setState({ showModal: false });
    const { setSubmitting } = formikActions;
    this.props.setLoading(true);
    const body = {
      product_id: values.product_id.value,
      date:
        typeof values.date === "string"
          ? values.date
          : datePickerFormatToParseDate(values.date),
      amount_remmited: values.amount_remmited,
      sale_price: values.sale_price,
      locked_price: values.locked_price
    };
    try {
      const response = await this.props.updateService(values.id, body);
      if (response.status === 200) {
        this.props.enqueueSnackbar("Service updated successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        const response = await this.props.getOrder(this.props.order.id);
        if (response.status === 200) {
          // this.setState({ services: this.orderData(response.data.services) });
          if(!!this.state.ocurrence_id){
            this.setState({ services: this.orderData(this.props.services.filter(
                  (_service) =>
                      _service.ocurrence_id !== this.state.ocurrence_id
                  )
              )}
            );
          }
          else{
            this.setState({ services: this.orderData(response.data.services) });
          }
        }
      }
    } catch (error) {
      this.props.enqueueSnackbar("Service updated failed.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      console.log(error);
    }
    this.props.setLoading(false);
    setSubmitting(false);
  };

  closeModal = () => {
    this.props.setCurrentForm(null);
    this.props.setHandleForm(false);
    this.setState({ showModal: false , showCloneModal: false});
  };

  render() {
    let { classes, loading, products } = this.props;
    const { column, type } = this.state.orderBy;
    const {
      showModal,
      showCloneModal,
      currentService,
      currentProductId,
      open,
      services,
      selectFocus,
    } = this.state;
    return (
      <TabContainer>
        <DialogDelete
          item="service"
          open={open}
          closeModal={() => this.setState({ open: false })}
          remove={this.handleDeleteService}
          isDelivery={false}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={column === COLUMNS.DATE}
                  direction={type}
                  onClick={this.handleOrderBy(COLUMNS.DATE)}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                    active={column === COLUMNS.INTERNAL_CODE}
                    direction={type}
                    onClick={this.handleOrderBy(COLUMNS.INTERNAL_CODE)}
                >
                  Reference Number
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={column === COLUMNS.PRODUCT}
                  direction={type}
                  onClick={this.handleOrderBy(COLUMNS.PRODUCT)}
                >
                  Product
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={column === COLUMNS.QUANTITY}
                  direction={type}
                  onClick={this.handleOrderBy(COLUMNS.QUANTITY)}
                >
                  Quantity
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={column === COLUMNS.SALE_PRICE}
                  direction={type}
                  onClick={this.handleOrderBy(COLUMNS.SALE_PRICE)}
                >
                  Sale price
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Tax
              </TableCell>
              <TableCell>Shipped</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.orderData(services).map((service) => (
                <TableRow key={service.id}>
                  <TableCell component="th" scope="row">
                    {moment(service.date).format("dddd, MMMM Do YYYY")}
                  </TableCell>
                  <TableCell>{service.product.internal_code}</TableCell>
                  <TableCell>{service.order_product.order_product_name}</TableCell>
                  <TableCell>{service.amount_remmited || "0"}</TableCell>
                  <TableCell>{ service.added_percentage ? service.adjusted_sale_price : (service.sale_price || "0")}</TableCell>
                  <TableCell>{ service.tax_amount ? service.tax_amount : "" }</TableCell>
                  <TableCell>
                    {service.shipped ? (
                      <CheckCircle style={{ color: "#06bb06" }} />
                    ) : (
                      <Cancel style={{ color: "#f50057" }} />
                    )}
                  </TableCell>
                  <TableCell>
                    <Grid container justify="center" wrap="nowrap">
                      <IconButton
                          disabled={loading}
                          onClick={() => this.openCloneService(service)}
                      >
                        <FileCopy color="primary" />
                      </IconButton>
                      <IconButton
                        disabled={loading}
                        onClick={this.openEditService(service)}
                      >
                        <Edit color="primary" />
                      </IconButton>
                      {service.shipped ? (null
                      ) : (
                        <IconButton
                        disabled={loading}
                        onClick={() =>
                          this.setState({ open: true, serviceId: service.id })
                        }
                      >
                        <Delete color="secondary" />
                      </IconButton>
                      )}

                    </Grid>
                  </TableCell>
                </TableRow>
            ))}
            {!!this.state.ocurrence_id ? (
                <TableRow style={{ height: 48 }}>
                  <TableCell colSpan={2} />
                  <TableCell colSpan={1}>
                    <Typography variant="subtitle2">Total:</Typography>
                  </TableCell>
                  <TableCell>
                    {formatNumber(
                        +services
                            .map(
                                (_service) =>
                                    +formatNumberWithOutComma(
                                        (_service.added_percentage ? +_service.adjusted_sale_price : +_service.sale_price || 0) *
                                        (+_service.amount_remmited || 0)
                                    )
                            )
                            .reduce((total, current) => +total + +current, 0)
                    )}
                  </TableCell>
                </TableRow>
            ): null}
          </TableBody>
        </Table>
        <Formik
            onSubmit={this.cloneService}
            initialValues={{
              date: null,
              order_product_id: currentService.order_product_id,
              amount_remmited: currentService.amount_remmited,
              adjusted_sale_price: currentService.adjusted_sale_price,
              percentage: currentService.percentage,
              added_percentage: currentService.added_percentage,
              product_id: currentProductId,
              shipped: Boolean(currentService.shipped),
              sale_price:  currentService.sale_price,
              tax_amount: currentService.tax_amount,
              locked_price: false,
            }}
            validationSchema={Yup.object().shape({
              date: Yup.date().required("Date is required"),
              sale_price: Yup.mixed().required("Sale price is required"),
              amount_remmited: Yup.mixed().required("Quantity is required"),
            })}
            enableReinitialize
        >
          {(props) => {
            const {
              values,
              handleSubmit,
              setFieldValue,
              handleBlur,
              isSubmitting,
              isValid,
              dirty,
            } = props;

            return (
                <Dialog
                    open={showCloneModal}
                    onClose={this.closeModal}
                    aria-labelledby="form-dialog-title"
                    maxWidth="sm"
                    fullWidth
                >
                  <Form>
                    <DialogTitle id="form-dialog-title">Clone service</DialogTitle>
                    <DialogContent
                        classes={{
                          root: selectFocus
                              ? classes.dialogContentMax
                              : classes.dialogContentMin,
                        }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Select
                              className={classes.select}
                              classNamePrefix="react-select"
                              name="product_id"
                              isDisabled={true}
                              isSearchable={false}
                              placeholder="Product *"
                              value={values.product_id}
                              blurInputOnSelect={true}
                              onFocus={() => this.setState({ selectFocus: true })}
                              onBlur={() => this.setState({ selectFocus: false })}
                              // onChange={(value) =>
                              //     setFieldValue("product_id", value)
                              // }
                              options={products.map((p) => {
                                return { label: p.name, value: p.id };
                              })}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <KeyboardDatePicker
                              name="date"
                              value={values.date || null}
                              label="Service Date"
                              invalidLabel={""}
                              margin="normal"
                              format={"MM/DD/YYYY"}
                              fullWidth
                              animateYearScrolling
                              onChange={(date) => {
                                setFieldValue("date", date);
                              }}
                              onBlur={handleBlur}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton aria-label="calendar">
                                        <Today />
                                      </IconButton>
                                    </InputAdornment>
                                ),
                              }}
                              placeholder="MM/DD/YYYY"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                              value={values.amount_remmited}
                              onChange={(e) => {
                                setFieldValue("amount_remmited", e.target.value);
                              }}
                              onBlur={handleBlur}
                              label="Quantity"
                              fullWidth
                              type="number"
                              margin="normal"
                              disabled={loading}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                              value={values.sale_price}
                              onChange={(e) => {
                                setFieldValue("sale_price", e.target.value);
                              }}
                              onBlur={handleBlur}
                              label="Sale price"
                              fullWidth
                              type="number"
                              margin="normal"
                              disabled={loading}
                          />
                        </Grid>
                        { false && (
                            <TextField
                                value={values.tax_amount}
                                onChange={(e) => {
                                  setFieldValue("tax_amount", e.target.value);
                                }}
                                onBlur={handleBlur}
                                label="Adjusted Sale Price"
                                fullWidth
                                type="number"
                                margin="normal"
                                disabled
                            />
                        )}
                        { values.added_percentage ? (
                            <Grid item xs={12} sm={3}>
                              <TextField
                                  value={values.adjusted_sale_price}
                                  onChange={(e) => {
                                    setFieldValue("adjusted_sale_price", e.target.value);
                                  }}
                                  onBlur={handleBlur}
                                  label="Adjusted Sale Price"
                                  fullWidth
                                  type="number"
                                  margin="normal"
                                  disabled
                              />
                            </Grid>
                        ): null}

                      </Grid>
                      <FormControlLabel
                          control={
                            <Checkbox
                                name="locked_price"
                                checked={values.locked}
                                onChange={(e) => {
                                  setFieldValue("locked_price", e.target.checked);
                                }}
                                onBlur={handleBlur}
                                color="primary"
                                disabled={loading}
                            />
                          }
                          label="Locked Price"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={this.closeModal}
                          color="primary"
                          disabled={loading}
                      >
                        Cancel
                      </Button>
                      <Button
                          onClick={handleSubmit}
                          type="submit"
                          color="primary"
                          disabled={
                            loading ||
                            !isValid ||
                            (isValid && !dirty) ||
                            (!isValid && dirty)
                          }
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Form>
                </Dialog>

            );
          }}
        </Formik>
        <Formik
          onSubmit={this.updateService}
          initialValues={{
            id: currentService.id,
            date: currentService.date,
            order_product_id: currentService.order_product_id,
            amount_remmited: currentService.amount_remmited,
            product_id: currentProductId,
            shipped: Boolean(currentService.shipped),
            sale_price: currentService.sale_price || 0,
            added_percentage: currentService.added_percentage,
            adjusted_sale_price: currentService.adjusted_sale_price || 0,
            locked_price: currentService.locked_price || 0,
          }}
          validationSchema={Yup.object().shape({
            date: Yup.date().required("Date is required"),
            sale_price: Yup.mixed().required("Sale price is required"),
            amount_remmited: Yup.mixed().required("Quantity is required"),
          })}
          enableReinitialize
        >
          {(props) => {
            const {
              values,
              handleSubmit,
              setFieldValue,
              handleBlur,
              isSubmitting,
              isValid,
              dirty,
            } = props;

            return (
              <Dialog
                open={showModal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth
              >
                <Form>
                  <DialogTitle id="form-dialog-title">Edit service</DialogTitle>
                  <DialogContent
                    classes={{
                      root: selectFocus
                        ? classes.dialogContentMax
                        : classes.dialogContentMin,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Select
                          className={classes.select}
                          classNamePrefix="react-select"
                          name="product_id"
                          isDisabled={loading}
                          isSearchable={true}
                          placeholder="Product *"
                          value={values.product_id}
                          blurInputOnSelect={true}
                          onFocus={() => this.setState({ selectFocus: true })}
                          onBlur={() => this.setState({ selectFocus: false })}
                          onChange={(value) =>
                            setFieldValue("product_id", value)
                          }
                          options={products.map((p) => {
                            return { label: p.name, value: p.id };
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          name="date"
                          value={values.date || null}
                          label="Service Date"
                          invalidLabel={""}
                          margin="normal"
                          format={"MM/DD/YYYY"}
                          fullWidth
                          animateYearScrolling
                          onChange={(date) => {
                            setFieldValue("date", date);
                          }}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <Today />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="MM/DD/YYYY"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.amount_remmited}
                          onChange={(e) => {
                            setFieldValue("amount_remmited", e.target.value);
                          }}
                          onBlur={handleBlur}
                          label="Quantity"
                          fullWidth
                          type="number"
                          margin="normal"
                          disabled={loading || values.shipped}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          value={values.sale_price}
                          onChange={(e) => {
                            setFieldValue("sale_price", e.target.value);
                          }}
                          onBlur={handleBlur}
                          label="Sale price"
                          fullWidth
                          type="number"
                          margin="normal"
                          disabled={loading || values.shipped}
                        />
                      </Grid>
                      { values.added_percentage ? (
                          <Grid item xs={12} sm={3}>
                            <TextField
                                value={values.adjusted_sale_price}
                                onChange={(e) => {
                                  setFieldValue("adjusted_sale_price", e.target.value);
                                }}
                                onBlur={handleBlur}
                                label="Adjusted Sale Price"
                                fullWidth
                                type="number"
                                margin="normal"
                                disabled
                            />
                          </Grid>
                      ): null}
                    </Grid>
                    <FormControlLabel
                        control={
                          <Checkbox
                              name="locked_price"
                              checked={values.locked_price || false}
                              onChange={(e) => {
                                setFieldValue("locked_price", e.target.checked);
                              }}
                              onBlur={handleBlur}
                              color="primary"
                              disabled={loading || values.shipped}
                          />
                        }
                        label="Locked Price"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeModal}
                      color="primary"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      color="primary"
                      disabled={
                        loading ||
                        isSubmitting ||
                        (isValid && !dirty) ||
                        (!isValid && dirty)
                      }
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Form>
              </Dialog>

            );
          }}
        </Formik>
      </TabContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    order: state.orders.order,
    products: state.products.listRaw,
    currentForm: state.global.currentForm,
    handleForm: state.global.handleForm,
  };
};

const mapDispatchToProps = {
  setLoading,
  setHandleForm,
  setCurrentForm,
  getOrder,
  updateService,
  cloneService,
  getProductOrderPrice,
  deleteService,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ServicesTab" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ServicesTab);
