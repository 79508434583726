import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useEffect } from "react";
import { getOrder } from "../../../redux/actions/orderActions";
import { setLoading } from "../../../redux/actions/globalActions";
import _get from "lodash/get";
import { Prompt } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getInvoice,
  updateInvoice,
} from "../../../redux/actions/invoiceActions";

function NotesDialog({ open, onClose: _handleClose, ocurrence, ...props }) {
  const [notes, setNotes] = useState({
    office_notes: "",
    invoice_notes: "",
  });
  const [dirtyInvoiceNotes, setDirtyInvoiceNotes] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (open) {
      fetchNotes();
    }
  }, [open]);

  const fetchNotes = async () => {
    props.setLoading(true);
    try {
      const { data: { office_notes = "" } = {} } = await props.getOrder(
        _get(ocurrence, "order.id")
      );
      const { data: { notes = "" } = {} } = await props.getInvoice(
        ocurrence.invoice
      );
      setNotes((prevNotes) => ({
        ...prevNotes,
        office_notes: office_notes,
        invoice_notes: notes,
      }));
    } catch (error) {}

    props.setLoading(false);
  };

  const _handleInvoiceNoteChange = (e) => {
    // console.log(e.target.value);
    const newValue = e.target.value;
    setNotes((prev) => ({
      ...prev,
      invoice_notes: newValue,
    }));
    setDirtyInvoiceNotes(true);
  };

  const _handleRequestClose = () => {
    if (dirtyInvoiceNotes) {
      const response = window.confirm(
        "Are you sure you want to leave?, You will lose your changes"
      );
      if (response) {
        _handleClose();
      }
      return;
    }
    _handleClose();
  };

  const _handleSubmit = async () => {
    if (!ocurrence.invoice) {
      _handleClose();
      return;
    }
    props.setLoading(true);

    try {
      const response = await props.updateInvoice(
        _get(ocurrence, "invoice", null),
        {
          notes: notes.invoice_notes,
        }
      );
      if (response.status === 200) {
        enqueueSnackbar("The invoice notes was updated succesfully", {
          variant: "success",
        });
        _handleClose();
      } else {
        enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("The request could not be processed!", {
        variant: "error",
      });
    }

    props.setLoading(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={ocurrence.invoice ? "md" : "sm"}
      onClose={_handleRequestClose}
      aria-labelledby="alert-notes-dialog-title"
      aria-describedby="alert-notes-dialog-description"
    >
      <DialogTitle id="alert-notes-dialog-title">Notes</DialogTitle>
      <Prompt
        when={dirtyInvoiceNotes}
        message="Are you sure you want to leave?, You will lose your changes"
      />
      <DialogContent>
        <Grid container spacing={2}>
          {ocurrence.invoice ? (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="office_notes"
                  margin="normal"
                  value={notes.office_notes}
                  disabled
                  label="Office Notes"
                  fullWidth
                  multiline
                  rows={15}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="patient_notes"
                  margin="normal"
                  disabled={props.loading}
                  value={notes.invoice_notes}
                  onChange={_handleInvoiceNoteChange}
                  label="Invoice Notes"
                  fullWidth
                  multiline
                  rows={15}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12} sm={12}>
              <TextField
                name="office_notes"
                margin="normal"
                value={notes.office_notes}
                disabled
                label="Office Notes"
                fullWidth
                multiline
                rows={15}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {ocurrence.invoice ? (
          <>
            <Button
              onClick={_handleClose}
              color="primary"
              disabled={props.loading}
            >
              Cancel
            </Button>
            <Button
              onClick={_handleSubmit}
              color="primary"
              disabled={props.loading}
            >
              Save
            </Button>
          </>
        ) : (
          <Button
            onClick={_handleSubmit}
            color="primary"
            disabled={props.loading}
          >
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

NotesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ocurrence: PropTypes.shape({}),
};

NotesDialog.defaultProps = {
  ocurrence: {},
};

const mapStateToProps = (state) => ({
  loading: state.global.loading,
});

const mapDispatchToProps = {
  getOrder,
  setLoading,
  getInvoice,
  updateInvoice,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesDialog);
