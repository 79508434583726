import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";

import { Autocomplete as MaterialAutocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

// import useStyles from "./styles";

function AutocompleteV2({
  options = [],
  value = null,
  onChange = () => {}, // (newValueMapped, newValueAlloptionObject) => void
  onBlur: _handleBlur = () => {},
  extractOptionValue = null, // dot notation - mapping from option object - if option is string or number take is same
  extractOptionLabel = null, // dot notation - mapping from option object - if option is string or number take is same
  disabled,
  onInputChange = () => {},
  loading = false,
  textFieldProps = {
    error: false,
    helperText: "",
    required: false,
    label: "Unlabeled field",
  },
  defaultValue = null,
}) {
  const getOptionValue = (option) =>
    extractOptionValue ? _get(option, extractOptionValue) : option;

  const getOptionLabel = (option) =>
    extractOptionLabel ? _get(option, extractOptionLabel) : option;

  const autocompleteValue =
    value && options.length
      ? options.find((_option) => getOptionValue(_option) === value)
      : null;

  return (
    <MaterialAutocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, _value) =>
        getOptionValue(option) === getOptionValue(_value)
      }
      defaultValue={defaultValue}
      value={autocompleteValue}
      onChange={(e, newValue, reason) => {
        onChange(
          getOptionValue(newValue),
          newValue /**all object include - use if is need */
        );
      }}
      onBlur={_handleBlur}
      fullWidth
      disabled={disabled}
      loading={loading}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField {...params} margin="normal" {...textFieldProps} />
      )}
    />
  );
}

AutocompleteV2.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.shape({}), PropTypes.string, PropTypes.number])
  ).isRequired,
  extractOptionValue: PropTypes.string,
  extractOptionLabel: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

AutocompleteV2.defaultProps = {
  //   disabled: false,
  //   extractOptionValue: false,
  //   extractOptionLabel : ,
};

export default AutocompleteV2;
