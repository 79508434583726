import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {Redirect, withRouter} from "react-router-dom";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import moment from "moment";
import {
  withStyles,
  Chip,
  Grid,
  IconButton,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  InputAdornment,
  FormControlLabel, TextField, MenuItem,
} from "@material-ui/core";
import { Layout, SimpleBreadcrumbs, Panel } from "../../../components";
import {
  setLoading,
  fetchPatientStates,
} from "../../../redux/actions/globalActions";
import {
  getShippingStatus,
  updateShipping,
  printShippingStatus,
  printTicket,
  rePrintTicket,
  setFiltersShippingStatus,
  getCountOcurrencesAvailablesForPrint, confirmShippingStatus, asyncConfirmShippingStatus,
} from "../../../redux/actions/shippingStatusActions";
import PdfIcon from "@material-ui/icons/CloudDownload";

import { KeyboardDatePicker } from "@material-ui/pickers";
import styles from "./styles";
import { getOrder } from "../../../redux/actions/orderActions";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Search, Today } from "@material-ui/icons";
import TableResponsive from "../../../components/TableResponsive";
import _debounce from "lodash/debounce";
import Autocomplete from "../../../components/Autocomplete";
import Link from "@material-ui/core/Link";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Shipping Status", to: null },
];

class ShippingStatusList extends React.Component {
  state = {
    showDialog: false,
    showDialogConfirm: false,
    // search: "",
  };

  config = {
    columns: [
      // {
      //   display_name: "Patient Name",
      //   name: "patient_name",
      //   type: "string",
      //   align: "inherit",
      //   sorted: true,
      //   sorted_name: "patient_name",
      // },
      {
        display_name: "Patient Name",
        name: "patient_name",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      this.props.history.push(
                          `/patients/${item.order.patient_id}`
                          // `/patients/${item.order.patient.id}`
                      );
                    }}
                    style={{ textAlign: "left" }}
                >
                  {this.getPatientName(item)}
                </Link>
            );
          },
        },
        align: "left",
        sorted: true,
        sorted_name: "patient_name",
      },
      {
        display_name: "Address State",
        name: "order.patient.state.name",
        type: "string",
        align: "right",
      },
      {
        display_name: "Order Number",
        name: "order.id",
        type: "string",
        align: "right",
        sorted: true,
        sorted_name: "order_number",
      },
      {
        display_name: "DOS",
        name: "date",
        type: "date",
        align: "right",
        sorted: true,
        sorted_name: "date",
      },
      {
        display_name: "Eligibility",
        name: "eligibility_toggle",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { classes } = this.props;
            return (
              <Chip
                className={classNames(
                  classes.chip,
                  item.eligibility_toggle ? classes.green : classes.red
                )}
              />
            );
          },
          // compare: (firstValue, secondValue) => firstValue - secondValue,
        },
        sorted: true,
        sorted_name: "eligibility_toggle",
        align: "center",
      },
      {
        display_name: "Customer Approved",
        name: "customer_approved",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { classes } = this.props;
            return (
              <Chip
                className={classNames(
                  classes.chip,
                  item.customer_approved ? classes.green : classes.red
                )}
              />
            );
          },
          compare: (firstValue, secondValue) => firstValue - secondValue,
        },
        sorted: true,
        sorted_name: "customer_approved",
        align: "center",
      },
      {
        display_name: "Ticket Created",
        name: "ticket_created",
        type: "date",
        align: "center",
      },
      {
        display_name: "Ticket Created By",
        name: "user_ticket.username",
        type: "string",
        align: "center",
      },
      {
        display_name: "Re Print Service",
        name: "print_service-client-side",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <IconButton
                aria-label="pdf-icon"
                color="primary"
                disabled={this.isNotAvailableForRePrint(item.order, item)}
                onClick={() => this.printTicket(item, "re-print")}
              >
                <PdfIcon />
              </IconButton>
            );
          },
          compare: () => 0,
        },
        align: "center",
      },
      {
        display_name: "Print Service",
        name: "print_service-client-side",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <IconButton
                aria-label="pdf-icon"
                color="primary"
                disabled={this.isNotAvailableForPrint(item.order, item)}
                onClick={() => this.printTicket(item, "print")}
              >
                <PdfIcon />
              </IconButton>
            );
          },
          compare: () => 0,
        },
        align: "center",
      },
      {
        display_name: "Shipped",
        name: "shipped",
        type: "custom",
        custom: {
          render: (item, column) => (
            <Switch
              checked={item.shipped}
              onChange={() => this.changeShipped(item)}
              disabled={this.props.loading || !item.ticket_created}
              color="primary"
            />
          ),
          compare: () => 0,
        },
        align: "center",
      },
      {
        display_name: "Shipped Date",
        name: "shipped_date",
        type: "date",
        align: "center",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchPatientStates();
      const { start_date, end_date } = this.props.filters;

      if (start_date && end_date) {
        await this.fetchShippingStatus();
      }
    } catch (error) {
      console.error(error);
    }
  };

  fetchShippingStatus = () => {
    return this.props.getShippingStatus();
  };

  fetchShippingStatusDelayed = _debounce((e) => {
    return this.fetchShippingStatus();
  }, 1500);
  handleFilters = async (name, value) => {
    const { filters } = this.props;
    filters[name] = value;
    this.props.setFiltersShippingStatus({ ...filters, page: 1 });
    if (
      filters.start_date &&
      moment(filters.start_date).isValid() &&
      filters.end_date &&
      moment(filters.end_date).isValid()
    ) {
      if (moment(filters.start_date).isAfter(moment(filters.end_date))) {
        return;
      }

      try {
        this.props.setFiltersShippingStatus({
          start_date: moment(filters.start_date).format("YYYY-MM-DD"),
          end_date: moment(filters.end_date).format("YYYY-MM-DD"),
          page: 1,
        });
        const response = await this.fetchShippingStatus();
        if (response.status !== 200) {
          this.props.enqueueSnackbar("The request could not processed!", {
            variant: "success",
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  getPatientName = (service) => {
    const { first_name = "", last_name = "" } = service.order.patient;

    return `${first_name} ${last_name}`;
  };

  openDialog = async () => {
    try {
      await this.props.getCountOcurrencesAvailablesForPrint(this.props.filters);
      this.setState({ showDialog: true });
    } catch (error) {}
  };


  openBatchDialog = async () => {
    try {
      await this.props.getCountOcurrencesAvailablesForPrint(this.props.filters);
      this.setState({ showBatchDialog: true });
    } catch (error) {}
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
  };

  closeBatchDialog = () => {
    this.setState({ showBatchDialog: false });
  };

  closeConfirmDialog = () => {
    this.setState({ showDialogConfirm: false });
  };

  // countServicesAvailableForPrint(shippingStatus) {
  //   const countAvailable = shippingStatus.filter(
  //     (ocurrence) =>
  //       !!!ocurrence.ticket_created &&
  //       ocurrence.eligibility_toggle &&
  //       ocurrence.customer_approved
  //   ).length;

  //   return countAvailable;
  // }

  isNotAvailableForPrint(order, ocurrence) {
    return !(
      !!!ocurrence.ticket_created &&
      ocurrence.eligibility_toggle &&
      ocurrence.customer_approved
    );
  }

  isNotAvailableForRePrint(order, ocurrence) {
    return !(ocurrence.ticket_created && !ocurrence.shipped_date);
  }

  transformData(data) {
    return data.map((ocurrence) => ({
      ...ocurrence,
      patient_name: this.getPatientName(ocurrence),
      customer_approved: ocurrence.customer_approved,
      eligibility_toggle: ocurrence.eligibility_toggle,
    }));
  }

  handleSearch = (event) => {
    console.log('foo')
    //to future move to tableResponsive component
    this.props.setFiltersShippingStatus({
      search: event.target.value,
      page: 1,
    });
    this.fetchShippingStatusDelayed.cancel();
    this.fetchShippingStatusDelayed();
  };

  changeShipped = async (ocurrence) => {
    try {
      const body = {
        shipped: !ocurrence.shipped,
        eligibility_toggle: ocurrence.eligibility_toggle,
      };
      const response = await this.props.updateShipping(ocurrence.id, body);
      await this.fetchShippingStatus();
      if (response.status === 200) {
        this.props.enqueueSnackbar("Shipped successfully ", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      } else {
        this.props.enqueueSnackbar("Shipped failed ", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });
      }
    } catch (error) {}
  };

  batchPrint = async (values, formikActions) => {
    const { setSubmitting } = formikActions;
    try {
      const params = {
        ...this.props.filters,
      };

      const response = await this.props.printShippingStatus(params);
      this.closeDialog();
      this.setState({ showDialogConfirm: true });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  batchConfirmPrint = async (values, formikActions) => {
    const { setSubmitting } = formikActions;
    try {
      const params = {
        ...this.props.filters,
      };

      const response = await this.props.confirmShippingStatus({batchPrintId: this.props.batchPrintId});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
          "download",
          `shipping-status-${params.start_date}-${params.end_date}.pdf`
      );
      document.body.appendChild(link);
      link.click();

      this.fetchShippingStatus();
      this.closeConfirmDialog();
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  asyncBatchConfirmPrint = async (values, formikActions) => {
    const { setSubmitting } = formikActions;
    try {
      const params = {
        ...this.props.filters,
      };

      const response = await this.props.asyncConfirmShippingStatus(params);
      this.closeBatchDialog();

      // return <Redirect to="/batch_shipping_status"/>;

    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
    debugger;
    return <Redirect to="/batch_shipping_status"/>;
  };


  secondsToTime(e){
    const h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');

    return h + ':' + m + ':' + s;
    //return `${h}:${m}:${s}`;
  }

  printTicket = async (ocurrence, type) => {
    try {
      let response;
      if (type === "print") {
        response = await this.props.printTicket(ocurrence.id);
      } else {
        response = await this.props.rePrintTicket(ocurrence.id);
      }
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `shipping-status-${ocurrence.id}.pdf`);
      document.body.appendChild(link);
      link.click();
      this.fetchShippingStatus();
    } catch (error) {
      this.props.enqueueSnackbar("Download error", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  getCurrentState = (states, state) => {
    if (state && states.length) {
      const currentState = states.find((_state) => _state.id === state);
      return {
        value: (currentState || {}).id,
        label: (currentState || {}).name,
      };
    }
    return null;
  };

  render() {
    const { showDialog, showDialogConfirm, showBatchDialog } = this.state;
    let {
      classes,
      shippingStatus,
      loading,
      filters,
      us_patient_states,
    } = this.props;

    return (
      <Layout title="Shipping Status">
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid container spacing={3}>
            <Grid item xs={9} sm={3}>
              <KeyboardDatePicker
                name="start_date"
                label="Start Date"
                value={filters.start_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => {
                  this.handleFilters("start_date", date);
                }}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="MM/DD/YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={9} sm={3}>
              <KeyboardDatePicker
                name="end_date"
                label="End Date"
                value={filters.end_date || null}
                invalidLabel={""}
                margin="normal"
                format={"MM/DD/YYYY"}
                onChange={(date) => {
                  this.handleFilters("end_date", date);
                }}
                disabled={loading}
                fullWidth
                animateYearScrolling
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="MM/DD/YYYY"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="calendar">
                        <Today />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid container item xs={12} sm={2} alignItems="flex-end">
              <Autocomplete
                onChange={(value) => {
                  const newValue = (value || {}).value;
                  this.props.setFiltersShippingStatus({
                    state: newValue ? newValue : "",
                    page: 1,
                  });

                  this.fetchShippingStatus();
                }}
                value={this.getCurrentState(us_patient_states, filters.state)}
                // value={filters.state_id}
                suggestions={us_patient_states.map((state) => ({
                  value: state.id,
                  label: state.name,
                }))}
                placeholder="State"
              />
            </Grid>
            <Grid item sm={1} xs={12} alignItems="flex-end" container>
              <TextField
                  label="Facility"
                  margin="normal"
                  value={filters.facility}
                  onChange={(value) => {
                    const newValue = (value || {}).target.value;
                    this.props.setFiltersShippingStatus({
                      facility: newValue ? newValue : "",
                      page: 1,
                    });

                    this.fetchShippingStatus();
                  }}
                  fullWidth
                  select
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={1}
              alignItems="flex-end"
              className={classes.downloadButtonContainer}
            >
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={this.openDialog}
              >
                Batch print
                <PdfIcon className={classes.rightIcon} />
              </Button>
            </Grid>
            <Grid
                container
                item
                xs={5}
                sm={2}
                alignItems="flex-end"
                className={classes.downloadButtonContainer}
            >
              <Button
                  variant="contained"
                  size="medium"
                  color="secondary"
                  onClick={this.openBatchDialog}
              >
                Large Batch print
                <PdfIcon className={classes.rightIcon} />
              </Button>
            </Grid>
          </Grid>
          <Grid container justify="flex-end" className={classes.divSearch}>
            <FormControlLabel
              style={{ marginLeft: "auto" }}
              control={
                <Switch
                  checked={filters.hidden_shipped}
                  onChange={() => {
                    this.props.setFiltersShippingStatus({
                      hidden_shipped: !filters.hidden_shipped,
                    });
                    this.fetchShippingStatus();
                  }}
                  disabled={this.props.loading}
                  color="primary"
                />
              }
              label="Hide Shipped"
            />
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <Search />
              </div>
              <InputBase
                name="search"
                placeholder="Search..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={filters.search}
                autoFocus={true}
                // disabled={loading}
                onChange={this.handleSearch}
              />
            </div>
          </Grid>
          <div className={classes.tableContainer}>
            <TableResponsive
              items={this.transformData(shippingStatus)}
              config={this.config}
              emptyMessage="SHIPPING STATUS"
              metaFilters={{
                filters: this.props.filters,
                setFilters: this.props.setFiltersShippingStatus,
              }}
              fetchData={this.fetchShippingStatus}
            />
          </div>
        </Panel>
        <Formik
            onSubmit={this.batchConfirmPrint}
            initialValues={{}}
            validationSchema={Yup.object().shape({})}
            enableReinitialize
        >
          {(props) => {
            const { isSubmitting, handleSubmit } = props;
            return (
                <Form>
                  <Dialog
                      open={showDialogConfirm}
                      onClose={this.closeDialog}
                      onBackdropClick={() =>
                          !loading ? this.closeDialog() : false
                      }
                      onEscapeKeyDown={() =>
                          !loading ? this.closeDialog() : false
                      }
                      aria-labelledby="form-dialog-title"
                      maxWidth="xs"
                      fullWidth
                  >
                    <DialogTitle id="form-dialog-title">
                      Confirm Batch Print
                    </DialogTitle>

                    <DialogContent>
                      The batch of {this.props.occurrencesAvailablesForPrint} records has been created and the records
                      have been marked as Printed, click OK to generate the PDF.
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={this.closeConfirmDialog}
                          color="primary"
                          disabled={loading}
                      >
                        Cancel
                      </Button>
                      <Button
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          type="submit"
                          color="primary"
                          disabled={
                            loading ||
                            isSubmitting ||
                            !!!this.props.occurrencesAvailablesForPrint
                          }
                      >
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Form>
            );
          }}
        </Formik>
        <Formik
          onSubmit={this.batchPrint}
          initialValues={{}}
          validationSchema={Yup.object().shape({})}
          enableReinitialize
        >
          {(props) => {
            const { isSubmitting, handleSubmit } = props;
            return (
              <Form>
                <Dialog
                  open={showDialog}
                  onClose={this.closeDialog}
                  onBackdropClick={() =>
                    !loading ? this.closeDialog() : false
                  }
                  onEscapeKeyDown={() =>
                    !loading ? this.closeDialog() : false
                  }
                  aria-labelledby="form-dialog-title"
                  maxWidth="xs"
                  fullWidth
                >
                  <DialogTitle id="form-dialog-title">
                    Confirm Batch Print
                  </DialogTitle>

                  <DialogContent>
                    You are creating Delivery Tickets for{" "}
                    {this.props.occurrencesAvailablesForPrint} of{" "}
                    {this.props.filters.count || 0} services between these dates{" "}
                    {moment(filters.start_date).format("MM/DD/YYYY")} -{" "}
                    {moment(filters.end_date).format("MM/DD/YYYY")}
                    {this.props.filters.state
                      ? ` and with State = ${
                          (
                            us_patient_states.find(
                              ({ id }) => id === filters.state
                            ) || {}
                          ).name
                        }`
                      : null}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeDialog}
                      color="primary"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      type="submit"
                      color="primary"
                      disabled={
                        loading ||
                        isSubmitting ||
                        !!!this.props.occurrencesAvailablesForPrint
                      }
                    >
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
        <Formik
            onSubmit={this.asyncBatchConfirmPrint}
            initialValues={{}}
            // validationSchema={Yup.object().shape({})}
            enableReinitialize
        >
          {(props) => {
            const { isSubmitting, handleSubmit } = props;
            return (
                <Form>
                  <Dialog
                      open={showBatchDialog}
                      onClose={this.closeBatchDialog}
                      onBackdropClick={() =>
                          !loading ? this.closeBatchDialog() : false
                      }
                      onEscapeKeyDown={() =>
                          !loading ? this.closeBatchDialog() : false
                      }
                      aria-labelledby="form-dialog-title"
                      maxWidth="xs"
                      fullWidth
                  >
                    <DialogTitle id="form-dialog-title">
                      Confirm Batch Print
                    </DialogTitle>

                    <DialogContent>
                      Please,In approximately {this.props.occurrencesAvailablesForPrint} seconds
                      review the Large Batch Print UI to download your file.
                    </DialogContent>
                    <DialogActions>
                      <Button
                          onClick={this.closeBatchDialog}
                          color="primary"
                          disabled={loading}
                      >
                        Cancel
                      </Button>
                      <Button
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          type="submit"
                          color="primary"
                          disabled={
                            loading ||
                            isSubmitting ||
                            !!!this.props.occurrencesAvailablesForPrint
                          }
                      >
                        Confirm
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Form>
            );
          }}
        </Formik>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shippingStatus: state.shippingStatus.list,
    filters: state.shippingStatus.filters,
    occurrencesAvailablesForPrint:
      state.shippingStatus.occurrencesAvailablesForPrint,
    batchPrintId: state.shippingStatus.batchPrintId,
    loading: state.global.loading,
    us_patient_states: state.global.us_patient_states,
  };
};

const mapDispatchToProps = {
  setLoading,
  getShippingStatus,
  getOrder,
  updateShipping,
  printShippingStatus,
  confirmShippingStatus,
  asyncConfirmShippingStatus,
  printTicket,
  rePrintTicket,
  setFiltersShippingStatus,
  fetchPatientStates,
  getCountOcurrencesAvailablesForPrint,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ShippingStatusList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ShippingStatusList);
