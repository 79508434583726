import React from "react";
import TabContainer from "../../../components/TabContainer";
import {
  Button,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
} from "@material-ui/core";
import {VerifiedUser, Delete, Edit, FileCopy, Add} from "@material-ui/icons";
import { withSnackbar } from "notistack";
import styles from "./styles";
import { compose } from "recompose";
import { copyTextFromNode } from "./../../../common/Helpers/ClipboardHelper";

class InsurancesTab extends React.Component {
  state = {
    form: {
      name: "",
      policy_number: "",
    },
    openDialog: false,
    action: "",
    insuranceId: "",
  };

  addInsurance = async () => {
    this.setState({ openDialog: false });
    this.props.addInsurance(this.state.form);
  };

  updateInsurance = async () => {
    const { insuranceId, form } = this.state;
    this.setState({ openDialog: false });
    this.props.updateInsurance(insuranceId, form);
  };

  render() {
    const { form, openDialog, action } = this.state;
    const { classes, insurances, loading , handleOpenModalDelete} = this.props;

    return (
      <div>
        <div className={classes.headSection}>
          <FormLabel component="legend">Insurances</FormLabel>
          <Button
            color="primary"
            // variant="outlined"
            size="large"
            onClick={() =>
              this.setState({
                openDialog: true,
                action: "create",
                form: {
                  name: "",
                  policy_number: "",
                },
              })
            }
          >
            <IconButton
                color="primary"
                aria-label="delete"
                size="small"
            >
              <Add />
            </IconButton>
          </Button>
        </div>

        <List>
          {insurances.map((insurance) => (
            <ListItem key={insurance.id}>
              <VerifiedUser />
              <ListItemText
                primary={insurance.name}
                // secondaryTypographyProps={{
                //   id: `insurance-item-${insurance.id}`,
                // }}
                secondary={insurance.policy_number}
              />

              <ListItemSecondaryAction>
                {/* <IconButton
                  aria-label="Comments"
                  color="primary"
                  onClick={() => {
                    const insuranceItem = document.getElementById(
                      `insurance-item-${insurance.id}`
                    );
                    copyTextFromNode(insuranceItem);
                    this.props.enqueueSnackbar("Copied to clipboard", {
                      variant: "info",
                      anchorOrigin: { vertical: "top", horizontal: "center" },
                    });
                  }}
                >
                  <FileCopy />
                </IconButton> */}
                <IconButton
                  aria-label="Comments"
                  color="primary"
                  onClick={() =>
                    this.setState({
                      insuranceId: insurance.id,
                      openDialog: true,
                      action: "update",
                      form: {
                        name: insurance.name,
                        policy_number: insurance.policy_number,
                      },
                    })
                  }
                >
                  <Edit />
                </IconButton>
                <IconButton
                  aria-label="Comments"
                  className={classes.iconDelete}
                  onClick={() => handleOpenModalDelete(insurance.id, "insurance")}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        <Dialog
          open={openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            {action === "create" ? "New Insurance" : "Update Insurance"}
          </DialogTitle>
          <DialogContent>
            <TextField
              name="name"
              value={form.name}
              onChange={(e) => {
                const value = e.target.value;
                this.setState((prevState) => {
                  return {
                    form: {
                      ...prevState.form,
                      name: value,
                    },
                  };
                });
              }}
              label="Name"
              fullWidth
              margin="normal"
              required
            />
            <TextField
              name="policy_number"
              value={form.policy_number}
              onChange={(e) => {
                const value = e.target.value
                  .replace(/\s/g, "")
                  .replace(/\./g, "")
                  .replace(/-/g, "");
                this.setState((prevState) => {
                  return {
                    form: {
                      ...prevState.form,
                      policy_number: value,
                    },
                  };
                });
              }}
              label="Policy Number"
              fullWidth
              margin="normal"
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openDialog: false })}
              color="primary"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={
                action === "create" ? this.addInsurance : this.updateInsurance
              }
              color="primary"
              disabled={
                loading || form.name === "" || form.policy_number === ""
              }
            >
              {action === "create" ? "Save" : "Update"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default compose(withSnackbar, withStyles(styles))(InsurancesTab);
