import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withSnackbar } from "notistack";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Divider,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { getVendors } from "./../../../redux/actions/vendorActions";
import ServiceList from "./ServiceList";
import { createRemittancePayment } from "../../../redux/actions/remittanceAction";
import * as Yup from "yup";
import { setLoading } from "../../../redux/actions/globalActions";
import moment from "moment";
import { Today } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

import _get from "lodash/get";
import NotesDialog from "./NotesDialog";
import { formatNumberWithOutComma } from "../../../common/Helpers/FormatNumberHelper";

const PAYMENT_METHODS = ["Cash", "Bank", "Insurance Adjustments", "Write Off"];

class RemittanceCheckDialog extends React.Component {
  state = {
    lastPayment: {
      id: "",
      services: this.props.remittance.services.map((service) => {
        return service.outstanding
          ? {
              ...service,
              outstanding_saved: service.outstanding,
              amount_ajusted: 0,
              write_off: 0,
            }
          : {
              ...service,
              outstanding: 0,
              outstanding_saved: 0,
              amount_ajusted: 0,
              write_off: 0
            };
      }),

      check_number: "",
      check_date: "",
      total_amount: "",
      payment_method: "",
      vendor: "",
      memo_notes: "",
    },
    isCheckSaved: false,
    openNotesDialog: false,
  };
  async componentDidMount() {
    try {
      this.props.getVendors();
      const { lastRemittancePayment } = this.props;
      if (lastRemittancePayment.id)
        this.setState((prevState) => {
          return {
            lastPayment: {
              ...prevState.lastPayment,
              ...lastRemittancePayment,
              total_amount:
                lastRemittancePayment.remmitance_payment.total_amount_check,
              check_date: lastRemittancePayment.remmitance_payment.check_date,
              check_number:
                lastRemittancePayment.remmitance_payment.check_number,
              vendor: lastRemittancePayment.remmitance_payment.vendor,
            },
            isCheckSaved: true,
          };
        });
    } catch (error) {
      console.log(error);
    }
  }

  handleSubmit = async (values, formikActions) => {
    try {
      this.props.handleClose();
      this.props.setLoading(true);
      const { remittance } = this.props;
      const body = {
        checks: [
          {
            check_number: values.check_number,
            check_date: moment(values.check_date).format("YYYY-MM-DD"),
            total_amount: values.total_amount,
            payment_method: values.payment_method,
            vendor_id: values.vendor || 0,
            memo_notes: values.memo_notes,
          },
        ],
        ocurrence_id: remittance.id,
        ///change field name to 'services' request to gerard more later
        services: values.services.map((service) => ({
          id: service.id,
          outstanding: (+service.amount_ajusted || +service.write_off)
            ? +formatNumberWithOutComma(
                +this.getInvoiceAmountFromService(service)
              )
            : +formatNumberWithOutComma(+service.outstanding),
          amount_ajusted: +service.amount_ajusted,
          write_off: +service.write_off,
        })),
      };

      const response = await this.props.createRemittancePayment(body);
      if (response.status === 200) {
        this.props.enqueueSnackbar("Remittance updated sucessfully!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
    this.props.setLoading(false);
  };

  hasSomeServiceWithAmount(services) {
    return (
      services.find((service) => {
        return (
          service.amountApplied !== 0 ||
          service.amountApplied !== undefined ||
          service.amountApplied !== "0.00"
        );
      }) === undefined
    );
  }

  getInvoiceAmountFromService(service) {
    const sale_price = service.added_percentage ? service.adjusted_sale_price : service.sale_price;
    return +service.amount_remmited * +sale_price;
  }

  getPatientName = (order) => {
    if (order.patient) {
      const { first_name = "", last_name = "" } = order.patient;
      return `${first_name} ${last_name}`;
    }
    return "-";
  };

  render() {
    const {
      loading,
      open,
      handleClose,
      vendors,
      remittance: {
        date: ocurrence_date = "",
        order: { name: order_name = "" } = {},
      } = {},
    } = this.props;

    const patient_name = this.getPatientName(this.props.remittance.order);

    const { lastPayment, isCheckSaved } = this.state;

    const isLastPayment = Boolean(lastPayment.id);

    return (
      <div>
        <Dialog
          onClose={handleClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
          maxWidth="lg"
        >
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={lastPayment}
            validationSchema={Yup.object().shape({
              check_number: Yup.string().required("Check number is required"),
              check_date: Yup.date().required("Check date is required"),
              total_amount: Yup.string().required("Total amount is required"),
              payment_method: Yup.mixed().required(
                "Payment method is required"
              ),
              vendor: Yup.mixed().required("Vendor is required"),
            })}
            enableReinitialize
          >
            {(props) => {
              const {
                values,
                handleBlur,
                handleSubmit,
                handleChange,
                errors,
                touched,
                setFieldValue,
                resetForm,
                isSubmitting,
                isValid,
                dirty,
              } = props;

              return (
                <Form>
                  <DialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                  >
                    Remittance Payment
                  </DialogTitle>
                  <DialogContent>
                    <Grid container justify="flex-end" style={{ marginTop: 8 }}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          this.setState({
                            openNotesDialog: true,
                          })
                        }
                      >
                        Show notes
                      </Button>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          name="check_number"
                          margin="normal"
                          value={values.check_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="number"
                          error={
                            !!touched.check_number && !!errors.check_number
                          }
                          disabled={isLastPayment}
                          helperText={
                            !!touched.check_number &&
                            !!errors.check_number &&
                            errors.check_number
                          }
                          label="Check Number"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <KeyboardDatePicker
                          name="check_date"
                          label="Check Date"
                          value={values.check_date || null}
                          invalidLabel={""}
                          margin="normal"
                          format={"MM/DD/YYYY"}
                          onChange={(date) => {
                            setFieldValue("check_date", date);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          disabled={isLastPayment}
                          animateYearScrolling
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <Today />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="MM/DD/YYYY"
                          required
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          name="total_amount"
                          margin="normal"
                          value={values.total_amount}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isLastPayment}
                          error={
                            !!touched.total_amount && !!errors.total_amount
                          }
                          helperText={
                            !!touched.total_amount &&
                            !!errors.total_amount &&
                            errors.total_amount
                          }
                          type="number"
                          label="Total amount per Check"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Field name="payment_method">
                          {(props) => {
                            const { field, form, meta } = props;
                            return (
                              <Autocomplete
                                options={PAYMENT_METHODS}
                                getOptionLabel={(option) => option}
                                fullWidth
                                value={field.value || null}
                                onChange={(e, newValue) => {
                                  setFieldValue(field.name, newValue);
                                }}
                                disabled={isLastPayment}
                                onBlur={() => {
                                  form.setFieldTouched(field.name, true, true);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    error={!!meta.touched && !!meta.error}
                                    helperText={
                                      !!meta.touched &&
                                      !!meta.error &&
                                      meta.error
                                    }
                                    required
                                    label="Payment Method"
                                  />
                                )}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Field name="vendor">
                          {(props) => {
                            const { field, form, meta } = props;

                            const automCompleteValue =
                              field.value && vendors.length
                                ? vendors.find(
                                    (_option) =>
                                      _get(_option, "id") === field.value
                                  )
                                : null;

                            return (
                              <Autocomplete
                                options={vendors}
                                getOptionLabel={(option) =>
                                  _get(option, "name")
                                }
                                getOptionSelected={(option, _value) =>
                                  _get(option, "id") === _get(_value, "id")
                                }
                                value={automCompleteValue}
                                onChange={(e, newValue) => {
                                  setFieldValue(
                                    field.name,
                                    _get(newValue, "id")
                                  );
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(field.name, true, true)
                                }
                                fullWidth
                                disabled={isLastPayment}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    error={!!meta.touched && !!meta.error}
                                    helperText={
                                      !!meta.touched &&
                                      !!meta.error &&
                                      meta.error
                                    }
                                    required
                                    label="Vendor"
                                  />
                                )}
                              />
                            );
                          }}
                        </Field>
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 8, marginBottom: 32 }} />
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          name="patient_name"
                          margin="normal"
                          value={patient_name}
                          label="Patient Name"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          name="order_name"
                          margin="normal"
                          value={order_name}
                          label="Order Name"
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          name="ocurrance_date"
                          margin="normal"
                          value={moment(ocurrence_date).format("MM/DD/YYYY")}
                          label="Ocurrence Date"
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                    <ServiceList
                      loading={loading}
                      services={values.services}
                      setFieldValue={setFieldValue}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleSubmit}
                      color="primary"
                      fullWidth
                      disabled={
                        isCheckSaved
                          ? loading ||
                            isSubmitting ||
                            (isValid && !dirty) ||
                            (!isValid && dirty) ||
                            this.hasSomeServiceWithAmount(values.services)
                          : loading ||
                            isSubmitting ||
                            !isValid ||
                            !dirty ||
                            this.hasSomeServiceWithAmount(values.services)
                      }
                    >
                      Ok
                    </Button>
                    <Button
                      onClick={() => {
                        resetForm();
                        this.props.handleClose();
                      }}
                      color="primary"
                      fullWidth
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState((prevState) => {
                          return {
                            lastPayment: {
                              ...prevState.lastPayment,
                              id: "",
                              check_number: "",
                              check_date: "",
                              total_amount: "",
                              payment_method: "",
                              vendor: "",
                              memo_notes: "",
                            },
                          };
                        });
                      }}
                      color="primary"
                      fullWidth
                    >
                      Next Check
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
        <NotesDialog
          open={this.state.openNotesDialog}
          key={`${this.state.openNotesDialog}${this.props.remittance.id}`}
          ocurrence={this.props.remittance}
          onClose={() =>
            this.setState({
              openNotesDialog: false,
            })
          }
        />
      </div>
    );
  }
}
RemittanceCheckDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  vendors: state.vendors.list,
  loading: state.global.loading,
  checks: state.remittance.checks,
  // remittancePayments: state.remittance.remittancePayments,
  lastRemittancePayment: state.remittance.lastRemittancePayment,
  remittance: state.remittance.remittance,
});
const mapDispatchToProps = {
  getVendors,
  createRemittancePayment,
  setLoading,
};
export default compose(
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps)
)(RemittanceCheckDialog);
