const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: "relative",
    minHeight: 200
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },
  buttonBack: {
    height: 35,
    marginBottom: 20
  }
});

export default styles;
