import React from 'react';
import { Search } from '@material-ui/icons';
import { InputBase, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

class InputSearch extends React.Component {

  render() {
    const { classes, value, disabled, autoFocus, ...rest } = this.props;

    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Search />
        </div>
        <InputBase
          placeholder="Search..."
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          onChange={(e) => this.props.onChange(e)}
          {...rest}
        />
      </div>
    )
  }
};

InputSearch.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

InputSearch.defaultProps = {
  disabled: false,
  autoFocus: false
};

export default withStyles(styles)(InputSearch);
