import Service from "./Service";

class ProductService extends Service {
  types = async () => {
    try {
      return await this.http.get("/product_types/");
    } catch (error) {
      return error.response;
    }
  };

  categories = async (parent_id = "") => {
    try {
      const params = { parent_id };

      return await this.http.get("/categories/", { params });
    } catch (error) {
      return error.response;
    }
  };

  list = async (params = {}) => {
    try {
      return await this.http.get("/products/", { params });
    } catch (error) {
      return error.response;
    }
  };

  create = async (form) => {
    try {
      const headers = { "content-type": "multipart/form-data" };

      return await this.http.post("/products/", form, { headers });
    } catch (error) {
      return error.response;
    }
  };

  async export_products() {
    try {
      return await this.http.get(`/export_products/`, {
        responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }

  update = async (id, form) => {
    try {
      const headers = { "content-type": "multipart/form-data" };
      const url = `/products/${id}/`;

      return await this.http.patch(url, form, { headers });
    } catch (error) {
      return error.response;
    }
  };

  get = async (id) => {
    try {
      const url = `/products/${id}/`;

      return await this.http.get(url);
    } catch (error) {
      return error.response;
    }
  };

  delete = async (id) => {
    try {
      const url = `/products/${id}/`;
      return await this.http.delete(url);
    } catch (error) {
      return error.response;
    }
  };

  async addQtyPerUnitMeasure(body) {
    try {
      const url = `/products/update_qty_of_hand/`;

      return await this.http.post(url, body);
    } catch (error) {
      return error.response;
    }
  }
}

export default ProductService;
