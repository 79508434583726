const styles = theme => ({
  paper: {
    padding: 20
  },

  textRedInput: {
    color: 'red'
  },

  textBlackInput: {
    color: 'black'
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  headSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  listSection: {
    overflowY: "auto"
  },
  link: {
    display: "inline-block",
    width: theme.spacing(35),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  tabContainer: {
    width: "100%",
    minWidth: "0px !important",
    flex: "1"
  },
  labelCheckBox: {
    paddingRight: 20,
    color: "rgba(0, 0, 0, 0.54)"
  },

  paddingAutocomplete: {
    // paddingBottom: 8
  },

  tabsContainer: {
    maxHeight: "100%",
    overflowY: "auto",
    padding: theme.spacing(2),
    width: "100%",
  },
  tabsContainer2: {
    height: "100%",
    maxHeight: "100%",
    overflowY: "auto",
    marginTop: 10,
    padding: theme.spacing(2),
    width: "100%",
  },

  iconDelete: {
    '& svg': {
      color: "#f50057"      
    },
    '&:hover': {
      backgroundColor: "rgba(237,83,85, 0.08)"
    },
    '&:disabled': {
      '& svg': {
        color: "gray"      
      },
    },
  },
});

export default styles;
