import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import moment from "moment";

import _get from "lodash/get";
import { TextEmpty } from "..";
import classNames from "classnames";
import formatNumber from "../../common/Helpers/FormatNumberHelper";

function TableResponsive({
  items,
  config,
  search,
  emptyMessage,
  metaFilters,
  fetchData,
}) {
  const getValue = (item, column) => {
    const value = _get(item, column.name, "-");
    // const value = Boolean(_get(item, column.name, "-"))
    //   ? _get(item, column.name, "-")
    //   : "-";

    if (item.invoice === 51) {
      console.log(item, column.name, value);
    }

    switch (column.type) {
      case "string":
        return value;
      case "number":
        return value;
      case "decimal":
        return value !== "-" ? formatNumber(value) : "0.00";
      case "price":
        return value === "-" ? "-" : (+value).toFixed(2);
      case "date":
        return value !== "-" && !!value
          ? moment(value).format("MM/DD/YYYY")
          : "-";
      case "custom":
        return column.custom.render(item, column);

      default:
        return "-";
    }
  };

  const _handleOrderBy = (_sortedName) => () => {
    if (metaFilters.filters.ordering === _sortedName) {
      metaFilters.setFilters({
        ordering: `-${_sortedName}`,
        page: 1,
      });
    } else {
      metaFilters.setFilters({
        ordering: _sortedName,
        page: 1,
      });
    }

    fetchData();
  };

  const _handleChangePage = (event, newPage) => {
    metaFilters.setFilters({
      page: newPage + 1, //table pagination of material ui is ZERO-based
    });
    fetchData();
  };

  const _handleChangeRowsPerPage = (event) => {
    metaFilters.setFilters({
      page_size: parseInt(event.target.value, 10), //table pagination of material ui is ZERO-based,
      page: 1,
    });
    fetchData();
  };

  const emptyRows = metaFilters.filters.page_size - items.length;

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {config.columns.map((_column, index) => (
                <TableCell
                  align={_column.align}
                  key={index}
                  style={_column.style ? _column.style : {}}
                >
                  {_column.sorted ? (
                    <TableSortLabel
                      active={
                        metaFilters.filters.ordering === _column.sorted_name ||
                        metaFilters.filters.ordering ===
                          `-${_column.sorted_name}`
                      }
                      direction={
                        metaFilters.filters.ordering ===
                        `-${_column.sorted_name}`
                          ? "desc"
                          : "asc"
                      }
                      onClick={_handleOrderBy(_column.sorted_name)}
                    >
                      {_column.display_name}
                    </TableSortLabel>
                  ) : (
                    _column.display_name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              // (config.default.no_pagination
              items.map((_item, index) => (
                <TableRow
                  key={`row-${_item.id} ${index}`}
                  className={classNames([
                    config.rowClassName && config.rowClassName(_item),
                  ])}
                >
                  {config.columns.map((_column, _index) => (
                    <TableCell
                      align={_column.align}
                      key={`row-cell${_column.name} ${_item.id} ${_index}`}
                    >
                      {getValue(_item, _column)}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            }
            {items.length === 0 && (
              <TableRow>
                <TableCell colSpan={config.columns.length}>
                  <TextEmpty
                    itemName={emptyMessage}
                    empty={items.length === 0}
                  />
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && config.pagination && (
              // <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={config.columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {config.pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 100]}
          component="div"
          count={metaFilters.filters.count}
          rowsPerPage={metaFilters.filters.page_size}
          page={metaFilters.filters.page - 1}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

TableResponsive.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  config: PropTypes.shape({
    default: PropTypes.shape({
      orderBy: PropTypes.string,
      order: PropTypes.string,
    }),
  }).isRequired,
  search: PropTypes.string,
  emptyMessage: PropTypes.string.isRequired,
  metaFilters: PropTypes.shape({
    filters: PropTypes.shape({}),
    setFilters: PropTypes.func,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
};
TableResponsive.defaultProps = {
  /**temp - future integrate internal search input */
  search: "",
  metaFilters: { filters: {}, setFilters: () => {} },
  fetchData: () => {},
};

export default TableResponsive;
