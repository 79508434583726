import React from "react";
import {
  Button,
  Grid,
  TextField,
  Tabs,
  Tab,
  Paper,
  MenuItem,
  FormControlLabel,
  withStyles,
  Switch,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import {
  Place,
  Work,
  VerifiedUser,
  Label,
  AttachFile,
  Today,
} from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import {
  Layout,
  Panel,
  SimpleBreadcrumbs,
  TabContainer,
  PhoneMask,
  Errors,
  NpiMask,
} from "../../../components";
import {
  setLoading,
  fetchStates,
  setHandleForm,
} from "../../../redux/actions/globalActions";
import { createPatient } from "../../../redux/actions/patientActions";
import styles from "./styles";
import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import { datePickerFormatToParseDate } from "../../../common/Helpers/DateHelper";
import Autocomplete from "../../../components/Autocomplete";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import ContactList from "../../../components/ContactList";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Patients", to: "/patients" },
  { name: "Create Patient", to: null },
];

class PatientCreate extends React.Component {
  state = {
    tab: 0,
    errorBirthdate: false,
  };

  componentDidMount = async () => {
    try {
      this.props.fetchStates();
    } catch (error) {
      console.log(error);
    }
  };

  submit = async (values, formikActions) => {
    const { setSubmitting, resetForm } = formikActions;
    this.props.setLoading(true);

    try {
      const form = { ...values };
      if (values.state_id) {
        form.state_id = values.state_id.value;
      }
      if (!values.call_before_delivery || !values.contact_different_patient) {
        delete form.contact_person;
      }
      form.birthdate = form.birthdate
        ? datePickerFormatToParseDate(form.birthdate)
        : null;
      const { status, data } = await this.props.createPatient(form);

      this.props.setHandleForm(false);
      if (status === 201) {
        resetForm();
        this.props.history.push(`/patients/${data.id}`);
        this.props.enqueueSnackbar("The patient has been created!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
      setSubmitting(false);
    }
  };

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { classes, loading, us_states } = this.props;
    const { tab, errorBirthdate } = this.state;

    return (
      <Layout title="Create Patient">
        <SimpleBreadcrumbs routes={breadcrumbs} />
        <Formik
          initialValues={{
            first_name: "",
            middle_name: "",
            last_name: "",
            address_1: "",
            address_2: "",
            city: "",
            state_id: null,
            zip: "",
            phone: "",
            mobile: "",
            fax: "",
            email: "",
            birthdate: null,
            notes: "",
            gender: "",
            facility: false,
            rentals: false,
            status: "Active",
            call_before_delivery: false,
            physician_name: "",
            physician_npi: "",
            physician_phone: "",
            physician_fax: "",
            contact_different_patient: false,
            // contact_name: "",
            // contact_number: "",
            // contact_relation: "",
            /**what-ever-name from backend */
            contact_person: [],
          }}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            middle_name: Yup.string(),
            address_1: Yup.string(),
            address_2: Yup.string(),
            city: Yup.string(),
            /**while no ave data of db */
            state_id: Yup.mixed(),
            zip: Yup.string(),
            phone: Yup.string(),
            mobile: Yup.string(),
            fax: Yup.string(),
            email: Yup.string(),
            birthdate: Yup.mixed(),
            gender: Yup.string(),
            facility: Yup.boolean(),
            rentals: Yup.boolean(),
            notes: Yup.string(),
            status: Yup.string(),
            call_before_delivery: Yup.boolean(),
            physician_name: Yup.string(),
            physician_npi: Yup.string().length(10),
            physician_phone: Yup.string(),
            physician_fax: Yup.string(),
            contact_person: Yup.array().of(
              Yup.object().shape({
                contact_name: Yup.string().required("Is required"),
                contact_number: Yup.mixed(),
                contact_relation: Yup.string(),
              })
            ),
          })}
          onSubmit={this.submit}
        >
          {(props) => {
            const {
              isSubmitting,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              dirty,
              isValid,
              setFieldValue,
            } = props;
            return (
              <Form className={classes.form}>
                <Prompt
                  when={dirty}
                  message="Are you sure you want to leave?, You will lose your changes"
                />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Panel>
                      <Grid
                        container
                        spacing={2}
                        justify-content="space-between"
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="first_name"
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.first_name && !!errors.first_name}
                            helperText={
                              !!touched.first_name &&
                              !!errors.first_name &&
                              errors.first_name
                            }
                            label="First Name"
                            fullWidth
                            margin="normal"
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="middle_name"
                            value={values.middle_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!touched.middle_name && !!errors.middle_name
                            }
                            helperText={
                              !!touched.middle_name &&
                              !!errors.middle_name &&
                              errors.middle_name
                            }
                            label="Middle Name"
                            fullWidth
                            margin="normal"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="last_name"
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.last_name && !!errors.last_name}
                            helperText={
                              !!touched.last_name &&
                              !!errors.last_name &&
                              errors.last_name
                            }
                            label="Last Name"
                            fullWidth
                            margin="normal"
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.phone && !!errors.phone}
                            helperText={
                              !!touched.phone && !!errors.phone && errors.phone
                            }
                            label="Primary Phone Number"
                            fullWidth
                            margin="normal"
                            InputProps={{
                              inputComponent: PhoneMask,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="mobile"
                            value={values.mobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.mobile && !!errors.mobile}
                            helperText={
                              !!touched.mobile &&
                              !!errors.mobile &&
                              errors.mobile
                            }
                            label="Secondary Phone Number"
                            fullWidth
                            margin="normal"
                            InputProps={{
                              inputComponent: PhoneMask,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                              name="gender"
                              label="Gender"
                              margin="normal"
                              value={values.gender}
                              onChange={(e) => {
                                  handleChange(e);
                                  this.setState({ changeField: true });
                              }}
                              onBlur={handleBlur}
                              error={!!touched.gender && !!errors.gender}
                              helperText={
                                  !!touched.gender &&
                                  !!errors.gender &&
                                  errors.gender
                              }
                              fullWidth
                              select
                              disabled={loading}
                              InputLabelProps={{ shrink: true }}
                          >
                              <MenuItem value="Female">Female</MenuItem>
                              <MenuItem value="Male">Male</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.email && !!errors.email}
                            helperText={
                              !!touched.email && errors.email && errors.email
                            }
                            label="Email Address"
                            fullWidth
                            margin="normal"
                            type="email"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <KeyboardDatePicker
                            name="birthdate"
                            label="Date of Birth"
                            value={values.birthdate || null}
                            margin="normal"
                            format={"MM/DD/YYYY"}
                            onChange={(date) => {
                              if (date && date.isValid()) {
                                this.setState({ errorBirthdate: false });
                                setFieldValue("birthdate", date);
                              } else {
                                if (date)
                                  this.setState({ errorBirthdate: true });
                                else {
                                  setFieldValue("birthdate", "");
                                  this.setState({ errorBirthdate: false });
                                }
                              }
                            }}
                            onBlur={handleBlur}
                            error={errorBirthdate}
                            helperText={errorBirthdate && "Invalid date"}
                            fullWidth
                            animateYearScrolling
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="MM/DD/YYYY"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton aria-label="calendar">
                                    <Today />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="status"
                            label="Status"
                            margin="normal"
                            value={values.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.status && !!errors.status}
                            helperText={
                              !!touched.status &&
                              !!errors.status &&
                              errors.status
                            }
                            fullWidth
                            select
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                            <MenuItem value="Deceased">Deceased</MenuItem>
                          </TextField>
                        </Grid>

                        {/*<Grid item xs={12}>*/}
                        {/*  <TextField*/}
                        {/*    name="notes"*/}
                        {/*    value={values.notes}*/}
                        {/*    onChange={handleChange}*/}
                        {/*    onBlur={handleBlur}*/}
                        {/*    error={!!touched.notes && !!errors.notes}*/}
                        {/*    helperText={*/}
                        {/*      !!touched.notes && !!errors.notes && errors.notes*/}
                        {/*    }*/}
                        {/*    label="General Notes"*/}
                        {/*    fullWidth*/}
                        {/*    margin="normal"*/}
                        {/*    multiline*/}
                        {/*    rows={2}*/}
                        {/*  />*/}
                        {/*</Grid>*/}

                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            name="call_before_delivery"
                            control={
                              <Switch
                                checked={values.call_before_delivery}
                                onChange={(e) => {
                                  setFieldValue(
                                    "call_before_delivery",
                                    e.target.checked
                                  );
                                }}
                                onBlur={handleBlur}
                                value="1"
                                color="primary"
                              />
                            }
                            label="Contact prior to delivery"
                          />
                        </Grid>
                        {(() => {
                          if (values.call_before_delivery) {
                            return (
                              <Grid item xs={12} sm={6}>
                                <FormControlLabel
                                  name="contact_different_patient"
                                  control={
                                    <Switch
                                      checked={values.contact_different_patient}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "contact_different_patient",
                                          e.target.checked
                                        );
                                        if (
                                          e.target.checked &&
                                          !values.contact_person.length
                                        ) {
                                          setFieldValue("contact_person", [
                                            {
                                              contact_name: "",
                                              contact_number: "",
                                              contact_relation: "",
                                            },
                                          ]);
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      value="1"
                                      color="primary"
                                    />
                                  }
                                  label="Contact different than patient"
                                />
                              </Grid>
                            );
                          }
                        })()}
                        {(() => {
                          if (
                            values.call_before_delivery &&
                            values.contact_different_patient
                          ) {
                            return <ContactList />;
                          }
                        })()}
                      </Grid>
                    </Panel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper>
                      <Tabs
                        value={tab}
                        onChange={this.handleTab}
                        variant="scrollable"
                        scrollButtons="auto"
                      >
                        <Tab icon={<Place />} />
                        <Tab icon={<Work />} />
                        <Tab icon={<VerifiedUser />} disabled />
                        <Tab icon={<AttachFile />} disabled />
                        <Tab icon={<Label />} disabled />
                      </Tabs>

                      {tab === 0 && (
                        <TabContainer>
                          <TextField
                            name="address_1"
                            value={values.address_1}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.address_1 && !!errors.address_1}
                            helperText={
                              !!touched.address_1 &&
                              !!errors.address_1 &&
                              errors.address_1
                            }
                            label="Address 1"
                            margin="normal"
                            fullWidth
                          />

                          <TextField
                            name="address_2"
                            value={values.address_2}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.address_2 && !!errors.address_2}
                            helperText={
                              !!touched.address_2 &&
                              !!errors.address_2 &&
                              errors.address_2
                            }
                            label="Address 2"
                            margin="normal"
                            fullWidth
                          />

                          <TextField
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!touched.city && !!errors.city}
                            helperText={
                              !!touched.city && !!errors.city && errors.city
                            }
                            label="City"
                            margin="normal"
                            fullWidth
                          />

                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              container
                              alignItems="flex-end"
                            >
                              <Autocomplete
                                onChange={(value) => {
                                  setFieldValue("state_id", value);
                                }}
                                value={values.state_id}
                                suggestions={us_states.map((state) => ({
                                  value: state.id,
                                  label: state.name,
                                }))}
                                required={true}
                                placeholder="State *"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                name="zip"
                                value={values.zip || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!touched.zip && !!errors.zip}
                                helperText={
                                  !!touched.zip && !!errors.zip && errors.zip
                                }
                                label="Zip"
                                margin="normal"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <label className={ classes.labelCheckBox }>Facility</label>
                                <FormControlLabel
                                    name="facility"
                                    control={
                                        <Switch
                                            checked={values.facility}
                                            onChange={(e) => {
                                                this.setState({ changeField: true });
                                                setFieldValue(
                                                    "facility",
                                                    e.target.checked
                                                );
                                            }}
                                            onBlur={handleBlur}
                                            value="1"
                                            color="primary"
                                        />
                                    }
                                    label={values.facility ? "Yes" : "No"}
                                    labelPlacement={values.facility ? "start" : "end"}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label className={ classes.labelCheckBox }>Rentals</label>
                                <FormControlLabel
                                    name="rentals"
                                    control={
                                        <Switch
                                            checked={values.rentals}
                                            onChange={(e) => {
                                                this.setState({ changeField: true });
                                                setFieldValue(
                                                    "rentals",
                                                    e.target.checked
                                                );
                                            }}
                                            onBlur={handleBlur}
                                            value="1"
                                            color="primary"
                                        />
                                    }
                                    label={values.rentals ? "Yes" : "No"}
                                    labelPlacement={values.rentals ? "start" : "end"}
                                />
                            </Grid>
                        </Grid>
                        </TabContainer>
                      )}
                      {tab === 1 && (
                        <TabContainer>
                          <TextField
                            name="physician_name"
                            value={values.physician_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!touched.physician_name &&
                              !!errors.physician_name
                            }
                            helperText={
                              !!touched.physician_name &&
                              !!errors.physician_name &&
                              errors.physician_fax
                            }
                            label="Physician Name"
                            fullWidth
                            margin="normal"
                          />
                          <TextField
                            name="physician_npi"
                            value={values.physician_npi}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!touched.physician_npi && !!errors.physician_npi
                            }
                            helperText={
                              !!touched.physician_npi &&
                              !!errors.physician_npi &&
                              errors.physician_npi
                            }
                            label="NPI"
                            fullWidth
                            margin="normal"
                            InputProps={{
                              inputComponent: NpiMask,
                            }}
                          />
                          <TextField
                            name="physician_phone"
                            value={values.physician_phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!touched.physician_phone &&
                              !!errors.physician_phone
                            }
                            helperText={
                              !!touched.physician_phone &&
                              !!errors.physician_phone &&
                              errors.physician_phone
                            }
                            label="Phone"
                            fullWidth
                            margin="normal"
                            InputProps={{
                              inputComponent: PhoneMask,
                            }}
                          />
                          <TextField
                            name="physician_fax"
                            value={values.physician_fax}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!touched.physician_fax && !!errors.physician_fax
                            }
                            helperText={
                              !!touched.physician_fax &&
                              !!errors.physician_fax &&
                              errors.physician_fax
                            }
                            label="Fax"
                            fullWidth
                            margin="normal"
                            InputProps={{
                              inputComponent: PhoneMask,
                            }}
                          />
                        </TabContainer>
                      )}
                    </Paper>
                  </Grid>
                </Grid>

                <br />

                <Button
                  onClick={handleSubmit}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={
                    loading ||
                    isSubmitting ||
                    !dirty ||
                    !isValid ||
                    errorBirthdate
                  }
                >
                  Create Patient
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    us_states: state.global.us_states,
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    errors: new Errors(state.patients.errors),
  };
};

const mapDispatchToProps = {
  createPatient,
  setLoading,
  fetchStates,
  setHandleForm,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "PatientCreate" }),
  connect(mapStateToProps, mapDispatchToProps)
)(PatientCreate);
