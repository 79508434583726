import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import {Link as RouterLink, withRouter} from "react-router-dom";
import { withSnackbar } from "notistack";
import classNames from "classnames";
import moment from "moment";
import {
  withStyles,
  Chip,
  Grid,
  IconButton,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  InputAdornment,
  FormControlLabel, TextField, MenuItem, Table, TableHead, TableRow, TableCell, TableBody, Typography, TableContainer,
} from "@material-ui/core";
import { Layout, SimpleBreadcrumbs, Panel } from "../../components";
import {
  setLoading,
  fetchPatientStates,
} from "../../redux/actions/globalActions";
import {
  getBatchShippingStatus,
} from "../../redux/actions/shippingStatusActions";
import PdfIcon from "@material-ui/icons/CloudDownload";

import { KeyboardDatePicker } from "@material-ui/pickers";
import styles from "./styles";
import { getOrder } from "../../redux/actions/orderActions";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {AttachFile, Delete, Edit, Search, Today} from "@material-ui/icons";
import TableResponsive from "../../components/TableResponsive";
import _debounce from "lodash/debounce";
import Autocomplete from "../../components/Autocomplete";
import Link from "@material-ui/core/Link";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Large Batch View", to: null },
];

class LargeBatchList extends React.Component {

  componentDidMount = async () => {
    try {
      await this.fetchShippingStatus();
    } catch (error) {
      console.error(error);
    }
  };

  fetchShippingStatus = () => {
    return this.props.getBatchShippingStatus();
  };

  fetchShippingStatusDelayed = _debounce((e) => {
    return this.getBatchShippingStatus();
  }, 1500);


  render() {
    let {
      classes,
      batches,
    } = this.props;

    return (
      <Layout title="List Batch Print">
        <SimpleBreadcrumbs routes={breadcrumbs} />
        <Panel>
          <div className={classes.tableContainer}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batches.map(batch => (
                      <TableRow key={batch.name}>
                        {/*<TableCell component="th" scope="row" align="left">*/}
                        {/*  {profile.first_name || " "} {profile.middle_name || " "}{" "}*/}
                        {/*  {profile.last_name || ""}*/}
                        {/*</TableCell>*/}
                        <TableCell>
                          <AttachFile />
                          { batch.url? (
                              <Link
                                  className={classes.link}
                                  href={batch.url}
                                  target="_blank"
                              >
                                {batch.name}
                              </Link>
                          ):(
                            batch.name
                          )}
                        </TableCell>

                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Panel>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    batches: state.batch_list.list,
    loading: state.global.loading,
  }
};

const mapDispatchToProps = {
  getBatchShippingStatus
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "LargeBatchList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(LargeBatchList);
