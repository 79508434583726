import React from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Layout from "../../../components/Layout/index";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import SimpleBreadcrumbs from "../../../components/SimpleBreadcrumbs";

import Panel from "../../../components/Panel";
import Errors from "../../../components/Errors";
import { PhoneMask } from "../../../components/InputMask";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { getVendor, updateVendor } from "../../../redux/actions/vendorActions";
import {
  setHandleForm,
  setLoading
} from "../../../redux/actions/globalActions";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Vendors", to: "/vendors" },
  { name: "Edit Vendors", to: null }
];

class VendorEdit extends React.Component {
  state = {
    tab: 0,
    changeField: false,
    form: {
      name: "",
      phone: ""
    },
    user: {}
  };

  componentDidMount = async () => {
    try {
      const vendorId = this.props.match.params.id;
      const response = await this.props.getVendor(vendorId);

      if (response.status === 200) {
        this.loadForm(response.data);
      } else {
        this.props.history.push("/404");
      }
    } catch (error) {
      console.error(error);
    }
  };

  loadForm = data => {
    const { name, phone } = data;

    this.setState({ form: { name, phone } });
  };

  handleChange = event => {
    const form = this.state.form;
    form[event.target.name] = event.target.value;

    this.setState({ form });
  };

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  handleSubmit = async (values, formikActions) => {
    const { setSubmitting } = formikActions;
    this.props.setLoading(true);
    try {
      const userId = this.props.match.params.id;
      const { name, phone } = values;

      const form = { name, phone };

      const response = await this.props.updateVendor(userId, form);

      if (response.status === 200) {
        this.setState({changeField: false})
        this.props.enqueueSnackbar("The vendor has been updated!", {
          variant: "success"
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error"
        });
      }
    } catch (error) {
      this.props.enqueueSnackbar(error.message, { variant: "error" });
      console.error(error);
    }
    setSubmitting(false);
    this.props.setLoading(false);
  };

  render() {
    const { classes, loading } = this.props;
    const { form, changeField } = this.state;

    return (
      <Layout title="Edit Vendor">
        <div className={classes.root}>
          <SimpleBreadcrumbs routes={breadcrumbs} />
          <Formik
            onSubmit={this.handleSubmit}
            initialValues={{
              ...form
            }}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
              phone: Yup.string().required("Phone Number is required")
            })}
          >
            {props => {
              const {
                isSubmitting,
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                dirty,
                isValid,
                handleSubmit
              } = props;
              return (
                <Form>
                  <Prompt
                    when={changeField}
                    message="Are you sure you want to leave?, You will lose your changes"
                  />
                  <Panel>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <TextField
                          name="name"
                          value={values.name}
                          onChange={e => {
                            handleChange(e)
                            this.setState({changeField: true})
                          }}
                          onBlur={handleBlur}
                          error={!!touched.name && !!errors.name}
                          helperText={
                            !!touched.name && !!errors.name && errors.name
                          }
                          label="Name"
                          fullWidth
                          margin="normal"
                          required
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          name="phone"
                          value={values.phone}
                          onChange={e => {
                            handleChange(e)
                            this.setState({changeField: true})
                          }}
                          onBlur={handleBlur}
                          error={!!touched.phone && !!errors.phone}
                          helperText={
                            !!touched.phone && !!errors.phone && errors.phone
                          }
                          label="Phone Number"
                          fullWidth
                          margin="normal"
                          required
                          InputProps={{
                            inputComponent: PhoneMask
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Panel>

                  <br />
                  <Grid container justify="center">
                    <Button
                      disabled={
                        loading ||
                        isSubmitting ||
                        (isValid && !dirty) ||
                        (!isValid && dirty)
                      }
                      onClick={e => {
                        handleSubmit(e);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Update Vendor
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    errors: new Errors(state.users.errors)
  };
};

const mapDispatchToProps = {
  getVendor,
  updateVendor,
  setHandleForm,
  setLoading
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "VendorEdit" }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(VendorEdit);
