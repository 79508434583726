import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableContainer,
  TablePagination, IconButton, Collapse, Box, Grid,
} from "@material-ui/core";
import moment from "moment";

import _get from "lodash/get";
import {DialogDelete, TextEmpty} from "..";
import classNames from "classnames";
import formatNumber from "../../common/Helpers/FormatNumberHelper";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {Delete} from "@material-ui/icons";

function Row(data) {
  const { remittance, props } = data;
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [elementToDelete, setElementToDelete] = React.useState(null);
  // const [ocurrence_po, setOcurrencePo] = React.useState( ocurrence.po_number);


  async function handleDeleteRemmitancePayment(){
    props.setLoading(true);

    try {
      const response = await props.deleteOcurrencePayment(elementToDelete);

      if (response.status === 204) {
        await props.getRevertRemittances();
        this.props.enqueueSnackbar("Unremit successfully!!", {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenDelete(false)
      setElementToDelete(null)
      props.setLoading(false);
    }
  };

  return (
      <React.Fragment>
        <TableRow key={remittance.id}>
          <TableCell component="th" scope="row">
            {remittance.check_number}
          </TableCell>
          <TableCell>
            {moment(remittance.check_date).format("MM/DD/YYYY")}
          </TableCell>
          <TableCell>
            {remittance.total_amount_check}
          </TableCell>
          <TableCell>
            {remittance.vendor}
          </TableCell>
          <TableCell>
            {remittance.total_amount_remmited.toFixed(2)}
          </TableCell>
          <TableCell style={{textAlign: "center"}}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <DialogDelete
                    item="service"
                    open={openDelete}
                    closeModal={() => setOpenDelete(false)}
                    remove={handleDeleteRemmitancePayment}
                    isDelivery={false}
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                        >
                          Patient Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                        >
                          Invoice Number
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                        >
                          DOS
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                        >
                          Amount Applied
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {remittance.remmitance_payments.map((remmitance_payment) => (
                        <TableRow key={remmitance_payment.id}>
                          <TableCell component="th" scope="row">
                            {remmitance_payment.patient}
                          </TableCell>
                          <TableCell>{remmitance_payment.invoice}</TableCell>
                          <TableCell>{remmitance_payment.date_of_service|| "0"}</TableCell>
                          <TableCell>{ remmitance_payment.amount_applied}</TableCell>
                          <TableCell>
                            <Grid container justify="center" wrap="nowrap">
                              <IconButton
                                  onClick={() =>{
                                    setOpenDelete(true);
                                    setElementToDelete(remmitance_payment.id)}
                                  }
                              >
                                <Delete color="secondary" />
                              </IconButton>
                            </Grid>
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
}

function TableResponsiveAccordion({
  items,
  config,
  search,
  emptyMessage,
  metaFilters,
  fetchData,
  props,
}) {
  const getValue = (item, column) => {
    const value = _get(item, column.name, "-");
    // const value = Boolean(_get(item, column.name, "-"))
    //   ? _get(item, column.name, "-")
    //   : "-";

    if (item.invoice === 51) {
      console.log(item, column.name, value);
    }

    switch (column.type) {
      case "string":
        return value;
      case "number":
        return value;
      case "decimal":
        return value !== "-" ? formatNumber(value) : "0.00";
      case "price":
        return value === "-" ? "-" : (+value).toFixed(2);
      case "date":
        return value !== "-" && !!value
          ? moment(value).format("MM/DD/YYYY")
          : "-";
      case "custom":
        return column.custom.render(item, column);

      default:
        return "-";
    }
  };

  const _handleOrderBy = (_sortedName) => () => {
    if (metaFilters.filters.ordering === _sortedName) {
      metaFilters.setFilters({
        ordering: `-${_sortedName}`,
        page: 1,
      });
    } else {
      metaFilters.setFilters({
        ordering: _sortedName,
        page: 1,
      });
    }

    fetchData();
  };

  const _handleChangePage = (event, newPage) => {
    metaFilters.setFilters({
      page: newPage + 1, //table pagination of material ui is ZERO-based
    });
    fetchData();
  };

  const _handleChangeRowsPerPage = (event) => {
    metaFilters.setFilters({
      page_size: parseInt(event.target.value, 10), //table pagination of material ui is ZERO-based,
      page: 1,
    });
    fetchData();
  };

  const emptyRows = metaFilters.filters.page_size - items.length;

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {config.columns.map((_column, index) => (
                <TableCell
                  align={_column.align}
                  key={index}
                  style={_column.style ? _column.style : {}}
                >
                  {_column.sorted ? (
                    <TableSortLabel
                      active={
                        metaFilters.filters.ordering === _column.sorted_name ||
                        metaFilters.filters.ordering ===
                          `-${_column.sorted_name}`
                      }
                      direction={
                        metaFilters.filters.ordering ===
                        `-${_column.sorted_name}`
                          ? "desc"
                          : "asc"
                      }
                      onClick={_handleOrderBy(_column.sorted_name)}
                    >
                      {_column.display_name}
                    </TableSortLabel>
                  ) : (
                    _column.display_name
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
              {items.map((remittance) => (
                  <Row key={remittance.id} remittance={remittance} props={props}/>
              ))}
            {items.length === 0 && (
              <TableRow>
                <TableCell colSpan={config.columns.length}>
                  <TextEmpty
                    itemName={emptyMessage}
                    empty={items.length === 0}
                  />
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && config.pagination && (
              // <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={config.columns.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {config.pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 100]}
          component="div"
          count={metaFilters.filters.count}
          rowsPerPage={metaFilters.filters.page_size}
          page={metaFilters.filters.page - 1}
          onChangePage={_handleChangePage}
          onChangeRowsPerPage={_handleChangeRowsPerPage}
        />
      )}
    </div>
  );
}

TableResponsiveAccordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  config: PropTypes.shape({
    default: PropTypes.shape({
      orderBy: PropTypes.string,
      order: PropTypes.string,
    }),
  }).isRequired,
  search: PropTypes.string,
  emptyMessage: PropTypes.string.isRequired,
  metaFilters: PropTypes.shape({
    filters: PropTypes.shape({}),
    setFilters: PropTypes.func,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
};
TableResponsiveAccordion.defaultProps = {
  /**temp - future integrate internal search input */
  search: "",
  metaFilters: { filters: {}, setFilters: () => {} },
  fetchData: () => {},
};

export default TableResponsiveAccordion;
