import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  buttonAccept: {
    borderColor: '#22c722 !important',
    color: '#22c722 !important',
    '&:hover, &:focus': {
      borderColor: '#22c722 !important', 
      backgroundColor: '#22c722 !important',
      color: 'white !important'
    }
  },
  buttonCancel: {
    borderColor: '#f50057 !important',
    color: '#f50057 !important',
    '&:hover, &:focus': {
      borderColor: '#f50057 !important',
      backgroundColor: '#f50057 !important',
      color: 'white !important'
    }
  },
});

export default useStyles;