import OrderService from "../../services/OrderService";
import {
  CREATE_ORDER,
  GET_ORDER,
  GET_ORDER_DELIVERIES,
  GET_ORDER_RECURRENCES,
  GET_ORDER_STATUS,
  GET_ORDERS,
  ORDER_ADD_PRODUCT_ERROR,
  ORDER_UPDATE_PRODUCT_ERROR,
  UPDATE_ORDER,
  UPDATE_ORDER_ERROR,
  GET_ALL_CALL_NOTES,
  ORDER_SET_PATIENT_ID_ORIGIN,
  SET_ORDER_FILTERS,
  ON_LOADING,
  GET_PRODUCT_PRICE_VARIANTS,
  GET_ORDER_PRODUCT,
} from "../actionTypes";

const service = new OrderService();

export const getOrders = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ON_LOADING, loading: true });

      const response = await service.list(getState().orders.filters);
      const { status, data } = response;
      if (status === 200) {
        const { results, ...meta } = data;

        dispatch({ type: GET_ORDERS, payload: results });
        dispatch({
          type: SET_ORDER_FILTERS,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      }
      dispatch({ type: ON_LOADING, loading: false });
      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const setOrderFilters = (payload) => ({
  type: SET_ORDER_FILTERS,
  payload,
});

export const getOrdersByPatient = (id) => async (dispatch) => {
  try {
    const response = await service.listByPatient(id);
    const { status, data } = response;

    if (status === 200) {
      dispatch({ type: GET_ORDERS, payload: data });
    }
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getOrder = (id) => {
  return async (dispatch) => {
    try {
      const response = await service.get(id);
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_ORDER, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const createOrder = (body = {}) => {
  return async (dispatch) => {
    try {
      const response = await service.create(body);
      const { status, data } = response;

      if (status === 201) {
        dispatch({ type: CREATE_ORDER, payload: data });
      } else {
        dispatch({ type: CREATE_ORDER, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const cloningOrder = (id) => {
  return async (dispatch) => {
    dispatch({ type: ON_LOADING, loading: true });
    try {
      const response = await service.cloningOrder(id);

      dispatch({ type: ON_LOADING, loading: false });
      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const updateOrder = (id, body) => {
  return async (dispatch) => {
    try {
      const response = await service.update(id, body);
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: UPDATE_ORDER, payload: data });
      } else {
        dispatch({ type: UPDATE_ORDER_ERROR, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteOrder = (id) => {
  return async (dispatch) => {
    try {
      const response = await service.delete(id);

      if (response.status === 204) {
        dispatch(getOrders());
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getStatus = () => {
  return async (dispatch) => {
    try {
      const response = await service.status();
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_ORDER_STATUS, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getDeliveries = () => {
  return async (dispatch) => {
    try {
      const response = await service.deliveries();
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_ORDER_DELIVERIES, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const getRecurrences = () => {
  return async (dispatch) => {
    try {
      const response = await service.recurrences();
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_ORDER_RECURRENCES, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const addProduct = (orderId, body = {}) => {
  return async (dispatch) => {
    try {
      const response = await service.addProduct(orderId, body);
      const { status, data } = response;

      if (status === 201) {
        dispatch(getOrder(orderId));
      } else {
        dispatch({ type: ORDER_ADD_PRODUCT_ERROR, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};


export const updateOcurrencePo = (ocurrenceId, body) => async (dispatch) => {
  try {
    const response = await service.updateOcurrencePo(ocurrenceId, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};


export const updateProduct = (orderId, orderProductId, body) => {
  return async (dispatch) => {
    try {
      const response = await service.updateProduct(
        orderId,
        orderProductId,
        body
      );
      const { status, data } = response;

      if (status === 200) {
        dispatch(getOrder(orderId));
      } else {
        dispatch({ type: ORDER_UPDATE_PRODUCT_ERROR, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const removeProduct = (orderId, orderProductId) => {
  return async (dispatch) => {
    try {
      const response = await service.removeProduct(orderId, orderProductId);

      if (response.status === 204) {
        dispatch(getOrder(orderId));
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const addExpiration = (orderId, body) => {
  return async (dispatch) => {
    try {
      const response = await service.addExpiration(orderId, body);

      if (response.status === 201) {
        dispatch(getOrder(orderId));
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const addCallNote = (serviceId, body) => async (dispatch) => {
  try {
    const response = await service.addCallNote(serviceId, body);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCallNotes = (serviceId) => async (dispatch) => {
  try {
    const response = await service.getAllCallNotes(serviceId);
    if (response.status === 200) {
      dispatch({ type: GET_ALL_CALL_NOTES, payload: response.data });
    } else {
      dispatch({ type: GET_ALL_CALL_NOTES, payload: [] });
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getProductPriceVariants = (productId) => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING, loading: true });
    const response = await service.getProductPriceVariants(productId);
    dispatch({ type: GET_PRODUCT_PRICE_VARIANTS, payload: response.data });
    dispatch({ type: ON_LOADING, loading: false });
    return response;
  } catch (error) {
    dispatch({ type: ON_LOADING, loading: false });
    console.log(error);
  }
};

export const getProductOrderPrice = (orderProductId) => async (dispatch) => {
  try {
    dispatch({ type: ON_LOADING, loading: true });
    const response = await service.getOrderProductPrice(orderProductId);
    dispatch({ type: GET_ORDER_PRODUCT, payload: response.data });
    dispatch({ type: ON_LOADING, loading: false });
    return response;
  } catch (error) {
    dispatch({ type: ON_LOADING, loading: false });
    console.log(error);
  }
};


export const updateService = (serviceId, body) => {
  return async (dispatch) => {
    try {
      const response = await service.updateService(serviceId, body);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const cloneService = (body) => {
  return async (dispatch) => {
    try {
      const response = await service.cloneService(body);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteService = (serviceId) => {
  return async (dispatch) => {
    try {
      const response = await service.deleteService(serviceId);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const setPatientIdOrigin = (patientId) => ({
  type: ORDER_SET_PATIENT_ID_ORIGIN,
  payload: patientId,
});
