import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { FieldArray, Field, useFormikContext } from "formik";
import { Delete, Add } from "@material-ui/icons";
import {DialogDelete, PhoneMask} from "../index";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import _get from "lodash/get";

const useStyles = makeStyles((theme) =>
  createStyles({
    Root: {
      padding: theme.spacing(2),
      width: "100%",
    },
  })
);

function ContactList({}) {
  const {
    values: { contact_person = [], open = false , remove_id = null},

    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormikContext();

  const classes = useStyles();

  const handleDeleteContact = () => {
      contact_person.splice(remove_id, 1);
      setFieldValue("open", false)
      // contact_person[remove_id].remove()
  };

  const _handleAddBtnClick = () => {
    setFieldValue("contact_person", [
      ...contact_person,
      {
        contact_name: "",
        contact_number: "",
        contact_relation: "",
      },
    ]);
  };

  const _handleDeleteBtnClick = (value, remove_id) => {
      setFieldValue("open", value);
      setFieldValue("remove_id", remove_id);
  };

  return (
    <Paper classes={{ root: classes.Root }}>
      <Grid container justify="space-between">
          <DialogDelete
              item="contact"
              open={open}
              closeModal={() => _handleDeleteBtnClick(false)}
              remove={handleDeleteContact}
              isDelivery={false}
          />
        <Typography variant="h6">Contacts</Typography>
        <Grid item>
          <IconButton aria-label="delete" onClick={_handleAddBtnClick}>
            <Add />
          </IconButton>
        </Grid>
      </Grid>
      <FieldArray
        name="contact_person"
        render={({ move, swap, push, insert, unshift, remove }) => {
          console.log(contact_person);

          return contact_person.map((_contact, index) => (
            <Grid container spacing={4}>
              <Grid item xs={12} md={11} container spacing={4}>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={`contact_person[${index}].contact_name`}
                    value={contact_person[index].contact_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      !!_get(
                        touched,
                        `contact_person[${index}].contact_name`
                      ) &&
                      !!_get(errors, `contact_person[${index}].contact_name`)
                    }
                    helperText={
                      !!_get(
                        touched,
                        `contact_person[${index}].contact_name`
                      ) &&
                      !!_get(errors, `contact_person[${index}].contact_name`) &&
                      _get(errors, `contact_person[${index}].contact_name`)
                    }
                    label="Contact Name"
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={`contact_person[${index}].contact_number`}
                    value={contact_person[index].contact_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      !!_get(
                        touched,
                        `contact_person[${index}].contact_number`
                      ) &&
                      !!_get(errors, `contact_person[${index}].contact_number`)
                    }
                    helperText={
                      !!_get(
                        touched,
                        `contact_person[${index}].contact_number`
                      ) &&
                      !!_get(
                        errors,
                        `contact_person[${index}].contact_number`
                      ) &&
                      _get(errors, `contact_person[${index}].contact_number`)
                    }
                    label="Contact Number"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      inputComponent: PhoneMask,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    name={`contact_person[${index}].contact_relation`}
                    value={contact_person[index].contact_relation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      !!_get(
                        touched,
                        `contact_person[${index}].contact_relation`
                      ) &&
                      !!_get(
                        errors,
                        `contact_person[${index}].contact_relation`
                      )
                    }
                    helperText={
                      !!_get(
                        touched,
                        `contact_person[${index}].contact_relation`
                      ) &&
                      !!_get(
                        errors,
                        `contact_person[${index}].contact_relation`
                      ) &&
                      _get(errors, `contact_person[${index}].contact_relation`)
                    }
                    label="Contact Relation"
                    fullWidth
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid item xs={1} container alignItems="center" justify="center">
                <IconButton
                  aria-label="delete"
                  disabled={contact_person.length <= 1}
                  // onClick={() => {
                  //   if (contact_person.length > 1) remove(index);
                  // }}
                  onClick={() => {
                      _handleDeleteBtnClick(true, index);
                  }}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ));
        }}
      />
    </Paper>
  );
}

ContactList.propTypes = {};

ContactList.defaultProps = {};

export default ContactList;
