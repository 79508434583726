import PatientService from "../../services/PatientService";
import TagService from "../../services/TagService";
import {
  CREATE_PATIENT,
  CREATE_PATIENT_ERROR,
  GET_PATIENT,
  GET_PATIENT_TAGS,
  GET_PATIENT_TAGS_ERROR,
  GET_PATIENTS,
  GET_PATIENTS_ERROR,
  UPDATE_PATIENT,
  UPDATE_PATIENT_ERROR,
  STORE_PATIENT,
  SET_PATIENT_FILTERS,
  GET_RAW_PATIENTS,
  ON_LOADING,
  SET_RAW_PATIENT_FILTERS,
} from "../actionTypes";

const patientService = new PatientService();
const tagService = new TagService();

export const getPatients = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ON_LOADING, loading: true });
      const response = await patientService.list(getState().patients.filters);
      const { status, data } = response;

      if (status === 200) {
        const { results, ...meta } = data;

        dispatch({ type: GET_PATIENTS, payload: results });
        dispatch({
          type: SET_PATIENT_FILTERS,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      } else if (status === 422) {
        dispatch({ type: GET_PATIENTS_ERROR, payload: data });
      }
      dispatch({ type: ON_LOADING, loading: false });

      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const getPatientsRentals = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ON_LOADING, loading: true });
      const response = await patientService.listRentals(getState().patients.filters);
      const { status, data } = response;

      if (status === 200) {
        const { results, ...meta } = data;

        dispatch({ type: GET_PATIENTS, payload: results });
        dispatch({
          type: SET_PATIENT_FILTERS,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      } else if (status === 422) {
        dispatch({ type: GET_PATIENTS_ERROR, payload: data });
      }
      dispatch({ type: ON_LOADING, loading: false });

      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};



export const getRawPatients = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ON_LOADING, loading: true });
      const response = await patientService.rawList(
        getState().patients.rawFilters
      );

      const {
        data: { count, page_size, current, results = [] } = {},
        status,
      } = response;
      if (status === 200) {
        dispatch({ type: GET_RAW_PATIENTS, payload: results });
        dispatch({
          type: SET_PATIENT_FILTERS,
          payload: {
            // ...meta,
            count: count,
            page_size: page_size,
            page: current,
          },
        });
      } else if (status === 422) {
        dispatch({ type: GET_PATIENTS_ERROR, payload: response.data });
      }

      dispatch({ type: ON_LOADING, loading: false });

      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const setPatientFilters = (payload) => ({
  type: SET_PATIENT_FILTERS,
  payload,
});

export const setRawPatientFilters = (payload) => ({
  type: SET_RAW_PATIENT_FILTERS,
  payload,
});

export const createPatient = (form) => {
  return async (dispatch) => {
    try {
      const response = await patientService.create(form);
      const { status, data } = response;

      if (status === 201) {
        dispatch({ type: CREATE_PATIENT, payload: data });
      } else if (status === 422) {
        dispatch({ type: CREATE_PATIENT_ERROR, payload: data });
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getPatient = (id) => {
  return async (dispatch) => {
    try {
      const response = await patientService.get(id);
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_PATIENT, payload: data });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const updatePatient = (id, form) => {
  return async (dispatch) => {
    try {
      const response = await patientService.update(id, form);
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: UPDATE_PATIENT, payload: data });
      } else {
        dispatch({ type: UPDATE_PATIENT_ERROR, payload: data });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const deletePatient = (id) => {
  return async (dispatch) => {
    try {
      const response = await patientService.delete(id);
      const { status } = response;

      if (status === 204) {
        dispatch(getPatients());
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const deleteRentalsPatient = (id) => {
  return async (dispatch) => {
    try {
      const response = await patientService.delete(id);
      const { status } = response;

      if (status === 204) {
        dispatch(getPatientsRentals());
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTags = () => {
  return async (dispatch) => {
    try {
      const response = await tagService.list();
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: GET_PATIENT_TAGS, payload: data });
      } else {
        dispatch({ type: GET_PATIENT_TAGS_ERROR, payload: data });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const addTag = (patientId, tagId) => {
  return async (dispatch) => {
    try {
      const response = await patientService.addTag(patientId, tagId);

      if (response.status === 201) {
        dispatch(getPatient(patientId));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeTag = (patientId, tagId) => {
  return async (dispatch) => {
    try {
      const response = await patientService.removeTag(patientId, tagId);

      if (response.status === 204) {
        dispatch(getPatient(patientId));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const addDiagnosis = (patientId, text) => {
  return async (dispatch) => {
    try {
      return await patientService.addDiagnosis(patientId, text);
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeDiagnosis = (patientId, diagnosisId) => {
  return async (dispatch) => {
    try {
      return await patientService.removeDiagnosis(patientId, diagnosisId);
    } catch (error) {
      console.log(error);
    }
  };
};

export const addHCPCS = (patientId, text) => {
  return async (dispatch) => {
    try {
      return await patientService.addHCPCS(patientId, text);
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeHCPCS = (patientId, hcpcsId) => {
  return async (dispatch) => {
    try {
      return await patientService.removeHCPCS(patientId, hcpcsId);
    } catch (error) {
      console.log(error);
    }
  };
};

export const addModifier = (patientId, hcpcsId, payload) => {
  return async (dispatch) => {
    try {
      return await patientService.addModifier(patientId, hcpcsId, payload);
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeModifier = (patientId, hcpcsId, modifierId) => {
  return async (dispatch) => {
    try {
      return await patientService.removeModifier(
        patientId,
        hcpcsId,
        modifierId
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const addAttachment = (formData) => {
  return async (dispatch) => {
    try {
      const response = await patientService.addAttachment(formData);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeAttachment = (id) => {
  return async (dispatch) => {
    try {
      return await patientService.removeAttachment(id);
    } catch (error) {
      console.log(error);
    }
  };
};

export const addInsurance = (patientId, body) => {
  return async (dispatch) => {
    try {
      return await patientService.addInsurance(patientId, body);
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeInsurance = (patientId, insuranceId) => {
  return async (dispatch) => {
    try {
      return await patientService.removeInsurance(patientId, insuranceId);
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateInsurance = (patientId, insuranceId, body) => {
  return async (dispatch) => {
    try {
      return await patientService.updateInsurance(patientId, insuranceId, body);
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateHCPCS = (patientId, hcpcsId, body) => {
  return async (dispatch) => {
    try {
      return await patientService.updateHCPCS(patientId, hcpcsId, body);
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateDiagnostics = (patientId, diagnosticsId, body) => {
  return async (dispatch) => {
    try {
      return await patientService.updateDiagnostics(patientId, diagnosticsId, body);
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateModifier = (patientId, modifierId, body) => {
  return async (dispatch) => {
    try {
      return await patientService.updateModifier(patientId, modifierId, body);
    } catch (error) {
      console.log(error);
    }
  };
};

export const storePatient = (patient) => ({
  type: STORE_PATIENT,
  payload: {
    patient,
  },
});
