const styles = theme => ({
  root: {

  },
  paper: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  textField: {
    width: '100%',
  }

});

export default styles;