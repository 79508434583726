const styles = theme => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  }
});

export default styles;
