import React from "react";
import {
  Layout,
  SimpleBreadcrumbs,
  Panel,
  DialogDelete,
  AutocompleteV2,
} from "../../../components";
import moment from "moment";
import {
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Fab,
  withStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Collapse,
  InputAdornment, Checkbox, FormControlLabel, Box, Typography, TableSortLabel,
} from "@material-ui/core";
import {Delete, Edit, Add, ArrowBack, Today, CheckCircle, Cancel} from "@material-ui/icons";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Errors } from "../../../components";
import { compose } from "recompose";
import { withRouter, Prompt } from "react-router-dom";
import { withSnackbar } from "notistack";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import {
  updateOrder,
  getOrder,
  getDeliveries,
  getStatus,
  getRecurrences,
  addProduct,
  updateProduct,
  removeProduct,
  updateOcurrencePo,
  addExpiration,
  setPatientIdOrigin,
  getProductPriceVariants,
} from "../../../redux/actions/orderActions";
import {
  getRawPatients,
  setRawPatientFilters,
} from "../../../redux/actions/patientActions";
import {
  setLoading,
  setHandleForm,
  setCurrentForm,
} from "../../../redux/actions/globalActions";
import { getRawProducts } from "../../../redux/actions/productActions";
import styles from "./styles";
import ServicesTab from "./ServicesTab";
import ExpirationsTab from "./ExpirationsTab";
import OcurrencesTab from "./OcurrencesTab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { datePickerFormatToParseDate } from "./../../../common/Helpers/DateHelper";
import { Formik, Form, Field } from "formik";
import { ORDER_EDIT_PRODUCTS } from "../../../common/Constants/FormConstants";
import * as Yup from "yup";
import Select from "react-select";
import _debounce from "lodash/debounce";
import formatNumber, {formatNumberWithOutComma} from "../../../common/Helpers/FormatNumberHelper";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Orders", to: "/orders" },
  { name: "Edit Order", to: null },
];


function Row(data) {
  const { ocurrence, props } = data;
  const [open, setOpen] = React.useState(false);
  const [ocurrence_po, setOcurrencePo] = React.useState( ocurrence.po_number);

  async function updateOcurrencePo(){
    props.setLoading(true);
    try {
      const form = {
        po_number: ocurrence_po,
      };
      const response = await props.updateOcurrencePo(ocurrence.id, form);
      if (response.status === 200) {
        props.enqueueSnackbar("The Ocurrence Po was added successfully", {
          variant: "success",
        });
        // this.props.getAllCallNotes(this.state.ocurrence.id);
      } else {
        props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      props.setLoading(false);
    }
  }

  return (
      <React.Fragment>
        <TableRow key={ocurrence.id}>
          <TableCell component="th" scope="row">
            {moment(ocurrence.date).format("dddd, MMMM Do YYYY")}
          </TableCell>
          <TableCell>
            {formatNumber(
              +ocurrence.services
                  .map(
                      (_service) =>
                          +formatNumberWithOutComma(
                              (_service.added_percentage ? +_service.adjusted_sale_price : +_service.sale_price || 0) *
                              (+_service.amount_remmited || 0)
                          )
                  )
                  .reduce((total, current) => +total + +current, 0)
            )}
          </TableCell>
          <TableCell>
            {ocurrence.shipped ? (
                <CheckCircle style={{ color: "#06bb06" }} />
            ) : (
                <Cancel style={{ color: "#f50057" }} />
            )}
          </TableCell>
          <TableCell>
            <TextField
                name="ocurrence_po"
                margin="normal"
                value={ocurrence_po || ""}
                onChange={(e) => setOcurrencePo(e.target.value)}
                onBlur={updateOcurrencePo}
                label=""
                // autoFocus={true}
                // disabled={loading}
                rows={1}
            />
          </TableCell>
          <TableCell style={{textAlign: "center"}}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <ServicesTab services={ocurrence.services} ocurrence_id={ocurrence.id} loading={props.loading} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
  );
}


class OrderEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      id: props.match.params.id,
      tab: 0,
      showModal: false,
      modalType: "create",
      orderProductId: 0,
      openDelete: false,
      form: {
        patient_id: "",
        name: "",
        delivery_id: "",
        po_number: "",
        patient_notes: "",
        office_notes: "",
      },
      currentProductForm: {
        product_id: "",
        recurrence_id: "",
        quantity: "",
        locked_price: false,
        service_date: "",
        end_date: "",
      },
      sale_price: "",
      added_percentage: "",
      tax_applied: "",
      nutritional: "",
      percentage: "",
      adjusted_sale_price: "",
      errorServiceDate: false,
      errorEndDate: false,
    };
  }
  formik = React.createRef();

  componentDidMount = async () => {
    this.props.setLoading(true);

    try {
      const { status, data } = await this.props.getOrder(this.state.id);
      this.props.setRawPatientFilters({
        search: `${data.patient.first_name} ${data.patient.last_name}`,
        page: 1,
      });
      await this.props.getRawPatients();
      this.props.getStatus();
      this.props.getDeliveries();
      this.props.getRawProducts();
      this.props.getRecurrences();

      if (status === 200) {
        this.loadForm(data);
      } else {
        this.props.history.push("/404");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  componentWillUnmount() {
    this.props.setRawPatientFilters({
      search: "",
      page: 1,
    });
  }

  loadForm = (data) => {
    this.setState({
      form: {
        patient_id: data.patient_id || "",
        name: data.name || "",
        patient_notes: data.patient_notes || "",
        office_notes: data.office_notes || "",
        delivery_id: data.delivery_id || "",
        po_number: data.po_number || "",
      },
    });
  };

  fetchRawPatients = () => {
    return this.props.getRawPatients();
  };

  fetchRawPatientsDelayed = _debounce((e) => {
    return this.fetchRawPatients();
  }, 1000);

  handleSearch = (_, value, reason) => {
    if (reason === "input"){
      this.props.setRawPatientFilters({
        search: value,
        page: 1,
      });
      this.fetchRawPatientsDelayed.cancel();
      this.fetchRawPatientsDelayed();
    }
  };

  updateOrder = async (form) => {
    const { id } = this.state;
    this.props.setLoading(true);
    try {
      const { status, data } = await this.props.updateOrder(id, form);

      if (status === 200) {
        this.props.enqueueSnackbar("The order has been update!", {
          variant: "success",
        });
        this.loadForm(data);
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  addProduct = async (values) => {
    this.props.setLoading(true);

    try {
      const { id } = this.state;

      const { variant, ...body } = values;
      const productForm = {
        ...body,
        service_date: datePickerFormatToParseDate(values.service_date),
        end_date: datePickerFormatToParseDate(values.end_date),
        product_id: values.product_id.value,
        variant: variant.value,
        sale_price: this.state.sale_price,
        percentage: values.percentage,
        tax_applied: values.tax_applied,
        adjusted_sale_price: values.adjusted_sale_price,
        added_percentage: values.added_percentage,
        nutritional: values.nutritional,
      };
      const { status } = await this.props.addProduct(id, productForm);

      if (status === 201) {
        this.props.enqueueSnackbar("The product has been added!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.closeModal();
      this.props.setLoading(false);
    }
  };

  updateProduct = async (values) => {
    this.props.setLoading(true);

    try {
      const { orderProductId, id } = this.state;

      const { variant, ...body } = values;
      const productForm = {
        ...body,
        service_date: datePickerFormatToParseDate(values.service_date),
        end_date: datePickerFormatToParseDate(values.end_date),
        product_id: values.product_id.value,
        sale_price: this.state.sale_price || values.sale_price,
        percentage: values.percentage,
        adjusted_sale_price: values.adjusted_sale_price,
        added_percentage: values.added_percentage,
        nutritional: values.nutritional,
        tax_applied: values.tax_applied,
        locked_price: values.locked_price,
        variant: variant.value,
      };
      const { status } = await this.props.updateProduct(
        id,
        orderProductId,
        productForm
      );

      if (status === 200) {
        this.props.enqueueSnackbar("The product has been updated!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.closeModal();
      this.props.setLoading(false);
    }
  };

  handleSubmitModal = async ({ internal_code, ...values }, formikActions) => {
    const { setSubmitting } = formikActions;
    if (this.state.modalType === "create") {
      await this.addProduct(values);
    } else {
      await this.updateProduct(values);
    }
    setSubmitting(false);
  };

  handleDeleteProduct = async () => {
    this.props.setLoading(true);
    this.setState({ openDelete: false });
    try {
      const { id, orderProductId } = this.state;
      const response = await this.props.removeProduct(id, orderProductId);
      if (response.status === 204) {
        this.props.enqueueSnackbar("The product has been deleted!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar(response.data.error, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  handleTab = (event, tab) => {
    this.setState({ tab });
  };

  closeModal = () => {
    this.formik.current.resetForm();
    this.props.setCurrentForm(null);
    this.props.setHandleForm(false);
    this.setState({ showModal: false , sale_price: null});
  };

  openEditProduct = async (data) => {
    // const productFounded = this.props.products.find(
    //   (_product) => _product.id === data.product_id
    // );

    try {
      const { data: variants } = await this.props.getProductPriceVariants(
        data.product_id
      );
      const variantFounded = variants.find(
        (_variant) => _variant.id === data.variant
      );

      this.props.setCurrentForm(ORDER_EDIT_PRODUCTS);
      this.setState({
        errorServiceDate: false,
        errorEndDate: false,
        currentProductForm: {
          ...data,
          service_date: data.service_date ? moment(data.service_date) : "",
          end_date: data.end_date ? moment(data.end_date) : "",
          product_id: this.props.products
            .map((p) => {
              return {
                label: p.name,
                value: p.id,
                internal_code: p.internal_code,
              };
            })
            .find(({ value }) => value === data.product_id),
          internal_code: {
            label: data.product.internal_code,
            value: data.product.internal_code,
            product_id: data.product.id,
          },
          sale_price: data.sale_price,
          added_percentage: data.added_percentage,
          nutritional: data.nutritional,
          tax_applied: data.tax_applied,
          percentage: data.percentage,
          adjusted_sale_price: data.adjusted_sale_price,
          variant: variantFounded
            ? {
                label:
                  variants.filter(
                    (_variant) =>
                      _variant.price_variant === variantFounded.price_variant
                  ).length > 1
                    ? `${variantFounded.price_variant} - ${variantFounded.description}`
                    : variantFounded.price_variant,
                value: variantFounded.id,
              }
            : "",
        },
        orderProductId: data.id,
        modalType: "edit",
        showModal: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  openCreateProduct = () => {
    const { modalType, currentProductForm } = this.state;

    if (modalType === "edit") {
      Object.keys(currentProductForm).forEach(
        (key) => (currentProductForm[key] = "")
      );
    }
    this.props.setCurrentForm(ORDER_EDIT_PRODUCTS);
    this.setState({
      modalType: "create",
      currentProductForm: {
        added_percentage: false,
        nutritional: false,
        tax_applied: false,
        percentage: 0,
        adjusted_sale_price: 0,
        locked_price: false,
      },
      showModal: true,
      errorServiceDate: false,
      errorEndDate: false,
    });

  };

  setSalePriceVariant = (variant, setFieldValue, values) => {
    const sale_price = (this.props.priceVariants.find(
            (_priceVariant) =>
                _priceVariant.id === variant.value
        ) || {}
    ).sale_price;
    this.setState(
        { sale_price: sale_price}
    );
    setFieldValue("sale_price", sale_price)
    this.setPercentageAmountPrice(sale_price, setFieldValue, values)

  };

  handleSalePrice = event => {
    this.setState({ sale_price: event.target.value });
    // this.setState({ currentProductForm: {sale_price: event.target.value }});
  };

  handleAddExpiration = async (form) => {
    this.props.setLoading(true);

    try {
      const { id } = this.state;
      const response = await this.props.addExpiration(id, form);

      if (response.status === 201) {
        this.props.enqueueSnackbar("The expiration has been created!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
        });
      }
      return response;
    } catch (error) {
      return error;
      console.log(error);
    } finally {
      this.props.setLoading(false);
    }
  };

  getPatientName = (patient) => {
    return `${patient.first_name} ${patient.last_name}`;
  };

  setPercentageAmountPrice(value, setFieldValue, values) {
    if(values.added_percentage){
      const percentage = values.percentage || 0;
      const percentage_amount = parseFloat(value) + (parseFloat(value))*parseFloat(percentage)/100;
      const round_amount = Number((percentage_amount).toFixed(3))
      this.setState({adjusted_sale_price: round_amount})
      setFieldValue("adjusted_sale_price", round_amount)
      console.log(percentage_amount);
    }
  }

  setPercentageAmount (value, setFieldValue, values) {
    this.setState({ percentage: value })
    if(value ==="" || value === 0){
      setFieldValue("adjusted_sale_price", this.state.sale_price)
    }
    if(value >= 0){
      this.setState({'percentage': value});
      const sale_price = values.sale_price;
      const percentage_amount = parseFloat(sale_price) + (parseFloat(sale_price))*parseFloat(value)/100;
      const round_amount = Number((percentage_amount).toFixed(3))
      setFieldValue("adjusted_sale_price", round_amount)
    }
  }

  PaperComponent = (props) => {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
          <Paper {...props} />
        </Draggable>
    );
  }

  validateDate(currentDate, values, changeServiceDate, setFieldValue) {
    const { service_date, end_date, recurrence_id } = values;
    if (changeServiceDate) {
      if (end_date && currentDate.isSameOrAfter(end_date, "day")) {
        this.props.enqueueSnackbar(
          "The service date cannot be equal to or greater than end date!",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      }
      if (
        recurrence_id !== 3 &&
        end_date &&
        currentDate.isAfter(end_date.clone().subtract(30, "day"), "day")
      ) {
        this.props.enqueueSnackbar(
          "The service date must be at most 30 days before the end date!",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      }
      if (
        recurrence_id === 3 &&
        end_date &&
        currentDate.isAfter(end_date.clone().subtract(15, "day"), "day")
      ) {
        this.props.enqueueSnackbar(
          "The service date must be at most 15 days before the end date!",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      }
      setFieldValue("service_date", currentDate);
      return;
    }
    if (service_date && currentDate.isSameOrBefore(service_date, "day")) {
      this.props.enqueueSnackbar(
        "The end date cannot be equal to or less than service date!",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    }
    if (
      recurrence_id !== 3 &&
      service_date &&
      currentDate.isBefore(service_date.clone().add(30, "day"), "day")
    ) {
      this.props.enqueueSnackbar(
        "The end date must be at least 30 days after the service date!",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    }
    if (
      recurrence_id === 3 &&
      service_date &&
      currentDate.isBefore(service_date.clone().add(15, "day"), "day")
    ) {
      this.props.enqueueSnackbar(
        "The service date must be at least 15 days after the service date!",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    }
    setFieldValue("end_date", currentDate);
    return;
  }

  render() {
    const {
      form,
      currentProductForm,
      tab,
      showModal,
      modalType,
      openDelete,
    } = this.state;
    const {
      classes,
      loading,
      order,
      rawPatients,
      deliveries,
      products,
      recurrences,
      patientIdOrigin,
    } = this.props;
    return (
      <Layout title="Edit Order">
        <DialogDelete
          item="product"
          open={openDelete}
          closeModal={() => this.setState({ openDelete: false })}
          remove={this.handleDeleteProduct}
          isDelivery={false}
        />
        <SimpleBreadcrumbs routes={breadcrumbs} />
        {patientIdOrigin ? (
          <Grid container justify="flex-end">
            <Fab
              variant="extended"
              aria-label="Back"
              color="primary"
              className={classes.buttonBack}
              onClick={() =>
                this.props.history.push(`/patients/${patientIdOrigin}`)
              }
            >
              <ArrowBack />
              Back to patient
            </Fab>
          </Grid>
        ) : null}
        <Panel>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} container alignItems="center">
              <AutocompleteV2
                loading={loading}
                options={rawPatients.map((_patient) => ({
                  ..._patient,
                  full_name: `${_patient.first_name} ${_patient.last_name}`,
                }))}
                extractOptionLabel="full_name"
                extractOptionValue="id"
                textFieldProps={{
                  label: "Patient",
                  helperText:
                    !loading && !form.patient_id && "Patient is required",
                  error: !loading && !form.patient_id,
                  required: true,
                }}
                onInputChange={this.handleSearch}
                value={form.patient_id}
                disabled={loading || !!patientIdOrigin}
                onChange={(newValue) => {
                  console.log(newValue);
                  this.setState((prevState) => {
                    return {
                      form: {
                        ...prevState.form,
                        patient_id: newValue,
                      },
                    };
                  });
                  if (newValue) {
                    this.updateOrder({ ...form, patient_id: newValue });
                  }
                }}
                // onChange={(newValue) => setFieldValue(field.name, newValue)}
              />

              {/* <TextField
                name="patient_id"
                value={form.patient_id}
                onChange={(e) => {
                  const value = e.target.value;
                  this.setState((prevState) => {
                    return {
                      form: {
                        ...prevState.form,
                        patient_id: value,
                      },
                    };
                  });
                  this.updateOrder({ ...form, patient_id: value });
                }}
                label="Patient"
                fullWidth
                select
                margin="normal"
                disabled={loading || !!patientIdOrigin}
                required
              >
                {rawPatients.map((patient) => {
                  return (
                    <MenuItem key={patient.id} value={patient.id}>
                      {this.getPatientName(patient)}
                    </MenuItem>
                  );
                })}
              </TextField> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={form.name}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    this.setState({
                      form: { ...this.state.form, name: e.target.value },
                    });
                  }
                }}
                onBlur={(e) =>
                  e.target.value !== ""
                    ? this.updateOrder({ ...form, name: e.target.value })
                    : null
                }
                label="Order Name"
                fullWidth
                margin="normal"
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={form.delivery_id}
                onChange={(e) => {
                  const value = e.target.value;
                  this.setState((prevState) => {
                    return {
                      form: {
                        ...prevState.form,
                        delivery_id: value,
                      },
                    };
                  });
                  this.updateOrder({ ...form, delivery_id: value });
                }}
                label="Delivery"
                fullWidth
                margin="normal"
                required
                select
                disabled={loading}
              >
                {deliveries.map((delivery) => {
                  return (
                    <MenuItem key={delivery.id} value={delivery.id}>
                      {delivery.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={form.po_number}
                onChange={(e) => {
                  this.setState({
                    form: { ...this.state.form, po_number: e.target.value },
                  });
                }}
                onBlur={(e) =>
                  this.updateOrder({ ...form, po_number: e.target.value })
                }
                label="PO Number"
                fullWidth
                margin="normal"
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Panel>
        <br />
        <Panel>
          <Tabs
            value={tab}
            onChange={this.handleTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Products" />
            <Tab label="Services w Totals" />
            <Tab label="Notes" />
            <Tab label="Expirations" />
            <Tab label="Services" />
          </Tabs>
          <div style={{ overflowX: "auto" }}>
            {tab === 0 && (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell align="right">Reference Number</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Unit of Measure</TableCell>
                      <TableCell align="right">Service Date</TableCell>
                      <TableCell align="right">End Date</TableCell>
                      <TableCell align="right">Recurrence</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.order_products.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          {item.product ? item.name_product : ""}
                        </TableCell>
                        <TableCell align="right">
                          {item.reference_product}
                        </TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">{item.unit_measure}</TableCell>
                        <TableCell align="right">
                          {moment(item.service_date).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          {moment(item.end_date).format("MM/DD/YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          {item.recurrence ? item.recurrence.name : ""}
                        </TableCell>
                        <TableCell>
                          <Grid container justify="center" wrap="nowrap">
                            <IconButton
                              disabled={loading}
                              onClick={() => this.openEditProduct(item)}
                            >
                              <Edit color="primary" />
                            </IconButton>
                            <IconButton
                              disabled={loading}
                              onClick={() =>
                                this.setState({
                                  openDelete: true,
                                  orderProductId: item.id,
                                })
                              }
                            >
                              <Delete color="secondary" />
                            </IconButton>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}

            {tab === 1 && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel>
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>
                        Total
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>
                        Shipped
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel>
                        Ocurrence PO
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.ocurrences.map((ocurrence) => (
                      <Row key={ocurrence.id} ocurrence={ocurrence} props={this.props}/>
                  ))}
                </TableBody>
              </Table>

            )}
            {tab === 2 && (
              <Formik
                onSubmit={(values, formikActions) =>
                  this.updateOrder({ ...form, ...values })
                }
                initialValues={{
                  ...form,
                }}
                enableReinitialize
              >
                {(props) => {
                  const {
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    dirty,
                  } = props;
                  return (
                    <div>
                      <Prompt
                        when={dirty}
                        message="Are you sure you want to leave?, You will lose your changes in notes tab"
                      />

                      <Grid
                        container
                        spacing={2}
                        style={{ margin: 0, width: "100%" }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="office_notes"
                            margin="normal"
                            value={values.office_notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Office Notes"
                            fullWidth
                            multiline
                            rows={15}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            name="patient_notes"
                            margin="normal"
                            value={values.patient_notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label="Patients Notes"
                            fullWidth
                            multiline
                            rows={15}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={loading}
                          >
                            Save Notes
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }}
              </Formik>
            )}
            {tab === 3 && (
              <ExpirationsTab
                expirations={order.expirations}
                // addExpiration={this.handleAddExpiration}
                loading={loading}
              />
            )}
            {tab === 4 && (
                <ServicesTab services={order.services} loading={loading} />
            )}
            {tab === 0 && (
              <Fab
                className={classes.fab}
                color="primary"
                onClick={this.openCreateProduct}
              >
                <Add />
              </Fab>
            )}
          </div>
        </Panel>

        <Formik
          onSubmit={this.handleSubmitModal}
          initialValues={{
            product_id: currentProductForm.product_id,
            recurrence_id: currentProductForm.recurrence_id,
            quantity: currentProductForm.quantity,
            service_date: currentProductForm.service_date,
            end_date: currentProductForm.end_date,
            locked_price: currentProductForm.locked_price,
            sale_price: currentProductForm.sale_price,
            added_percentage: currentProductForm.added_percentage,
            tax_applied: currentProductForm.tax_applied,
            percentage: currentProductForm.percentage,
            nutritional: currentProductForm.nutritional,
            adjusted_sale_price: currentProductForm.adjusted_sale_price,
            internal_code: currentProductForm.internal_code || "",
            variant: currentProductForm.variant || "",
            // "unit_measure-clien_side": "",
          }}
          enableReinitialize
          innerRef={this.formik}
          validationSchema={Yup.object().shape({
            product_id: Yup.mixed().required("Product is required"),
            variant: Yup.mixed().required("Price variant is required"),
            recurrence_id: Yup.mixed().required("Recurrence is required"),
            quantity: Yup.number().required("Quantity is required"),
            sale_price: Yup.number().required("Sale Price is required"),
            added_percentage: Yup.boolean(),
            nutritional: Yup.boolean(),
            tax_applied: Yup.boolean(),
            percentage: Yup.number().when('added_percentage', {
              is: true,//just an e.g. you can return a function
              then: Yup.number().required('Percentage is required'),
              otherwise: Yup.number()
            }),
            service_date: Yup.date().required("Service date is required"),
            end_date: Yup.date().required("End date is required"),
          })}
        >
          {(props) => {
            const {
              errors,
              values,
              setFieldValue,
              setFieldTouched,
              touched,
              isSubmitting,
              dirty,
              isValid,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;

            return (
              <Form>
                <Prompt
                  when={dirty}
                  message="Are you sure you want to leave?, You will lose your changes"
                />
                <Dialog
                  open={showModal}
                  PaperComponent={this.PaperComponent}
                  onClose={this.closeModal}
                  aria-labelledby="draggable-dialog-title"
                  maxWidth="sm"
                  fullWidth
                >
                  <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                    {modalType === "create" ? "Add" : "Edit"} product
                  </DialogTitle>

                  <DialogContent >
                    <Grid container>
                      <Select
                        className={classes.select}
                        classNamePrefix="react-select"
                        name="product_id"
                        isDisabled={loading}
                        isSearchable={true}
                        placeholder="Product *"
                        value={values.product_id}
                        onChange={async (value) => {
                          setFieldValue("product_id", value);
                          setFieldValue("internal_code", {
                            label: value.internal_code,
                            value: value.internal_code,
                            product_id: value.value,
                          });
                          setFieldValue("variant", "");
                          try {
                            this.props.getProductPriceVariants(value.value);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        options={products.map((p) => {
                          return {
                            label: p.name,
                            value: p.id,
                            internal_code: p.internal_code,
                          };
                        })}
                      />
                    </Grid>
                    <Grid container>
                      <Select
                        className={classes.select}
                        classNamePrefix="react-select"
                        name="internal_code"
                        isDisabled={loading}
                        isSearchable={true}
                        placeholder="Reference number *"
                        value={values.internal_code}
                        onChange={(value) => {
                          const productById =
                            products.find(
                              (_product) => _product.id === value.product_id
                            ) || {};
                          setFieldValue("product_id", {
                            label: productById.name,
                            value: productById.id,
                            internal_code: productById.internal_code,
                          });
                          setFieldValue("internal_code", value);
                          setFieldValue("variant", "");
                          try {
                            this.props.getProductPriceVariants(productById.id);
                          } catch (error) {
                            console.log(error);
                          }
                        }}
                        options={products.map((p) => {
                          return {
                            label: p.internal_code,
                            value: p.internal_code,
                            product_id: p.id,
                          };
                        })}
                      />
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} container>
                        <Select
                          className={classes.select}
                          classNamePrefix="react-select"
                          name="variant"
                          isDisabled={loading}
                          isSearchable={true}
                          placeholder="Variant *"
                          value={values.variant}
                          onBlur={handleBlur}
                          onChange={(value) => {
                            setFieldValue("variant", value);
                            setFieldTouched("variant", true, true);
                            setFieldValue("sale_price", value.sale_price);
                            this.setSalePriceVariant(value, setFieldValue, values);
                          }}
                          options={this.props.priceVariants.map((variant) => {
                            return {
                              label:
                                this.props.priceVariants.filter(
                                  (_variant) =>
                                    _variant.price_variant ===
                                    variant.price_variant
                                ).length > 1
                                  ? `${variant.price_variant} - ${variant.description}`
                                  : `${variant.price_variant}`,
                              value: variant.id,
                            };
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="unit_measure"
                          margin="normal"
                          value={
                            values.variant && values.variant.value
                              ? (
                                  this.props.priceVariants.find(
                                    (_priceVariant) =>
                                      _priceVariant.id === values.variant.value
                                  ) || {}
                                ).unit_measure
                              : ""
                          }
                          disabled
                          label="Unit of Measure"
                          fullWidth
                          InputProps={{ min: 1 }}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="recurrence_id"
                          margin="normal"
                          value={values.recurrence_id}
                          error={
                            !!touched.recurrence_id && !!errors.recurrence_id
                          }
                          helperText={
                            !!touched.recurrence_id &&
                            !!errors.recurrence_id &&
                            errors.recurrence_id
                          }
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          label="Recurrence"
                          fullWidth
                          select
                          required
                        >
                          {recurrences.map((recurrence) => {
                            return (
                              <MenuItem
                                key={recurrence.id}
                                value={recurrence.id}
                              >
                                {recurrence.name}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <TextField
                          name="quantity"
                          margin="normal"
                          value={values.quantity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!touched.quantity && !!errors.quantity}
                          helperText={
                            !!touched.quantity &&
                            !!errors.quantity &&
                            errors.quantity
                          }
                          label="Quantity"
                          fullWidth
                          type="number"
                          InputProps={{ min: 1 }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          name="service_date"
                          label="Service Date"
                          value={values.service_date || null}
                          invalidLabel={""}
                          margin="normal"
                          disabled={loading || values.recurrence_id === ""}
                          format={"MM/DD/YYYY"}
                          onChange={(date) => {
                            if (date && date.isValid()) {
                              this.validateDate(
                                date,
                                values,
                                true,
                                setFieldValue
                              );
                            } else {
                              setFieldValue("service_date", date);
                            }
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          animateYearScrolling
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <Today />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="MM/DD/YYYY"
                          required
                        />
                        <KeyboardDatePicker
                          name="end_date"
                          label="End Date"
                          value={values.end_date || null}
                          invalidLabel={""}
                          margin="normal"
                          format={"MM/DD/YYYY"}
                          onChange={(date) => {
                            if (date && date.isValid()) {
                              this.validateDate(
                                date,
                                values,
                                false,
                                setFieldValue
                              );
                            } else {
                              setFieldValue("end_date", date);
                            }
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          disabled={loading || values.recurrence_id === ""}
                          animateYearScrolling
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <Today />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="MM/DD/YYYY"
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} container>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  name="nutritional"
                                  checked={values.nutritional}
                                  onChange={(e) => {
                                    setFieldValue("nutritional", e.target.checked);
                                    this.setState({nutritional: !this.state.nutritional})
                                  }}
                                  onBlur={handleBlur}
                                  color="primary"
                              />
                            }
                            label="Nutritional"
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} container>
                        <TextField
                            name="sale_price"
                            margin="normal"
                            value={
                              this.state.sale_price || values.sale_price
                            }
                            onChange={(e) => {
                              // this.setState({ sale_price: e.target.value };
                              setFieldValue('sale_price', e.target.value);
                              this.setState({'sale_price': e.target.value});
                              this.setPercentageAmountPrice(e.target.value, setFieldValue, values);
                            }}
                            defaultValue={0.00}

                            onBlur={handleBlur}
                            error={!!touched.sale_price && !!errors.sale_price}
                            helperText={
                              !!touched.sale_price &&
                              !!errors.sale_price &&
                              errors.sale_price
                            }
                            label="Sale Price"
                            fullWidth
                            type="number"
                            InputProps={{ min: 1 }}
                            required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} container>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  name="added_percentage"
                                  checked={values.added_percentage}
                                  onChange={(e) => {
                                    setFieldValue("added_percentage", e.target.checked);
                                    this.setState({added_percentage: !this.state.added_percentage})
                                  }}
                                  onBlur={handleBlur}
                                  color="primary"
                              />
                            }
                            label="Add Percentage to Order"
                        />
                      </Grid>
                      { values.added_percentage  ? (
                          <>
                            <Grid item xs={12} sm={6} container>
                              <TextField
                                  name="percentage"
                                  margin="normal"
                                  value={
                                    values.percentage
                                  }
                                  onChange={(e) => {
                                    setFieldValue('percentage', e.target.value)
                                    this.setState({percentage: e.target.value})
                                    this.setPercentageAmount(
                                        e.target.value,
                                        setFieldValue,
                                        values,
                                    );
                                  }}
                                  defaultValue={0.00}

                                  onBlur={handleBlur}
                                  error={!!touched.percentage && !!errors.percentage}
                                  helperText={
                                    !!touched.percentage &&
                                    !!errors.percentage &&
                                    errors.percentage
                                  }
                                  label="Percentage"
                                  fullWidth
                                  type="number"
                                  InputProps={{ min: 1 }}
                                  required
                              />
                              <TextField
                                name="adjusted_sale_price"
                                margin="normal"
                                value={
                                   values.adjusted_sale_price
                                }
                                onChange={(e) => {
                                // this.setState({ sale_price: e.target.value };
                                setFieldValue('adjusted_sale_price', e.target.value);
                                // this.setState({'adjusted_sale_price': e.target.value})
                                }}
                                defaultValue={0.00}

                                onBlur={handleBlur}
                                label="Adjusted Sale Price"
                                fullWidth
                                type="number"
                                InputProps={{ min: 1 }}
                                disabled
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} container>
                              </Grid>
                          </>
                      ) : null }

                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  name="locked_price"
                                  checked={values.locked_price}
                                  onChange={(e) => {
                                    setFieldValue("locked_price", e.target.checked);
                                  }}


                                  onBlur={handleBlur}
                                  color="primary"
                              />
                            }
                            label="Locked Price"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  name="tax_applied"
                                  checked={values.tax_applied}
                                  onChange={(e) => {
                                    setFieldValue("tax_applied", e.target.checked);
                                    this.setState({tax_applied: !this.state.tax_applied})
                                  }}
                                  onBlur={handleBlur}
                                  color="primary"
                              />
                            }
                            label="Add Tax"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeModal}
                      color="primary"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      color="primary"
                      disabled={
                        modalType === "create"
                          ? loading || !isValid || !dirty || isSubmitting
                          : loading ||
                            isSubmitting ||
                            (isValid && !dirty) ||
                            (!isValid && dirty)
                      }
                    >
                      {modalType === "create" ? "Save" : "Update"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    currentForm: state.global.currentForm,
    order: state.orders.order,
    rawPatients: state.patients.rawList,
    status: state.orders.status,
    deliveries: state.orders.deliveries,
    recurrences: state.orders.recurrences,
    patientIdOrigin: state.orders.patientIdOrigin,
    products: state.products.listRaw,
    priceVariants: state.orders.productPriceVariants,
    errors: new Errors(state.orders.errors),
  };
};

const mapDispatchToProps = {
  setLoading,
  getOrder,
  updateOcurrencePo,
  updateOrder,
  getRawPatients,
  setRawPatientFilters,
  getStatus,
  getDeliveries,
  getRawProducts,
  getRecurrences,
  setPatientIdOrigin,
  addProduct,
  removeProduct,
  updateProduct,
  addExpiration,
  setHandleForm,
  setCurrentForm,
  getProductPriceVariants,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "OrderEdit" }),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderEdit);
