import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  GET_PRODUCT,
  GET_CATEGORIES_LEVEL_1,
  GET_CATEGORIES_LEVEL_2,
  GET_CATEGORIES_LEVEL_3,
  GET_PRODUCT_TYPES,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
  DELETE_PRODUCT,
  SET_PRODUCTS_FILTERS,
  GET_PRODUCTS_RAW,
} from "../actionTypes";

const defaultState = {
  list: [],
  listRaw: [],
  product: {
    id: 0,
  },
  types: [],
  categories_1: [],
  categories_2: [],
  categories_3: [],
  errors: {},

  filters: {
    search: "",
    ordering: "",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_LEVEL_1:
      return {
        ...state,
        categories_1: action.payload,
      };

    case GET_CATEGORIES_LEVEL_2:
      return {
        ...state,
        categories_2: action.payload,
      };

    case GET_CATEGORIES_LEVEL_3:
      return {
        ...state,
        categories_3: action.payload,
      };

    case GET_PRODUCT_TYPES:
      return {
        ...state,
        types: action.payload,
      };

    case GET_PRODUCTS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_PRODUCTS_RAW:
      return {
        ...state,
        listRaw: action.payload,
      };

    case SET_PRODUCTS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        list: [],
      };

    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };

    case CREATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        errors: {},
      };

    case CREATE_PRODUCT_ERROR:
      return {
        ...state,
        errors: action.payload,
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        errors: {},
      };

    case UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        errors: action.payload,
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        list: state.list.filter(({ id }) => id !== action.payload),
      };

    default:
      return state;
  }
};
