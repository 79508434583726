import InvoiceService from "../../services/InvoiceService";
import {
  GET_INVOICES,
  UPDATE_INVOICE,
  UPDATE_WRITE_OFF_INVOICE,
  GET_INVOICE,
  SET_INVOICE_FILTERS,
  ON_LOADING,
} from "../actionTypes";

const invoiceService = new InvoiceService();
export const getInvoices = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ON_LOADING, loading: true });
    const response = await invoiceService.getAll(getState().invoice.filters);
    const { status, data } = response;

    if (status === 200) {
      const { results, ...meta } = data;

      dispatch({
        type: GET_INVOICES,
        payload: results,
      });
      dispatch({
        type: SET_INVOICE_FILTERS,
        payload: {
          // ...meta,
          count: meta.count,
          page_size: meta.page_size,
          page: meta.current,
        },
      });
    }

    dispatch({ type: ON_LOADING, loading: false });
    return response;
  } catch (error) {
    dispatch({ type: ON_LOADING, loading: false });
    console.log(error);
  }
};

export const setInvoiceFilters = (payload) => ({
  type: SET_INVOICE_FILTERS,
  payload,
});

export const getInvoice = (invoiceId) => async (dispatch) => {
  try {
    const response = await invoiceService.getInvoice(invoiceId);
    dispatch({
      type: GET_INVOICE,
      payload: response.data,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const updateInvoice = (id, body) => async (dispatch) => {
  try {
    const response = await invoiceService.updateInvoice(id, body);
    dispatch({
      type: UPDATE_INVOICE,
      payload: response.data,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const writeOffServiceInvoice = (body) => async (dispatch) => {
    try {
        const response = await invoiceService.updateServiceWriteOff(body);
        dispatch({
            type: UPDATE_WRITE_OFF_INVOICE,
            payload: response.data,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const printInvoice = (invoiceId) => async (dispatch) => {
  try {
    const response = await invoiceService.printInvoice(invoiceId);
    return response;
  } catch (error) {
    return error;
  }
};
