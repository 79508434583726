import React from "react";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Checkbox,
  withStyles,
  IconButton,
  InputAdornment,
  Switch,
} from "@material-ui/core";
import TabContainer from "../../../components/TabContainer";
import { Add, CheckCircle, Cancel, Today } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Delete, Edit } from "@material-ui/icons";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter, Prompt } from "react-router-dom";

import styles from "./styles";
import { compose } from "recompose";
import { withSnackbar } from "notistack";
import {
  setLoading,
  setHandleForm,
  setCurrentForm,
} from "../../../redux/actions/globalActions";
import {
  deleteExpiration,
  updateExpiration,
} from "./../../../redux/actions/expirationActions";
import { getOrder, addExpiration } from "./../../../redux/actions/orderActions";
import { ORDER_EDIT_EXPIRATIONS } from "../../../common/Constants/FormConstants";
import { DialogDelete } from "../../../components";
import * as Yup from "yup";

const typeDialog = {
  CREATE: "create",
  UPDATE: "update",
};
class ExpirationsTab extends React.Component {
  state = {
    showDialog: false,
    openDelete: false,
    typeDialog: typeDialog.CREATE,
    expiration: { yellow: false },
  };
  componentDidMount() {}

  closeDialog = (handleReset) => () => {
    handleReset();
    this.props.setCurrentForm(null);
    this.props.setHandleForm(false);
    this.setState({ showDialog: false });
  };

  openDialog = (
    typeDialog,
    expiration = {
      date: null,
      waiver: false,
      note: "",
      yellow: false,
      yellow_date: null,
    }
  ) => () => {
    this.props.setCurrentForm(ORDER_EDIT_EXPIRATIONS);
    this.setState({
      showDialog: true,
      typeDialog,
      expiration: {
        ...expiration,
        date:
          typeDialog === "update" && expiration.date
            ? moment(expiration.date)
            : expiration.date,
        yellow_date:
          typeDialog === "update" && expiration.yellow_date
            ? moment(expiration.yellow_date)
            : expiration.yellow_date,
      },
    });
  };

  handleSubmit = async (values, formikActions) => {
    const { setSubmitting } = formikActions;
    const { typeDialog } = this.state;
    this.props.setLoading(true);
    try {
      const { yellow = false, waiver, note } = values;
      const form = {
        date:
          typeDialog === "create"
            ? values.date.format("YYYY-MM-DD")
            : moment(values.date).format("YYYY-MM-DD"),
        yellow,
        waiver,
        note,
      };
      if (form.yellow) {
        form.yellow_date =
          typeDialog === "create"
            ? values.yellow_date.format("YYYY-MM-DD")
            : moment(values.yellow_date).format("YYYY-MM-DD");
      } else {
        form.yellow_date = null;
      }
      let response = "";
      let message = "";
      if (typeDialog === "create") {
        const orderId = this.props.match.params.id;
        response = await this.props.addExpiration(orderId, form);
        message = "The expiration was added succesfully!";
      } else {
        response = await this.props.updateExpiration(values.id, form);
        message = "The expiration was updated succesfully!";
      }
      console.log(typeDialog);
      console.log(response);
      if (response.status === 200) {
        this.props.enqueueSnackbar(message, {
          variant: "success",
        });
        this.props.getOrder(values.order_id);
      }
    } catch (error) {
      this.props.enqueueSnackbar("The request could not be processed!", {
        variant: "error",
      });
      console.error(error);
    }
    this.props.setLoading(false);
    setSubmitting(false);
    this.closeDialog(formikActions.resetForm)();
  };

  handleDelete = async () => {
    this.setState({ openDelete: false });
    this.props.setLoading(true);
    try {
      /**if error verify here */
      const { expiration } = this.state;
      const response = await this.props.deleteExpiration(expiration.id);
      if (response.status === 204) {
        this.props.getOrder(expiration.order_id);
        this.props.enqueueSnackbar("Expiration deleted successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      } else {
        this.props.enqueueSnackbar("The request could not be processed!", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
      }
    } catch (error) {
      console.error(error);
    }
    this.props.setLoading(false);

    this.closeDialog();
  };

  render() {
    const { showDialog, openDelete, typeDialog } = this.state;
    const { classes, expirations, loading } = this.props;
    return (
      <TabContainer>
        <DialogDelete
          item="expiration"
          open={openDelete}
          closeModal={() => this.setState({ openDelete: false })}
          remove={this.handleDelete}
          isDelivery={false}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Yellow</TableCell>
              <TableCell>Yellow Date</TableCell>
              <TableCell>Waiver</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expirations.map((expiration) => (
              <TableRow key={expiration.id}>
                <TableCell>
                  {moment(expiration.date).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {expiration.note}
                </TableCell>
                <TableCell>{expiration.yellow ? "Yes" : "No"}</TableCell>
                <TableCell>
                  {expiration.yellow_date
                    ? moment(expiration.yellow_date).format("MM/DD/YYYY")
                    : "-"}
                </TableCell>
                <TableCell>
                  {expiration.waiver ? (
                    <CheckCircle style={{ color: "#06bb06" }} />
                  ) : (
                    <Cancel style={{ color: "#f50057" }} />
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    disabled={loading}
                    onClick={this.openDialog("update", expiration)}
                  >
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton
                    disabled={loading}
                    onClick={() =>
                      this.setState({ openDelete: true, expiration })
                    }
                  >
                    <Delete color="secondary" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Formik
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            date: Yup.date().required("Date is required"),
            yellow: Yup.mixed(),
            waiver: Yup.boolean(),
            yellow_date: Yup.mixed().when("yellow", (yellow, schema) =>
              Boolean(yellow)
                ? schema.required("Yellow date is required")
                : schema
            ),
            note: Yup.string().required("Note is required"),
          })}
          initialValues={{ ...this.state.expiration }}
          enableReinitialize
        >
          {(props) => {
            const {
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              isValid,
              dirty,
              errors,
              touched,
              values,
              handleBlur,
              handleChange,
              handleReset,
              handleSubmit,
            } = props;

            return (
              <Form>
                <Prompt
                  when={dirty}
                  message="Are you sure you want to leave?, You will lose your changes"
                />
                <Dialog
                  open={showDialog}
                  onClose={this.closeDialog(handleReset)}
                  aria-labelledby="form-dialog-title"
                  maxWidth="xs"
                  fullWidth
                >
                  <DialogTitle id="form-dialog-title">
                    {typeDialog === "create" ? "New" : "Edit"} Expiration
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          name="date"
                          label="Date"
                          value={values.date || null}
                          invalidLabel={""}
                          margin="normal"
                          format={"MM/DD/YYYY"}
                          onChange={(date) => {
                            setFieldValue("date", date);
                          }}
                          required
                          onBlur={handleBlur}
                          fullWidth
                          animateYearScrolling
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <Today />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="MM/DD/YYYY"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                          name="yellow"
                          control={
                            <Switch
                              checked={values.yellow}
                              onChange={(e) => {
                                setFieldValue("yellow", e.target.checked);
                                if (!e.target.checked) {
                                  setFieldValue("yellow_date", null, true);
                                  setTimeout(
                                    () => setFieldTouched("yellow"),
                                    250
                                  );
                                }
                              }}
                              onBlur={handleBlur}
                              value="1"
                              color="primary"
                            />
                          }
                          label="Yellow"
                        />
                      </Grid>
                      {values.yellow ? (
                        <>
                          <Grid item xs={12} sm={6} />
                          <Grid item xs={12} sm={6}>
                            <KeyboardDatePicker
                              name="yellow_date"
                              label="Yellow Date"
                              value={values.yellow_date || null}
                              invalidLabel={""}
                              margin="normal"
                              format={"MM/DD/YYYY"}
                              required
                              onChange={(date) => {
                                setFieldValue("yellow_date", date);
                              }}
                              onBlur={handleBlur}
                              fullWidth
                              animateYearScrolling
                              InputLabelProps={{
                                shrink: true,
                              }}
                              placeholder="MM/DD/YYYY"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton aria-label="calendar">
                                      <Today />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </>
                      ) : null}
                    </Grid>

                    <TextField
                      name="note"
                      margin="normal"
                      value={values.note}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!touched.note && !!errors.note}
                      helperText={
                        !!touched.note && !!errors.note && errors.note
                      }
                      label="Note"
                      required
                      fullWidth
                      multiline
                      rows={2}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="waiver"
                          checked={values.waiver}
                          onChange={(e) => {
                            setFieldValue("waiver", e.target.checked);
                          }}
                          onBlur={handleBlur}
                          color="primary"
                        />
                      }
                      label="Waiver"
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeDialog(handleReset)}
                      color="primary"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={
                        typeDialog === "create"
                          ? loading || isSubmitting || !dirty || !isValid
                          : loading ||
                            isSubmitting ||
                            (isValid && !dirty) ||
                            (!isValid && dirty)
                      }
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      {typeDialog === "create" ? "Save" : "Update"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            );
          }}
        </Formik>
        <Fab
          className={classes.fab}
          color="secondary"
          onClick={this.openDialog("create")}
        >
          <Add />
        </Fab>
      </TabContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    handleForm: state.global.handleForm,
    currentForm: state.global.currentForm,
  };
};
const mapDispatchToProps = {
  setLoading,
  setHandleForm,
  setCurrentForm,
  updateExpiration,
  addExpiration,
  deleteExpiration,
  getOrder,
};

export default compose(
  withRouter,
  withSnackbar,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { name: "ExpirationTab" })
)(ExpirationsTab);
