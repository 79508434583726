import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

const theme = createMuiTheme({
  typography: {
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    green: {
      main: "#67c23a",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 14,
      },
    },
    MuiTableCell: {
      root: {
        padding: "5px 20px",
        fontSize: 13,
      },
      head: {
        color: "gray",
        padding: "10px 20px",
        lineHeight: "13px",
      },
    },
    MuiTableRow: {
      root: {
        "&$selected": {
          backgroundColor: "rgba(0, 0, 0, 0.2) !important",
        },
      },
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider
          maxSnack={2}
          autoHideDuration={2000}
          classes={{
            variantSuccess: "snack-toast_success",
            variantError: "snack-toast_error",
            variantInfo: "snack-toast_info",
          }}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
