import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Checkbox,
  Typography,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Input } from "@material-ui/core";
import formatNumber, {
  formatNumberWithOutComma,
} from "../../../../common/Helpers/FormatNumberHelper";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import EditServiceModal from "./EditServiceModal";

const useStyles = makeStyles((theme) =>
  createStyles({
    TextLink: {
      color: "#3f51b5",
      textDecoration: "underline",
      "&:hover": {
        color: "#3f51b5",
        cursor: "pointer",
      },
    },
  })
);

function LinkText({ children, ...props }) {
  const classes = useStyles();
  return (
    <Typography className={classes.TextLink} {...props}>
      {children}
    </Typography>
  );
}

class ServiceList extends React.Component {
  state = {
    openEditServiceModal: false,
    currentService: {},
    checkAllRemmited: false
  };
  handleChange = (id, name, serviceSelected = {}) => (e) => {
    const { setFieldValue, services } = this.props;

    if (name === "entireRemit") {
      const checked = e.target.checked;
      setFieldValue(
        "services",
        services.map((service) =>
          service.id === id
            ? {
                ...service,
                entireRemit: checked,
                amountApplied: checked ? this.getOutstanding(service, true) : 0,
                credit: 0,
                percentage: 0,
                outstanding: checked
                  ? service.outstanding_saved + this.getOutstanding(service)
                  : service.outstanding_saved,
              }
            : service
        )
      );
    }
    if (name === "credit") {
      const value =
        parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : 0;
      const balance =
        this.getInvoiceAmount(serviceSelected) -
        serviceSelected.outstanding_saved;

      if (value > balance) return;
      setFieldValue(
        "services",
        services.map((service) =>
          service.id === id
            ? {
                ...service,
                entireRemit: false,
                credit: value,
                amountApplied: value,
                percentage: 0,
                outstanding: value
                  ? value + service.outstanding_saved
                  : service.outstanding_saved,
              }
            : service
        )
      );
    }

    if (name === "is_ia") {
      const checked = e.target.checked;
      setFieldValue(
        "services",
        services.map((service) =>
          service.id === id
            ? {
                ...service,

                amount_ajusted: checked
                  ? this.getOutstanding(service, service.entireRemit)
                  : 0,
              }
            : service
        )
      );
    }

    if (name === "is_wf") {
      const checked = e.target.checked;
      setFieldValue(
          "services",
          services.map((service) =>
              service.id === id
                  ? {
                      ...service,
                      write_off: checked
                          ? this.getOutstanding(service, service.entireRemit)
                          : 0,
                  }
                  : service
          )
      );
    }


      if (name === "percentage") {
      const value =
        e.target.value >= 0 && e.target.value <= 100 ? e.target.value : 0;
      setFieldValue(
        "services",
        services.map((service) => {
          return service.id === id
            ? {
                ...service,
                percentage: value ? parseFloat(value) : 0,
                entireRemit: false,
                amountApplied: value
                  ? (
                      (parseFloat(value) / 100) *
                      (this.getInvoiceAmount(service) -
                        // (product.quantity * product.variants.sale_price -
                        service.outstanding_saved)
                    ).toFixed(2)
                  : 0,
                credit: 0,
                outstanding: value
                  ? (parseFloat(value) / 100) *
                      (this.getInvoiceAmount(service) -
                        // (product.quantity * product.variants.sale_price -
                        service.outstanding_saved) +
                    service.outstanding_saved
                  : service.outstanding_saved,
              }
            : service;
        })
      );
    }
  };

  getInvoiceAmount(service) {
      const sale_price = service.added_percentage ? service.adjusted_sale_price : service.sale_price;
      console.log((+service.amount_remmited * +sale_price).toFixed(2))
      return +(+service.amount_remmited * +sale_price).toFixed(2);
  }

  getTotalInvoiceAmount(){
        const { services } = this.props;
        return formatNumber(
            +services
                .map(
                    (_service) =>
                        +formatNumberWithOutComma(
                            (_service.added_percentage ? _service.adjusted_sale_price : _service.sale_price || 0) *
                            (+_service.amount_remmited || 0)
                        )
                )
                .reduce((total, current) => +total + +current, 0)
        )
  }

  getTotalOutstanding() {
      const { services } = this.props;
      return formatNumber(
          +services
              .map(
                  (_service) =>
                      +this.getOutstanding(
                          _service
                      )
              )
              .reduce((total, current) => +total + +current, 0)
      )
  }

  getTotalAmountApplied() {
      const { services } = this.props;

      return formatNumber(
        +services
            .map(
                (_service) =>
                    _service.amountApplied
                        ? formatNumberWithOutComma(+_service.amountApplied)
                        : 0
            )
            .reduce((total, current) => +total + +current, 0)
      )
  }

    getTotalAmountAdjusted() {
        const { services } = this.props;

        return formatNumber(
            +services
                .map(
                    (_service) =>
                        _service.amount_ajusted
                            ? formatNumberWithOutComma(+_service.amount_ajusted)
                            : 0
                )
                .reduce((total, current) => +total + +current, 0)
        )
    }

    getTotalWriteOff() {
        const { services } = this.props;

        return formatNumber(
            +services
                .map(
                    (_service) =>
                        _service.write_off
                            ? formatNumberWithOutComma(+_service.write_off)
                            : 0
                )
                .reduce((total, current) => +total + +current, 0)
        )
    }

  getOutstanding(service, entireRemit = false) {
    if (entireRemit) service.outstanding = service.outstanding_saved;
    if (service.amount_ajusted > 0 || service.write_off > 0) {
      return 0;
    }
    return this.getInvoiceAmount(service) - +service.outstanding;
  }

  handleProductServiceModalClose = () => {
    this.setState({
      openEditServiceModal: false,
      currentService: {},
    });
  };

  handleEditServiceModalOpen = (service) => () => {
    this.setState({
      openEditServiceModal: true,
      currentService: service,
    });
  };

  handleCheckBox = () => (e) => {
      const { setFieldValue, services } = this.props;
      this.setState({'checkAllRemmited': e.target.checked})
      const checked = e.target.checked;
      setFieldValue(
          "services",
          services.map((service) =>
              !(this.getInvoiceAmount(service) - service.outstanding_saved === 0 ||
                  Boolean(+service.amount_ajusted) ||
                  Boolean(+service.write_off))
                  ? {
                      ...service,
                      entireRemit: checked,
                      amountApplied: checked ? this.getOutstanding(service, true) : 0,
                      credit: 0,
                      percentage: 0,
                      outstanding: checked
                          ? service.outstanding_saved + this.getOutstanding(service)
                          : service.outstanding_saved,
                  }
                  : service
          )
      );
  };

  render() {
    const { services } = this.props;
    const { currentService, openEditServiceModal } = this.state;
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell align="left">Original Amount</TableCell>
              <TableCell align="left">Outstanding</TableCell>
              <TableCell align="right">
                  Entire Remit
                  <Checkbox
                      checked={this.state.checkAllRemmited}
                      onClick={this.handleCheckBox()}
                      value="checkedB"
                      color="primary"
                  />
              </TableCell>
              <TableCell align="right">$</TableCell>
              <TableCell align="right">%</TableCell>
              <TableCell align="right">Amount Applied</TableCell>
              <TableCell align="right">IA</TableCell>
              <TableCell align="right">Amount Adjusted</TableCell>
              <TableCell align="right">Write Off</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service) => (
              <TableRow key={service.id}>
                <TableCell component="th" scope="row">
                  <Link
                    to={`/products/${service.product.id}`}
                    style={{ color: "#3f51b5" }}
                  >
                    {service.order_product.order_product_name}
                  </Link>
                </TableCell>
                <TableCell component="th" scope="row">
                  <LinkText onClick={this.handleEditServiceModalOpen(service)}>
                    {formatNumber(this.getInvoiceAmount(service))}
                  </LinkText>
                </TableCell>
                <TableCell align="left">
                  {formatNumber(+this.getOutstanding(service))}
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={service.entireRemit || false}
                    disabled={
                      this.getInvoiceAmount(service) -
                        service.outstanding_saved ===
                        0 || Boolean(+service.amount_ajusted) || Boolean(+service.write_off)
                    }
                    onClick={this.handleChange(service.id, "entireRemit")}
                    value="checkedB"
                    color="primary"
                  />
                </TableCell>
                <TableCell align="right">
                  <Input
                    disabled={+this.getOutstanding(service) === 0}
                    value={service.credit || ""}
                    style={{ maxWidth: 150 }}
                    type="number"
                    onChange={this.handleChange(service.id, "credit", service)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Input
                    disabled={+this.getOutstanding(service) === 0}
                    value={service.percentage || ""}
                    style={{ maxWidth: 150 }}
                    type="number"
                    onChange={this.handleChange(service.id, "percentage")}
                  />
                </TableCell>
                <TableCell align="right">
                  {service.amountApplied
                    ? formatNumberWithOutComma(+service.amountApplied)
                    : 0}
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                    checked={Boolean(+service.amount_ajusted) || false}
                    disabled={
                      this.getInvoiceAmount(service) -
                        service.outstanding_saved ===
                        0 || service.entireRemit
                    }
                    onClick={this.handleChange(service.id, "is_ia")}
                    value="checkedB"
                    color="primary"
                  />
                </TableCell>
                <TableCell align="right">
                  {service.amount_ajusted
                    ? (+service.amount_ajusted).toFixed(2)
                    : "0.00"}
                </TableCell>
                <TableCell align="right">
                  <Checkbox
                      checked={Boolean(+service.write_off) || false}
                      disabled={
                          this.getInvoiceAmount(service) -
                          service.outstanding_saved ===
                          0 || service.entireRemit
                      }
                      onClick={this.handleChange(service.id, "is_wf")}
                      value="checkedB"
                      color="primary"
                  />
                </TableCell>

              </TableRow>
            ))}
              <TableRow>
                  <TableCell component="th" scope="row">
                      <p style={{fontSize: 15}}>Total</p>
                  </TableCell>
                  <TableCell component="th" scope="row">
                      {this.getTotalInvoiceAmount()}
                  </TableCell>
                  <TableCell align="left">
                      {this.getTotalOutstanding()}
                  </TableCell>
                  <TableCell align="right">

                  </TableCell>
                  <TableCell align="right">

                  </TableCell>
                  <TableCell align="right">

                  </TableCell>
                  <TableCell align="right">
                      {this.getTotalAmountApplied()}
                  </TableCell>
                  <TableCell align="right">

                  </TableCell>
                  <TableCell align="right">
                      {this.getTotalAmountAdjusted()}
                  </TableCell>
                  <TableCell align="right">
                      {this.getTotalWriteOff()}
                  </TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <EditServiceModal
          open={openEditServiceModal}
          onClose={this.handleProductServiceModalClose}
          service={currentService}
        />
      </TableContainer>
    );
  }
}

export default ServiceList;
