function copyTextFromNode(node) {
  const range = document.createRange();

  range.selectNode(node);

  window.getSelection().removeAllRanges(); // clear current selection
  window.getSelection().addRange(range); // to select text
  document.execCommand("copy");
  window.getSelection().removeAllRanges();
}

export { copyTextFromNode };
