import Service from "./Service";

class ShippingStatusService extends Service {
  async list(params = {}) {
    try {
      return await this.http.get("/shipping-status/", { params });
    } catch (error) {
      return error.response;
    }
  }

  async list_batch(params = {}) {
    try {
      return await this.http.get("/list_batch_prints/", { params });
    } catch (error) {
      return error.response;
    }
  }
  async update(shippingStatusId, body) {
    try {
      return await this.http.patch(
        `/shipping-status/${shippingStatusId}/`,
        body
      );
    } catch (error) {
      return error.response;
    }
  }
  async updateServices(shippingStatusId, params = {}) {
    return await this.http
      .patch(`/services/${shippingStatusId}/`, params)
      .then(({ data }) => data);
  }

  async printBatchDeliveryTickets(params = {}) {
    try {
      return await this.http.get(`/export_batch`, {
        params,
        // responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }

  async confirmBatchDeliveryTickets(params = {}) {
    try {
      return await this.http.get(`/confirm_export_batch`, {
        params,
        responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }

  async asyncConfirmBatchDeliveryTickets(params = {}) {
    try {
      return await this.http.get(`/async_confirm_export_batch`, {
        params,
        responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }

  async printDeliveryTickets(shippingStatusId) {
    try {
      return await this.http.get(`/export/${shippingStatusId}/print/`, {
        responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }

  async rePrintDeliveryTickets(shippingStatusId) {
    try {
      return await this.http.get(`/export/${shippingStatusId}/reprint/`, {
        responseType: "blob",
      });
    } catch (error) {
      return error.response;
    }
  }

  async getCountOcurrencesAvailablesForPrint(params = {}) {
    try {
      return await this.http.get(`/getCountOcurrencesAvailablesForPrint/`, {
        params,
      });
    } catch (error) {
      return error.response;
    }
  }
}

export default ShippingStatusService;
