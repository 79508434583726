import RemittanceService from "../../services/RemittanceService";
import {
  GET_REMITTANCE,
  GET_REMITTANCES,
  GET_REMITTANCE_PAYMENT,
  SET_REMITTANCE_CHECKS,
  UPDATE_REMITTANCE,
  SET_REMITTANCE_FILTERS,
  ON_LOADING,
  // UPDATE_REMITTANCE_PRODUCT_SERVICE,
  GET_LAST_REMITTANCE_PAYMENT,
} from "../actionTypes";
import OrderService from "../../services/OrderService";

const remittanceService = new RemittanceService();
const orderService = new OrderService();

export const getRemittances = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ON_LOADING, loading: true });

    const response = await remittanceService.getAll(
      getState().remittance.filters
    );
    const { status, data } = response;

    if (status === 200) {
      const { results, ...meta } = data;

      dispatch({
        type: GET_REMITTANCES,
        payload: results,
      });
      dispatch({
        type: SET_REMITTANCE_FILTERS,
        payload: {
          // ...meta,
          count: meta.count,
          page_size: meta.page_size,
          page: meta.current,
        },
      });
    }
    dispatch({ type: ON_LOADING, loading: false });

    return response;
  } catch (error) {
    dispatch({ type: ON_LOADING, loading: false });
    console.log(error);
  }
};

export const getRemittance = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ON_LOADING, loading: true });

    const response = await remittanceService.get(id);
    const { status, data } = response;

    if (status === 200) {
      dispatch({
        type: GET_REMITTANCE,
        payload: data,
      });
    }
    dispatch({ type: ON_LOADING, loading: false });

    return response;
  } catch (error) {
    dispatch({ type: ON_LOADING, loading: false });
    console.log(error);
  }
};

export const setRemittanceFilters = (payload) => ({
  type: SET_REMITTANCE_FILTERS,
  payload,
});

export const getRemittancesPayment = (ocurrenceId) => async (dispatch) => {
  try {
    const response = await remittanceService.getAllRemittancesPayment(
      ocurrenceId
    );
    dispatch({
      type: GET_REMITTANCE_PAYMENT,
      payload: response.data,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getLastRemittancesPayment = () => async (dispatch) => {
  try {
    const response = await remittanceService.getLastRemittancesPayment();
    dispatch({
      type: GET_LAST_REMITTANCE_PAYMENT,
      payload: response.data,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createRemittancePayment = (body) => async (dispatch) => {
  try {
    const response = await remittanceService.createRemittancePayment(body);
    if (response.status === 200) {
      dispatch({
        type: UPDATE_REMITTANCE,
        payload: response.data,
      });
    }
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const setRemittanceChecks = (checks) => ({
  type: SET_REMITTANCE_CHECKS,
  payload: checks,
});

export const updateService = (serviceId, body) => {
  // export const updateProductService = (serviceId, body, path) => {
  return async (dispatch) => {
    try {
      const response = await orderService.updateService(serviceId, body);

      const { amount_remmited, sale_price } = response.data;
      // dispatch({
      //   type: UPDATE_REMITTANCE_PRODUCT_SERVICE,
      //   payload: { path, sale_price, amount_remmited },
      // });
      /**UPDATE SERVICE IN REMITTANCE REDUCERS DISPATCHERS */
      return response;
    } catch (error) {
      console.log(error);
    }
  };
};
