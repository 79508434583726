import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { withSnackbar } from "notistack";
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Grid,
    Divider,
    InputAdornment,
    IconButton,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "recompose";

import { getVendors } from "../../../redux/actions/vendorActions";
import ServiceList from "./ServiceList";
import { createRemittancePayment } from "../../../redux/actions/remittanceAction";
import { writeOffServiceInvoice } from "../../../redux/actions/invoiceActions";
import * as Yup from "yup";
import { setLoading } from "../../../redux/actions/globalActions";
import moment from "moment";
import { Today } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";

import _get from "lodash/get";
import { formatNumberWithOutComma } from "../../../common/Helpers/FormatNumberHelper";

const PAYMENT_METHODS = ["Cash", "Bank", "Insurance Adjustments", "Write Off"];

class InvoiceCheckDialog extends React.Component {
    state = {
        lastPayment: {
            id: "",
            services: this.props.services.map((service) => {
                return service.outstanding
                    ? {
                        ...service,         
                        outstanding_saved: service.outstanding,
                        amount_ajusted: 0,
                        write_off: 0,
                    }
                    : {
                        ...service,
                        outstanding: 0,
                        outstanding_saved: 0,
                        amount_ajusted: 0,
                        write_off: 0
                    };
            }),
            // services: this.props.services,
            isCheckSaved: false,
            openNotesDialog: false,
        },
    };

    async componentDidMount() {
        // try {
        //     this.props.getVendors();
        //     const { lastRemittancePayment } = this.props;
        //     if (lastRemittancePayment.id)
        //         this.setState((prevState) => {
        //             return {
        //                 lastPayment: {
        //                     ...prevState.lastPayment,
        //                     ...lastRemittancePayment,
        //                     total_amount:
        //                     lastRemittancePayment.remmitance_payment.total_amount_check,
        //                     check_date: lastRemittancePayment.remmitance_payment.check_date,
        //                     check_number:
        //                     lastRemittancePayment.remmitance_payment.check_number,
        //                     vendor: lastRemittancePayment.remmitance_payment.vendor,
        //                 },
        //                 isCheckSaved: true,
        //             };
        //         });
        // } catch (error) {
        //     console.log(error);
        // }
    }

    handleSubmit = async (values, formikActions) => {
        try {
            this.props.handleClose();
            this.props.setLoading(true);
            const body = {

                ///change field name to 'services' request to gerard more later
                services: values.services.map((service) => ({
                    id: service.id,
                    outstanding: (+service.amount_ajusted || +service.write_off)
                        ? +formatNumberWithOutComma(
                            +this.getInvoiceAmountFromService(service)
                        )
                        : +formatNumberWithOutComma(+service.outstanding),
                    amount_ajusted: +service.amount_ajusted,
                    write_off: +service.write_off,
                })),
            };

            const response = await this.props.writeOffServiceInvoice(body);
            if (response.status === 200) {
                this.props.enqueueSnackbar("Services updated sucessfully!", {
                    variant: "success",
                });
                window.location.reload();
            } else {
                this.props.enqueueSnackbar("The request could not be processed!", {
                    variant: "error",
                });
            }
        } catch (error) {
            console.log(error);
        }
        this.props.setLoading(false);
    };

    hasSomeServiceWithAmount(services) {
        return (
            services.find((service) => {
                return (
                    service.amountApplied !== 0 ||
                    service.amountApplied !== undefined ||
                    service.amountApplied !== "0.00"
                );
            }) === undefined
        );
    }

    getInvoiceAmountFromService(service) {
        const sale_price = service.added_percentage ? service.adjusted_sale_price : service.sale_price;
        return +service.amount_remmited * +sale_price;
    }

    getPatientName = (order) => {
        if (order.patient) {
            const { first_name = "", last_name = "" } = order.patient;
            return `${first_name} ${last_name}`;
        }
        return "-";
    };

    render() {
        const {
            loading,
            open,
            handleClose,
            // services,
        } = this.props;

        // const patient_name = this.getPatientName(this.state.invoice.order);

        const { lastPayment, isCheckSaved } = this.state;


        return (
            <div>
                <Dialog
                    onClose={handleClose}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth
                    maxWidth="lg"
                >
                    <Formik
                        onSubmit={this.handleSubmit}
                        initialValues={lastPayment}
                        enableReinitialize
                    >
                        {(props) => {
                            const {
                                values,
                                handleBlur,
                                handleSubmit,
                                handleChange,
                                errors,
                                touched,
                                setFieldValue,
                                resetForm,
                                isSubmitting,
                                isValid,
                                dirty,
                            } = props;

                            return (
                                <Form>
                                    <DialogTitle
                                        id="customized-dialog-title"
                                        onClose={handleClose}
                                    >
                                        Write Off
                                    </DialogTitle>
                                    <DialogContent>
                                        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                                        <ServiceList
                                            loading={loading}
                                            services={values.services}
                                            setFieldValue={setFieldValue}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleSubmit}
                                            color="primary"
                                            fullWidth
                                            disabled={
                                                isCheckSaved
                                                    ? loading ||
                                                    isSubmitting ||
                                                    (isValid && !dirty) ||
                                                    (!isValid && dirty) ||
                                                    this.hasSomeServiceWithAmount(values.services)
                                                    : loading ||
                                                    isSubmitting ||
                                                    !isValid ||
                                                    !dirty ||
                                                    this.hasSomeServiceWithAmount(values.services)
                                            }
                                        >
                                            Ok
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                resetForm();
                                                this.props.handleClose();
                                            }}
                                            color="primary"
                                            fullWidth
                                        >
                                            Cancel
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </Dialog>
            </div>
        );
    }
}
InvoiceCheckDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.global.loading,
});
const mapDispatchToProps = {
    getVendors,
    createRemittancePayment,
    writeOffServiceInvoice,
    setLoading,
};
export default compose(
    withSnackbar,
    connect(mapStateToProps, mapDispatchToProps)
)(InvoiceCheckDialog);
