import {
  GET_SHIPPING_STATUS,
  UPDATE_SHIPPING,
  ON_LOADING,
  SET_FILTER_SHIPPING_STATUS,
  GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
  GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
  GET_PATIENTS_ERROR,
  BATCH_PRINT, GET_USERS,
} from "../actionTypes";
import ShippingStatusService from "../../services/ShippingStatusService";

const service = new ShippingStatusService();

export const setLoading = (loading) => ({ type: ON_LOADING, loading });

export const getShippingStatus = () => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const {
        start_date,
        end_date,
        ...restFilters
      } = getState().shippingStatus.filters;
      //temp frontend validation

      const newFilters = { ...restFilters };

      if (start_date && end_date) {
        newFilters.start_date = start_date;
        newFilters.end_date = end_date;
      }

      const response = await service.list(newFilters);
      const { data, status } = response;
      if (status === 200) {
        const { results, ...meta } = data;

        dispatch({ type: GET_SHIPPING_STATUS, payload: results });
        dispatch({
          type: SET_FILTER_SHIPPING_STATUS,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      }
      return response;
    } catch (error) {
      dispatch({ type: GET_SHIPPING_STATUS, payload: [] });

      console.error(error);
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const getBatchShippingStatus = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: ON_LOADING, loading: true });
      const response = await service.list_batch();
      const { status, data } = response;

      if (status === 200) {
        dispatch({ type: BATCH_PRINT, payload: response.data });
      } else if (status === 422) {
        dispatch({ type: GET_PATIENTS_ERROR, payload: data });
      }
      dispatch({ type: ON_LOADING, loading: false });

      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const updateShipping = (ocurrenceId, body) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.update(ocurrenceId, body);
      console.log(response);
      if (response.status === 200) {
        dispatch({ type: UPDATE_SHIPPING, payload: response.data });
      }
      return response;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const printShippingStatus = (params) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.printBatchDeliveryTickets(
          params
      );
      if (response.status === 200) {
        dispatch({
          type: GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
          payload: 0,
        });
      }
      console.log("ID_BATC", response)
      return response;
    } catch (error) {
      dispatch({
        type: GET_BATCH_PRINT_ID_AND_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
        payload: 0,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const confirmShippingStatus = (params) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.confirmBatchDeliveryTickets(params);
      return response;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const asyncConfirmShippingStatus = (params) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.asyncConfirmBatchDeliveryTickets(params);
      return response;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const printTicket = (ocurrenceId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.printDeliveryTickets(ocurrenceId);
      return response;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const rePrintTicket = (ocurrenceId) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.rePrintDeliveryTickets(ocurrenceId);
      return response;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const setFiltersShippingStatus = (payload) => ({
  type: SET_FILTER_SHIPPING_STATUS,
  payload,
});

export const getCountOcurrencesAvailablesForPrint = (params = {}) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.getCountOcurrencesAvailablesForPrint(
        params
      );
      if (response.status === 200) {
        dispatch({
          type: GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
          payload: 0,
        });
      }
      return response;
    } catch (error) {
      dispatch({
        type: GET_COUNT_OCURRENCES_AVAILABLES_FOR_PRINT,
        payload: 0,
      });
    } finally {
      dispatch(setLoading(false));
    }
  };
};
