import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  InputAdornment,
  TextField,
  Typography,
  TableContainer,
} from "@material-ui/core";

import styles from "./styles";
import { Edit, Delete } from "@material-ui/icons";
import CalendarIcon from "@material-ui/icons/Today";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import {
  setLoading,
  setCurrentForm,
  setHandleForm,
} from "./../../../redux/actions/globalActions";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  updateService,
  deleteService,
} from "./../../../redux/actions/orderActions";
import { getCallCustomerWEligibility } from "./../../../redux/actions/callCustomerActionsWCheckEligibilityActions";
import { ORDER_EDIT_SERVICES } from "../../../common/Constants/FormConstants";
import * as Yup from "yup";
import {DialogDelete, TextEmpty} from "../../../components";
import _get from "lodash/get";
import formatNumber, {
  formatNumberWithOutCommaDecimal,
} from "../../../common/Helpers/FormatNumberHelper";

class ServicesTab extends React.Component {
  state = {
    showModal: false,
    currentService: {},
    selectFocus: false,
  };

  openEditService = (service) => () => {
    this.props.setCurrentForm(ORDER_EDIT_SERVICES);
    this.setState({
      currentService: service,
      open: false,
      serviceId: 0,
      showModal: true,
    });
  };
  handleDeleteService = async () => {
    this.setState({ open: false });
    this.props.setLoading(true);
    const { serviceId } = this.state;
    try {
      const response = await this.props.deleteService(serviceId);
      if (response.status === 204) {
        this.props.enqueueSnackbar("Service deleted successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });

        await this.props.getCallCustomerWEligibility(this.props.ocurrenceId);
      }
    } catch (error) {
      this.props.enqueueSnackbar("Service delete failed.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
    }
    this.props.setLoading(false);
  };
  closeModal = () => {
    this.props.setCurrentForm(null);
    this.props.setHandleForm(false);
    this.setState({ showModal: false });
  };

  updateService = async (values, formikActions) => {
    this.setState({ showModal: false });
    const { setSubmitting } = formikActions;
    this.props.setLoading(true);
    const body = {
      // product_id: values.product_id.value,
      amount_remmited: values.amount_remmited,
    };
    try {
      const response = await this.props.updateService(values.id, body);
      if (response.status === 200) {
        this.props.enqueueSnackbar("Service updated successfully.", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        await this.props.getCallCustomerWEligibility(this.props.ocurrenceId);
      }
    } catch (error) {
      this.props.enqueueSnackbar("Service updated failed.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "center" },
      });
      console.log(error);
    }
    this.props.setLoading(false);
    setSubmitting(false);
  };

  render() {
    let { classes, loading, ocurrence: { services = [] } = {} } = this.props;
    const {
      showModal,
      currentService,
      shipped,
      // currentProductId,
      // services,
      open,
      selectFocus,
    } = this.state;

    return (
      <div style={{ flex: 1 }}>
        <TableContainer>
          <DialogDelete
              item="service"
              open={open}
              closeModal={() => this.setState({ open: false })}
              remove={this.handleDeleteService}
              isDelivery={false}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Variant</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  Product #
                </TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>UOM</TableCell>
                <TableCell>$</TableCell>
                <TableCell>Tax</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {services.map((service) => (
                <TableRow key={service.id}>
                  <TableCell>
                    {_get(service, "variant.price_variant")}
                  </TableCell>
                  <TableCell>{service.order_product.order_product_reference_code}</TableCell>
                  <TableCell>{service.order_product.order_product_name}</TableCell>
                  <TableCell>{service.amount_remmited || "0"}</TableCell>
                  <TableCell>{_get(service, "variant.unit_measure")}</TableCell>
                  <TableCell>{service.added_percentage ?
                      formatNumber((service.adjusted_sale_price || 0)- (service.tax_amount || 0))
                      : formatNumber((service.sale_price || 0) - (service.tax_amount || 0))}
                  </TableCell>
                  <TableCell>{service.tax_amount || 0}</TableCell>
                  <TableCell>
                    {formatNumber(
                      (service.added_percentage ? formatNumber(service.adjusted_sale_price || 0) : formatNumber(service.sale_price || 0)) *
                        (+service.amount_remmited || 0)
                    )}
                  </TableCell>
                  <TableCell align="right" className={classes.actionButtons}>
                    <IconButton
                      disabled={loading}
                      onClick={this.openEditService(service)}
                    >
                      <Edit color="primary" />
                    </IconButton>
                    <IconButton
                      disabled={loading}
                      onClick={() => this.setState({ open: true, serviceId: service.id })}
                    >
                      <Delete color="secondary" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {services.length ? (
                <TableRow style={{ height: 48 }}>
                  <TableCell colSpan={4} />
                  <TableCell colSpan={2}>
                    <Typography variant="subtitle2">Total:</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {formatNumber(
                        +services
                          .map(
                            (_service) =>
                              +formatNumberWithOutCommaDecimal(
                                (_service.added_percentage ? +_service.adjusted_sale_price : +_service.sale_price || 0) *
                                  (+_service.amount_remmited || 0)
                              )
                          )
                          .reduce((total, current) => +total + +current, 0)
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={1} />
                </TableRow>
              ) : null}
              {services.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <TextEmpty
                      itemName={"SERVICES"}
                      empty={services.length === 0}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Formik
          onSubmit={this.updateService}
          initialValues={{
            id: currentService.id,
            date: currentService.date,
            order_product_id: currentService.order_product_id,
            // product_id: currentProductId,
            amount_remmited: currentService.amount_remmited,
          }}
          enableReinitialize
          validationSchema={Yup.object().shape({
            date: Yup.date(),
            amount_remmited: Yup.mixed().required("Quantity is required"),
          })}
        >
          {(props) => {
            const {
              values,
              handleSubmit,
              setFieldValue,
              handleBlur,
              dirty,
              isValid,
              isSubmitting,
            } = props;
            return (
              <Dialog
                open={showModal}
                onClose={this.closeModal}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth
              >
                <Form>
                  <DialogTitle id="form-dialog-title">Edit service</DialogTitle>
                  <DialogContent
                    classes={{
                      root: selectFocus
                        ? classes.dialogContentMax
                        : classes.dialogContentMin,
                    }}
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={_get(currentService, "product.name", "")}
                          label="Product"
                          fullWidth
                          margin="normal"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <KeyboardDatePicker
                          name="date"
                          value={values.date || null}
                          label="Service Date"
                          invalidLabel={""}
                          margin="normal"
                          format={"MM/DD/YYYY"}
                          fullWidth
                          autoOk={true}
                          animateYearScrolling
                          onChange={(date) => {
                            setFieldValue("date", date);
                          }}
                          onBlur={handleBlur}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton aria-label="calendar">
                                  <CalendarIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          disabled
                          placeholder="MM/DD/YYYY"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.amount_remmited}
                          onChange={(e) => {
                            setFieldValue("amount_remmited", e.target.value);
                          }}
                          onBlur={handleBlur}
                          label="Quantity"
                          fullWidth
                          type="number"
                          margin="normal"
                          disabled={loading || this.props.shipped}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.closeModal}
                      color="primary"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSubmit}
                      type="submit"
                      color="primary"
                      disabled={
                        loading ||
                        isSubmitting ||
                        (isValid && !dirty) ||
                        (!isValid && dirty)
                      }
                    >
                      Update
                    </Button>
                  </DialogActions>
                </Form>
              </Dialog>
            );
          }}
        </Formik>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    // order: state.orders.order,
    // products: state.products.list,
    ocurrence: state.callCustomerwCheckelibility.ocurrence,
    currentForm: state.global.currentForm,
    handleForm: state.global.handleForm,
  };
};

const mapDispatchToProps = {
  setLoading,
  setHandleForm,
  setCurrentForm,
  updateService,
  deleteService,
  getCallCustomerWEligibility,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "ServicesTab" }),
  connect(mapStateToProps, mapDispatchToProps)
)(ServicesTab);
