import { GET_DELIVERIES, REMOVE_DELIVERY } from "../actionTypes";

const initialState = {
  list: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DELIVERIES:
      return {
        ...state,
        list: action.payload
      };

    case REMOVE_DELIVERY:
      return {
        ...state,
        list: state.list.filter(({id}) => id !== action.payload)
      };
    default:
      return state;
  }
};
