import {
  GET_VENDORS,
  CREATE_VENDOR,
  UPDATE_VENDOR,
  GET_VENDOR,
  DELETE_VENDOR
} from "../actionTypes";

const initialState = {
  list: [],
  vendor: {
    id: 0
  },
  errors: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_VENDORS:
      return {
        ...state,
        list: action.payload,
        errors: []
      };

    case CREATE_VENDOR:
      return {
        ...state,
        vendor: action.payload,
        errors: []
      };

    case UPDATE_VENDOR:
      return {
        ...state,
        vendor: action.payload
      };

    case GET_VENDOR:
      return {
        ...state,
        vendor: action.payload
      };

    case DELETE_VENDOR:
      return {
        ...state,
        list: state.list.filter(vendor => vendor.id !== action.payload)
      };

    default:
      return state;
  }
};
