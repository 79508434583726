import { GET_INVOICES, GET_INVOICE, SET_INVOICE_FILTERS } from "../actionTypes";

const initial_state = {
  list: [],
  invoice: {},
  filters: {
    search: "",
    ordering: "",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        list: action.payload,
      };

    case SET_INVOICE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case GET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };

    default:
      return state;
  }
};
