import {
  ON_LOADING,
  SET_FILTER_CALL_CUSTOMERS_W_ELIGIBILITIES,
  GET_CALL_CUSTOMERS_W_ELIGIBILITIES,
  GET_CALL_CUSTOMER_W_ELIGIBILITIES,
  UPDATE_CALL_CUSTOMER_W_ELIGIBILITIES,
  RESET_CALL_CUSTOMER_W_ELIGIBILITIES_OCURRENCE,
} from "../actionTypes";
import CallCustomerWEligibilityService from "../../services/CallCustomerWEligibilityService";

const service = new CallCustomerWEligibilityService();

export const setFiltersCallCustomerWEligibilities = (payload) => ({
  type: SET_FILTER_CALL_CUSTOMERS_W_ELIGIBILITIES,
  payload,
});

export const getCallCustomersWEligibilities = (params = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: ON_LOADING, loading: true });
    try {
      const {
        start_date,
        end_date,
        state,
        facility,
        ...restFilters
      } = getState().callCustomerwCheckelibility.filters;
      //temp frontend validation

      const newFilters = { ...restFilters };

      if (state) {
        newFilters.state = state;
      }

      if (facility) {
        newFilters.facility = facility;
      }

      if (start_date && end_date) {
        newFilters.start_date = start_date;
        newFilters.end_date = end_date;
      }

      const response = await service.list(newFilters);
      const { data, status } = response;

      if (status === 200) {
        const { results, ...meta } = data;

        dispatch({
          type: GET_CALL_CUSTOMERS_W_ELIGIBILITIES,
          payload: results,
        });
        dispatch({
          type: SET_FILTER_CALL_CUSTOMERS_W_ELIGIBILITIES,
          payload: {
            // ...meta,
            count: meta.count,
            page_size: meta.page_size,
            page: meta.current,
          },
        });
      }
      dispatch({ type: ON_LOADING, loading: false });

      return response;
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });
      console.error(error);
    }
  };
};

export const getCallCustomerWEligibility = (ocurrenceId) => {
  return async (dispatch) => {
    try {
      const response = await service.get(ocurrenceId);
      if (response.status === 200) {
        dispatch({
          type: GET_CALL_CUSTOMER_W_ELIGIBILITIES,
          payload: response.data,
        });
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateCallCustomerWEligibility = (ocurrenceId, body) => {
  return async (dispatch) => {
    try {
      const response = await service.update(ocurrenceId, body);
      const { data, status } = response;

      if (status === 200) {
        dispatch({
          type: UPDATE_CALL_CUSTOMER_W_ELIGIBILITIES,
          payload: { ...body, ocurrenceId },
        });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const resetCallCustomerWEligibilityOcurrence = () => ({
  type: RESET_CALL_CUSTOMER_W_ELIGIBILITIES_OCURRENCE,
});
