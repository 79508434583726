import {
  CREATE_PATIENT,
  CREATE_PATIENT_ERROR,
  GET_PATIENT,
  GET_PATIENT_TAGS,
  GET_PATIENT_TAGS_ERROR,
  GET_PATIENTS,
  GET_PATIENTS_ERROR,
  STORE_PATIENT,
  SET_PATIENT_FILTERS,
  GET_RAW_PATIENTS,
  SET_RAW_PATIENT_FILTERS,
} from "../actionTypes";

const defaultState = {
  list: [],
  rawList: [],
  patient: {
    id: 0,
    tags: [],
    diagnostics: [],
    attachments: [],
    insurances: [],
  },
  tags: [],
  errors: {},
  filters: {
    search: "",
    status: "Active",
    state: null,
    //pagination filters
    ordering: "first_name",
    page: 1,
    page_size: 25,
    count: 0,
  },
  rawFilters: {
    search: "",
    status: "Active",
    state: null,
    //pagination filters
    ordering: "first_name",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PATIENTS:
      return {
        ...state,
        list: action.payload,
      };
    case GET_RAW_PATIENTS:
      return {
        ...state,
        rawList: action.payload,
      };

    case SET_PATIENT_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case SET_RAW_PATIENT_FILTERS:
      return {
        ...state,
        rawFilters: {
          ...state.rawFilters,
          ...action.payload,
        },
      };

    case GET_PATIENTS_ERROR:
      return {
        ...state,
        list: [],
      };

    case CREATE_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };

    case CREATE_PATIENT_ERROR:
      return {
        ...state,
        errors: action.payload,
      };

    case GET_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };

    case GET_PATIENT_TAGS:
      return {
        ...state,
        tags: action.payload,
      };

    case GET_PATIENT_TAGS_ERROR:
      return {
        ...state,
        tags: [],
      };

    case STORE_PATIENT:
      return {
        ...state,
        patient: action.payload.patient,
      };

    default:
      return state;
  }
};
