import Service from './Service';

class CallCustomerService extends Service {

  async list(params = {}) {
    try {
      return await this.http.get('/call-customers/', { params });
    } catch (error) {
      return error.response;
    }
  }

  async get(ocurrenceId) {
    try {
      return await this.http.get(`/call-customers/${ocurrenceId}`);
    } catch (error) {
      return error.response;
    }
  }

  async update(id, body) {
    try {
      return await this.http.patch(`/call-customers/${id}/`, body);
    } catch (error) {
      return error.response;
    }
  }
}

export default CallCustomerService;