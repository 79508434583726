import {fade} from "@material-ui/core";

const styles = theme => ({
  chip: {
    margin: theme.spacing(1),
    width: theme.spacing(4),
    height: theme.spacing(1)
  },
  red: {
    backgroundColor: '#F44336'
  },
  green: {
    backgroundColor: '#4CAF50'
  },
  downloadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  tableContainer:{
    overflow: 'auto',
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  
});

export default styles;
