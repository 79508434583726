import React from "react";

class Error404 extends React.Component {
  render() {
    return (
      <div>
        <h1>404</h1>
      </div>
    );
  }
}

export default Error404;
