import Service from "./Service";

class CheckEligibilityService extends Service {
  async list(params = {}) {
    try {
      if (!!params.start_date && !!params.end_date) {
        return await this.http.get("/check-eligibility/", { params });
      } else {
        return await this.http.get("/check-eligibility/", { params });
      }
    } catch (error) {
      return error.response;
    }
  }

  async update(id, body) {
    try {
      return await this.http.patch(`/check-eligibility/${id}/`, body);
    } catch (error) {
      return error.response;
    }
  }
}

export default CheckEligibilityService;
