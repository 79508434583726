import {
    BATCH_PRINT
} from '../actionTypes';

const initialState = {
    list: [],
    errors: {}
};

export default (state = initialState, action) => {

    switch (action.type) {
        case BATCH_PRINT:
            return {
                ...state,
                list: action.payload,
                errors: []
            };

        default:
            return state;
    }
};