import ExpirationService from "../../services/ExpirationService";
import {
  GET_EXPIRATIONS,
  SET_EXPIRATIONS_FILTERS,
  ON_LOADING,
} from "../actionTypes";
import {setLoading} from "./shippingStatusActions";

const service = new ExpirationService();

export const getExpirations = () => {
  return async (dispatch, getState) => {
    dispatch({ type: ON_LOADING, loading: true });
    try {
      const {
        start_date,
        end_date,
        state,
        ...restFilters
      } = getState().expirations.filters;
      //temp frontend validation

      const newFilters = { ...restFilters };

      if (state) {
        newFilters.state = state;
      }

      if (start_date && end_date) {
        newFilters.start_date = start_date;
        newFilters.end_date = end_date;
      }

      if (start_date && end_date) {
        const response = await service.list(newFilters);
        const { data, status } = response;

        if (status === 200) {
          const { results, ...meta } = data;
          dispatch({ type: GET_EXPIRATIONS, payload: results });
          dispatch({
            type: SET_EXPIRATIONS_FILTERS,
            payload: {
              // ...meta,
              count: meta.count,
              page_size: meta.page_size,
              page: meta.current,
            },
          });
        }
        dispatch({ type: ON_LOADING, loading: false });
        return response;
      }

      dispatch({ type: ON_LOADING, loading: false });
      return { status: 200 };
    } catch (error) {
      dispatch({ type: ON_LOADING, loading: false });

      console.error(error);
    }
  };
};

export const setExpirationFilters = (payload) => ({
  type: SET_EXPIRATIONS_FILTERS,
  payload,
});

export const getCheckExpirations = () => {
  return async (dispatch) => {
    try {
      const response = await service.listCheckExpirations();
      const { data, status } = response;

      if (status === 200) {
        dispatch({ type: GET_EXPIRATIONS, payload: data });
      }

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateExpiration = (id, body) => {
  return async (dispatch) => {
    try {
      const response = await service.update(id, body);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
};

export const printBatchExpirations = (params) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await service.printBatchExpirationRows(params);
      return response;
    } catch (error) {
    } finally {
      dispatch(setLoading(false));
    }
  };
};

export const deleteExpiration = (id) => {
  return async (dispatch) => {
    try {
      const response = await service.delete(id);

      return response;
    } catch (error) {
      console.error(error);
    }
  };
};
