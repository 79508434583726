import {
  CREATE_ORDER,
  CREATE_ORDER_ERROR,
  GET_ORDER,
  GET_ORDER_DELIVERIES,
  GET_ORDER_RECURRENCES,
  GET_ORDER_STATUS,
  GET_ORDERS,
  UPDATE_ORDER,
  UPDATE_ORDER_ERROR,
  GET_ALL_CALL_NOTES,
  ORDER_SET_PATIENT_ID_ORIGIN,
  SET_ORDER_FILTERS,
  GET_PRODUCT_PRICE_VARIANTS,
} from "../actionTypes";

const defaultState = {
  list: [],
  order: {
    id: 0,
    expirations: [],
    order_products: [],
    services: [],
  },
  callPatientNotes: [],
  status: [],
  deliveries: [],
  recurrences: [],
  errors: {},
  patientIdOrigin: "",
  productPriceVariants: [],
  ///
  filters: {
    search: "",
    ordering: "",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ORDER_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case GET_ORDER_DELIVERIES:
      return {
        ...state,
        deliveries: action.payload,
      };

    case GET_ORDER_RECURRENCES:
      return {
        ...state,
        recurrences: action.payload,
      };

    case GET_ORDERS:
      return {
        ...state,
        list: action.payload,
      };

    case SET_ORDER_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case CREATE_ORDER:
      return {
        ...state,
        order: action.payload,
        errors: {},
      };

    case CREATE_ORDER_ERROR:
      return {
        ...state,
        errors: action.payload,
      };

    case GET_ORDER:
      return {
        ...state,
        order: action.payload,
      };

    case UPDATE_ORDER:
      return {
        ...state,
        order: action.payload,
        errors: {},
      };

    case UPDATE_ORDER_ERROR: {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case GET_ALL_CALL_NOTES: {
      return {
        ...state,
        callPatientNotes: action.payload,
      };
    }

    case GET_PRODUCT_PRICE_VARIANTS: {
      return {
        ...state,
        productPriceVariants: action.payload,
      };
    }

    case ORDER_SET_PATIENT_ID_ORIGIN: {
      return {
        ...state,
        patientIdOrigin: action.payload,
      };
    }

    default:
      return state;
  }
};
