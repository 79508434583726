import {
  GET_REMITTANCE,
  GET_REMITTANCE_PAYMENT,
  SET_REMITTANCE_CHECKS,
  UPDATE_REMITTANCE,
  SET_REMITTANCE_FILTERS,
  // UPDATE_REMITTANCE_PRODUCT_SERVICE,
  GET_LAST_REMITTANCE_PAYMENT,
  GET_REMITTANCES,
} from "../actionTypes";

// import _set from "lodash/set";
// import _get from "lodash/get";

const initial_state = {
  list: [],
  remittance: {
    id: 0,
    services: [],
  },
  remittancePayments: [],
  checks: [],
  lastRemittancePayment: {},

  filters: {
    search: "",
    ordering: "patient_name",
    page: 1,
    page_size: 25,
    count: 0,
  },
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case GET_REMITTANCES:
      return {
        ...state,
        list: action.payload,
      };
    case GET_REMITTANCE:
      return {
        ...state,
        remittance: action.payload,
      };

    case SET_REMITTANCE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case UPDATE_REMITTANCE:
      return {
        ...state,
        list: state.list.map((remittance) =>
          remittance.id === action.payload.id ? action.payload : remittance
        ),
      };

    case GET_REMITTANCE_PAYMENT:
      return {
        ...state,
        remittancePayments: action.payload,
      };

    case GET_LAST_REMITTANCE_PAYMENT:
      return {
        ...state,
        lastRemittancePayment: action.payload,
      };

    case SET_REMITTANCE_CHECKS:
      return {
        ...state,
        checks: action.payload,
      };

    // case UPDATE_REMITTANCE_PRODUCT_SERVICE:
    //   console.log(_get(state.list, action.payload.path));
    //   _set(state.list, action.payload.path, {
    //     ..._get(state.list, action.payload.path),
    //     sale_price: action.payload.sale_price,
    //     amount_remmited: action.payload.amount_remmited,
    //   });
    //   console.log(_get(state.list, action.payload.path));
    //   return {
    //     ...state,
    //     list: _set(state.list, action.payload.path, {
    //       ..._get(state.list, action.payload.path),
    //       sale_price: action.payload.sale_price,
    //       amount_remmited: action.payload.amount_remmited,
    //     }),
    //   };

    default:
      return state;
  }
};
