import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { withSnackbar } from "notistack";
import Link from "@material-ui/core/Link";
import PropTypes from "prop-types";
import {
  withStyles,
  Button,
  InputBase,
  IconButton,
  Typography,
  Chip,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import {Delete, Edit, Search} from "@material-ui/icons";
import {Layout, SimpleBreadcrumbs, Panel, DialogDelete} from "../../../components";
import { setPatientIdOrigin } from "../../../redux/actions/orderActions";
import {
  // getPatients,
  deletePatient,
  setPatientFilters, getPatientsRentals, deleteRentalsPatient,
} from "../../../redux/actions/patientActions";
import {
  fetchPatientStates,
  setLoading,
} from "../../../redux/actions/globalActions";
import styles from "./styles";
import _debounce from "lodash/debounce";

import TableResponsive from "../../../components/TableResponsive";
import Autocomplete from "../../../components/Autocomplete";

const breadcrumbs = [
  { name: "Home", to: "/home" },
  { name: "Patients Rentals", to: null },
];

class PatientRentalsList extends React.Component {
  state = {
    selected: [],
    data: [],
    open: false,
    patientId: "",
  };

  config = {
    columns: [
      {
        display_name: "First Name",
        name: "first_name",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "first_name",
      },
      {
        display_name: "Last Name",
        name: "last_name",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "last_name",
      },
      {
        display_name: "DOB",
        name: "birthdate",
        type: "date",
        align: "inherit",
        sorted: true,
        sorted_name: "birthdate",
      },
      {
        display_name: "City",
        name: "city",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "city",
      },
      {
        display_name: "State",
        name: "state.name",
        type: "string",
        align: "inherit",
        sorted: true,
        sorted_name: "state_name",
      },
      {
        display_name: "Primary Phone",
        name: "phone",
        type: "string",
        align: "right",
        sorted: true,
        sorted_name: "phone",
      },
      {
        display_name: "Status",
        name: "status",
        type: "custom",
        custom: {
          render: (item, column) => {
            return (
              <Typography
                color={item.status === "Active" ? "primary" : "secondary"}
              >
                {item.status}
              </Typography>
            );
          },
        },
        align: "center",
        sorted: true,
      },
      {
        display_name: "Actions",
        name: "actions",
        type: "custom",
        custom: {
          render: (item, column) => {
            const { classes } = this.props;
            return (
              <div className={classes.actionButtons}>
                <Link component={RouterLink}
                      to={{
                        pathname:`/patients/${item.id}`,
                        state: "rentals",
                      }}>
                  <IconButton aria-label="Edit" color="primary">
                    <Edit />
                  </IconButton>
                </Link>

                <IconButton
                    color="secondary"
                    aria-label="Delete"
                    onClick={() =>
                        this.setState({
                          open: true,
                          patientId: item.id,
                        })
                    }
                    disabled={this.props.loading}
                >
                  <Delete />
                </IconButton>
              </div>
            );
          },
        },
        align: "center",
      },
    ],
    pagination: true,
  };

  componentDidMount = async () => {
    try {
      await this.props.fetchPatientStates();
      this.props.setPatientIdOrigin("");

      const response = await this.fetchPatients();
      if (response.status !== 200) {
        this.props.history.push("404");
      }
    } catch (error) {
      console.log(error);
      this.props.history.push("404");
    }
  };

  componentWillUnmount() {
    this.props.setPatientFilters({
      search: "",
      status: "Active",
      state: null,
      page: 1,
    });
  }

  fetchPatients = () => {
    return this.props.getPatientsRentals();
  };

  fetchPatientsDelayed = _debounce((e) => {
    return this.fetchPatients();
  }, 1500);

  handleDelete = async () => {
    this.props.setLoading(true);
    const id = this.state.patientId;
    try {
      const { status } = await this.props.deleteRentalsPatient(id);

      if (status === 204) {
        this.props.enqueueSnackbar("The patient has been deleted!", {
          variant: "success",
        });
      } else {
        this.props.enqueueSnackbar("Patient cannot be deleted until you delete all the orders", {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.setLoading(false);
    }
    this.setState({open: false})
  };

  handleSearch = (event) => {
    //to future move to tableResponsive component
    this.props.setPatientFilters({
      search: event.target.value,
      page: 1,
    });
    this.fetchPatientsDelayed.cancel();
    this.fetchPatientsDelayed();
  };

  getCurrentState = (states, state) => {
    if (state && states.length) {
      const currentState = states.find((_state) => _state.id === state);
      return {
        value: (currentState || {}).id,
        label: (currentState || {}).name,
      };
    }
    return null;
  };

  render() {
    const { classes, patients, us_patient_states, loading } = this.props;
    const { search, open, patientId } = this.state;
    const data = patients;

    return (
      <Layout title="Patients Rentals">
        <DialogDelete
            item="patient"
            open={open}
            closeModal={() => this.setState({ open: false })}
            remove={this.handleDelete}
            isDelivery={false}
        />
        <SimpleBreadcrumbs routes={breadcrumbs} />

        <Panel>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} sm={4}>
              <Link component={RouterLink}
                    to={{
                      pathname:"/patients/create",
                      state: "rentals",
                    }}>
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                >
                  New Patient
                </Button>
              </Link>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              container
              justify="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              <Grid item xs={12} sm={4} container alignItems="flex-end">
                <Autocomplete
                  onChange={(value) => {
                    const newValue = (value || {}).value;
                    this.props.setPatientFilters({
                      state: newValue ? newValue : "",
                      page: 1,
                    });

                    this.fetchPatients();
                  }}
                  value={this.getCurrentState(
                    us_patient_states,
                    this.props.filters.state
                  )}
                  // value={filters.state_id}
                  suggestions={us_patient_states.map((state) => ({
                    value: state.id,
                    label: state.name,
                  }))}
                  placeholder="State"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Status"
                  margin="normal"
                  value={this.props.filters.status}
                  onChange={(e) => {
                    this.props.setPatientFilters({
                      status: e.target.value,
                      page: 1,
                    });
                    this.fetchPatients();
                  }}
                  select
                  // style={{ maxWidth: 150 }}
                  fullWidth
                  disabled={this.props.loading}
                  InputLabelProps={{ shrink: true }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                  <MenuItem value="Deceased">Deceased</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={4}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <Search />
                  </div>

                  <InputBase
                    name="search"
                    placeholder="Search..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    value={this.props.filters.search}
                    // disabled={loading}
                    autoFocus={true}
                    onChange={this.handleSearch}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.tableWrapper}>
            <TableResponsive
              search={search}
              items={data}
              config={this.config}
              emptyMessage="PATIENTS"
              metaFilters={{
                filters: this.props.filters,
                setFilters: this.props.setPatientFilters,
              }}
              fetchData={this.fetchPatients}
            />
          </div>
        </Panel>
      </Layout>
    );
  }
}

PatientRentalsList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.global.loading,
    patients: state.patients.list,
    filters: state.patients.filters,
    us_patient_states: state.global.us_patient_states,
  };
};
const mapDispatchToProps = {
  // getPatients,
  getPatientsRentals,
  deletePatient,
  deleteRentalsPatient,
  setLoading,
  setPatientIdOrigin,
  setPatientFilters,
  fetchPatientStates,
};

export default compose(
  withRouter,
  withSnackbar,
  withStyles(styles, { name: "PatientList" }),
  connect(mapStateToProps, mapDispatchToProps)
)(PatientRentalsList);
